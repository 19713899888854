import { FunctionComponent, useCallback } from 'react'
import styled, { css } from 'styled-components'

import useViewportTracking from '@hooks/useViewportTracking'
import { VideoRecommendedItem } from '@hooks/useRecommendations'
import Blink from '@components/Blink'
import VideoLabel from '@components/VideoLabel'

import Title from './Title'
import ImageComponent from './Image'

export type VideoRecommendationsItemProps = VideoRecommendedItem & {
  hidden?: boolean
  isFullScreen?: boolean
  onImpression?: (item: VideoRecommendedItem) => void
  onClick?: (articleId: string, item: VideoRecommendedItem) => void
}

const StyledVideoRecommendationsItem = styled(Blink)`
  display: block;
  appearance: none;
  text-decoration: none;
`

const GridWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: minmax(0, 1fr) auto;
  grid-template-columns: minmax(0, 1fr);
  align-items: flex-end;
`

const TextStack = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  align-items: flex-end;
  justify-items: flex-start;
`

const GradientStack = styled.div`
  ${({
    theme: {
      spacing: { spacing8 },
    },
  }) => css`
    box-sizing: border-box;
    width: 100%;
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    align-items: flex-start;
    justify-items: flex-start;
    padding: 0 ${spacing8} ${spacing8} ${spacing8};

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      background: linear-gradient(
        186.3deg,
        rgba(0, 0, 0, 0) 34.56%,
        rgba(0, 0, 0, 0.75) 82.5%
      );
    }
  `}
`

const StyledVideoLabel = styled(VideoLabel)`
  ${({
    theme: {
      spacing: { spacing12 },
    },
  }) => css`
    margin-bottom: ${spacing12};
  `}
`

const VideoRecommendationsItem: FunctionComponent<
  VideoRecommendationsItemProps
> = ({ onImpression, onClick, hidden, isFullScreen, ...item }) => {
  const { title, link, image, targetContentType, video, aureusOfferId } = item

  const onClickHandler = useCallback(() => {
    if (onClick) {
      onClick(item.articleId, item)
    }
  }, [onClick, item])

  const onImpressionHandler = useCallback(() => {
    if (onImpression) {
      onImpression(item)
    }
  }, [onImpression, item])

  const viewportRef = useViewportTracking({
    track: !hidden,
    onImpression: onImpressionHandler,
  })

  const clickableProps = {
    ...link,
    aureusOfferId,
    onClick,
    ...(link?.title ? { ariaLabel: link.title } : {}),
  }

  return (
    <StyledVideoRecommendationsItem
      {...clickableProps}
      ref={viewportRef}
      onClick={onClickHandler}>
      <ImageComponent {...image}>
        <GridWrapper>
          <TextStack>
            <GradientStack>
              {!!video && (
                <StyledVideoLabel
                  duration={video.duration}
                  targetContentType={targetContentType}
                />
              )}
              <Title isFullScreen={isFullScreen}>{title}</Title>
            </GradientStack>
          </TextStack>
        </GridWrapper>
      </ImageComponent>
    </StyledVideoRecommendationsItem>
  )
}

export default VideoRecommendationsItem
