import LanguageSwitcher from '@components/LanguageSwitcher'
import useIsContentForceCentered from '@hooks/useIsContentForceCentered'
import {
  desktopCSS,
  desktopCenteredContentCSS,
  desktopLeftAlignedCSS,
  mobileCSS,
  tabletCSS,
} from '@measures/responsive'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import BurgerMenu from './BurgerMenu'
import LeftButtonsContainer from './LeftButtonsContainer'
import Logo from './Logo'
import SearchInput from './SearchInput'
import UserMenu from './UserMenu'
import Weather from './Weather'
import OfferPageLink from './OfferPageLink'
import GeolocationIndicator from '@components/Header/FirstRow/GeolocationIndicator'
import LeserreporterLink from './LeserreporterLink'
import { printCSS } from '@utils/style'
import useIsImmersiveHeaderActive from '@hooks/useIsImmersiveHeaderActive'
import config from '@config'
import useGetPageIdentity from '@hooks/useGetPageIdentity'

const {
  publication: { publication },
} = config

const StyledFirstRow = styled.div<{
  isContentForceCentered: boolean
  isImmersiveHeaderActive: boolean
  isHerotelling: boolean
}>`
  ${({
    theme: {
      color: {
        primary: { primary02: primary02Color },
      },
    },
    isContentForceCentered,
    isImmersiveHeaderActive,
    isHerotelling,
  }) => css`
    background-color: ${primary02Color};
    transition: background-color 0.5s linear;

    z-index: 2;
    position: relative;

    display: grid;
    grid-template-columns: 994px;
    align-items: center;
    height: 80px;

    ${isImmersiveHeaderActive &&
    css`
      ${mobileCSS(css`
        background-color: transparent;
      `)}
      ${tabletCSS(css`
        background-color: transparent;
      `)}
    `}

    ${desktopCSS(css`
      justify-content: center;
      ${!isHerotelling &&
      desktopLeftAlignedCSS(css`
        ${!isContentForceCentered &&
        css`
          justify-content: flex-start;
        `};
      `)};
    `)};

    ${desktopCenteredContentCSS(css`
      justify-content: center;
    `)}

    ${isContentForceCentered &&
    css`
      justify-content: center;
    `}

    ${tabletCSS(css`
      grid-template-columns: 1fr;
      height: 48px;
    `)}

    ${mobileCSS(css`
      grid-template-columns: 1fr;
      height: 48px;
    `)}
  `}
`

const MainContainer = styled.div`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => css`
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    padding: 0 ${spacing16};

    ${desktopCSS(css`
      padding: 0 15px;
    `)}
  `}
`

const StyledLanguageSwitcher = styled(LanguageSwitcher)`
  ${mobileCSS(css`
    display: none;
  `)}
  ${tabletCSS(css`
    display: none;
  `)}
`

const StyledWeather = styled(Weather)`
  ${mobileCSS(css`
    display: none;
  `)}
  ${tabletCSS(css`
    display: none;
  `)}
`

const StyledSearchInput = styled(SearchInput)`
  ${mobileCSS(css`
    display: none;
  `)}
  ${tabletCSS(css`
    display: none;
  `)}
`

const RightButtonsContainer = styled.div`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => css`
    justify-self: flex-end;
    display: grid;
    grid-auto-flow: column;
    align-items: center;

    ${printCSS(css`
      display: none;
    `)}

    ${desktopCSS(css`
      grid-gap: ${spacing16};
    `)}
  `}
`

const FirstRow: FunctionComponent<{ forceCenter?: boolean }> = ({
  forceCenter,
}) => {
  const { isHerotelling } = useGetPageIdentity()
  const isContentForceCentered = useIsContentForceCentered()
  const isImmersiveHeaderActive = useIsImmersiveHeaderActive()

  return (
    <StyledFirstRow
      isContentForceCentered={!!forceCenter || isContentForceCentered}
      isImmersiveHeaderActive={isImmersiveHeaderActive}
      isHerotelling={isHerotelling}>
      <GeolocationIndicator />
      <MainContainer>
        <LeftButtonsContainer>
          <BurgerMenu />
          <StyledSearchInput />
          {publication !== 'romandie' && <LeserreporterLink />}
          <StyledWeather />
        </LeftButtonsContainer>
        <Logo />
        <RightButtonsContainer>
          <StyledLanguageSwitcher />
          {publication !== 'romandie' && <OfferPageLink />}
          <UserMenu />
        </RightButtonsContainer>
      </MainContainer>
    </StyledFirstRow>
  )
}

export default FirstRow
