import { ConfigProps } from '@config/types'

const config = ({}: ConfigProps) =>
  ({
    projectId: '43892',
    nonBlickPlusSubscriberContentId: 'bb3148ba-857b-4733-8197-a5c4ffe04877',
    informationLink: 'https://www.blick.ch/id19711657.html',
  }) as const satisfies Record<string, unknown>

export default config
