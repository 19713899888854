import { FunctionComponent, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { mobileCSS } from '@measures/responsive'
import translate from '@i18n'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import useViewportTracking from '@hooks/useViewportTracking'
import {
  videoElementClickHandler,
  videoElementImpressionHandler,
} from '@hooks/useVideoTracking/eventMapper'
import { VideoElementTrackingParams } from '@widgets/Video/types'
import useScrollToCliffhanger from '@hooks/useScrollToCliffhanger'
import MainText from '@components/Video/Trailer/MainText'
import StyledButton from '@components/Video/Trailer/Button'

interface TrailerOverlayProps {
  trackingInfo: {
    videoTitle: string
    videoId: string
  }
}

const StyledOverlayWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 8;
  width: 100%;
  height: 100%;
  background-color: black;
`
const StyledOverlayContainer = styled.div`
  ${({
    theme: {
      spacing: { spacing24 },
      color: {
        primary: { primary02 },
      },
    },
  }) => {
    return css`
      max-width: 500px;
      padding: 0 ${spacing24};
      color: ${primary02};
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: black;
      ${mobileCSS(css`
        max-width: unset;
        width: 100%;
      `)}
    `
  }}
`

const TrailerBlockerOverlay: FunctionComponent<TrailerOverlayProps> = ({
  trackingInfo,
}) => {
  const onImpression = useCallback<TrackingFnType>(
    () =>
      videoElementImpressionHandler({
        extraData: {
          ...trackingInfo,
          element: 'trailer_blocker',
        },
      }),
    [trackingInfo]
  )

  const trackedOnImpression = useTracking(onImpression)

  const viewportRef = useViewportTracking({
    onImpression: trackedOnImpression,
    track: true,
  })

  const onClick = useCallback<
    TrackingFnType<Pick<VideoElementTrackingParams, 'buttonText'>>
  >(
    ({ extraData }) =>
      videoElementClickHandler({
        extraData: {
          element: 'trailer_blocker',
          buttonText: extraData.buttonText,
          ...trackingInfo,
        },
      }),
    [trackingInfo]
  )

  const trackedOnClick = useTracking(onClick)
  const handleScroll = useScrollToCliffhanger(trackedOnClick)

  return (
    <StyledOverlayWrapper ref={viewportRef}>
      <StyledOverlayContainer>
        <MainText>{translate(`video.blickPlus.mainText`)}</MainText>
        <StyledButton size="large" onClick={handleScroll}>
          {translate('video.blickPlus.buttonLabel')}
        </StyledButton>
      </StyledOverlayContainer>
    </StyledOverlayWrapper>
  )
}

export default TrailerBlockerOverlay
