import Script from 'next/script'
import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import config from '@config'
import useCMPHasUserInteracted from '@hooks/useCMPHasUserInteracted'
import { useQueryClient } from '@tanstack/react-query'

const {
  video: { playerScript },
} = config

const VideoManager: FunctionComponent = () => {
  const queryClient = useQueryClient()
  const [loadLibrary, setLoadLibrary] = useState(false)

  const onJWPlayerScriptLoaded = useCallback(() => {
    queryClient.setQueryData(['has-jwplayer-script-loaded'], true)
    queryClient.invalidateQueries({
      queryKey: ['has-jwplayer-script-loaded'],
      exact: true,
    })
  }, [queryClient])

  useEffect(() => {
    queryClient.setQueryData(['trigger-jwplayer-script-loading-func'], () => {
      setLoadLibrary(true)
    })
  }, [queryClient])

  const hasUserInteracted = useCMPHasUserInteracted()

  return hasUserInteracted && loadLibrary ? (
    <Script
      id="JWPlayer"
      strategy="afterInteractive"
      defer={true}
      src={playerScript}
      onLoad={onJWPlayerScriptLoaded}
    />
  ) : null
}

export default VideoManager
