import { useRef, useState, useCallback, useEffect, useMemo } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { SubscriptionData } from '@components/Auth/types'

export type SubscriptionStatus =
  | 'subscribed'
  | 'notSubscribed'
  | 'notSpecified' // In case of error on the subscription checker
  | undefined // When the subscription data is not fetched yet

export type UseSubscriptionStatus = () => {
  subscriptionStatus: SubscriptionStatus
  getCurrentSubscriptionStatus: () => SubscriptionStatus
}

const useSubscriptionStatus: UseSubscriptionStatus = () => {
  const queryClient = useQueryClient()
  const queryCache = queryClient.getQueryCache()
  const unsubscribeFnRef = useRef<() => void>()

  const getCurrentSubscriptionStatus = useCallback(
    () =>
      queryClient.getQueryData<SubscriptionData>(['subscription-data'])
        ?.user_status,
    [queryClient]
  )

  const [subscriptionStatus, setSubscriptionStatus] =
    useState<SubscriptionStatus>(() => getCurrentSubscriptionStatus())

  const updateSubscriptionStatus = useCallback(
    (args: any) => {
      if (
        args?.action?.type === 'invalidate' &&
        args.query.queryKey[0] === 'subscription-data'
      ) {
        setSubscriptionStatus(getCurrentSubscriptionStatus())
      }
    },
    [getCurrentSubscriptionStatus]
  )

  useEffect(() => {
    setSubscriptionStatus(getCurrentSubscriptionStatus())
    unsubscribeFnRef.current = queryCache.subscribe(updateSubscriptionStatus)

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [
    updateSubscriptionStatus,
    queryCache,
    queryClient,
    getCurrentSubscriptionStatus,
  ])

  return useMemo(
    () => ({ subscriptionStatus, getCurrentSubscriptionStatus }),
    [subscriptionStatus, getCurrentSubscriptionStatus]
  )
}

export default useSubscriptionStatus
