import SvgIcon from '@components/SvgIcon'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { IconButtonProps, StyledIconButtonProps } from './types'

const StyledIconButton = styled.button<StyledIconButtonProps>`
  ${({ buttonSize }) => css`
    width: ${buttonSize ? `${buttonSize}px` : '48px'};
    height: ${buttonSize ? `${buttonSize}px` : '48px'};
    border: 0;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    line-height: 0;
  `}
`

const IconButton: FunctionComponent<IconButtonProps> = ({
  onClick,
  ariaLabel,
  iconName,
  iconColor,
  iconSize,
  buttonSize,
  className,
  type,
}) => {
  return (
    <StyledIconButton
      onClick={onClick}
      aria-label={ariaLabel}
      buttonSize={buttonSize}
      className={className}
      type={type}>
      <SvgIcon iconName={iconName} color={iconColor} size={iconSize} />
    </StyledIconButton>
  )
}

export default IconButton
