import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { desktopCSS } from '@measures/responsive'

interface CaptionProps {
  children: string
  isFullScreen?: boolean
}

type StyledCaptionProps = Pick<CaptionProps, 'isFullScreen'>

const CaptionText = styled.div<StyledCaptionProps>`
  ${({
    theme: {
      typography: {
        bodycopy: {
          xxsmall: { bundledCSS: xxsmallBodycopyCSS },
        },
      },
      color: {
        primary: { primary01: primary01Color, primary02: primary02Color },
      },
      utils: { ellipsis },
    },
    isFullScreen,
  }) => css`
    ${xxsmallBodycopyCSS};
    color: ${isFullScreen ? primary02Color : primary01Color};

    ${ellipsis('100%', 3)};

    ${desktopCSS(css`
      ${isFullScreen &&
      css`
        color: ${primary01Color};
      `}
    `)}
  `}
`

const Caption: FunctionComponent<CaptionProps> = ({
  children,
  isFullScreen,
}) => <CaptionText isFullScreen={isFullScreen}>{children}</CaptionText>

export default Caption
