import { FunctionComponent } from 'react'
import useScoreboard from '@hooks/useScoreboard'
import styled, { css } from 'styled-components'
import { desktopCSS } from '@measures/responsive'
import useViewportType from '@hooks/useViewport/useViewportType'
import { LIVE_EVENT_DATA_SPORT_TYPES } from '@utils/formatters/common'
import ScoreboardTeamLogo from './ScoreboardTeamLogo'
import ScoreboardOpponentSeparator from './ScoreboardOpponentSeparator'

interface StyledTennisScoreTableProps {
  numberOfGamesCompleted: number
  hasGameInProgress: boolean
  shouldShowOpponentSeparator: boolean
}

interface StyledSetScoreProps {
  isPending: boolean
  isWinning: boolean
}

interface StyledCompetitorNameProps {
  isWinner: boolean
  isAway?: boolean
}

const Divider = styled.div`
  ${({
    theme: {
      color: {
        tertiary: { grey1000: grey1000Color },
      },
      spacing: { spacing12, spacing16 },
    },
  }) => css`
    border-bottom: 1px solid ${grey1000Color};
    margin-bottom: ${spacing16};
    margin-top: ${spacing12};
  `}
`

const StyledScoreboardMatchResult = styled.div`
  width: 100%;
`

const StyledCompetitionLocation = styled.div`
  align-items: center;
  display: flex;
`

const StyledCompetitionOverview = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledTournamentName = styled.div`
  ${({
    theme: {
      typography: {
        headings: {
          small: { bundledCSS: smallHeadingsCSS },
        },
      },
      color: {
        primary: { primary01: primary01Color },
      },
      utils: { ellipsis },
    },
  }) => css`
    ${ellipsis('100%', 2)};
    color: ${primary01Color};
    ${desktopCSS(css`
      max-width: 200px;
    `)}
    ${smallHeadingsCSS};
  `}
`

const StyledLocation = styled.div`
  ${({
    theme: {
      spacing: { spacing8 },
      color: {
        primary: { primary01: primary01Color },
      },
      typography: {
        eyebrows: {
          medium2: { bundledCSS: medium2EyebrowsCSS },
        },
      },
    },
  }) => css`
    text-transform: uppercase;
    margin: 0 ${spacing8};
    color: ${primary01Color};
    ${medium2EyebrowsCSS};
  `}
`

const FlexWrapper = styled.div<{
  isAwayPlayer?: boolean
  shouldShowOpponentSeparator?: boolean
}>`
  ${({ isAwayPlayer, shouldShowOpponentSeparator }) => css`
    display: flex;
    justify-self: ${isAwayPlayer && !!shouldShowOpponentSeparator
      ? `end`
      : `start`};
  `}
`

const StyledTennisScoreTable = styled.div<StyledTennisScoreTableProps>`
  ${({
    theme: {
      spacing: { spacing4, spacing12 },
    },
    numberOfGamesCompleted,
    hasGameInProgress,
    shouldShowOpponentSeparator,
  }) => {
    const gridColumnNumber =
      1 + numberOfGamesCompleted + (hasGameInProgress ? 1 : 0)
    return css`
      display: grid;
      align-items: baseline;
      width: 100%;
      box-sizing: border-box;
      grid-template-columns: ${shouldShowOpponentSeparator
        ? ` minmax(0, 1fr) auto minmax(0, 1fr)`
        : ` minmax(0, 1fr) repeat(
          ${gridColumnNumber - 1},
          auto
        )`};
      grid-column-gap: ${spacing4};
      grid-row-gap: ${spacing12};
    `
  }}
`

const StyledSetScore = styled.div<StyledSetScoreProps>`
  ${({
    theme: {
      color: {
        tertiary: { grey700: grey700Color },
        primary: { primary01: primary01Color },
      },
      typography: {
        eyebrows: {
          medium1: { bundledCSS: medium1EyebrowsCSS },
        },
      },
      spacing: { spacing12, spacing16 },
    },
    isWinning,
  }) => css`
    justify-self: flex-end;
    text-align: center;
    color: ${isWinning ? primary01Color : grey700Color};
    min-width: ${spacing12};
    ${desktopCSS(css`
      min-width: ${spacing16};
    `)}
    ${medium1EyebrowsCSS};
  `}
`

const StyledCompetitorName = styled.div<StyledCompetitorNameProps>`
  ${({
    theme: {
      typography: {
        headings: {
          xsmall: { bundledCSS: headingXSmall },
          xxsmall: { bundledCSS: headingXXSmall },
        },
      },
      color: {
        primary: { primary01: primary01Color },
        secondary: { greenAccessible: greenAccessibleColor },
      },
    },
    isWinner,
    isAway,
  }) => css`
    color: ${isWinner ? greenAccessibleColor : primary01Color};
    ${headingXXSmall};
    ${desktopCSS(css`
      ${headingXSmall};
    `)};
    ${isAway && `text-align: right`};
  `}
`

const StyledCompetitorsDivider = styled.div`
  ${({
    theme: {
      color: {
        tertiary: { grey300: grey300Color },
      },
    },
  }) => css`
    border-top: 1px solid ${grey300Color};
    grid-column: 1 / -1;
  `}
`

const StyledGameScore = styled.div`
  ${({
    theme: {
      color: {
        secondary: { greenAccessible: greenAccessibleColor },
      },
      typography: {
        eyebrows: {
          medium2: { bundledCSS: medium2EyebrowsCSS },
        },
      },
      spacing: { spacing12, spacing16 },
    },
  }) => css`
    color: ${greenAccessibleColor};
    justify-self: flex-start;
    text-align: center;
    height: ${spacing12};
    ${desktopCSS(css`
      height: ${spacing16};
    `)}
    ${medium2EyebrowsCSS};
  `}
`

const { TENNIS } = LIVE_EVENT_DATA_SPORT_TYPES

const ScoreboardMatchResultTennis: FunctionComponent = () => {
  const { formattedData } = useScoreboard<typeof TENNIS>()
  const viewportType = useViewportType()
  const isMobile = viewportType === 'mobile'

  const {
    competitors,
    countryLogoSrc,
    tournamentName,
    location,
    periodScores,
    gameState,
    isScheduled,
    isCancelled,
  } = formattedData ?? {}

  const [homePlayer, awayPlayer] = competitors

  const shouldShowOpponentSeparator = !!isScheduled || !!isCancelled

  return (
    <StyledScoreboardMatchResult>
      <StyledCompetitionOverview>
        <StyledTournamentName>{tournamentName}</StyledTournamentName>
        <StyledCompetitionLocation>
          <StyledLocation>{location}</StyledLocation>
          {!!countryLogoSrc && (
            <ScoreboardTeamLogo
              name={location}
              size={isMobile ? 32 : 40}
              url={countryLogoSrc}
            />
          )}
        </StyledCompetitionLocation>
      </StyledCompetitionOverview>
      <Divider />
      <StyledTennisScoreTable
        numberOfGamesCompleted={periodScores?.length}
        hasGameInProgress={!!gameState}
        shouldShowOpponentSeparator={shouldShowOpponentSeparator}>
        <FlexWrapper>
          <StyledCompetitorName isWinner={homePlayer?.isWinner}>
            {homePlayer?.name}
            {homePlayer?.seed}
          </StyledCompetitorName>
        </FlexWrapper>
        {!shouldShowOpponentSeparator &&
          periodScores?.map((tennisSet) => (
            <StyledSetScore
              key={tennisSet.number}
              isWinning={tennisSet.winningScore === 'home'}
              isPending={!tennisSet.winningScore}>
              {tennisSet.home_score}
            </StyledSetScore>
          ))}
        {gameState && (
          <StyledGameScore>{gameState.sdpHomeScore}</StyledGameScore>
        )}
        {isScheduled || isCancelled ? (
          <ScoreboardOpponentSeparator isTennis={true} />
        ) : (
          <StyledCompetitorsDivider />
        )}
        <FlexWrapper
          isAwayPlayer={true}
          shouldShowOpponentSeparator={shouldShowOpponentSeparator}>
          <StyledCompetitorName isAway={true} isWinner={awayPlayer?.isWinner}>
            {awayPlayer?.name}
            {awayPlayer?.seed}
          </StyledCompetitorName>
        </FlexWrapper>
        {!shouldShowOpponentSeparator &&
          periodScores?.map((tennisSet) => (
            <StyledSetScore
              key={tennisSet.number}
              isWinning={tennisSet.winningScore === 'away'}
              isPending={!tennisSet.winningScore}>
              {tennisSet.away_score}
            </StyledSetScore>
          ))}
        {!shouldShowOpponentSeparator && gameState && (
          <StyledGameScore>{gameState.sdpAwayScore}</StyledGameScore>
        )}
      </StyledTennisScoreTable>
    </StyledScoreboardMatchResult>
  )
}

export default ScoreboardMatchResultTennis
