import Blink from '@components/Blink'
import useTrackingUtils from '@components/Header/useTrackingUtils'
import useIsImmersiveHeaderActive from '@hooks/useIsImmersiveHeaderActive'
import { mobileCSS, tabletCSS } from '@measures/responsive'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

interface FirstLevelLinkProps {
  children: string
  href: string
}

const StyledFirstLevelLink = styled(Blink)<{
  isImmersiveHeaderActive: boolean
}>`
  ${({
    theme: {
      typography: {
        labels: {
          label2: { bundledCSS: label2LabelsCSS },
        },
      },
      color: {
        primary: { primary01: primary01Color, primary02: primary02Color },
      },
    },
    isImmersiveHeaderActive,
  }) => css`
    ${label2LabelsCSS}
    color: ${primary01Color};

    appearance: none;
    text-decoration: none;
    white-space: nowrap;

    ${isImmersiveHeaderActive &&
    css`
      ${mobileCSS(css`
        color: ${primary02Color};
      `)}
      ${tabletCSS(css`
        color: ${primary02Color};
      `)}
    `}
  `}
`

const FirstLevelLink: FunctionComponent<FirstLevelLinkProps> = ({
  href,
  children,
}) => {
  const isImmersiveHeaderActive = useIsImmersiveHeaderActive()
  const { navbarClickTrackingFn } = useTrackingUtils({
    title: children,
    url: href,
  })
  return (
    <StyledFirstLevelLink
      href={href}
      ariaLabel={children}
      onClick={navbarClickTrackingFn}
      isImmersiveHeaderActive={isImmersiveHeaderActive}>
      {children}
    </StyledFirstLevelLink>
  )
}

export default FirstLevelLink
