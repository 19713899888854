import { memo, FunctionComponent, MouseEventHandler } from 'react'
import styled, { css, useTheme } from 'styled-components'

import SvgIcon from '../../../SvgIcon'
import StyledButton from '../StyledButton'

export interface GoToLiveButtonProps {
  label: string
  onClick: MouseEventHandler<HTMLButtonElement>
}

const StyledGoToLiveButton = styled(StyledButton)`
  ${({
    theme: {
      colors: {
        blicktvGrey: { btvGrey_60 },
      },
    },
  }) => css`
    background: ${btvGrey_60};
  `}
`

const RestartIcon: FunctionComponent<{}> = () => {
  const theme = useTheme()

  return (
    <SvgIcon
      iconName="video-fast-forward"
      size={16}
      color={theme.colors.white}
    />
  )
}

const GoToLiveButton: FunctionComponent<GoToLiveButtonProps> = ({
  onClick,
  label,
}) => {
  return (
    <StyledGoToLiveButton onClick={onClick}>
      <RestartIcon />
      {label}
    </StyledGoToLiveButton>
  )
}

export default memo(GoToLiveButton)
