import Script from 'next/script'
import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import {
  getHasUserConcludedConsent,
  updateCMPCookieCategories,
  updateCMPHasUserInteracted,
  logCMP,
  mockCMP,
} from './utils'
import config from '@config'

interface CMPManagerProps {
  isStorybook?: boolean
}

const {
  cmp: { scriptUrl: cmpScriptUrl, scriptDomain: cmpScriptDomain },
} = config

const isInsideIframe = (window: any) => {
  try {
    return window.self !== window.top
  } catch (err) {
    // Assume it's in an iframe if there's an error
    return true
  }
}

const isNonBlickLocalhostDomain = (window: any) => {
  try {
    return (
      window.location.protocol === 'http:' &&
      !window.location.hostname.endsWith('blick.ch')
    )
  } catch (err) {
    // Err on the side of caution and return false
    return false
  }
}

const CMPManager: FunctionComponent<CMPManagerProps> = ({ isStorybook }) => {
  const [loadCMPScript, setLoadCMPScript] = useState(false)
  const queryClient = useQueryClient()

  const onConsentChanged = useCallback(() => {
    logCMP(queryClient, 'OneTrust onConsentChanged trigerred!')
    if (window.OneTrust.IsAlertBoxClosed() && getHasUserConcludedConsent()) {
      logCMP(queryClient, 'Alert box closed and user consent concluded!')
      updateCMPCookieCategories(queryClient)
      updateCMPHasUserInteracted(queryClient)
    } else {
      if (!window.OneTrust.IsAlertBoxClosed()) {
        logCMP(queryClient, 'Alert box not closed!')
      }
      if (!getHasUserConcludedConsent()) {
        logCMP(queryClient, 'Aser consent not concluded!')
      }
    }
  }, [queryClient])

  useEffect(() => {
    const hasOnConsentCallbackRegistered = !!queryClient.getQueryData<boolean>([
      'cmp-has-on-consent-callback-registered',
    ])

    if (!hasOnConsentCallbackRegistered) {
      queryClient.setQueryData(['cmp-has-on-consent-callback-registered'], true)

      if (window.OneTrust?.OnConsentChanged) {
        window.OneTrust.OnConsentChanged(onConsentChanged)
        onConsentChanged()
      } else {
        const existingOptanonWrapper = window.OptanonWrapper
        window.OptanonWrapper = () => {
          existingOptanonWrapper()
          window.OneTrust.OnConsentChanged(onConsentChanged)
          onConsentChanged()
        }
      }
    }
  }, [onConsentChanged, queryClient])

  useEffect(() => {
    if (
      isStorybook ||
      isNonBlickLocalhostDomain(window) ||
      isInsideIframe(window)
    ) {
      mockCMP(queryClient, window)
    } else {
      setLoadCMPScript(true)
    }
  }, [isStorybook, queryClient])

  return loadCMPScript ? (
    <Script
      id="CMP"
      strategy="afterInteractive"
      defer={true}
      src={cmpScriptUrl}
      data-domain-script={cmpScriptDomain}
      data-document-language="true"
    />
  ) : null
}

export default CMPManager
