import { FunctionComponent } from 'react'
import { formatDate } from '@utils/date'
import { PostedCommentingItemProps } from './types'
import translate from '@i18n'
import AnswerIndicator from './AnswerIndicator'
import ItemContent from './ItemContent'
import ItemHeader from './ItemHeader'
import styled, { css } from 'styled-components'
import DatePublished from './DatePublished'
import TextContent from './TextContent'
import ItemSuperUserIndicator from './ItemSuperUserIndicator'
import ActionsResponding from './ActionsResponding'
import {
  getGenderAdjustedSuperUserTitle,
  getIsAuthorTypeSuperUser,
} from './utils'
import CommentingSecondaryButton from '@widgets/Commenting/CommentingButtons/CommentingSecondaryButton'

const AuthorName = styled.div`
  ${({
    theme: {
      typography: {
        headings: {
          xsmall: { bundledCSS: headingXSmallCSS },
        },
      },
    },
  }) => css`
    ${headingXSmallCSS};
  `}
`

const ItemActions = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const PostedCommentingItem: FunctionComponent<PostedCommentingItemProps> = ({
  type,
  authorType,
  gender,
  authorName,
  datePublished,
  textContent,
  onDelete,
}) => {
  const isSuperUser = getIsAuthorTypeSuperUser(authorType)

  return (
    <>
      {type === 'answer' && <AnswerIndicator />}
      <ItemContent type={type}>
        {isSuperUser ? (
          <ItemSuperUserIndicator
            userTitle={getGenderAdjustedSuperUserTitle(authorType, gender)}
          />
        ) : null}
        <ItemHeader>
          <AuthorName>{authorName}</AuthorName>
          <DatePublished>
            {formatDate(datePublished)
              ? (formatDate(datePublished) as any)?.dateOrTime
              : ''}
          </DatePublished>
        </ItemHeader>
        <TextContent>{textContent}</TextContent>
        <ItemActions>
          <ActionsResponding>
            <CommentingSecondaryButton onClick={onDelete} size="small">
              {translate('commenting.delete')}
            </CommentingSecondaryButton>
          </ActionsResponding>
        </ItemActions>
      </ItemContent>
    </>
  )
}

export default PostedCommentingItem
