import { FunctionComponent, useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import config from '@config'
import { generateHelpers } from './utils'

const {
  backend: { processedReleaseVersion },
  debug: { windowKey },
} = config

const DebugManager: FunctionComponent = () => {
  const queryClient = useQueryClient()

  useEffect(() => {
    const isRegistered = queryClient.getQueryData<boolean>([
      'debug',
      'isRegistered',
    ])

    if (!isRegistered) {
      queryClient.setQueryData(
        ['debug', 'client', 'version'],
        processedReleaseVersion
      )

      window[windowKey] = generateHelpers(queryClient)

      queryClient.setQueryData(['debug', 'isRegistered'], true)
      queryClient.invalidateQueries({ queryKey: ['debug'] })
    }
  })

  return null
}

export default DebugManager
