import {
  useMemo,
  MouseEvent,
  FunctionComponent,
  useState,
  useCallback,
  useRef,
  useEffect,
} from 'react'
import useVideoTracking from '@hooks/useVideoTracking'
import useLivestreamStoryContext from './useLivestreamStoryContext'
import config from '@config'
import BlickTVStoryComponent, * as BlickTVStoryComponents from '@components/Video/BlickTVStory'
import translate from '@i18n'
import { ChapterAPIResponse, APIChapter, Chapter } from './types'
import {
  calculateChapterTimelinePosition,
  normalizePlayerCurrentTimeValue,
  hasChaptersPlaceholder,
} from './utils'
import { getPlayerType, formatDuration } from '@widgets/Video/utils'
import { PlayableTeaserVideo } from '@widgets/BlickTVTeaser/types'
import { useQuery } from '@tanstack/react-query'
import useIsSwissGeolocation from '@hooks/useIsSwissGeolocation'
import {
  getJWPlayer,
  playerSeekTo,
  playerSeekToLive,
  setPlayerGeoBlockedError,
} from '@utils/videoPlayer'

const { StoryWrapper } = BlickTVStoryComponents

const {
  chaptersApiUrl,
  story: {
    dvrWindow,
    pollingIntervalInMilliseconds,
    showGoToLiveDelayInMilliseconds,
  },
} = config.video

export type LivestreamStoryProps = {
  title: string
  video: Pick<
    PlayableTeaserVideo,
    'isBlickTV' | 'duration' | 'position' | 'videoId' | 'jwVideoId'
  >
  hidden?: boolean
}

const formatChapters = (chapters: APIChapter[]): Chapter[] =>
  chapters.reverse().map((chapter: APIChapter, index: number) => ({
    ...chapter,
    duration: formatDuration(chapter?.length),
    isLive: index === chapters?.length - 1, // the last chapter should be live
    image: chapter?.filename
      ? {
          src: `/chapters/${chapter?.filename}`,
          alt: chapter?.headline,
        }
      : undefined,
  }))

const getChapters = async (): Promise<Chapter[]> => {
  const res = await fetch(`${chaptersApiUrl}?dvr=${dvrWindow}`)
  const data: ChapterAPIResponse = await res.json()

  return data ? formatChapters(data) : []
}

const LivestreamStory: FunctionComponent<LivestreamStoryProps> = (props) => {
  const {
    chapters,
    setChapters,
    activeChapter,
    setActiveChapter,
    timelinePointers,
    widgetId,
    setUserLastSelectedTimestamp,
  } = useLivestreamStoryContext()

  const swiperRef = useRef<BlickTVStoryComponents.SwiperClass | null>(null)

  const [showRestartButton, setShowRestartButton] = useState<boolean>(false)
  const [showGoToLiveButton, setShowGoToLiveButton] = useState<boolean>(false)

  const isGeoBlockedError = useRef<boolean>(false)

  const showGoToLiveButtonTimeoutRef = useRef<ReturnType<typeof setTimeout>>()

  const {
    title,
    video: { isBlickTV, position, duration, videoId, jwVideoId },
  } = props || {}

  const playerType = getPlayerType({ isBlickTV, duration })

  const trackingCallbacks = useVideoTracking({
    isBlickTV,
    position,
    autoplay: true, // autoplay is always enabled for livestream
    widgetId,
    playerType,
    videoId,
    jwVideoId,
    title,
    duration,
    startMuted: true,
  })

  const { data: chaptersData } = useQuery<Chapter[]>({
    queryKey: ['chapters'],
    queryFn: () => getChapters(),
    refetchInterval: pollingIntervalInMilliseconds,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: true,
  })

  const isSwissGeolocation = useIsSwissGeolocation()

  useEffect(() => {
    if (chaptersData) {
      setChapters(chaptersData)
    }
  }, [chaptersData, setChapters])

  const activeChapterIndex = useMemo(
    () =>
      activeChapter?.id && chapters
        ? chapters?.findIndex((chapter) => chapter?.id === activeChapter?.id)
        : -1,
    [activeChapter?.id, chapters]
  )

  const seekTo = useCallback(
    (seconds: number) => {
      const jwPlayer = getJWPlayer(widgetId)

      if (jwPlayer) {
        playerSeekTo(jwPlayer, seconds)
      }
    },
    [widgetId]
  )

  const seekToLive = useCallback(() => {
    const jwPlayer = getJWPlayer(widgetId)

    if (jwPlayer) {
      playerSeekToLive(jwPlayer)
    }
  }, [widgetId])

  const slideTo = useCallback((slideIndex: number) => {
    swiperRef.current?.slideTo(slideIndex)
  }, [])

  const seekToChapterPosition = useCallback(
    (chapterData?: Chapter) => {
      if (chapterData) {
        const jwPlayer = getJWPlayer(widgetId)

        if (!jwPlayer || !chapterData.timestamp) {
          return
        }

        const playerNewCurrentTimeValue = calculateChapterTimelinePosition(
          chapterData.timestamp,
          timelinePointers
        )

        const seekToPosition = normalizePlayerCurrentTimeValue(
          playerNewCurrentTimeValue,
          jwPlayer
        )

        seekTo(seekToPosition)
      }
    },
    [widgetId, seekTo, timelinePointers]
  )

  const setLastChapterAsActive = useCallback(() => {
    if (chapters?.length && !hasChaptersPlaceholder(chapters)) {
      const lastChapter = chapters[chapters.length - 1]

      if (lastChapter) {
        setActiveChapter(lastChapter)
      }
    }
  }, [chapters, setActiveChapter])

  const makeActiveChapterVisibleWithinView = useCallback(() => {
    const hasActiveSlide = activeChapterIndex >= 0

    if (hasActiveSlide) {
      const currentActiveIndex = swiperRef.current?.activeIndex
      const isSlideVisible =
        activeChapterIndex === currentActiveIndex ||
        activeChapterIndex - 1 === currentActiveIndex ||
        activeChapterIndex + 1 === currentActiveIndex

      if (!isSlideVisible) {
        slideTo(activeChapterIndex)
      }
    }
  }, [activeChapterIndex, slideTo])

  const setGeoBlockedError = useCallback(
    (showError: boolean) => {
      const jwPlayer = getJWPlayer(widgetId)

      if (jwPlayer) {
        setPlayerGeoBlockedError(jwPlayer, showError)

        if (showError) {
          jwPlayer.pause()
        } else {
          seekToChapterPosition(activeChapter)
          jwPlayer.play()
        }
      }

      isGeoBlockedError.current = showError
    },
    [activeChapter, widgetId, seekToChapterPosition]
  )

  // set last chapter as initial value for activeChapter
  useEffect(() => {
    if (!activeChapter?.id) {
      setLastChapterAsActive()
    }
  }, [activeChapter?.id, setLastChapterAsActive])

  useEffect(() => {
    if (activeChapter?.id) {
      makeActiveChapterVisibleWithinView()
    }
  }, [activeChapter?.id, makeActiveChapterVisibleWithinView])

  useEffect(() => {
    if (!isSwissGeolocation && activeChapter?.id) {
      if (activeChapter?.is_geoblocked) {
        setGeoBlockedError(true)
      } else {
        if (isGeoBlockedError.current) {
          setGeoBlockedError(false)
        }
      }
    }
  }, [
    isSwissGeolocation,
    activeChapter?.id,
    activeChapter?.is_geoblocked,
    setGeoBlockedError,
  ])

  // show GoToLiveButton if activeChapter is not live (with delay 5 sec)
  useEffect(() => {
    if (activeChapter && !activeChapter?.isLive && !showGoToLiveButton) {
      setShowRestartButton(false)

      showGoToLiveButtonTimeoutRef.current = setTimeout(() => {
        setShowGoToLiveButton(true)
      }, showGoToLiveDelayInMilliseconds)
    }

    return () => {
      if (showGoToLiveButtonTimeoutRef.current) {
        clearTimeout(showGoToLiveButtonTimeoutRef.current)
      }
    }
  }, [activeChapter, showGoToLiveButton])

  // show RestartButton if activeChapter is live
  useEffect(() => {
    if (activeChapter && activeChapter?.isLive) {
      if (showGoToLiveButtonTimeoutRef.current) {
        clearTimeout(showGoToLiveButtonTimeoutRef.current)
      }

      setShowGoToLiveButton(false)
      setShowRestartButton(true)
    }
  }, [activeChapter])

  const handleOnClickRestart = useCallback(() => {
    makeActiveChapterVisibleWithinView()
    seekToChapterPosition(activeChapter)

    trackingCallbacks?.onChapterButtonClick({
      chapterUniqueId: activeChapter?.id,
      chapterHeadline: activeChapter?.headline,
      chapterTimecode: activeChapter?.timestamp,
      chapterLength: activeChapter?.length,
      buttonLabel: translate('blickTVStory.restartCurrentChapter'),
    })
  }, [
    activeChapter,
    makeActiveChapterVisibleWithinView,
    seekToChapterPosition,
    trackingCallbacks,
  ])

  const handleOnClickGoToLive = useCallback(() => {
    setUserLastSelectedTimestamp(Date.now().valueOf())
    setLastChapterAsActive()
    seekToLive()

    trackingCallbacks?.onChapterButtonClick({
      chapterUniqueId: activeChapter?.id,
      chapterHeadline: activeChapter?.headline,
      chapterTimecode: activeChapter?.timestamp,
      chapterLength: activeChapter?.length,
      buttonLabel: translate('blickTVStory.goToLive'),
    })
  }, [
    setUserLastSelectedTimestamp,
    activeChapter?.headline,
    activeChapter?.id,
    activeChapter?.length,
    activeChapter?.timestamp,
    seekToLive,
    setLastChapterAsActive,
    trackingCallbacks,
  ])

  const handleOnClickChapter = useCallback(
    (_e: MouseEvent<HTMLDivElement>, _id: string, chapterData: Chapter) => {
      setUserLastSelectedTimestamp(Date.now().valueOf())
      setActiveChapter(chapterData)

      seekToChapterPosition(chapterData)

      trackingCallbacks?.onChapterClick({
        chapterUniqueId: chapterData?.id,
        chapterHeadline: chapterData?.headline,
        chapterTimecode: chapterData?.timestamp,
        chapterLength: chapterData?.length,
      })
    },
    [
      seekToChapterPosition,
      setActiveChapter,
      trackingCallbacks,
      setUserLastSelectedTimestamp,
    ]
  )

  const handleOnNavigationChange = useCallback(() => {
    trackingCallbacks?.onChapterSwipe({
      chapterUniqueId: activeChapter?.id,
      chapterHeadline: activeChapter?.headline,
      chapterTimecode: activeChapter?.timestamp,
      chapterLength: activeChapter?.length,
      buttonLabel: 'Prev|Next',
    })
  }, [
    trackingCallbacks,
    activeChapter?.id,
    activeChapter?.headline,
    activeChapter?.timestamp,
    activeChapter?.length,
  ])

  return !props.hidden && chapters?.length ? (
    <StoryWrapper>
      <BlickTVStoryComponent
        activeItem={activeChapter}
        swiperRef={swiperRef}
        data={chapters}
        showRestartButton={showRestartButton}
        showGoToLiveButton={showGoToLiveButton}
        showPlaceholderButton={hasChaptersPlaceholder(chapters)}
        restartButtonlabel={translate('blickTVStory.restartCurrentChapter')}
        goToLiveButtonlabel={translate('blickTVStory.goToLive')}
        storyTitle={translate('blickTVStory.chapterTitle')}
        onClickRestart={handleOnClickRestart}
        onClickGoToLive={handleOnClickGoToLive}
        onClickItem={handleOnClickChapter}
        onNavigationChange={handleOnNavigationChange}
      />
    </StoryWrapper>
  ) : null
}

export default LivestreamStory
