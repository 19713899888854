import styled from 'styled-components'

const StoryWrapper = styled.div`
  padding-top: 24px;
  padding-bottom: 38px;

  background: linear-gradient(
    180deg,
    rgba(28, 30, 33, 0.0001) 0%,
    rgba(28, 30, 33, 0.5) 51.18%,
    rgba(28, 30, 33, 0.775952) 87.09%,
    #1c1e21 100%
  );
`

export default StoryWrapper
