import SvgIcon from '@components/SvgIcon'
import { FunctionComponent } from 'react'
import styled, { css, useTheme } from 'styled-components'

interface LeftArrowFaderProps {
  scrollToStart: () => void
}

interface RightArrowFaderProps {
  scrollToEnd: () => void
}

interface ArrowsProps {
  leftOrRight: 'left' | 'right'
}

const leftArrowStyle = css`
  left: 0;
  bottom: 0;
`

const rightArrowStyle = css`
  right: 0;
  bottom: 0;
`

const Fader = styled.div<ArrowsProps>`
  ${({
    theme: {
      color: {
        tertiary: { grey200 },
      },
    },
    leftOrRight,
  }) => css`
    height: 100%;
    position: absolute;
    ${leftOrRight === 'left' ? leftArrowStyle : rightArrowStyle}

    background: linear-gradient(
                ${leftOrRight === 'left' ? '90deg' : '270deg'},
                ${grey200} 37.78%,
                rgba(238, 238, 238, 0) 95.56%
        );
    width: 34px;
  `}
`
const IconWrapper = styled.button<ArrowsProps>`
  ${({ leftOrRight }) => css`
    height: 100%;
    position: absolute;
    ${leftOrRight === 'left' ? leftArrowStyle : rightArrowStyle}
    background: transparent;
    display: grid;
    align-items: center;
    justify-items: center;
    border: 0;
    cursor: pointer;
    padding: 0;
    line-height: 0;
    z-index: 1;
  `}
`

const LeftArrowFader: FunctionComponent<LeftArrowFaderProps> = ({
  scrollToStart,
}) => {
  const theme = useTheme()

  return (
    <>
      <IconWrapper
        aria-label="Scroll left"
        onClick={scrollToStart}
        leftOrRight="left">
        <SvgIcon
          size={24}
          iconName="header-left-arrow"
          color={theme.color.primary.primary01}
        />
      </IconWrapper>
      <Fader leftOrRight="left" />
    </>
  )
}

const RightArrowFader: FunctionComponent<RightArrowFaderProps> = ({
  scrollToEnd,
}) => {
  const theme = useTheme()

  return (
    <>
      <IconWrapper
        aria-label="Scroll right"
        onClick={scrollToEnd}
        leftOrRight="right">
        <SvgIcon
          size={24}
          iconName="header-right-arrow"
          color={theme.color.primary.primary01}
        />
      </IconWrapper>
      <Fader leftOrRight="right" />
    </>
  )
}

const ArrowFader = { Left: LeftArrowFader, Right: RightArrowFader }

export default ArrowFader
