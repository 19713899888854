import HeadlineLeadComponents from '@components/HeadlineLead'
import Social from '@components/Social'
import config from '@config'
import Timestamps from '@components/Timestamps'
import { FunctionComponent } from 'react'
import {
  NormalHeadlineLeadAPIProps,
  ExtraHeadlineCommonProps,
} from '@widgets/HeadlineLead/types'
import { useQueryClient } from '@tanstack/react-query'
import { scrollToCommentingWidget } from '@widgets/Commenting/utils'

export type NormalHeadlineLeadProps = NormalHeadlineLeadAPIProps &
  ExtraHeadlineCommonProps

const {
  publication: { publication },
} = config

const {
  ArticleHeader,
  ArticleMetadata,
  ArticleLead,
  CommentButton,
  TitleCatchword,
  SharingAndCommentWrapper,
} = HeadlineLeadComponents

const NormalHeadlineLead: FunctionComponent<NormalHeadlineLeadProps> = ({
  catchword,
  title,
  lead,
  displayCommentsOnArticle,
  publishedDate,
  updatedDate,
  commentsNumber,
  handleArticleClickShowCommentTracking,
}) => {
  const queryClient = useQueryClient()
  return (
    <ArticleHeader>
      <TitleCatchword title={title} catchword={catchword}>
        {!!lead && <ArticleLead>{lead}</ArticleLead>}
      </TitleCatchword>
      <ArticleMetadata>
        <Timestamps publishedDate={publishedDate} updatedDate={updatedDate} />
        <SharingAndCommentWrapper>
          <Social endOfArticle={false} />
          {publication !== 'romandie' && displayCommentsOnArticle && (
            <CommentButton
              onClick={() => {
                handleArticleClickShowCommentTracking()
                scrollToCommentingWidget(queryClient)
              }}>
              {commentsNumber}
            </CommentButton>
          )}
        </SharingAndCommentWrapper>
      </ArticleMetadata>
    </ArticleHeader>
  )
}

export default NormalHeadlineLead
