import { FunctionComponent } from 'react'
import styled, { css, useTheme } from 'styled-components'
import translate from '@i18n'
import SvgIcon from '@components/SvgIcon'
import { printCSS } from '@utils/style'

interface ShowMoreButtonProps {
  isMore: boolean
  onClick: (buttonText: string) => void
  size?: 'small' | 'large'
  className?: string
}

type StyledButtonProps = Pick<ShowMoreButtonProps, 'size'>

const StyledButton = styled.div<StyledButtonProps>`
  ${({
    theme: {
      color: {
        primary: { primary02, blickRed },
        secondary: { darkRed },
      },
      spacing: { spacing12, spacing16, spacing24 },
      typography: {
        subheads: {
          small1: { bundledCSS: subheadsSmallCSS },
        },
      },
    },
    size,
  }) => css`
    display: inline-flex;
    align-items: center;
    background-color: ${blickRed};
    color: ${primary02};
    cursor: pointer;
    align-self: center;
    text-transform: capitalize;
    border-radius: 50px;
    padding: ${spacing12} ${size === 'large' ? spacing24 : spacing16};
    ${subheadsSmallCSS}

    @media (hover: hover) {
      &:hover {
        background-color: ${darkRed};
      }
    }

    ${printCSS(css`
      display: none;
    `)}
  `};
`

const StyledSvgIcon = styled(SvgIcon)<{ isMore: boolean }>`
  ${({
    isMore,
    theme: {
      animation: {
        transition: { fast: fastTransition },
      },
      spacing: { spacing8 },
    },
  }) => css`
    transform: rotate(${isMore ? 0 : 180}deg);
    transition: transform ${fastTransition} ease;
    margin-left: ${spacing8};
  `}
`

const ShowMoreButton: FunctionComponent<ShowMoreButtonProps> = ({
  isMore,
  onClick,
  size = 'small',
  className,
}) => {
  const {
    colors: { white },
  } = useTheme()
  const buttonText = isMore ? translate('more') : translate('less')
  const iconSize = size === 'large' ? 24 : 16
  return (
    <StyledButton
      className={className}
      onClick={() => onClick(buttonText)}
      size={size}>
      {buttonText}
      <StyledSvgIcon
        isMore={isMore}
        iconName="chevron-down"
        size={iconSize}
        color={white}
      />
    </StyledButton>
  )
}

export default ShowMoreButton
