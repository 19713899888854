import { FunctionComponent } from 'react'
import styled, { css, useTheme } from 'styled-components'
import IconButton from '@components/Buttons/IconButton'

interface CaptionsCTAButtonProps {
  captionsAvailable: boolean
  captionsEnabled: boolean
  onCaptionsButtonClick?: () => void
}

const StyledIconButton = styled(IconButton)<{ disabled: boolean }>`
  ${({ disabled }) => css`
    border-radius: 50%;
    background: rgba(17, 17, 17, 0.5);

    opacity: ${disabled ? '0.2' : 1};
    pointer-events: ${disabled ? 'none' : 'all'};
  `}
`

const CaptionsCTAButton: FunctionComponent<CaptionsCTAButtonProps> = ({
  captionsAvailable,
  captionsEnabled,
  onCaptionsButtonClick,
}) => {
  const theme = useTheme()

  return (
    <StyledIconButton
      ariaLabel="Volume"
      buttonSize={40}
      onClick={onCaptionsButtonClick}
      disabled={!captionsAvailable}
      iconName={
        captionsAvailable && captionsEnabled
          ? 'subtitles-solid'
          : 'subtitles-slash-solid'
      }
      iconSize={20}
      iconColor={theme.color.primary.primary02}
    />
  )
}

export default CaptionsCTAButton
