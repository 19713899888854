import { FunctionComponent } from 'react'
import GTMScript from '@components/GTMScript'

export interface NonErrorPagePostCSSComponentsProps {
  isStorybook?: boolean
}

const NonErrorPagePostCSSComponents: FunctionComponent<
  NonErrorPagePostCSSComponentsProps
> = ({ isStorybook }) => <>{!isStorybook && <GTMScript />}</>

export default NonErrorPagePostCSSComponents
