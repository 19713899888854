import config from '@config'
import { BlickBitesData } from '@widgets/Video/BlickBites'

export type FetchBlickBites = (options?: {
  id?: string
  bl?: string
  size?: number
  before?: number
  pub?: string
}) => Promise<BlickBitesData>

const base64Options = ['bl']
const base64Encode = (value: string) => btoa(value)

const {
  publication: { publication },
  blickBites: { blickBitesApiUrl },
} = config

const isInServerBundle = typeof window === 'undefined'

const fetchBlickBites: FetchBlickBites = async (options = {}) => {
  const requestUrl = new URL(`${blickBitesApiUrl}`)
  const defaultOptions = { pub: publication }

  Object.entries({ ...defaultOptions, ...options }).forEach(([key, value]) => {
    if (value !== undefined) {
      requestUrl.searchParams.append(
        key,
        base64Options.includes(key) ? base64Encode(`${value}`) : `${value}`
      )
    }
  })

  try {
    return (
      await fetch(
        requestUrl,
        isInServerBundle ? { headers: { 'User-Agent': 'next' } } : {}
      )
    ).json()
  } catch (error) {
    console.error('Error fetching blick bites', error)
    // TODO: handle error state
  }
}

export { fetchBlickBites }
