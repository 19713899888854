import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import translate from '@i18n'
import { CommonLottoData } from './types'
import { Link } from '@cook-types/cook'
import Blink from '@components/Blink'
import PrimaryButton from '@components/Buttons/Primary'
import { mobileCSS } from '@measures/responsive'

interface FooterProps {
  jackpot: CommonLottoData['formatted-jackpot']
  nextDate: CommonLottoData['next-draw-date']
  link: Link
}

const StyledFooter = styled.div`
  display: flex;
`

const JackpotInfo = styled.div`
  ${({
    theme: {
      typography: {
        bodycopy: {
          small2: { bundledCSS: bodycopySmall2CSS },
        },
      },
      spacing: { spacing24 },
    },
  }) => css`
    ${bodycopySmall2CSS};
    margin-top: ${spacing24};
    ${mobileCSS(css`
      width: 125px;
    `)}
  `}
`

const NextDate = styled.div`
  ${mobileCSS(css`
    margin-bottom: 22px;
  `)}
`

const StyledBlink = styled(Blink)`
  display: flex;
  margin-top: 30px;
  margin-left: auto;
  text-decoration: none;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  ${({
    theme: {
      color: {
        primary: { blickRed },
        secondary: { darkRed },
      },
      typography: {
        subheads: {
          small1: { bundledCSS: smallBodyCopy },
        },
      },
      spacing: { spacing12, spacing16 },
    },
  }) => {
    return css`
      ${smallBodyCopy};
      text-transform: none;
      padding: ${spacing12} ${spacing16};
      background-color: ${blickRed};

      &:hover {
        background-color: ${darkRed};
      }
    `
  }}
`

const Footer: FunctionComponent<FooterProps> = ({
  nextDate,
  jackpot,
  link,
}) => {
  return (
    <StyledFooter>
      <JackpotInfo>
        <NextDate>
          {`${translate('lotto.nextDraw', {
            nextDate: nextDate,
          })}`}
        </NextDate>
        <div className="jackpot-info__jackpot">
          {`${translate('lotto.nextJackpot')}: ${translate(
            'lotto.chf'
          )} ${jackpot}`}
        </div>
      </JackpotInfo>
      <StyledBlink {...link}>
        <StyledPrimaryButton size="small" onClick={() => {}}>
          {translate('lotto.nowPlaying')}
        </StyledPrimaryButton>
      </StyledBlink>
    </StyledFooter>
  )
}

export default Footer
