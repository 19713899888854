import { FunctionComponent, useCallback, useState } from 'react'
import styled, { css, useTheme } from 'styled-components'
import config from '@config'
import translate from '@i18n'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import SummaryContainer from '@components/Summary/SummaryContainer'
import SummaryDisclaimer from '@components/Summary/SummaryDisclaimer'
import SvgIcon from '@components/SvgIcon'
import SummaryHeader from '@components/Summary/SummaryHeader'
import SecondaryButton from '@components/Buttons/Secondary'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import useViewportTracking from '@hooks/useViewportTracking'
import { ErrorView } from '@components/Summary/SummaryError'
import {
  SkeletonSeed,
  SkeletonView,
} from '@components/Summary/SummaryLoadingSkeleton'
import SummaryRequestView from './SummaryRequestView'
import SummaryResultView from './SummaryResultView'
import {
  getAnswers,
  getQuestions,
  trackClickHandler,
  trackSectionSummaryImpression,
} from './utils'
import { FetchState, SectionSummaryProps } from './types'
import useABTestValue from '@hooks/useABTestValue'
import { mobileCSS } from '@measures/responsive'

const {
  sectionSummary: { moreInfoLink, feedbackLink },
} = config

const IconPullRight = styled.div`
  flex: 1;
  display: flex;
  justify-content: right;
  width: 100%;
  cursor: pointer;
`
const SummaryWrapper = styled.div`
  width: 100%;
  min-height: 211.19px;

  ${mobileCSS(css`
    min-height: 254.38px;
  `)}
`

const StyledSecondaryButton = styled(SecondaryButton)`
  ${({
    theme: {
      color: {
        primary: { primary01 },
      },
      typography: {
        subheads: {
          small1: { bundledCSS: smallBodyCopy },
        },
      },
      spacing: { spacing4, spacing8, spacing16 },
    },
  }) => {
    return css`
      ${smallBodyCopy}
      color: ${primary01};
      text-transform: none;
      width: fit-content;
      gap: ${spacing8};
      padding: ${spacing4} ${spacing16};
      background-color: transparent;
      border: 1px solid ${primary01};
      &:hover {
        background-color: transparent;
      }
    `
  }}
`

const SectionSummary: FunctionComponent<SectionSummaryProps> = ({
  title = '',
  links,
  content,
  state: initalState = FetchState.Initial,
}) => {
  const [summaryData, setSummaryData] = useState<
    Pick<SectionSummaryProps, 'content' | 'links'>
  >({ content, links })
  const [state, setState] = useState<FetchState>(initalState)
  const theme = useTheme()
  const trackWidgetImpression = useTracking(trackSectionSummaryImpression)
  const trackClick = useTracking(trackClickHandler)
  const isSummaryBriefingABTestEnabled =
    useABTestValue('showSummaryBriefing') === 'show'

  const viewportRef = useViewportTracking({
    track: isSummaryBriefingABTestEnabled,
    onImpression: trackWidgetImpression,
  })

  const trackingOnClick = useCallback<TrackingFnType<{ label: string }>>(
    ({ extraData: { label } }) => ({
      event: 'home_summary_impression',
      button_label: label,
    }),
    []
  )
  const handleTrackingOnClick = useTracking(trackingOnClick)

  const disclaimer = {
    title: translate('sectionSummary.disclaimerText'),
    links: [
      {
        label: translate('sectionSummary.disclaimerMoreInfo'),
        link: moreInfoLink,
        target: '_blank',
        onClickHandler: (label: string) => handleTrackingOnClick({ label }),
      },
      {
        label: translate('sectionSummary.disclaimerFeedback'),
        link: feedbackLink,
        target: '_blank',
        onClickHandler: (label: string) => handleTrackingOnClick({ label }),
      },
    ],
  }

  const dataStateHandler = async () => {
    try {
      trackClick()
      setState(FetchState.Loading)
      const { questions } = await getQuestions()
      const id = questions[0].id
      const data = await getAnswers({ questionId: id })
      if (data.content.length === 0) {
        throw new Error('No data found')
      }
      setSummaryData(data)
      setState(FetchState.Success)
    } catch (e) {
      console.log(e)
      setState(FetchState.Error)
    }
  }

  const fetchState = state
  const isSummariesLoaded =
    !!(summaryData?.content && summaryData?.content?.length > 0) &&
    fetchState === FetchState.Success

  return (
    <SummaryWrapper id="summary-briefing">
      {isSummaryBriefingABTestEnabled && (
        <SummaryContainer ref={viewportRef}>
          {state === FetchState.Initial && (
            <SummaryRequestView
              title={title}
              text={translate('sectionSummary.request.text')}
              onPrimaryClick={dataStateHandler}
            />
          )}

          {isSummariesLoaded && (
            <SummaryResultView
              title={translate('sectionSummary.request.title')}
              links={summaryData.links}
              content={summaryData.content}
              disclaimer={disclaimer}
              onCloseHandler={() => setState(FetchState.Initial)}
            />
          )}

          {fetchState === FetchState.Loading && (
            <>
              <SummaryHeader
                prefixIconName="stars"
                after={
                  <IconPullRight onClick={() => setState(FetchState.Initial)}>
                    <SvgIcon
                      iconName="close"
                      size={24}
                      color={theme.color.primary.primary01}
                    />
                  </IconPullRight>
                }>
                {translate('sectionSummary.request.title')}
              </SummaryHeader>

              <SkeletonView
                seed={[
                  SkeletonSeed.BulletPoint,
                  SkeletonSeed.BulletPoint,
                  SkeletonSeed.BulletPoint,
                  SkeletonSeed.Paragraph,
                ]}
              />
            </>
          )}
          {fetchState === FetchState.Error && (
            <ErrorView>
              <IconPullRight onClick={() => setState(FetchState.Initial)}>
                <SvgIcon
                  iconName="close"
                  size={24}
                  color={theme.color.primary.primary01}
                />
              </IconPullRight>
              {translate('sectionSummary.request.error')}

              <StyledSecondaryButton
                type="button"
                size="large"
                onClick={dataStateHandler}>
                {translate('sectionSummary.request.error.primaryButton')}
                <SvgIcon
                  iconName="arrows-rotate"
                  size={16}
                  color={theme.color.primary.primary01}
                />
              </StyledSecondaryButton>

              {disclaimer && <SummaryDisclaimer links={disclaimer.links} />}
            </ErrorView>
          )}
        </SummaryContainer>
      )}
    </SummaryWrapper>
  )
}

const widget = {
  kind: ['section-summary'],
  component: SectionSummary,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
