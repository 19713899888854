import { FunctionComponent, useCallback, useMemo } from 'react'
import { nanoid } from 'nanoid'
import VideoContainer from '@components/Video/VideoPlayer/VideoContainer'
import usePageMetadata from '@hooks/usePageMetadata'
import { isVideoOnlyArticle } from '@utils/cook'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import LivestreamStoryProvider from '@widgets/Video/story/LivestreamStoryProvider'
import BlickTVStory from '@widgets/Video/story/LivestreamStory'
import VideoPlayer from './VideoPlayer'
import VideoTitle from './VideoTitle'
import useVideoOverlay from '@hooks/useVideoOverlay'
import { isLiveStream } from './utils'
import { VideoWidgetAPIProps } from '@widgets/Video/types'
import useSubscriptionStatus from '@hooks/useSubscriptionStatus'
import TrailerBlockerOverlayComponents from 'components/Video/Trailer/TrailerBlockerOverlay'
import blickTVLogo from '@assets/images/blick-tv-logo.svg'
import VideoPlayerWrapper from '@components/Video/VideoPlayer/VideoPlayerWrapper'
import useGetPageIdentity from '@hooks/useGetPageIdentity'

const { TrailerBlockerOverlayWrapper, TrailerBlockerOverlay } =
  TrailerBlockerOverlayComponents

const VideoWidget: FunctionComponent<VideoWidgetAPIProps> = (props) => {
  const pageMetadata = usePageMetadata()
  const { isArticle } = useGetPageIdentity()

  const {
    id: articleId,
    catchword: catchwordArticle,
    title: titleArticle,
    teaser: { targetContentType } = {},
    hasPlaylist,
  } = pageMetadata

  const widgetId = useMemo(() => nanoid(), [])

  // TODO: add isE2ETest when it is ready
  const isE2ETest = false

  const videoTaxonomy = props.ad?.metadata?.iabcategories
  const e2eAdsExcludedElement = {
    ...props,
    ...(isE2ETest ? { isAdDisabled: true } : {}),
  }

  const {
    catchword,
    duration,
    isMainElement,
    syndication,
    title,
    videoId,
    jwVideoId,
    image,
    isAdDisabled,
    isInScoreboardContent,
    isPlus,
    trailer,
  } = e2eAdsExcludedElement

  const { subscriptionStatus } = useSubscriptionStatus()
  const hasTrailer = !!trailer
  const shouldWaitForBlickPlusOverlay =
    !hasTrailer && isPlus && !subscriptionStatus
  const shouldShowBlickPlusOverlay =
    isPlus && subscriptionStatus && subscriptionStatus !== 'subscribed'
  const shouldShowTrailer = shouldShowBlickPlusOverlay && hasTrailer
  const { isOverlay } = useVideoOverlay()
  const isSameName = catchword === catchwordArticle && title === titleArticle
  const showTitle =
    (!isSameName || !isMainElement) &&
    (!isMainElement || targetContentType !== 'video')
  const isInVideoOnlyArticle = isVideoOnlyArticle(targetContentType)
  const isLiveStreamVideo = isLiveStream(duration)

  const videoPlayerProps = {
    articleId,
    videoId,
    jwVideoId,
    isInVideoOnlyArticle,
    isInArticle: isArticle,
    hasPlaylist,
    isMainElement,
    isBlickTV: false,
    geoblocking: props.geoblocking,
    isAnimatedPreviewDisabled: props.isAnimatedPreviewDisabled,
    shouldShowTrailer,
    widgetId,
    ...{
      videoTaxonomy,
      duration,
      isAdDisabled,
      syndication,
      title,
      image,
      isInScoreboardContent,
    },
  }

  const trailerProps = {
    ...trailer,
    articleId,
    jwVideoId: trailer?.jwVideoId,
    title: trailer?.title || '',
    geoblocking: trailer?.geoblocking,
    duration: trailer?.duration || 0,
    isAdDisabled: trailer?.isAdDisabled === true,
    isInVideoOnlyArticle,
    isInArticle: isArticle,
    isBlickTV: false,
    allowFullscreen: false,
    syndication,
    isMainElement,
    shouldShowTrailer,
    isAnimatedPreviewDisabled: props.isAnimatedPreviewDisabled,
    widgetId,
  }

  const videoTitleInfo = {
    title,
    catchword,
    isLiveStreamVideo,
  }

  const trailerTitleInfo = {
    title: trailer?.title || '',
    catchword: trailer?.catchword || '',
    duration: trailer?.duration || 0,
  }

  // chapters should be enabled for live stream video
  // (it is required for live geo-blocking support)
  const chaptersEnabled = isLiveStreamVideo
  // chapters should be hidden for video widget
  const chaptersVisible = false

  const blickTVStoryProps = {
    title: videoPlayerProps.title,
    video: {
      isBlickTV: videoPlayerProps.isBlickTV,
      position: 'beforeMega',
      duration: videoPlayerProps.duration,
      videoId: videoPlayerProps.videoId ?? '',
      jwVideoId: videoPlayerProps.jwVideoId ?? '',
    },
  }

  const trackingInfo = { videoId: jwVideoId || '', videoTitle: title }

  const finalVideoPlayerProps = shouldShowTrailer
    ? trailerProps
    : videoPlayerProps

  const finalVideoTitleInfo = shouldShowTrailer
    ? trailerTitleInfo
    : videoTitleInfo

  const onThumbnailData = useCallback(() => {
    const { catchword, title } = finalVideoTitleInfo
    const mediaSessionTitle = catchword
      ? `${catchword}${title ? `: ${title}` : ''}`
      : `${title ?? ''}`

    if ('mediaSession' in navigator) {
      navigator.mediaSession.metadata = new MediaMetadata({
        title: mediaSessionTitle,
        artwork: [{ src: blickTVLogo }],
      })
    }
  }, [finalVideoTitleInfo])

  return (
    <LivestreamStoryProvider enabled={chaptersEnabled} widgetId={widgetId}>
      <VideoContainer
        // TODO: check this => we should move to jwVideoId as a key here
        // WARNING: Unless you want to have a heart attack please make sure that for different videoId the component is rerendered,
        // because the VideoPlayer component never rerenders!!!
        key={jwVideoId}
        {...{
          isInOverlay: isOverlay,
          isInVideoOnlyArticle,
          isInScoreboardContent,
        }}>
        <VideoPlayerWrapper>
          {!shouldWaitForBlickPlusOverlay ? (
            shouldShowBlickPlusOverlay && !hasTrailer ? (
              <TrailerBlockerOverlayWrapper>
                <TrailerBlockerOverlay trackingInfo={trackingInfo} />
              </TrailerBlockerOverlayWrapper>
            ) : (
              <VideoPlayer {...{ ...finalVideoPlayerProps, onThumbnailData }} />
            )
          ) : null}
        </VideoPlayerWrapper>
        {showTitle ? <VideoTitle {...finalVideoTitleInfo} /> : null}
      </VideoContainer>
      {chaptersEnabled ? (
        <BlickTVStory {...blickTVStoryProps} hidden={!chaptersVisible} />
      ) : null}
    </LivestreamStoryProvider>
  )
}

const widget = {
  kind: ['widget', 'video'],
  component: VideoWidget,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
