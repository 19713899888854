import useVideoOverlay from '@hooks/useVideoOverlay'
import { desktopCSS, mobileCSS, tabletCSS } from '@measures/responsive'
import { printCSS } from '@utils/style'
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled, { css } from 'styled-components'
import FirstRow from './FirstRow'
import SecondRow from './SecondRow'
import useIsImmersiveHeaderActive from '@hooks/useIsImmersiveHeaderActive'
import { useQueryClient } from '@tanstack/react-query'
import useOnScroll from '@hooks/useOnScroll'
import useGetPageIdentity from '@hooks/useGetPageIdentity'

const IMMERSIVE_HEADER_THRESHOLD = 96

const StyledHeader = styled.header`
  box-sizing: border-box;
  width: 100%;

  ${desktopCSS(css`
    margin: 0 -20px;
    width: calc(100% + 2 * 20px);
  `)}
`

const StyledHeaderWrapper = styled.div<{
  isVideoOverlayOpen: boolean
  isImmersiveHeaderActive: boolean
}>`
  ${({ isVideoOverlayOpen, isImmersiveHeaderActive }) => css`
    width: 100%;
    box-sizing: border-box;
    position: sticky;
    ${printCSS(css`
      position: unset;
      margin-bottom: 8px;
    `)}
    /* Header should not be hidden by ads */
    /* the ads' highest z-index we know is 9400 */
    z-index: 10000;
    top: 0;
    ${desktopCSS(css`
      padding: 0 20px;
    `)}

    ${isVideoOverlayOpen &&
    css`
      visibility: hidden;
    `}

    ${isImmersiveHeaderActive &&
    css`
      ${mobileCSS(css`
        background: linear-gradient(
          180deg,
          rgba(17, 17, 17, 0.8) 0%,
          rgba(17, 17, 17, 0) 100%
        );
      `)}
      ${tabletCSS(css`
        background: linear-gradient(
          180deg,
          rgba(17, 17, 17, 0.8) 0%,
          rgba(17, 17, 17, 0) 100%
        );
      `)}
    `}
  `}
`

const Header: FunctionComponent<{
  className?: string
  forceDisableSecondLevelNavigation?: boolean
  forceCenter?: boolean
}> = ({ className, forceDisableSecondLevelNavigation, forceCenter }) => {
  const { isOverlay } = useVideoOverlay()
  const { isArticle, isImmersive, isDossier } = useGetPageIdentity()
  const queryClient = useQueryClient()
  const shouldShowSecondLevelNavigation =
    !forceDisableSecondLevelNavigation && !(isArticle || isDossier)

  const windowRef = useRef<typeof window | null>(null)

  const isImmersiveHeaderActive = useIsImmersiveHeaderActive()

  // Track scroll only when windowRef is set and immersive hero is present
  const [shouldTrackOnScroll, setShouldTrackOnScroll] = useState(false)

  const updateImmersiveHeaderState = useCallback(() => {
    queryClient.setQueryData(
      ['is-immersive-header-active'],
      isImmersive && window.scrollY < IMMERSIVE_HEADER_THRESHOLD
    )
    queryClient.invalidateQueries({ queryKey: ['is-immersive-header-active'] })
  }, [isImmersive, queryClient])

  useOnScroll({
    track: shouldTrackOnScroll,
    delay: 0,
    elementRef: windowRef,
    onScroll: updateImmersiveHeaderState,
  })

  useEffect(() => {
    windowRef.current = window
    setShouldTrackOnScroll(isImmersive)
    updateImmersiveHeaderState()
  }, [isImmersive, updateImmersiveHeaderState])

  return (
    <StyledHeaderWrapper
      isVideoOverlayOpen={!!isOverlay}
      isImmersiveHeaderActive={isImmersiveHeaderActive}
      className={className}>
      <StyledHeader id="main-header">
        <FirstRow forceCenter={forceCenter} />
        {shouldShowSecondLevelNavigation && <SecondRow />}
      </StyledHeader>
    </StyledHeaderWrapper>
  )
}

export { StyledHeaderWrapper }

export default Header
