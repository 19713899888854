import usePageMetadata from '@hooks/usePageMetadata'
import { FunctionComponent, useCallback, useMemo } from 'react'
import {
  createAuthor,
  createNutritionInformation,
  createStep,
  getImageSet,
  getParsedAuthors,
} from '@utils/schema'
import SchemaHTMLElement from '@components/SchemaHTMLElement'

const RecipeSchema: FunctionComponent = () => {
  const pageMetadata = usePageMetadata()
  const {
    htmlTitle,
    title,
    recipeSchema,
    lastModifiedDate,
    publishedDate,
    teaser,
    authors,
  } = pageMetadata
  const {
    nutrition,
    recipeInstructions,
    cookTime,
    prepTime,
    recipeYield,
    ...restSchema
  } = recipeSchema || {}

  const getInstructionsSet = useCallback(() => {
    if (recipeInstructions) {
      return recipeInstructions.map((instruction) =>
        createStep(instruction.text, instruction?.image)
      )
    }
    return []
  }, [recipeInstructions])

  const getNutritionInformation = useCallback(
    () => createNutritionInformation(nutrition),
    [nutrition]
  )

  const imageSet = useMemo(() => getImageSet(teaser?.image), [teaser?.image])
  const preparedAuthors = useMemo(() => getParsedAuthors(authors), [authors])

  return (
    <SchemaHTMLElement
      scriptKey="recipe"
      schemaObject={{
        '@type': 'Recipe',
        image: imageSet,
        dateModified: lastModifiedDate,
        datePublished: publishedDate,
        author: !authors
          ? createAuthor()
          : preparedAuthors.map((author) => createAuthor(author.name)),
        name: htmlTitle || title,
        ...(!!nutrition ? getNutritionInformation() : {}),
        ...(!!cookTime ? { cookTime } : {}),
        ...(!!prepTime ? { prepTime } : {}),
        ...(!!recipeYield ? { recipeYield } : {}),
        recipeInstructions: getInstructionsSet(),
        ...restSchema,
      }}
    />
  )
}

export default RecipeSchema
