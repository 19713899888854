import { FunctionComponent } from 'react'
import styled, { css, useTheme } from 'styled-components'
import SvgIcon from '@components/SvgIcon'
import translate from '@i18n'
import Blink from '@components/Blink'
import { Link } from '@cook-types/cook'

interface ReadMoreCTAButtonProps {
  clickableProps?: Link
  onClick?: () => void
}

const ButtonWrapper = styled(Blink)`
  ${({
    theme: {
      color: {
        primary: { blickRed: blickRedColor },
      },
      spacing: { spacing4, spacing8, spacing16 },
    },
  }) => css`
    display: flex;
    height: 40px;
    box-sizing: border-box;
    padding: ${spacing4} ${spacing16};

    justify-content: center;
    align-items: center;
    gap: ${spacing8};

    border-radius: 100px;
    border: 0;
    background-color: ${blickRedColor};
    text-decoration: none;

    cursor: pointer;
  `}
`

const ButtonText = styled.div`
  ${({
    theme: {
      typography: {
        subheads: {
          small1: { bundledCSS: small1SubheadsCSS },
        },
      },
      color: {
        primary: { primary02: primary02Color },
      },
    },
  }) => css`
    ${small1SubheadsCSS};
    color: ${primary02Color};
  `}
`

const ReadMoreCTAButton: FunctionComponent<ReadMoreCTAButtonProps> = ({
  clickableProps,
  onClick,
}) => {
  const theme = useTheme()

  if (clickableProps?.href) {
    const modifiedClickableProps = {
      ...clickableProps,
      target: '_blank',
    }

    return (
      <ButtonWrapper {...modifiedClickableProps} onClick={onClick}>
        <ButtonText>{translate('blickBites.readMore')}</ButtonText>
        <SvgIcon
          iconName="arrow-right"
          size={16}
          color={theme.color.primary.primary02}
        />
      </ButtonWrapper>
    )
  }

  return null
}

export default ReadMoreCTAButton
