import { FunctionComponent } from 'react'
import styled, { useTheme, css } from 'styled-components'
import { useFullScreenGallery } from '@hooks/useFullScreenGallery'
import IconButton from '@components/Buttons/IconButton'
import { desktopCSS } from '@measures/responsive'

const CloseButtonWrapper = styled.div<{ isHidden: boolean }>`
  ${({
    theme: {
      spacing: { spacing4, spacing16, spacing24 },
    },
    isHidden,
  }) => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    padding: ${spacing24} ${spacing16} ${spacing4} 0;

    ${desktopCSS(css`
      padding: ${spacing24} 0 ${spacing4} 0;
    `)}

    ${isHidden &&
    css`
      visibility: hidden;
    `}
  `}
`

interface CloseButtonProps {
  onClose: () => void
}

const CloseButton: FunctionComponent<CloseButtonProps> = ({ onClose }) => {
  const theme = useTheme()
  const { isFullScreen, hideFullScreenGalleryControls } = useFullScreenGallery()

  if (!isFullScreen) {
    return null
  }

  return (
    <CloseButtonWrapper isHidden={hideFullScreenGalleryControls}>
      <IconButton
        ariaLabel="Gallery Close"
        onClick={onClose}
        iconName="close"
        iconSize={24}
        iconColor={theme.color.primary.primary02}
        buttonSize={24}
      />
    </CloseButtonWrapper>
  )
}

export default CloseButton
