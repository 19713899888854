import SvgIcon from '@components/SvgIcon'
import { FunctionComponent } from 'react'
import styled, { css, useTheme } from 'styled-components'
import { StyledCommonButtonProps, ButtonProps } from './types'

const StyledSecondaryButton = styled.button<StyledCommonButtonProps>`
  ${({
    theme: {
      fonts: { goodOT },
      colors: {
        white,
        grey: { grey_50, grey_75, grey_90 },
        secondary: { focus },
      },
    },
    size,
    isDisabled,
  }) => css`
    font-size: 14px;
    line-height: 20px;
    background-color: ${white};
    border-radius: ${size === 'small' ? '20px' : '24px'};
    color: ${grey_90};
    padding: ${size === 'small' ? '10px' : '14px'} 24px;
    border: 1.2px solid ${grey_90};
    cursor: pointer;
    font-family: ${goodOT};
    text-transform: uppercase;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    height: ${size === 'small' ? '40px' : '48px'};
    ${isDisabled
      ? css`
          border: 1.2px solid ${grey_50};
          color: ${grey_50};
          cursor: auto;
        `
      : css`
          &:hover {
            border: 1.2px solid ${grey_75};
            color: ${grey_75};
          }
          &:focus-visible {
            border: 4px solid ${focus};
          }
        `}
  `}
`

const SecondaryButton: FunctionComponent<ButtonProps> = ({
  size,
  withArrow,
  isDownArrow,
  children,
  onClick,
  isDisabled,
  className,
}) => {
  const theme = useTheme()
  return (
    <StyledSecondaryButton
      size={size}
      isDisabled={isDisabled}
      onClick={!isDisabled ? onClick : () => {}}
      className={className}>
      {children}
      {withArrow && (
        <SvgIcon
          iconName={isDownArrow ? 'chevron-down' : 'chevron-up'}
          size={20}
          //convert hard coded colors to variables coming from theme
          color={
            isDisabled ? theme.colors.grey.grey_50 : theme.colors.grey.grey_90
          }
        />
      )}
    </StyledSecondaryButton>
  )
}

export default SecondaryButton
