import config from '@config'
import { PageMetadata } from '@hooks/usePageMetadata'

export type ArticleId = string | number | undefined

const {
  dlApi: {
    tenantId,
    adsNoBanner,
    noDfp,
    no_gemius,
    async,
    tenant,
    cmpLang,
    cmd,
  },
} = config

const articleIdIsSet = (articleId: ArticleId) =>
  articleId !== undefined && articleId !== null && articleId !== 0

const getKropkaTarget = ({
  metadata,
  isArticle = false,
}: {
  metadata: PageMetadata
  isArticle?: boolean
}) => {
  // Return an empty target, if there is no url present
  // The url is needed to create the kropka target
  if (!metadata?.url) {
    return ''
  }

  // The section path is taken from the url removing the last part
  // So on an article it will remove the article slug
  // If we are on home, the value is main_page
  const sectionPath =
    metadata?.context === 'home'
      ? '/main_page'
      : metadata.url.substring(0, metadata.url.lastIndexOf('/')).toLowerCase()

  return `${tenant}${sectionPath}${isArticle ? '/article' : ''}`
}

const getKropkaConfigObject = ({
  metadata,
  isSwissGeolocation,
}: {
  metadata: PageMetadata
  isSwissGeolocation: boolean
}) => {
  const articleId = metadata?.id
  const articleParams = articleIdIsSet(articleId)
    ? {
        DV: getKropkaTarget({ metadata, isArticle: true }),
        kropka: {
          PU: articleId,
          DX: `PV,cue,${articleId}_${tenant},9,a`,
        },
      }
    : {}
  const config = {
    ...(isSwissGeolocation ? { gdprApplies: false } : {}),
    target: getKropkaTarget({ metadata }),
    tid: `EA-${tenantId}`,
    adsNoBanner,
    noDfp,
    no_gemius,
    cmd,
    async,
    cmpLang,
    cmpTid: 'no-gdpr',
    ...articleParams,
  }
  return config
}

export { articleIdIsSet, getKropkaTarget, getKropkaConfigObject }
