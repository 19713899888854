import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { desktopCSS } from '@measures/responsive'
import useClientViewportType from '@hooks/useClientViewportType'
import Separator from './Separator'

const StyledMobileOnlySeparator = styled(Separator)`
  ${desktopCSS(css`
    display: none;
  `)};
`

const MobileOnlySeparator: FunctionComponent = () =>
  useClientViewportType() !== 'desktop' ? <StyledMobileOnlySeparator /> : null

export default MobileOnlySeparator
