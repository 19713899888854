import {
  GetSubscriptionDataToTrackFn,
  NotSpecifiedSubscriptionData,
  NotSubscribedSubscriptionData,
} from './types'

const getSubscriptionDataToTrack: GetSubscriptionDataToTrackFn = (
  subscriptions
) => {
  // Discard inactive subscriptions, they are not relevant for tracking
  const activeSubscriptions = subscriptions.filter(
    (subscription) => subscription.active
  )

  if (activeSubscriptions.length === 0) {
    return {
      user_status: 'notSubscribed',
      product_name: '',
      product_id: '',
      is_trial: false,
    }
  }

  // In case user has more than one active subscription, we should track the one with the most recent startDate
  const { typeName, typeId, expirationDate, isInTrial } =
    activeSubscriptions.length > 1
      ? activeSubscriptions.sort(
          (subA, subB) =>
            new Date(subA.startDate).getTime() -
            new Date(subB.startDate).getTime()
        )[0]
      : activeSubscriptions[0]

  return {
    user_status: 'subscribed',
    product_name: typeName,
    product_id: typeId,
    subscription_valid_until: expirationDate,
    is_trial: isInTrial,
  }
}

const notSubscribedPayload: NotSubscribedSubscriptionData = {
  user_status: 'notSubscribed',
}

const notSpecifiedPayload: NotSpecifiedSubscriptionData = {
  user_status: 'notSpecified',
}

export { getSubscriptionDataToTrack, notSubscribedPayload, notSpecifiedPayload }
