import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'

export interface BadgeProps {
  children: ReactNode
  className?: string
  backgroundColor: string
}

export type StyledBadgeProps = Pick<BadgeProps, 'backgroundColor'>

const StyledBadge = styled.div<StyledBadgeProps>`
  ${({
    theme: {
      spacing: { spacing2, spacing4 },
      color: {
        primary: { primary02: primary02Color },
      },
      typography: {
        eyebrows: {
          small2: { bundledCSS: small2EyebrowCSS },
        },
      },
    },
    backgroundColor,
  }) => css`
    position: relative;
    white-space: nowrap;
    display: inline-flex;
    column-gap: ${spacing2};
    ${small2EyebrowCSS};
    color: ${primary02Color};
    background-color: ${backgroundColor};
    padding: ${spacing4};
    text-transform: uppercase;
  `}
`

const Badge: FunctionComponent<BadgeProps> = ({
  children,
  className,
  backgroundColor,
}) => {
  return (
    <StyledBadge className={className} backgroundColor={backgroundColor}>
      {children}
    </StyledBadge>
  )
}

export default Badge
