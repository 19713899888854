import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'

const StyledPlaceholder = styled.div`
  ${({
    theme: {
      colors: {
        blicktvGrey: { btvGrey_60 },
        blicktvGradients: { radialRed },
      },
    },
  }) => css`
    width: 100%;
    height: 100%;
    border-radius: 4px 4px 0 0;
    background-color: ${btvGrey_60};
    background-image: ${radialRed};

    & img {
      border-radius: 4px 4px 0 0;
    }
  `}
`

const Placeholder: FunctionComponent<{ children?: ReactNode }> = ({
  children,
}) => <StyledPlaceholder>{children}</StyledPlaceholder>

export default Placeholder
