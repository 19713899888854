import { FunctionComponent, useCallback, useRef, useState } from 'react'
import config from '@config'
import styled, { css, useTheme } from 'styled-components'
import Blink from '@components/Blink'
import IconButton from '@components/Buttons/IconButton'
import Popover from '@components/Popover'

const {
  textToSpeech: { informationLink },
} = config

const Wrapper = styled.div`
  position: relative;
`

const PopoverContent = styled.div`
  ${({
    theme: {
      color: {
        primary: { primary01 },
      },
      typography: {
        bodycopy: {
          xsmall: { bundledCSS: xsmallBodycopyCSS },
        },
      },
    },
  }) => css`
    color: ${primary01};
    ${xsmallBodycopyCSS}

    min-width: 326px;
    padding: 12px;
    box-sizing: border-box;
  `}
`

const StyledLink = styled(Blink)`
  ${({
    theme: {
      color: {
        primary: { primary01 },
      },
    },
  }) => css`
    color: ${primary01};
    display: inline-block;
  `}
`

const StyledPopover = styled(Popover)`
  bottom: 20px;
`

const TextToSpeechInfo: FunctionComponent = () => {
  const theme = useTheme()
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)

  const togglePopover = useCallback(() => {
    setIsPopoverOpen((prevValue) => !prevValue)
  }, [])

  return (
    <Wrapper ref={wrapperRef}>
      <IconButton
        iconName={isPopoverOpen ? 'xmark-large' : 'circle-info'}
        iconColor={theme.color.tertiary.grey700}
        iconSize={24}
        onClick={togglePopover}
        ariaLabel="Info"
      />

      {isPopoverOpen && (
        <StyledPopover
          position="top"
          trackClickOutside={true}
          wrapperRef={wrapperRef}
          onClickOutside={togglePopover}>
          <PopoverContent>
            Die Vorlese-Funktion basiert auf der Stimme von Steffi Buchli und
            wird mit Hilfe Künstlicher Intelligenz erzeugt.{' '}
            <StyledLink target="blank" href={informationLink}>
              So nutzt Blick KI zu deinem Vorteil.
            </StyledLink>
          </PopoverContent>
        </StyledPopover>
      )}
    </Wrapper>
  )
}

export default TextToSpeechInfo
