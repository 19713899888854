import { ConfigProps } from '@config/types'

const config = ({ teamcityEnv, releaseVersion, publication }: ConfigProps) => {
  const processedReleaseVersion =
    teamcityEnv !== 'prod' ? releaseVersion.slice(0, 7) : releaseVersion

  return {
    DSN: 'https://bf1d13534a6e41b0b20546fce4a793e5@o101405.ingest.sentry.io/6194957',
    sentryReleaseVersion: `next-${teamcityEnv}-${processedReleaseVersion}-${publication}`,
  } as const satisfies Record<string, unknown>
}

export default config
