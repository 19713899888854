import { useState, useCallback, useRef, useEffect } from 'react'

import useInViewChange from '@hooks/useInViewChange'
import useMouseHover from '@hooks/useMouseHover'
import useAnimatedPreviews from '@components/AnimatedPreview/useAnimatedPreviews'
import { isLiveStream } from '@widgets/Video/utils'
import useIsVideoGeoblocked from '@hooks/useIsVideoGeoblocked'
import { VideoWidgetAPIProps } from '@widgets/Video/types'
import useIsInTeaserPreviewPage from '@hooks/useIsInTeaserPreviewPage'
import isChromatic from 'chromatic/isChromatic'
import { isIos } from '@measures/responsive'

export type UseAnimatedPreviewProps = (args: {
  video?: {
    jwVideoId: string
    duration: number
    geoblocking?: VideoWidgetAPIProps['geoblocking']
    isAnimatedPreviewDisabled?: VideoWidgetAPIProps['isAnimatedPreviewDisabled']
  }
  articleUrl: string
  isAnimatedPreviewEnabled?: boolean
}) => (node: HTMLElement | null) => void

const useAnimatedPreview: UseAnimatedPreviewProps = ({
  video,
  articleUrl,
  isAnimatedPreviewEnabled = true,
}) => {
  const videoId = video?.jwVideoId
  const isLiveStreamVideo = !!video && isLiveStream(video.duration)
  const isPreviewDisabled =
    video?.isAnimatedPreviewDisabled || !isAnimatedPreviewEnabled || isIos()
  const isGeoblocked = useIsVideoGeoblocked(video?.geoblocking)
  const isInTeaserPreviewPage = useIsInTeaserPreviewPage()
  const hasAnimatedPreview =
    !isInTeaserPreviewPage &&
    !!videoId &&
    !isLiveStreamVideo &&
    !isGeoblocked &&
    !isPreviewDisabled &&
    !isChromatic()

  const wrapperRef = useRef<HTMLElement | null>(null)

  const [isInViewport, setIsInViewport] = useState<boolean | null>(null)

  const {
    registerAnimatedPreview,
    deRegisterAnimatedPreview,
    playAnimatedPreview,
  } = useAnimatedPreviews()

  const isWrapperHovered = useMouseHover({
    track: hasAnimatedPreview,
    elementRef: wrapperRef,
  })

  const onInViewChangeHandler = useCallback((inView: any) => {
    setIsInViewport(inView)
  }, [])

  const viewportRef = useInViewChange({
    track: hasAnimatedPreview,
    threshold: 0.75,
    delay: 500,
    onInViewChange: onInViewChangeHandler,
  })

  useEffect(() => {
    if (hasAnimatedPreview) {
      if (isInViewport) {
        registerAnimatedPreview({
          videoId,
          articleUrl,
          wrapperRef,
        })
      } else if (isInViewport === false) {
        deRegisterAnimatedPreview({
          videoId,
          articleUrl,
          wrapperRef,
        })
      }
    }
  }, [
    registerAnimatedPreview,
    deRegisterAnimatedPreview,
    isInViewport,
    videoId,
    isGeoblocked,
    articleUrl,
    hasAnimatedPreview,
  ])

  useEffect(() => {
    return () => {
      if (hasAnimatedPreview) {
        deRegisterAnimatedPreview({
          videoId,
          articleUrl,
          wrapperRef,
        })
      }
    }
  }, [
    deRegisterAnimatedPreview,
    videoId,
    isGeoblocked,
    articleUrl,
    hasAnimatedPreview,
  ])

  useEffect(() => {
    if (hasAnimatedPreview && isWrapperHovered) {
      playAnimatedPreview({
        videoId,
        articleUrl,
        wrapperRef,
      })
    }
  }, [
    isWrapperHovered,
    playAnimatedPreview,
    videoId,
    isGeoblocked,
    articleUrl,
    hasAnimatedPreview,
  ])

  const wrapperRefFn = useCallback<ReturnType<UseAnimatedPreviewProps>>(
    (node) => {
      if (hasAnimatedPreview) {
        wrapperRef.current = node
        viewportRef(node)
      }
    },
    [viewportRef, hasAnimatedPreview]
  )

  return wrapperRefFn
}

export default useAnimatedPreview
