import useExecuteOnClientNavigation from '@hooks/useExecuteOnClientNavigation'
import { Image, Link } from '@cook-types/cook'
import { TargetContentType, TeaserVideo } from '@utils/cook/types'
import { useCallback, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { AuthorProps } from '@widgets/Author/types'
import { TeaserImageProps } from '@utils/schema'
import { HeaderTopics } from './useHeaderTopics'
import { AllowedSports } from '@widgets/SportTableInline/types'
import { LiveEventDataSportTypes } from '@utils/formatters/liveEvent'
import { JSONWidgetType as AdRingierJSONWidgetType } from '@widgets/AdRingier'

export interface Breadcrumb {
  title: string
  href: string
}

interface RecipeSchema {
  description: string
  prepTime?: string
  cookTime?: string
  keywords: string
  recipeYield?: string
  nutrition?: string
  recipeIngredient: string[]
  recipeInstructions: { text: string; image?: string }[]
}

interface AuthorSchema {
  description?: string
  links?: string[]
}

export type Breadcrumbs = Breadcrumb[]

export interface PageMetadata {
  [key: string]: unknown

  id?: string
  url: string
  htmlTitle: string
  type: 'article' | 'section'
  teaser?: Record<string, unknown> & {
    targetContentType: TargetContentType
    video?: Pick<TeaserVideo, 'duration' | 'geoblocking'> & {
      videoId: string
      title: string
    }
    isPlus: boolean
    image: TeaserImageProps['teaserImage']
    section?: {
      name?: string
      uniqueName?: string
    }
  }
  hasImmersiveHero?: [boolean]
  title?: string
  teaserTitle?: string
  openGraphTitle?: string
  metaTitle?: string
  metaDescription: string
  publishedDate?: string
  lastModifiedDate?: string
  videoTranscript?: string
  liveVideoStartTime?: string
  liveVideoEndTime?: string
  syndication?: string
  contentOrigin?: string
  catchword?: string
  teaserCatchword?: string
  context: string
  contextId: string
  sideBarAd?: Omit<AdRingierJSONWidgetType, 'slotCodeTablet' | 'slotCodeMobile'>
  welcomeAd?: AdRingierJSONWidgetType
  commentAd?: AdRingierJSONWidgetType
  livetickerAd?: AdRingierJSONWidgetType
  gamification?: {
    active: boolean
    text: string
    icon: string
    link: Link
  }
  sectionIds: string[]
  thirdPartyId?: string
  thirdPartyIds?: {
    brightcoveId?: string
    jwVideoId?: string
    livescoreMatchId?: string
  }
  hasPlaylist?: boolean
  hasSummary?: boolean
  playlistSrc?: string
  cueLiveIds?: string[]
  cueLiveTeaserIds?: string[]
  sportsTableIds?: {
    currentSeasonId: string
    typeOfSport: AllowedSports
  }[]
  sportsEventIds?: {
    matchId: string
    typeOfSport: LiveEventDataSportTypes
  }[]
  hasVideo?: boolean
  sectionUniqueNames?: string[]
  ressort?: string
  breadcrumbs: Breadcrumbs
  fr_link?: string
  de_link?: string
  author?: string
  socialPostImage?: Image
  noIndex?: boolean
  canonical?: string
  authors?: AuthorProps[]
  recipeSchema?: RecipeSchema
  authorSchema?: AuthorSchema
  hasSideBar?: boolean
}

export interface PageData {
  [key: string]: unknown

  metadata: PageMetadata
  topics: HeaderTopics
  children?: unknown[]
  childrenBeta?: unknown[]
}

//! This hook is *always* returning valid PageMetata
//! when getServerSideProps is filtering invalid results
//! by redirecting to 404 / 500 pages etc.
const usePageMetadata = (): PageMetadata => {
  const queryClient = useQueryClient()

  const [pageMetadata, setPageMetadata] = useState<PageMetadata>(
    () => queryClient.getQueryData<PageData>(['page'])?.metadata as PageMetadata
  )

  const updatePageMetadata = useCallback((pageMetadata: any) => {
    setPageMetadata(pageMetadata)
  }, [])

  useExecuteOnClientNavigation(updatePageMetadata)

  return pageMetadata
}

export default usePageMetadata
