import config from '@config'
import { TrackingFnType } from '@hooks/useTracking'

const {
  chatbot: { baseUrl },
  sectionSummary: { answserUrl, questionsUrl },
} = config

export const trackSectionSummaryImpression: TrackingFnType = () => ({
  event: 'home_summary_impression',
})

export const trackClickHandler: TrackingFnType = () => ({
  event: 'home_summary_click',
})

export const getAnswers = async ({ questionId }: { questionId: string }) => {
  const url = new URL(`${baseUrl}${answserUrl}`)
  url.searchParams.append('publication', 'blick')
  url.searchParams.append('questionId', questionId)
  url.searchParams.append('widgetId', 'dailyBriefing')
  url.searchParams.append('context', 'home')

  const response = await fetch(url, {
    method: 'GET',
  })

  if (response.status >= 400 && response.status < 600) {
    throw new Error(`Server responded with ${response.status}`)
  }

  return await response.json()
}

export const getQuestions = async () => {
  const url = new URL(`${baseUrl}${questionsUrl}`)
  url.searchParams.append('publication', 'blick')
  url.searchParams.append('widgetId', 'dailyBriefing')
  url.searchParams.append('context', 'home')

  const response = await fetch(url, {
    method: 'GET',
  })

  if (response.status >= 400 && response.status < 600) {
    throw new Error(`Server responded with ${response.status}`)
  }

  return await response.json()
}
