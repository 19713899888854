import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { desktopCSS, mobileCSS } from '@measures/responsive'
import SportsPartnerComponent, {
  SportsPartnerProps as SportsPartnerComponentProps,
} from '@components/SportsPartner'
import useClientViewportType from '@hooks/useClientViewportType'

export interface SportsPartnerProps {
  partner: SportsPartnerComponentProps['partner']
}

const StyledSportsPartner = styled(SportsPartnerComponent)`
  ${({
    theme: {
      spacing: { spacing16 },
      measures: { outerPadding },
    },
  }) => css`
    margin-top: -${spacing16};

    ${desktopCSS(css`
      display: none;
    `)};

    ${mobileCSS(css`
      margin-left: -${outerPadding.mobile};
      margin-right: -${outerPadding.mobile};
      width: calc(100% + 2 * ${outerPadding.mobile});
    `)};
  `}
`

const SportsPartner: FunctionComponent<SportsPartnerProps> = ({ partner }) =>
  useClientViewportType() !== 'desktop' ? (
    <StyledSportsPartner partner={partner} />
  ) : null

export default SportsPartner
