import { FunctionComponent, useCallback, useEffect } from 'react'
import config from '@config'
import { QueryClient, useQueryClient } from '@tanstack/react-query'
import nanoid from '@utils/random'

const {
  abTest: { windowKey, localStorageUpdateKey, featureFlagsEntries },
} = config

const variantKeys = Object.keys(featureFlagsEntries)

const updateVariantsFromLocalStorage = (queryClient: QueryClient): void => {
  try {
    const featureFlagStorageInfo =
      JSON.parse(localStorage.getItem(windowKey) as any) ?? {}

    const modifiedVariantKeys: string[] = []

    variantKeys.forEach((variantName) => {
      if (featureFlagStorageInfo.hasOwnProperty(variantName)) {
        const reactQueryValue = queryClient.getQueryData([
          windowKey,
          variantName,
        ])
        const localStorageValue = featureFlagStorageInfo[variantName]
        if (reactQueryValue !== localStorageValue) {
          modifiedVariantKeys.push(variantName)
          queryClient.setQueryData([windowKey, variantName], localStorageValue)
        }
      } else {
        const reactQueryValue = queryClient.getQueryData([
          windowKey,
          variantName,
        ])
        if (typeof reactQueryValue !== 'undefined') {
          modifiedVariantKeys.push(variantName)
          const hasOptimizeValue = window[windowKey].hasOwnProperty(
            `_${variantName}`
          )

          if (hasOptimizeValue) {
            queryClient.setQueryData(
              [windowKey, variantName],
              window[windowKey][`_${variantName}`]
            )
          } else {
            queryClient.removeQueries({
              queryKey: [windowKey, variantName],
              exact: true,
            })
          }
        }
      }
    })

    queryClient.invalidateQueries({
      predicate: (query) =>
        query.queryKey[0] === windowKey &&
        modifiedVariantKeys.includes(query.queryKey[1] as string),
    })

    queryClient.setQueryData(['blick_abTest', localStorageUpdateKey], nanoid())
  } catch (err) {
    //! nothing we can do!
    console.error(err)
  }
}

const registerABTestListeners = (queryClient: QueryClient): void => {
  window[windowKey]._registered = true
  variantKeys.forEach((variantKey) => {
    Object.defineProperty(window[windowKey], variantKey, {
      configurable: true,
      enumerable: true,
      set: function (value) {
        try {
          const featureFlagStorageInfo = (JSON.parse(
            localStorage.getItem(windowKey) as any
          ) ?? {}) as Record<string, unknown>

          if (!featureFlagStorageInfo.hasOwnProperty(variantKey)) {
            queryClient.setQueryData([windowKey, variantKey], value)
            queryClient.invalidateQueries({
              queryKey: [windowKey, variantKey],
              exact: true,
            })
          }
        } catch (err) {
          //! nothing we can do!
          console.error(err)
          queryClient.setQueryData([windowKey, variantKey], value)
          queryClient.invalidateQueries({
            queryKey: [windowKey, variantKey],
            exact: true,
          })
        }

        this[`_${variantKey}`] = value
      },
      get: function () {
        return this[`_${variantKey}`]
      },
    })
  })
}

const ABTestManager: FunctionComponent = () => {
  const queryClient = useQueryClient()

  const onLocalStorageChanged = useCallback<(event: StorageEvent) => void>(
    (event) => {
      if (event.key === windowKey) {
        updateVariantsFromLocalStorage(queryClient)
      }
    },
    [queryClient]
  )

  useEffect(() => {
    if (!window[windowKey]._registered) {
      registerABTestListeners(queryClient)
      updateVariantsFromLocalStorage(queryClient)
      window[windowKey]._updateVariantsFromLocalStorage = () => {
        updateVariantsFromLocalStorage(queryClient)
      }
    }

    addEventListener('storage', onLocalStorageChanged)

    return () => {
      removeEventListener('storage', onLocalStorageChanged)
    }
  }, [onLocalStorageChanged, queryClient])

  return null
}

export default ABTestManager
