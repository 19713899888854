import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { TransformedDataHockey, TransformedDataSoccer } from '../utils'
import TeamLogo from '../TeamLogo'
import { desktopCSS, mobileCSS, tabletCSS } from '@measures/responsive'

type HockeySoccerProps = {
  sportsData: TransformedDataHockey | TransformedDataSoccer
  viewType: 'full' | 'compact'
  className?: string
}

type WrapperProps = {
  hasScoreExplanation: boolean
}

type MiddleInfoProps = Pick<WrapperProps, 'hasScoreExplanation'>
type TeamLogoProps = { type: 'away' | 'home' }
type TeamNameProps = TeamLogoProps

const Wrapper = styled.div<WrapperProps>`
  ${({
    hasScoreExplanation,
    theme: {
      spacing: { spacing8, spacing16 },
      color: {
        primary: { primary02, primary01 },
      },
    },
  }) => css`
    /*
    * L1: Logo 1
    * N1: Team Name 1
    * SC: Middle info (score or "VS")
    * SE: Score explanation
    * N2: Team Name 2
    * L2: Logo 2
    */
    display: grid;
    grid-template-rows: repeat(2, auto);
    align-items: center;
    grid-column-gap: ${spacing8};
    color: ${primary02};
    background-color: ${primary01};
    width: 100%;
    box-sizing: border-box;
    padding: ${spacing16};
    height: 51px;

    ${desktopCSS(css`
      height: 94px;
      grid-template-areas:
        'L1 N1 SC N2 L2'
        'L1 N1 ${hasScoreExplanation ? 'SE' : 'SC'} N2 L2';
      grid-template-columns: 48px minmax(0, 1fr) auto minmax(0, 1fr) 48px;
    `)};

    ${tabletCSS(css`
      height: 98px;
      grid-template-areas:
        'L1 SC L2'
        'N1 ${hasScoreExplanation ? 'SE' : 'SC'} N2';
      grid-template-columns: 100px minmax(0, 1fr) 100px;
      grid-row-gap: ${spacing8};
    `)};

    ${mobileCSS(css`
      height: 98px;
      grid-template-areas:
        'L1 SC L2'
        'N1 ${hasScoreExplanation ? 'SE' : 'SC'} N2';
      grid-template-columns: 100px minmax(0, 1fr) 100px;
      grid-row-gap: ${spacing8};
    `)}
  `}
`

const StyledTeamLogo = styled(TeamLogo)<TeamLogoProps>`
  ${({ type }) => css`
    grid-area: ${type === 'home' ? 'L1' : 'L2'};
  `}
`

const TeamName = styled.div<TeamNameProps>`
  ${({
    type,
    theme: {
      typography: {
        eyebrows: {
          medium2: { bundledCSS: eyebrowsMedium2 },
        },
      },
      utils: { ellipsis },
    },
  }) => css`
    ${eyebrowsMedium2};
    text-transform: uppercase;
    ${ellipsis('100%', 1)};
    display: inline-block;
    grid-area: ${type === 'home' ? 'N1' : 'N2'};

    ${desktopCSS(css`
      justify-self: flex-${type === 'home' ? 'start' : 'end'};
    `)}

    ${tabletCSS(css`
      justify-self: center;
    `)}

      ${mobileCSS(css`
      justify-self: center;
    `)}
  `}
`

const ScoreExplanation = styled.span`
  ${({
    theme: {
      typography: {
        eyebrows: {
          small2: { bundledCSS: eyebrowsSmall2 },
        },
      },
    },
  }) => css`
    ${eyebrowsSmall2}
    grid-area: SE;
    justify-self: center;
    align-self: flex-start;
  `}
`

const MiddleInfo = styled.span<MiddleInfoProps>`
  ${({
    hasScoreExplanation,
    theme: {
      typography: {
        headings: {
          xxxlarge: { bundledCSS: headingsXXXLarge },
        },
      },
    },
  }) => css`
    ${headingsXXXLarge};
    grid-area: SC;
    justify-self: center;
    align-self: ${hasScoreExplanation ? 'flex-end' : 'center'};
  `}
`

const HockeySoccer: FunctionComponent<HockeySoccerProps> = ({
  sportsData,
  viewType,
  className,
}) => {
  const {
    homeTeam,
    homeTeamScore,
    awayTeam,
    awayTeamScore,
    isLive,
    scoreExplanation,
    hasEnded,
  } = sportsData

  return (
    <Wrapper className={className} hasScoreExplanation={!!scoreExplanation}>
      <StyledTeamLogo
        type="home"
        url={homeTeam.logoUrl}
        name={homeTeam.name}
        imageWidth={48}
      />
      {viewType === 'full' && <TeamName type="home">{homeTeam.name}</TeamName>}
      <MiddleInfo hasScoreExplanation={!!scoreExplanation}>
        {!isLive && !hasEnded ? 'VS' : homeTeamScore + ' : ' + awayTeamScore}
      </MiddleInfo>
      {scoreExplanation && (
        <ScoreExplanation>{scoreExplanation}</ScoreExplanation>
      )}
      {viewType === 'full' && <TeamName type="away">{awayTeam.name}</TeamName>}
      <StyledTeamLogo
        type="away"
        url={awayTeam.logoUrl}
        name={awayTeam.name}
        imageWidth={48}
      />
    </Wrapper>
  )
}

export default HockeySoccer
