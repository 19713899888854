import { forwardRef } from 'react'
import styled, { css } from 'styled-components'

interface CTAButtonProps {
  children: string
  size?: 'small' | 'large'
  colorVariant?: 'red' | 'green' | 'white' | 'black' | 'grey'
  disabled?: boolean
  className?: string
  onClick?: () => void
}

const ButtonWrapper = styled.button<
  Pick<CTAButtonProps, 'size' | 'disabled' | 'colorVariant'>
>`
  ${({
    theme: {
      color: {
        primary: { blickRed: blickRedColor },
        secondary: {
          darkRed: darkRedColor,
          greenAccessible: greenAccessibleColor,
          darkGreen: darkGreenColor,
        },
        tertiary: { grey200: grey200Color },
      },
      spacing: { spacing4, spacing8, spacing12, spacing16, spacing24 },
    },
    colorVariant,
    disabled,
    size,
  }) => css`
    display: flex;
    width: fit-content;
    justify-content: center;
    align-items: center;

    height: 48px;
    padding: ${spacing12} ${spacing24};
    gap: ${spacing8};

    appearance: none;
    text-decoration: none;
    border: 0;
    cursor: pointer;

    border-radius: 50px;
    background-color: ${blickRedColor};

    @media (hover: hover) {
      &:hover {
        background-color: ${darkRedColor};
      }
    }

    ${disabled &&
    css`
      pointer-events: none;
      background-color: ${grey200Color};
    `};

    ${size === 'small' &&
    css`
      height: 40px;
      padding: ${spacing4} ${spacing16};
    `};

    ${colorVariant === 'green' &&
    css`
      background-color: ${greenAccessibleColor};

      @media (hover: hover) {
        &:hover {
          background-color: ${darkGreenColor};
        }
      }
    `};
  `}
`

const ButtonText = styled.div<Pick<CTAButtonProps, 'disabled'>>`
  ${({
    theme: {
      typography: {
        subheads: {
          small1: { bundledCSS: small1SubheadsCSS },
        },
      },
      color: {
        primary: { primary02: primary02Color },
        tertiary: { grey400: grey400Color },
      },
    },
    disabled,
  }) => css`
    ${small1SubheadsCSS};
    color: ${disabled ? grey400Color : primary02Color};
  `}
`

const PrimaryCTAButton = forwardRef<HTMLButtonElement, CTAButtonProps>(
  (
    {
      className,
      children,
      size = 'large',
      colorVariant = 'red',
      disabled = false,
      onClick,
    },
    ref
  ) => (
    <ButtonWrapper
      ref={ref}
      className={className}
      size={size}
      colorVariant={colorVariant}
      disabled={disabled}
      onClick={onClick}>
      <ButtonText disabled={disabled}>{children}</ButtonText>
    </ButtonWrapper>
  )
)

PrimaryCTAButton.displayName = 'PrimaryCTAButton'

export default PrimaryCTAButton
