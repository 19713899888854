import useIsGiftedArticle from '@hooks/useIsGiftedArticle'
import { desktopLeftAlignedCSS } from '@measures/responsive'
import { FunctionComponent, useCallback, useState } from 'react'
import styled, { css, useTheme } from 'styled-components'
import IconButton from './Buttons/IconButton'

const StyledGiftedArticleBanner = styled.div`
  ${({
    theme: {
      color: {
        primary: { primary02 },
        tertiary: { grey300 },
      },
      spacing: { spacing16 },
    },
  }) => css`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    z-index: 10000000;

    background-color: ${primary02};
    padding: ${spacing16};
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid ${grey300};
    ${desktopLeftAlignedCSS(css`
      justify-content: flex-start;
    `)};
  `}
`

const StyledIconButton = styled(IconButton)`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
`

const StyledText = styled.div`
  ${({
    theme: {
      spacing: { spacing24 },
      typography: {
        bodycopy: {
          xsmall: { bundledCSS: bodycopyXSmallCSS },
        },
      },
    },
  }) => css`
    ${bodycopyXSmallCSS};
    margin-right: ${spacing24};
  `}
`

const GiftedArticleBanner: FunctionComponent = () => {
  const isGiftedArticle = useIsGiftedArticle()
  const [isHidden, setIsHidden] = useState<boolean>(false)
  const {
    color: {
      primary: { primary01 },
    },
  } = useTheme()

  const hideBanner = useCallback(() => {
    setIsHidden(true)
  }, [])

  return (
    isGiftedArticle &&
    !isHidden && (
      <StyledGiftedArticleBanner>
        <StyledText>
          Dieser Artikel wurde dir von einem Blick+-Abonnenten geschenkt.
        </StyledText>
        <StyledIconButton
          iconName="navigation-close"
          iconSize={24}
          iconColor={primary01}
          onClick={hideBanner}
          ariaLabel="close"
        />
      </StyledGiftedArticleBanner>
    )
  )
}

export default GiftedArticleBanner
