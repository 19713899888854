import Blink from '@components/Blink'
import { desktopCSS, mobileCSS, tabletCSS } from '@measures/responsive'
import { ReactNode, forwardRef } from 'react'
import styled, { css } from 'styled-components'
import { ListItemTeaserAPIProps } from './types'

type TeaserWrapperProps = {
  clickableProps: ListItemTeaserAPIProps['link']
  isOnSearchPage?: boolean
  children?: ReactNode
}

const BlinkWrapper = styled(Blink)<Pick<TeaserWrapperProps, 'isOnSearchPage'>>`
  ${({
    theme: {
      color: {
        tertiary: { grey400 },
      },
      spacing: { spacing16, spacing24, spacing32 },
    },
    isOnSearchPage,
  }) => css`
    position: relative;
    text-decoration: none;
    overflow: hidden;
    display: grid;
    border-bottom: 1px solid ${grey400};

    ${desktopCSS(css`
      grid-template-columns: minmax(0, 1fr) 300px;
      grid-column-gap: ${spacing32};
      padding-bottom: ${spacing24};
    `)}

    ${tabletCSS(css`
      grid-template-columns: minmax(0, 1fr) 171px;
      grid-column-gap: ${spacing16};
      padding-bottom: ${spacing16};
    `)};

    ${mobileCSS(css`
      grid-template-columns: minmax(0, 1fr) 171px;
      grid-column-gap: ${spacing16};
      padding-bottom: ${spacing16};
    `)};
    ${isOnSearchPage &&
    css`
      &:last-child,
      &:nth-child(3) {
        border-bottom: 0;
      }
    `}
  `}
`

const TeaserWrapper = forwardRef<HTMLAnchorElement, TeaserWrapperProps>(
  ({ children, clickableProps, isOnSearchPage }, ref) => (
    <BlinkWrapper {...clickableProps} isOnSearchPage={isOnSearchPage} ref={ref}>
      {children}
    </BlinkWrapper>
  )
)

TeaserWrapper.displayName = 'TeaserWrapper'

export default TeaserWrapper
