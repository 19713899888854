import FreeRatioImage from '@components/FreeRatioImage'
import { desktopCSS, mobileCSS, tabletCSS } from '@measures/responsive'
import { Image } from '@utils/cook/types'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { ListItemTeaserAPIProps } from './types'

export interface CommercialBadgeProps {
  commercialType: ListItemTeaserAPIProps['commercialType']
  commercialLogo?: Image<'free'>
  children: string
}

const maxWidth = 72
const maxHeight = 20

const Wrapper = styled.div<{ isPromo: boolean }>`
  ${({
    isPromo,
    theme: {
      spacing: { spacing8, spacing12 },
    },
  }) => css`
    display: grid;
    margin-bottom: ${spacing12};

    ${desktopCSS(
      isPromo
        ? css`
            display: none;
          `
        : css`
            grid-template-columns: auto auto;
            align-items: center;
            column-gap: ${spacing8};
          `
    )};

    ${!isPromo &&
    tabletCSS(css`
      grid-template-rows: auto auto;
      row-gap: ${spacing8};
    `)};

    ${!isPromo &&
    mobileCSS(css`
      grid-template-rows: auto auto;
      row-gap: ${spacing8};
    `)};
  `}
`

const StyledCommercialLabel = styled.div`
  ${({
    theme: {
      typography: {
        eyebrows: {
          small1: { bundledCSS: eyebrowsSmall1 },
        },
      },
      color: {
        tertiary: { grey800 },
      },
      utils: { ellipsis },
    },
  }) => css`
    ${eyebrowsSmall1}
    color: ${grey800};
    text-transform: uppercase;

    ${ellipsis('100%', 1)};
  `}
`

const StyledLogo = styled.div`
  position: relative;
`

const CommercialLabel: FunctionComponent<CommercialBadgeProps> = ({
  children,
  commercialType,
  commercialLogo,
}) => {
  return (
    <Wrapper isPromo={commercialType === 'promo'}>
      <StyledCommercialLabel>{children}</StyledCommercialLabel>
      {commercialLogo?.src && (
        <StyledLogo>
          <FreeRatioImage
            src={commercialLogo.src}
            alt={commercialLogo.alt ?? ''}
            originalDimensions={{
              width: commercialLogo.width,
              height: commercialLogo.height,
            }}
            constraints={{
              desktop: { width: maxWidth, height: maxHeight },
              tablet: { width: maxWidth, height: maxHeight },
              mobile: { width: maxWidth, height: maxHeight },
              mobileSmall: { width: maxWidth, height: maxHeight },
            }}
            crops={commercialLogo.crops ?? {}}
          />
        </StyledLogo>
      )}
    </Wrapper>
  )
}

export default CommercialLabel
