import { ConfigProps } from '@config/types'

const config = ({}: ConfigProps) => {
  return {
    answserUrl: '/api/v1/answer',
    questionsUrl: '/api/v1/questions',
    moreInfoLink: 'https://www.blick.ch/id19711657',
    feedbackLink: 'https://form.typeform.com/to/ioxxDi25',
  } as const
}

export default config
