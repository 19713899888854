import useDisableThirdPartyScripts from '@hooks/useDisableThirdPartyScripts'
import useSubscriptionStatus from '@hooks/useSubscriptionStatus'
import useCMPHasUserInteracted from '@hooks/useCMPHasUserInteracted'
import { User } from '@hooks/useUser'
import { FunctionComponent, useEffect } from 'react'
import { QueryClient, useQueryClient } from '@tanstack/react-query'
import { SubscriptionData } from './Auth/types'
import config from '@config'
import { convertToStrings } from '@hooks/useTracking/utils'

const {
  abTest: { windowKey },
  backend: { deploymentEnv },
} = config

const enrichAndPushEvent = (
  event: Record<string, unknown>,
  queryClient: QueryClient,
  autoIncrementId: number
) => {
  const user = queryClient.getQueryData<User>(['user'])
  const subscriptionData = queryClient.getQueryData<SubscriptionData>([
    'subscription-data',
  ])
  const enableTrackingDebugLog =
    queryClient.getQueryData<'show'>([windowKey, 'showTrackingDebugView']) ===
    'show'

  const enrichedEvent = convertToStrings({
    ...event,
    ...(!subscriptionData
      ? {}
      : {
          ...subscriptionData,
          user_status: !user ? 'notLoggedIn' : subscriptionData?.user_status,
        }),
    userId: (event?.userId as string) ?? user?.sub,
  })

  window.dataLayer.push(enrichedEvent)

  if (deploymentEnv === 'dev' || enableTrackingDebugLog) {
    queryClient.setQueryData(
      ['event-debugger', (enrichedEvent as any).event, autoIncrementId],
      //! Because JSON doesn't support `undefined`, this will get rid of all the explicitly
      //! undefined variables. It will also create deep copies of the obeject each time it's parsed.
      JSON.parse(JSON.stringify(enrichedEvent))
    )
  }
}

const TrackingManager: FunctionComponent = () => {
  const queryClient = useQueryClient()
  const disableThirdPartyScripts = useDisableThirdPartyScripts()
  const { subscriptionStatus } = useSubscriptionStatus()

  //! We need to wait until the user has interacted with the CMP popup,
  //! because of how GTM handles events.
  const hasUserInteracted = useCMPHasUserInteracted()

  useEffect(() => {
    if (!disableThirdPartyScripts && subscriptionStatus && hasUserInteracted) {
      if (window.eventQueueDataLayer) {
        window.eventQueueDataLayer.forEach((event, eventIndex) =>
          enrichAndPushEvent(event, queryClient, eventIndex)
        )
        ;(window as any).eventQueueDataLayer = {
          length: window.eventQueueDataLayer.length,
          push: (...events: Record<string, unknown>[]) => {
            length++
            events.forEach((event) =>
              enrichAndPushEvent(event, queryClient, length + 1)
            )
          },
          forEach: window.eventQueueDataLayer.forEach,
        }
      } else {
        console.error('eventQueueDataLayer is not defined! it should be!')
      }
    }
  }, [
    subscriptionStatus,
    hasUserInteracted,
    queryClient,
    disableThirdPartyScripts,
  ])

  return null
}

export default TrackingManager
