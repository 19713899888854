import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { desktopCSS, mobileCSS, tabletCSS } from '@measures/responsive'
import useClientViewportType from '@hooks/useClientViewportType'

interface SideFlowWrapperProps {
  children: ReactNode
}

const StyledSideFlowWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing24 },
    },
  }) => css`
    ${desktopCSS(css`
      grid-column: span 4;
      display: grid;
      height: 100%;
      grid-template-columns: minmax(0, 1fr);
      grid-template-rows: repeat(3, auto) minmax(0, 1fr);
      grid-row-gap: ${spacing24};
      align-items: flex-start;
    `)}

    ${tabletCSS(css`
      display: none;
    `)};

    ${mobileCSS(css`
      display: none;
    `)};
  `}
`

const SideFlowWrapper: FunctionComponent<SideFlowWrapperProps> = ({
  children,
}) => {
  const clientViewportType = useClientViewportType()

  if (!['server', 'desktop'].includes(clientViewportType)) {
    return null
  }

  return <StyledSideFlowWrapper>{children}</StyledSideFlowWrapper>
}

export default SideFlowWrapper
