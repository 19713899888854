import { useState, useEffect } from 'react'

type DeviceType = 'ios' | 'android'

const useDeviceType = (): DeviceType | null => {
  const [deviceType, setDeviceType] = useState<null | DeviceType>(null)

  useEffect(() => {
    const userAgent =
      typeof navigator !== 'undefined' ? navigator.userAgent : ''

    if (userAgent.match(/iPhone|iPad|iPod/i)) {
      setDeviceType('ios')
    } else if (userAgent.match(/Android/i)) {
      setDeviceType('android')
    }
  }, [])

  return deviceType
}

export default useDeviceType
