import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent } from 'react'
import GalleryWidgetWrapper from './GalleryWidget'
import { GalleryWidget } from './types'
import GalleryContentContext from '@contexts/galleryContent'
import StretchContext from '@contexts/storyTellingFullWidth'
import useViewportType from '@hooks/useViewport/useViewportType'
import useGetPageIdentity from '@hooks/useGetPageIdentity'
import useIsInRecipeIngredientsContent from '@hooks/useIsInRecipeIngredientsContent'

const Gallery: FunctionComponent<GalleryWidget> = (props) => {
  const { imageWidth } = props
  const { isHerotelling } = useGetPageIdentity()

  const isInRecipeIngredients = useIsInRecipeIngredientsContent()

  const viewportType = useViewportType()

  const shouldStretchToCardWidth = isInRecipeIngredients

  const shouldStretch =
    isHerotelling &&
    (imageWidth === 'fullwidth' ||
      (viewportType === 'mobile' && imageWidth === 'narrow'))

  const shouldStretchToHeaderWidth = isHerotelling && imageWidth === 'fullwidth'

  return (
    <GalleryContentContext.Provider value={true}>
      <StretchContext.Provider
        value={{
          shouldStretch,
          shouldStretchToHeaderWidth,
          shouldStretchToCardWidth,
        }}>
        <GalleryWidgetWrapper {...props} />
      </StretchContext.Provider>
    </GalleryContentContext.Provider>
  )
}

const widget = {
  kind: ['widget', 'gallery'],
  component: Gallery,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
