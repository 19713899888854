import { FunctionComponent, useCallback, useMemo } from 'react'
import styled, { css } from 'styled-components'
import Blink from '@components/Blink'
import SvgIcon from '@components/SvgIcon'
import color from '@themes/default/color'
import { ScoreboardAPIProps } from '@widgets/Scoreboard/types'
import FreeRatioImage from '@components/FreeRatioImage'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import useViewportTracking from '@hooks/useViewportTracking'

export type ScoreboardStreamingLinkProps = Required<
  Pick<ScoreboardAPIProps, 'streaming'>
>

const StyledLinkContainer = styled('div')`
  display: flex;
  justify-content: center;
`

const StyledLink = styled(Blink)`
  ${({
    theme: {
      typography: {
        subheads: {
          small1: { bundledCSS: small1SubheadingCSS },
        },
      },
      spacing: { spacing8, spacing16, spacing24 },
      color: {
        primary: { primary01: primary01Color, primary02: primary02Color },
      },
    },
  }) => css`
    align-items: center;
    align-self: center;
    color: ${primary01Color};
    background-color: ${primary02Color};
    border: 1px solid ${primary01Color};
    border-radius: ${spacing24};
    display: flex;
    column-gap: ${spacing8};
    justify-content: center;
    align-content: center;
    padding: ${spacing8} ${spacing16};
    margin: 0 ${spacing16} ${spacing16} ${spacing16};
    text-decoration: none;
    ${small1SubheadingCSS};
  `}
`

const StyledTitle = styled('span')`
  ${({
    theme: {
      utils: { ellipsis },
    },
  }) => css`
    ${ellipsis('100%', 2)};
  `};
`

const StyledImageContainer = styled('span')`
  flex-shrink: 0;
`

const StyledArrow = styled(SvgIcon)`
  flex-shrink: 0;
`

const ScoreboardStreamingLink: FunctionComponent<
  ScoreboardStreamingLinkProps
> = ({ streaming: { logo, link, title } }) => {
  const onClickTrackingFn = useCallback<TrackingFnType>(
    () => ({
      element: 'streaming_partner',
      event: 'element_click',
      link_url: link?.href,
    }),
    [link?.href]
  )

  const onImpressionTrackingFn = useCallback<TrackingFnType>(
    () => ({
      element: 'streaming_partner',
      event: 'element_impression',
      link_url: link?.href,
    }),
    [link?.href]
  )

  const onClick = useTracking(onClickTrackingFn)
  const onImpression = useTracking(onImpressionTrackingFn)

  const viewportRef = useViewportTracking({
    onImpression,
    track: true,
  })

  const { src, alt, height, width, crops } = logo

  const constraints = useMemo(() => {
    const constraint = { width: 150, height: 22 }

    return {
      desktop: constraint,
      tablet: constraint,
      mobile: constraint,
      mobileSmall: constraint,
    }
  }, [])

  return (
    <StyledLinkContainer ref={viewportRef}>
      <StyledLink {...link} onClick={onClick}>
        <StyledTitle>{title}</StyledTitle>
        <StyledImageContainer>
          <FreeRatioImage
            src={src}
            alt={alt}
            originalDimensions={{ width, height }}
            constraints={constraints}
            crops={crops ?? {}}
          />
        </StyledImageContainer>
        <StyledArrow
          color={color.primary.primary01}
          iconName="chevron-right"
          size={20}
        />
      </StyledLink>
    </StyledLinkContainer>
  )
}

export default ScoreboardStreamingLink
