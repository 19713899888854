import config from '@config'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import {
  LiveEventDatas,
  LiveEventDataSportTypes,
} from '@utils/formatters/liveEvent'
import {
  LiveEventTransformedFormula1,
  transformLiveEvent as transformLiveEventFormula1,
} from '@utils/formatters/formula1'
import {
  LiveEventTransformedHockey,
  transformLiveEvent as transformLiveEventHockey,
} from '@utils/formatters/hockey'
import {
  LiveEventTransformedSki,
  transformLiveEvent as transformLiveEventSki,
} from '@utils/formatters/ski'
import {
  LiveEventTransformedSoccer,
  transformLiveEvent as transformLiveEventSoccer,
} from '@utils/formatters/soccer'
import {
  LiveEventTransformedTennis,
  transformLiveEvent as transformLiveEventTennis,
} from '@utils/formatters/tennis'
import { LIVE_EVENT_DATA_SPORT_TYPES } from '@utils/formatters/common'

export type EnrichedLiveEventTransformed =
  | EnrichedLiveEventTransformedFormula1
  | EnrichedLiveEventTransformedTennis
  | EnrichedLiveEventTransformedSoccer
  | EnrichedLiveEventTransformedHockey
  | EnrichedLiveEventTransformedSki

export interface EnrichedLiveEventTransformedFormula1 {
  isPlaceholderData?: boolean
  sportsEventCacheDurationInMilliseconds: number
  sportsEventId: string
  sportsEventType: typeof LIVE_EVENT_DATA_SPORT_TYPES.FORMULA1
  formattedData: LiveEventTransformedFormula1
}

export interface EnrichedLiveEventTransformedTennis {
  isPlaceholderData?: boolean
  sportsEventCacheDurationInMilliseconds: number
  sportsEventId: string
  sportsEventType: typeof LIVE_EVENT_DATA_SPORT_TYPES.TENNIS
  formattedData: LiveEventTransformedTennis
}

export interface EnrichedLiveEventTransformedSoccer {
  isPlaceholderData?: boolean
  sportsEventCacheDurationInMilliseconds: number
  sportsEventId: string
  sportsEventType: typeof LIVE_EVENT_DATA_SPORT_TYPES.SOCCER
  formattedData: LiveEventTransformedSoccer
}

export interface EnrichedLiveEventTransformedHockey {
  isPlaceholderData?: boolean
  sportsEventCacheDurationInMilliseconds: number
  sportsEventId: string
  sportsEventType: typeof LIVE_EVENT_DATA_SPORT_TYPES.HOCKEY
  formattedData: LiveEventTransformedHockey
}

export interface EnrichedLiveEventTransformedSki {
  isPlaceholderData?: boolean
  sportsEventCacheDurationInMilliseconds: number
  sportsEventId: string
  sportsEventType: typeof LIVE_EVENT_DATA_SPORT_TYPES.SKI
  formattedData: LiveEventTransformedSki
}

interface TransformDataProps {
  expectedTypeOfSport: LiveEventDataSportTypes
  expectedMatchId: string
  data: unknown
}

export type UseSportsEventProps =
  | UseSportsEventPropsUnknownSport
  | UseSportsEventPropsFormula1
  | UseSportsEventPropsHockey
  | UseSportsEventPropsSki
  | UseSportsEventPropsSoccer
  | UseSportsEventPropsTennis

export interface UseSportsEventPropsCommon {
  matchId: string
  shouldRefetch?: boolean
  placeholderData?: any
}

export interface UseSportsEventPropsUnknownSport
  extends UseSportsEventPropsCommon {
  typeOfSport: LiveEventDataSportTypes
}

export interface UseSportsEventPropsFormula1 extends UseSportsEventPropsCommon {
  typeOfSport: typeof LIVE_EVENT_DATA_SPORT_TYPES.FORMULA1
}

export interface UseSportsEventPropsHockey extends UseSportsEventPropsCommon {
  typeOfSport: typeof LIVE_EVENT_DATA_SPORT_TYPES.HOCKEY
}

export interface UseSportsEventPropsSki extends UseSportsEventPropsCommon {
  typeOfSport: typeof LIVE_EVENT_DATA_SPORT_TYPES.SKI
}

export interface UseSportsEventPropsSoccer extends UseSportsEventPropsCommon {
  typeOfSport: typeof LIVE_EVENT_DATA_SPORT_TYPES.SOCCER
}

export interface UseSportsEventPropsTennis extends UseSportsEventPropsCommon {
  typeOfSport: typeof LIVE_EVENT_DATA_SPORT_TYPES.TENNIS
}

const {
  sports: { baseUrl: sportApiUrl, eventUrlTemplate: sportsEventUrlTemplate },
} = config

const validMatchId = (matchId: UseSportsEventProps['matchId']) =>
  !!matchId && matchId !== '0'

const validTypeOfSport = (typeOfSport: UseSportsEventProps['typeOfSport']) =>
  Object.values(LIVE_EVENT_DATA_SPORT_TYPES).includes(typeOfSport)

const transformLiveEvent = (data: LiveEventDatas) => {
  switch (data.sportsEventType) {
    case LIVE_EVENT_DATA_SPORT_TYPES.FORMULA1: {
      return transformLiveEventFormula1(data)
    }

    case LIVE_EVENT_DATA_SPORT_TYPES.HOCKEY: {
      return transformLiveEventHockey(data)
    }

    case LIVE_EVENT_DATA_SPORT_TYPES.SKI: {
      return transformLiveEventSki(data)
    }

    case LIVE_EVENT_DATA_SPORT_TYPES.SOCCER: {
      return transformLiveEventSoccer(data)
    }

    case LIVE_EVENT_DATA_SPORT_TYPES.TENNIS: {
      return transformLiveEventTennis(data)
    }
  }
}

const transformData = ({
  expectedTypeOfSport,
  expectedMatchId,
  data,
}: TransformDataProps) => {
  const isValid = isValidSportsEvent(data)

  if (!isValid) {
    throw new Error(
      `Invalid sports event data for sport: ${expectedTypeOfSport} with matchId: ${expectedMatchId}`
    )
  } else {
    const {
      sportsEventType,
      sportsEventId,
      sportsEventCacheDurationInMilliseconds,
    } = data
    return {
      sportsEventCacheDurationInMilliseconds,
      sportsEventType,
      sportsEventId,
      formattedData: transformLiveEvent(data),
    } as EnrichedLiveEventTransformed
  }
}

const isValidSportsEvent = (
  sportsEventData: any
): sportsEventData is LiveEventDatas =>
  typeof sportsEventData === 'object' &&
  Object.values(LIVE_EVENT_DATA_SPORT_TYPES).includes(
    sportsEventData.sportsEventType
  ) &&
  sportsEventData.sportsEventType !== 'NO_SPORT'

const composeRequestUrl = ({
  matchId,
  typeOfSport,
}: Pick<UseSportsEventProps, 'matchId' | 'typeOfSport'>) =>
  `${sportApiUrl}${sportsEventUrlTemplate
    .replace('{SPORT}', typeOfSport)
    .replace('{EVENT_ID}', matchId)}`

const getSportsEventData =
  ({
    expectedTypeOfSport,
    expectedMatchId,
  }: Pick<TransformDataProps, 'expectedTypeOfSport' | 'expectedMatchId'>) =>
  async (requestUrl: string) => {
    return transformData({
      expectedTypeOfSport,
      expectedMatchId,
      data: await (await fetch(requestUrl)).json(),
    })
  }

type UseSportsEventReturnValue<T> = UseQueryResult<
  T extends UseSportsEventPropsSoccer
    ? EnrichedLiveEventTransformedSoccer
    : T extends UseSportsEventPropsTennis
      ? EnrichedLiveEventTransformedTennis
      : T extends UseSportsEventPropsHockey
        ? EnrichedLiveEventTransformedHockey
        : T extends UseSportsEventPropsFormula1
          ? EnrichedLiveEventTransformedFormula1
          : T extends UseSportsEventPropsSki
            ? EnrichedLiveEventTransformedSki
            : EnrichedLiveEventTransformed
>

function useSportsEvent<T extends UseSportsEventProps>({
  matchId,
  typeOfSport,
  shouldRefetch,
  placeholderData,
}: T): UseSportsEventReturnValue<T> {
  const validRequestData =
    validMatchId(matchId) && validTypeOfSport(typeOfSport)

  return useQuery({
    queryKey: ['sportsEvent', typeOfSport, matchId],
    queryFn: () =>
      getSportsEventData({
        expectedTypeOfSport: typeOfSport,
        expectedMatchId: matchId,
      })(composeRequestUrl({ matchId, typeOfSport })),
    ...(placeholderData ? { placeholderData } : {}),
    enabled: validRequestData,
    refetchOnMount: 'always',
    refetchInterval: (query) =>
      !shouldRefetch
        ? false
        : (query.state.data?.sportsEventCacheDurationInMilliseconds ?? false),
  })
}

export { transformData, getSportsEventData, composeRequestUrl }

export default useSportsEvent
