import styled, { css } from 'styled-components'
import PrimaryButton from '@components/Buttons/Primary'

const StyledButton = styled(PrimaryButton)`
  ${({
    theme: {
      typography: {
        subheads: {
          small1: { bundledCSS: subheadingSmall1CSS },
        },
      },
      color: {
        primary: { primary02, blickRed },
        secondary: { darkRed },
      },
    },
  }) => {
    return css`
      border-radius: 50px;
      color: ${primary02};
      background-color: ${blickRed};
      text-transform: none;
      ${subheadingSmall1CSS};
      @media (hover: hover) {
        &:hover:not([disabled]) {
          background-color: ${darkRed};
        }
      }
    `
  }}
`

export default StyledButton
