import {
  FunctionComponent,
  useCallback,
  useState,
  useRef,
  useEffect,
} from 'react'
import {
  ImageCrops,
  ChapterImageRatioPerDevice,
} from '@widgets/Video/story/types'
import ResponsiveImageV2 from '../../../../../ResponsiveImageV2'
import Placeholder from './Placeholder'

const ON_ERROR_RETRY_INTERVAL = 30 * 1000 // 30 sec
const ON_ERROR_RETRY_ATTEMPTS = 5

export const IMAGE_RATIOS: ChapterImageRatioPerDevice = {
  mobile: '16_9',
  tablet: '16_9',
  desktop: '16_9',
}

export const IMAGE_WIDTHS = {
  desktop: 263,
  tablet: 263,
  mobile: 144,
  mobileSmall: 144,
}

interface TeaserImageProps {
  src: string
  alt: string
  availableCrops?: ImageCrops
}

const TeaserImage: FunctionComponent<TeaserImageProps> = ({
  src,
  alt,
  availableCrops,
}) => {
  const retryTimeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const [retryAttemptsNumber, setRetryAttemptsNumber] = useState(0)

  const shouldReloadImage = retryAttemptsNumber > 0
  // setting image key will force component to re-render and reload image
  const imageKey = shouldReloadImage
    ? `${src}-retry-${retryAttemptsNumber}`
    : src

  useEffect(() => {
    return () => {
      // cleaning up retry timeout
      retryTimeoutRef.current && clearTimeout(retryTimeoutRef.current)
    }
  }, [])

  const onErrorHandler = useCallback(() => {
    if (retryAttemptsNumber < ON_ERROR_RETRY_ATTEMPTS) {
      retryTimeoutRef.current = setTimeout(() => {
        // increment attempts number
        setRetryAttemptsNumber((value) => ++value)
      }, ON_ERROR_RETRY_INTERVAL)
    }
  }, [retryAttemptsNumber])

  return (
    <ResponsiveImageV2
      key={imageKey}
      src={src}
      alt={alt}
      ratios={IMAGE_RATIOS}
      crops={availableCrops ?? {}}
      widths={IMAGE_WIDTHS}
      Placeholder={Placeholder}
      onErrorCallback={onErrorHandler}
    />
  )
}

export default TeaserImage
