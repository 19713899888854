import { FunctionComponent } from 'react'
import styled, { css, useTheme } from 'styled-components'

import SvgIcon from '@components/SvgIcon'
import Blink, { BlinkProps } from '@components/Blink'

interface CTAButtonProps {
  text: string
  link: BlinkProps
  backgroundColor?: string
  className?: string
}

const ButtonWrapper = styled(Blink)<Pick<CTAButtonProps, 'backgroundColor'>>`
  ${({
    theme: {
      color: {
        secondary: { greenAccessible, darkGreen },
      },
      spacing: { spacing8, spacing12, spacing24 },
    },
    backgroundColor,
  }) => css`
    display: flex;
    width: fit-content;
    justify-content: center;
    align-items: center;

    padding: ${spacing12} ${spacing24};
    gap: ${spacing8};

    appearance: none;
    text-decoration: none;

    border-radius: 50px;
    background-color: ${backgroundColor || greenAccessible};

    @media (hover: hover) {
      &:hover {
        background-color: ${darkGreen};
      }
    }
  `}
`

const ButtonText = styled.div`
  ${({
    theme: {
      typography: {
        subheads: {
          small1: { bundledCSS: small1SubheadsCSS },
        },
      },
      color: {
        primary: { primary02: primary02Color },
      },
    },
  }) => {
    return css`
      ${small1SubheadsCSS};
      color: ${primary02Color};
    `
  }}
`

const CTAButton: FunctionComponent<CTAButtonProps> = ({
  className,
  text,
  link,
  backgroundColor,
}) => {
  const theme = useTheme()

  return (
    <ButtonWrapper
      className={className}
      backgroundColor={backgroundColor}
      {...link}>
      <ButtonText>{text}</ButtonText>
      <SvgIcon
        iconName="arrow-right"
        size={24}
        color={theme.color.primary.primary02}
      />
    </ButtonWrapper>
  )
}

export default CTAButton
