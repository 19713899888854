import { FunctionComponent, useEffect, useState } from 'react'
import {
  ActionPopupTextLimitProps,
  CommentingActionPopupFormProps,
} from './types'
import translate from '@i18n'
import styled, { css } from 'styled-components'
import ActionPopupTextInput from './ActionPoputTextInput'
import { charactersLeftText } from './utils'
import config from '@config'
import Blink from '@components/Blink'
import { mobileCSS } from '@measures/responsive'
import CommentingPrimaryButton from '@widgets/Commenting/CommentingButtons/CommentingPrimaryButton'
import CommentingSecondaryButton from '@widgets/Commenting/CommentingButtons/CommentingSecondaryButton'

const {
  community: { textInputCharacterLimit: characterLimit, commentingRuleUrl },
} = config

const ActionPopupForm = styled.form`
  ${({
    theme: {
      spacing: { spacing8 },
    },
  }) => {
    return css`
      margin-top: ${spacing8};
    `
  }}
`

const ActionPopupTextLimit = styled.div<ActionPopupTextLimitProps>`
  ${({
    theme: {
      color: {
        primary: { blickRed },
        tertiary: { grey700 },
      },
      typography: {
        subheads: {
          xsmall1: { bundledCSS: subheadingXSmall1CSS },
        },
      },
    },
    invalid,
  }) => {
    return css`
      flex: 1;
      flex-wrap: wrap;
      align-self: center;
      ${subheadingXSmall1CSS};

      color: ${invalid ? blickRed : grey700};
    `
  }}
`

const ActionPopupActions = styled.div`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => {
    return css`
      margin-top: ${spacing16};
    `
  }}
`

const ActionPopupActionButtons = styled.div`
  ${({
    theme: {
      spacing: { spacing12 },
    },
  }) => {
    return css`
      display: flex;
      flex-wrap: wrap;
      align-self: flex-end;

      ${mobileCSS(css`
        margin-top: ${spacing12};
      `)}
    `
  }}
`

const CommentingFormFooter = styled.div`
  ${({
    theme: {
      spacing: { spacing20 },
    },
  }) => {
    return css`
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      flex-wrap: wrap;

      ${mobileCSS(css`
        flex-direction: column;
      `)}

      margin-top: ${spacing20};
    `
  }}
`

const CommentingRulesTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${mobileCSS(css`
    flex-direction: row;
  `)}
`

const CommentingRulesText = styled.span`
  ${({
    theme: {
      typography: {
        bodycopy: {
          xxsmall: { bundledCSS: bodycopyXXSmallCSS },
        },
      },
      spacing: { spacing4 },
    },
  }) => css`
    margin-right: ${spacing4};
    ${bodycopyXXSmallCSS};
  `}
`

const CommentingRulesLinkText = styled(Blink)`
  ${({
    theme: {
      typography: {
        bodycopy: {
          xxsmall: { bundledCSS: bodycopyXXSmallCSS },
        },
      },
      color: {
        primary: { primary01 },
      },
    },
  }) => css`
    ${bodycopyXXSmallCSS};
    color: ${primary01};
  `}
`

const isValid = (
  textValue: string,
  characterLimit: number,
  actionType: string
) => {
  if (actionType === 'reply' || actionType === 'report') {
    return textValue.length <= characterLimit
  } else {
    return true
  }
}

const CommentingActionPopupForm: FunctionComponent<
  CommentingActionPopupFormProps
> = ({ actionType, onConfirm, onCancel, textInput }) => {
  const [textValue, setTextValue] = useState(textInput)
  const [invalid, setInvalid] = useState(
    !isValid(textInput, characterLimit, actionType)
  )

  useEffect(() => {
    setInvalid(!isValid(textValue, characterLimit, actionType))
  }, [textValue, actionType])

  const onSubmit = () => {
    if (!invalid) {
      if (actionType === 'delete') {
        onConfirm()
      } else {
        if (textValue.trim() !== '') {
          onConfirm(textValue)
        } else {
          setInvalid(true)
        }
      }
    }
  }

  return (
    <ActionPopupForm>
      {(actionType === 'report' || actionType === 'reply') && (
        <ActionPopupTextInput
          invalid={invalid}
          onChange={(value = '') => {
            setTextValue(value)
          }}
          textValue={textValue}
        />
      )}
      <ActionPopupActions>
        {(actionType === 'report' || actionType === 'reply') && (
          <ActionPopupTextLimit invalid={invalid}>
            {charactersLeftText(characterLimit - textValue.length)}
          </ActionPopupTextLimit>
        )}
        <CommentingFormFooter>
          <CommentingRulesTextWrapper>
            <CommentingRulesText>
              {translate('commenting.commentingRulesText')}
            </CommentingRulesText>
            <CommentingRulesLinkText target="_blank" href={commentingRuleUrl}>
              {translate('commenting.commentingRulesLinkText')}
            </CommentingRulesLinkText>
          </CommentingRulesTextWrapper>
          <ActionPopupActionButtons>
            <CommentingSecondaryButton onClick={onCancel} size="small">
              {translate('commenting.cancel')}
            </CommentingSecondaryButton>
            <CommentingPrimaryButton onClick={onSubmit} size="small">
              {
                ((actionType === 'delete' && translate('commenting.delete')) ||
                  (actionType === 'reply' &&
                    translate('commenting.postSubmit')) ||
                  (actionType === 'report' &&
                    translate('commenting.report'))) as string
              }
            </CommentingPrimaryButton>
          </ActionPopupActionButtons>
        </CommentingFormFooter>
      </ActionPopupActions>
    </ActionPopupForm>
  )
}

export default CommentingActionPopupForm
