import { FunctionComponent } from 'react'
import styled from 'styled-components'
import darkSpinner from '@assets/images/spinner-dark.svg'

const StyledLoadingSpinner = styled.span`
  @keyframes spin-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  display: inline-block;
  animation-name: spin-animation;
  animation-iteration-count: infinite;
  animation-duration: 1s;
  animation-timing-function: linear;
  background: url(${darkSpinner}) no-repeat center;
  width: 50px;
  height: 50px;
  background-size: 50px 50px;
  margin-right: 8px;
`

const LoadingSpinner: FunctionComponent = (props) => (
  <StyledLoadingSpinner {...props} />
)

export default LoadingSpinner
