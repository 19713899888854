import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { mobileCSS } from '@measures/responsive'
import usePageMetadata from '@hooks/usePageMetadata'
import { isVideoOnlyArticle } from '@utils/cook'

export const Wrapper = styled.div<{ isVideoOnly: boolean }>`
  ${({
    theme: {
      color: {
        tertiary: { grey300 },
      },
      spacing: { spacing20, spacing16, spacing12 },
    },
    isVideoOnly,
  }) => {
    return css`
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: ${spacing16};
      ${!isVideoOnly &&
      css`
        border-bottom: 1px solid ${grey300};
        padding-bottom: ${spacing20};
        ${mobileCSS(css`
          padding-bottom: ${spacing12};
        `)}
      `}
    `
  }}
`

const SharingAndCommentWrapper: FunctionComponent<{ children?: ReactNode }> = ({
  children,
}) => {
  const { teaser } = usePageMetadata()
  const isVideoOnly = isVideoOnlyArticle(teaser?.targetContentType)

  return <Wrapper isVideoOnly={isVideoOnly}>{children}</Wrapper>
}

export default SharingAndCommentWrapper
