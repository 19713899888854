import { Children, FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { desktopCSS, tabletCSS, mobileCSS } from '@measures/responsive'
import useHasInvertedColors from '@hooks/useHasInvertedColors'

export interface SponsoredLabelProps {
  children?: ReactNode
}

export interface InvertedColorsStyledProps {
  hasInvertedColors: boolean
}

export interface StyledSponsoredLabelProps extends InvertedColorsStyledProps {
  hasLogo: boolean
}

const StyledSponsoredLabel = styled.div<StyledSponsoredLabelProps>`
  ${({
    theme: {
      spacing: { spacing8 },
      color: {
        tertiary: { grey300: grey300Color, grey800: grey800Color },
      },
      typography: {
        eyebrows: {
          small1: { bundledCSS: small1EyebrowCSS },
        },
      },
    },
    hasLogo,
    hasInvertedColors,
  }) => {
    const nonDesktopWithLogoCSS = css`
      line-height: 20px;
      > :nth-child(2) {
        vertical-align: top;
        display: inline-block;
      }
    `

    return css`
      ${small1EyebrowCSS};
      color: ${hasInvertedColors ? grey300Color : grey800Color};
      text-transform: uppercase;
      margin-bottom: ${spacing8};

      ${desktopCSS(css`
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto 1fr;
        grid-row-gap: ${spacing8};
      `)};

      ${hasLogo &&
      css`
        ${tabletCSS(nonDesktopWithLogoCSS)};
        ${mobileCSS(nonDesktopWithLogoCSS)};
      `}
    `
  }}
`

const Spacer = styled.div`
  ${({
    theme: {
      spacing: { spacing8 },
    },
  }) => css`
    display: inline-block;
    width: ${spacing8};
    ${desktopCSS(css`
      display: none;
    `)}
  `}
`

const SponsoredLabel: FunctionComponent<SponsoredLabelProps> = ({
  children,
}) => {
  const hasInvertedColors = useHasInvertedColors()

  const childrenAsArray = Children.toArray(children)
  const hasLogo = childrenAsArray.length > 1
  return (
    <StyledSponsoredLabel
      hasLogo={hasLogo}
      hasInvertedColors={hasInvertedColors}>
      {[childrenAsArray[0], <Spacer key="spacer" />, childrenAsArray[1]]}
    </StyledSponsoredLabel>
  )
}

export default SponsoredLabel
