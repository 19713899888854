import { FunctionComponent, useMemo } from 'react'
import { GalleryWidget, Slide } from '@widgets/Gallery/types'
import FreeRatioImage from '@components/FreeRatioImage'
import styled, { css } from 'styled-components'
import useShouldStretch from '@hooks/useShouldStretch'
import {
  getConstraints,
  getConstraintsForFullScreen,
  shouldUseHeroImageRatio,
} from '@components/Gallery/Common/SlideImage/utils'
import useIsMultiImagesGallery from '@hooks/useIsMultiImages'
import { useFullScreenGallery } from '@hooks/useFullScreenGallery'
import useIsInHerotellingPage from '@hooks/useIsInHerotellingPage'
import HerotellingSingleImage from '@components/Gallery/SingleImageGallery/HerotellingSingleImage'
import useViewportType from '@hooks/useViewport/useViewportType'

const SlideImageContainer = styled.button`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 0;
  padding: 0;
  background-color: transparent;
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  cursor: pointer;

  > div {
    > div {
      width: 100%;
      height: 100%;

      > picture {
        > img {
          object-fit: contain;
        }
      }
    }
  }
`

const ImageContainer = styled.div`
  ${({
    theme: {
      color: {
        primary: { primary01: primary01Color },
      },
    },
  }) => css`
    background-color: ${primary01Color};
    width: 100%;
    height: 100%;
    display: grid;
    justify-items: center;
    align-items: center;
  `}
`

type SlideImageProps = Pick<Slide, 'image' | 'credit'> & {
  onClick: () => void
  shouldPreload: boolean
  imageWidth?: GalleryWidget['imageWidth']
}

const SlideImage: FunctionComponent<SlideImageProps> = ({
  shouldPreload,
  image,
  onClick,
  imageWidth,
}) => {
  const { shouldStretchToCardWidth: isInRecipe, shouldStretchToHeaderWidth } =
    useShouldStretch()
  const hasHeightConstraint = useIsMultiImagesGallery()
  const { isFullScreen } = useFullScreenGallery()
  const isInHerotellingPage = useIsInHerotellingPage()
  const viewportType = useViewportType()
  const constraints = useMemo(
    () =>
      getConstraints({
        hasHeightConstraint,
        hasDesktopSidebar: !shouldStretchToHeaderWidth,
        isInRecipe,
      }),
    [hasHeightConstraint, isInRecipe, shouldStretchToHeaderWidth]
  )

  const constraintsForFullScreen = useMemo(
    () => getConstraintsForFullScreen({ isSingleImage: !hasHeightConstraint }),
    [hasHeightConstraint]
  )
  const freeCrop = image.crops?.['free']
  const originalDimensions = freeCrop
    ? { width: freeCrop.width, height: freeCrop.height }
    : { width: image.width, height: image.height }

  const shouldUseHeroImageRatioValue =
    shouldUseHeroImageRatio(imageWidth, viewportType) &&
    isInHerotellingPage &&
    !hasHeightConstraint

  return (
    <SlideImageContainer onClick={onClick} aria-label="fullscreen">
      <ImageContainer>
        {shouldPreload ? (
          <>
            {isFullScreen && (
              <FreeRatioImage
                isLazy={false}
                src={image.src ?? ''}
                alt={image.alt ?? ''}
                crops={image.crops ?? {}}
                originalDimensions={originalDimensions}
                constraints={constraintsForFullScreen}
              />
            )}
            {!isFullScreen &&
              (shouldUseHeroImageRatioValue ? (
                <HerotellingSingleImage
                  src={image.src ?? ''}
                  alt={image.alt ?? ''}
                  crops={image.crops ?? {}}
                  imageWidth={imageWidth}
                />
              ) : (
                <FreeRatioImage
                  isLazy={false}
                  src={image.src ?? ''}
                  alt={image.alt ?? ''}
                  crops={image.crops ?? {}}
                  originalDimensions={originalDimensions}
                  constraints={constraints}
                />
              ))}
          </>
        ) : null}
      </ImageContainer>
    </SlideImageContainer>
  )
}

export default SlideImage
