const COOKIES_DELIMETER = ';'
const COOKIES_EQUALITY = '='
const MAX_AGE_VALUE = 2147483647

interface CookieOpts {
  name: string
  value: string | boolean
  path?: string
  domain?: string
  secure?: boolean
  expireDate?: string
  sameSite?: string
}

const handleType = (value: string | number): boolean | string => {
  if (typeof value === 'number') {
    return Boolean(value)
  }
  if (value === 'true') return true
  else if (value === 'false') return false
  return value
}

const getCookieValue = (name: string): boolean | string => {
  if (typeof document !== 'undefined' && document) {
    try {
      const cookie = document.cookie
        .split(COOKIES_DELIMETER)
        .find((cookie) => cookie.includes(name))

      if (cookie) {
        const splitCookie = cookie.split(COOKIES_EQUALITY)
        if (splitCookie.length === 2 && splitCookie[1]) {
          return handleType(splitCookie[1])
        }
      }
    } catch (err) {
      // we might get an error when cookies are disabled
    }
  }

  return false
}

const setCookieValue = ({
  name,
  value,
  path = '/',
  domain,
  secure = true,
  expireDate,
  sameSite = 'none',
}: CookieOpts) => {
  if (typeof document !== 'undefined' && document) {
    const cookieName = `${name}${COOKIES_EQUALITY}${value}`
    const cookiePath = `path${COOKIES_EQUALITY}${path}`
    const cookieDomain = domain && `domain${COOKIES_EQUALITY}${domain}`
    const cookieSecure = secure && 'secure'
    const cookieExpireDate =
      expireDate &&
      `max-age${COOKIES_EQUALITY}${
        expireDate === 'MAX' ? MAX_AGE_VALUE : expireDate
      }`
    const cookieSamesite = `samesite${COOKIES_EQUALITY}${sameSite}`
    const cookieChunks = [
      cookieName,
      cookiePath,
      cookieDomain,
      cookieExpireDate,
      cookieSamesite,
    ]
    // Avoid setting secure if input parameter is false
    if (cookieSecure) cookieChunks.push(cookieSecure)
    const result = cookieChunks.reduce((acc, chunk) => {
      if (!chunk) return acc
      return `${acc}${chunk}${COOKIES_DELIMETER}`
    }, '')
    if (result) document.cookie = result
  }
}

export { getCookieValue, setCookieValue }
