import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { TeaserStandardVerticalAPIProps } from '@widgets/TeaserStandardVertical'
import { desktopCSS } from '@measures/responsive'

export interface TitleProps
  extends Pick<TeaserStandardVerticalAPIProps, 'layoutTemplate'> {
  children: string
}

export type StyledTitleProps = Pick<TitleProps, 'layoutTemplate'>

const StyledTitle = styled.div<StyledTitleProps>`
  ${({
    theme: {
      color: {
        primary: { primary01: primary01Color },
      },
      typography: {
        headings: {
          large: { bundledCSS: largeHeadingCSS },
          medium: { bundledCSS: mediumHeadingCSS },
          small: { bundledCSS: smallHeadingCSS },
        },
      },
      utils: { ellipsis },
    },
    layoutTemplate,
  }) => css`
    ${ellipsis('100%', 3)};
    ${layoutTemplate === 'compact' ? smallHeadingCSS : mediumHeadingCSS};
    ${desktopCSS(css`
      ${layoutTemplate === 'compact' ? smallHeadingCSS : largeHeadingCSS};
    `)};
    color: ${primary01Color};
  `}
`

const Title: FunctionComponent<TitleProps> = ({ layoutTemplate, children }) => {
  return <StyledTitle layoutTemplate={layoutTemplate}>{children}</StyledTitle>
}

export default Title
