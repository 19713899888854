import { memo, FunctionComponent, MouseEventHandler } from 'react'
import { useTheme } from 'styled-components'

import SvgIcon from '../../../SvgIcon'
import StyledButton from '../StyledButton'

export interface RestartButtonProps {
  label: string
  onClick: MouseEventHandler<HTMLButtonElement>
}

const RestartIcon: FunctionComponent<{}> = () => {
  const theme = useTheme()

  return (
    <SvgIcon iconName="video-restart" size={16} color={theme.colors.white} />
  )
}

const RestartButton: FunctionComponent<RestartButtonProps> = ({
  onClick,
  label,
}) => {
  return (
    <StyledButton onClick={onClick}>
      <RestartIcon />
      <span>{label}</span>
    </StyledButton>
  )
}

const MemoizedRestartButton = memo(RestartButton)

MemoizedRestartButton.displayName = 'MemoizedRestartButton'

export default MemoizedRestartButton
