import { DataToValidate, Validation } from '../types'

const defaultValidation: Validation = {
  name: true,
  phone: true,
  description: true,
  files: true,
  consent: true,
}

const validateData = (data: DataToValidate) => {
  let validation = { ...defaultValidation }
  if (!data.name) {
    validation.name = 'Bitte fülle das Feld aus'
  }
  if (!data.phone) {
    validation.phone = 'Bitte fülle das Feld aus'
  }
  if (!data.description || data.description.trim().length < 10) {
    validation.description = '10 Zeichen fehlen (min. 10 Zeichen)'
  }
  if (data.category !== 'tip' && !data.files.length) {
    validation.files = 'Bitte füge ein Bild/Video hinzu'
  }
  if (!data.consent) {
    validation.consent = 'Bitte akzeptiere die Leserreporter-AGB'
  }
  return validation
}

const isValidSubmission = (validationObj: Validation) =>
  Object.values(validationObj).every(
    (validationEntry) => validationEntry === true
  )

export { validateData, defaultValidation, isValidSubmission }
