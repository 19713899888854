import { useContext } from 'react'

import LivestreamStoryContext, {
  LivestreamStoryContextType,
} from './LivestreamStoryContext'

const useLivestreamStoryContext = (): LivestreamStoryContextType => {
  return useContext(LivestreamStoryContext)
}

export default useLivestreamStoryContext
