import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import translate from '@i18n'
import SummaryHeader from '@components/Summary/SummaryHeader'
import PrimaryButton from '@components/Buttons/Primary'

const BetaBadge = styled.div`
  ${({
    theme: {
      color: {
        primary: { primary02, blickRed },
      },
      typography: {
        subheads: {
          xsmall2: { bundledCSS: xsmall2 },
        },
      },
      spacing: { spacing4 },
    },
  }) => css`
    color: ${primary02};
    background-color: ${blickRed};
    text-align: center;
    padding: 0 ${spacing4};
    border-radius: ${spacing4};
    height: fit-content;
    position: relative;
    top: -1px;
    ${xsmall2}
  `}
`

const Paragraph = styled.p`
  ${({
    theme: {
      spacing: { spacing24 },
      typography: {
        subheads: { large2 },
      },
    },
  }) => css`
    ${large2}
    margin: ${spacing24} 0 0 0;
  `}
`

const StyledPrimaryButton = styled(PrimaryButton)`
  ${({
    theme: {
      color: {
        primary: { blickRed },
        secondary: { darkRed },
      },
      typography: {
        subheads: {
          small1: { bundledCSS: smallBodyCopy },
        },
      },
      spacing: { spacing16, spacing24 },
    },
  }) => {
    return css`
      ${smallBodyCopy}
      text-transform: none;
      padding: ${spacing16} ${spacing24};
      background-color: ${blickRed};

      &:hover {
        background-color: ${darkRed};
      }
    `
  }}
`

const SummaryRequestView: FunctionComponent<{
  title: string
  text: string
  onPrimaryClick: () => void
}> = ({ title, text, onPrimaryClick }) => {
  return (
    <>
      <SummaryHeader prefixIconName="stars">
        {title}
        <BetaBadge>Beta</BetaBadge>
      </SummaryHeader>
      <Paragraph>{text}</Paragraph>
      <Paragraph>
        <StyledPrimaryButton
          type="button"
          size="large"
          onClick={onPrimaryClick}>
          {translate('sectionSummary.request.primaryButton')}
        </StyledPrimaryButton>
      </Paragraph>
    </>
  )
}

export default SummaryRequestView
