import { FunctionComponent, useMemo } from 'react'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { TeaserToiAPIProps } from '@widgets/TeaserToi'
import { TeaserToiCommercialAPIProps } from '@widgets/TeaserToiCommercial'
import { TeaserStandardHybridAPIProps } from '@widgets/TeaserStandardHybrid'
import { EmbeddedContentWidgetProps } from '@widgets/EmbeddedContent'
import SectionHeader from '@components/SectionHeader'
import JSONRenderer from '@components/JSONRenderer'
import { JSONWidgetType as AdRingierJSONWidgetType } from '@widgets/AdRingier'
import { Link } from '@utils/cook/types'
import { Logo } from '@cook-types/cook'
import MobileOnlySeparator from '@components/Chunk/MobileOnlySeparator'
import SideFlowWrapper from '@components/Chunk/SideFlowWrapper'
import Separator from '@components/Chunk/Separator'
import StyledChunkWrapper from '@components/Chunk/Wrapper'
import MainFlowWrapper from '@components/Chunk/MainFlowWrapper'
import SideAd from '@components/Chunk/SideAd'

export interface ChunkCommonProps {
  mainItem: TeaserToiAPIProps | TeaserToiCommercialAPIProps
  items: TeaserStandardHybridAPIProps[]
  embeddedItem?: EmbeddedContentWidgetProps
  commercialItem?: TeaserToiCommercialAPIProps
  accentColor: string
  title?: string
  link?: Link
  sideAd: AdRingierJSONWidgetType
  logo?: Logo
  ctaText?: string
}

export interface ChunkNoAdProps {
  teaserAd1?: undefined
  teaserAd2?: undefined
}

export interface ChunkAdProps {
  teaserAd1: AdRingierJSONWidgetType
  teaserAd2: AdRingierJSONWidgetType
}

export type ChunkMaybeAdProps = ChunkNoAdProps | ChunkAdProps

export type ChunkAPIProps = ChunkCommonProps & ChunkMaybeAdProps

const Chunk: FunctionComponent<ChunkAPIProps> = (props) => {
  const {
    mainItem,
    items,
    accentColor,
    title,
    link,
    commercialItem,
    embeddedItem,
    sideAd,
    logo,
    ctaText,
  } = props

  const hasPubliAds = props.teaserAd1 && props.teaserAd2

  const {
    teaserAd1Desktop,
    teaserAd1TabletAndMobile,
    teaserAd2Desktop,
    teaserAd2TabletAndMobile,
  } = useMemo(() => {
    const platformStrippedAd1 = hasPubliAds
      ? { kind: props.teaserAd1.kind, placement: props.teaserAd1.placement }
      : null

    const platformStrippedAd2 = hasPubliAds
      ? { kind: props.teaserAd2.kind, placement: props.teaserAd2.placement }
      : null

    const teaserAd1Desktop = hasPubliAds
      ? {
          ...platformStrippedAd1,
          slotCodeDesktop: props.teaserAd1.slotCodeDesktop,
        }
      : null

    const teaserAd1TabletAndMobile = hasPubliAds
      ? {
          ...platformStrippedAd1,
          slotCodeTablet: props.teaserAd1.slotCodeTablet,
          slotCodeMobile: props.teaserAd1.slotCodeMobile,
        }
      : null

    const teaserAd2Desktop = hasPubliAds
      ? {
          ...platformStrippedAd2,
          slotCodeDesktop: props.teaserAd2.slotCodeDesktop,
        }
      : null

    const teaserAd2TabletAndMobile = hasPubliAds
      ? {
          ...platformStrippedAd2,
          slotCodeTablet: props.teaserAd2.slotCodeTablet,
          slotCodeMobile: props.teaserAd2.slotCodeMobile,
        }
      : null

    return {
      teaserAd1Desktop,
      teaserAd1TabletAndMobile,
      teaserAd2Desktop,
      teaserAd2TabletAndMobile,
    }
  }, [hasPubliAds, props.teaserAd1, props.teaserAd2])

  return (
    <StyledChunkWrapper>
      {title && (
        <SectionHeader
          accentColor={accentColor}
          title={title}
          link={link}
          logo={logo}
          ctaText={ctaText}
        />
      )}
      <MainFlowWrapper>
        <JSONRenderer>{mainItem}</JSONRenderer>
        <JSONRenderer>{{ ...items[0], render: 'horizontal' }}</JSONRenderer>
        {hasPubliAds && (
          <>
            <MobileOnlySeparator />
            <JSONRenderer>{teaserAd1TabletAndMobile}</JSONRenderer>
          </>
        )}
        <MobileOnlySeparator />
        <JSONRenderer>{{ ...items[1], render: 'horizontal' }}</JSONRenderer>
        {hasPubliAds && !!commercialItem && (
          <>
            <MobileOnlySeparator />
            <JSONRenderer>{{ ...items[2], render: 'horizontal' }}</JSONRenderer>
          </>
        )}
        {hasPubliAds ? (
          <>
            <JSONRenderer>{{ ...items[1], render: 'vertical' }}</JSONRenderer>
            <JSONRenderer>{{ ...items[2], render: 'vertical' }}</JSONRenderer>
            {!!commercialItem && <JSONRenderer>{commercialItem}</JSONRenderer>}
            <JSONRenderer>{teaserAd2Desktop}</JSONRenderer>
            <JSONRenderer>{{ ...items[3], render: 'vertical' }}</JSONRenderer>
          </>
        ) : (
          <>
            <JSONRenderer>{{ ...items[2], render: 'vertical' }}</JSONRenderer>
            <JSONRenderer>{{ ...items[3], render: 'vertical' }}</JSONRenderer>
            {!!commercialItem && <JSONRenderer>{commercialItem}</JSONRenderer>}
          </>
        )}
        {(!hasPubliAds || !commercialItem) && (
          <>
            {!commercialItem && <MobileOnlySeparator />}
            <JSONRenderer>{{ ...items[2], render: 'horizontal' }}</JSONRenderer>
          </>
        )}
        {hasPubliAds && (
          <>
            {!commercialItem && <MobileOnlySeparator />}
            <JSONRenderer>{teaserAd2TabletAndMobile}</JSONRenderer>
          </>
        )}
        <MobileOnlySeparator />
        <JSONRenderer>{{ ...items[3], render: 'horizontal' }}</JSONRenderer>
        {!!embeddedItem && <JSONRenderer>{embeddedItem}</JSONRenderer>}
      </MainFlowWrapper>
      <SideFlowWrapper>
        <JSONRenderer>{{ ...items[0], render: 'vertical' }}</JSONRenderer>
        <Separator />
        {hasPubliAds ? (
          <JSONRenderer>{teaserAd1Desktop}</JSONRenderer>
        ) : (
          <JSONRenderer>{{ ...items[1], render: 'vertical' }}</JSONRenderer>
        )}
        <SideAd>
          <JSONRenderer>{sideAd}</JSONRenderer>
        </SideAd>
      </SideFlowWrapper>
    </StyledChunkWrapper>
  )
}

const widget = {
  kind: ['chunk'],
  component: Chunk,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
