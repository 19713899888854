import { dayjs } from '@utils/date'
import { LiveEventScheduledAt } from './liveEvent'
import translate from '@i18n'

export interface LiveEventTransformedCompetitor {
  teamname?: string
  logo: string
  name: string
  position: number
  time: string
}

const LIVE_EVENT_DATA_TEAM_QUALIFIER_TYPES = {
  HOME: 'home',
  AWAY: 'away',
} as const

const LIVE_EVENT_DATA_FORMULA1_STAGE_TYPES = {
  SEASON: 'season',
  EVENT: 'event',
  PRACTICE: 'practice',
  QUALIFYING: 'qualifying',
  RACE: 'race',
  QUALIFYING_PART: 'qualifying_part',
  LAP: 'lap',
  SPORT: 'sport',
} as const

const LIVE_EVENT_DATA_FORMULA1_EVENT_STATUS = {
  NOT_STARTED: 'Not started',
  WARMUP: 'Warmup',
  IN_GRID: 'In grid',
  CLEAR_GRID: 'Grid must be cleared',
} as const

const LIVE_EVENT_DATA_SKI_STAGE_TYPES = {
  GESAMT: translate('liveEvent.dataSkiStageTypes.GESAMT'),
  ABFAHRT: translate('liveEvent.dataSkiStageTypes.ABFAHRT'),
  KOMBINATION: translate('liveEvent.dataSkiStageTypes.KOMBINATION'),
  SLALOM: translate('liveEvent.dataSkiStageTypes.SLALOM'),
  SUPERG: translate('liveEvent.dataSkiStageTypes.SUPERG'),
  RIESENSLALOM: translate('liveEvent.dataSkiStageTypes.RIESENSLALOM'),
} as const

const LIVE_EVENT_DATA_SOCCER_MATCH_STATUS = {
  NOT_STARTED: 'not_started',
  LIVE: 'live',
  FIRST_HALF: '1st_half',
  SECOND_HALF: '2nd_half',
  OVERTIME: 'overtime',
  FIRST_EXTRA: '1st_extra',
  SECOND_EXTRA: '2nd_extra',
  AWAITING_PENALTIES: 'awaiting_penalties',
  PENALTIES: 'penalties',
  PAUSE: 'pause',
  AWAITING_EXTRA_TIME: 'awaiting_extra_time',
  INTERRUPTED: 'interrupted',
  ABANDONED: 'abandoned',
  POSTPONED: 'postponed',
  DELAYED: 'delayed',
  CANCELLED: 'cancelled',
  ENDED: 'ended',
  CLOSED: 'closed',
  HALFTIME: 'halftime',
  FULLTIME: 'full-time',
  EXTRA_TIME: 'extra_time',
  AET: 'aet',
  AP: 'ap',
} as const

const LIVE_EVENT_DATA_HOCKEY_MATCH_STATUS = {
  NOT_STARTED: 'not_started',
  LIVE: 'live',
  FIRST_PERIOD: '1st_period',
  SECOND_PERIOD: '2nd_period',
  THIRD_PERIOD: '3rd_period',
  OVERTIME: 'overtime',
  FIRST_EXTRA: '1st_extra',
  SECOND_EXTRA: '2nd_extra',
  THIRD_EXTRA: '3rd_extra',
  AWAITING_PENALTIES: 'awaiting_penalties',
  PENALTIES: 'penalties',
  PAUSE: 'pause',
  AWAITING_EXTRA_TIME: 'awaiting_extra_time',
  INTERRUPTED: 'interrupted',
  ABANDONED: 'abandoned',
  POSTPONED: 'postponed',
  DELAYED: 'delayed',
  CANCELLED: 'cancelled',
  ENDED: 'ended',
  AET: 'aet',
  AP: 'ap',
} as const

const LIVE_EVENT_DATA_TENNIS_MATCH_STATUS = {
  NOT_STARTED: 'not_started',
  MATCH_ABOUT_TO_START: 'match_about_to_start',
  DELAYED: 'delayed',
  LIVE: 'live',
  FIRST_SET: '1st_set',
  SECOND_SET: '2nd_set',
  THIRD_SET: '3rd_set',
  FOURTH_SET: '4th_set',
  FIFTH_SET: '5th_set',
  ENDED: 'ended',
  WALKOVER: 'walkover',
  INTERRUPTED: 'interrupted',
  SUSPENDED: 'suspended',
  CANCELLED: 'cancelled',
  CANCELED: 'canceled',
  POSTPONED: 'postponed',
  ABANDONED: 'abandoned',
  RETIRED: 'retired',
  CLOSED: 'closed',
} as const

const LIVE_EVENT_DATA_TENNIS_EVENT_STATUS = {
  NOT_STARTED: 'not_started',
  MATCH_ABOUT_TO_START: 'match_about_to_start',
  DELAYED: 'delayed',
  LIVE: 'live',
  ENDED: 'ended',
  INTERRUPTED: 'interrupted',
  SUSPENDED: 'suspended',
  CANCELLED: 'cancelled',
  CANCELED: 'canceled',
  ABANDONED: 'abandoned',
  CLOSED: 'closed',
} as const

const LIVE_EVENT_DATA_SPORT_TYPES = {
  FORMULA1: 'formula1',
  TENNIS: 'tennis',
  SOCCER: 'soccer',
  HOCKEY: 'hockey',
  SKI: 'ski',
  DEFAULT: 'NO_SPORT',
} as const

const LIVE_EVENT_DATA_STATUS_TYPES = {
  CANCELLED: 'Cancelled',
  FINISHED: 'Finished',
  RUNNING: 'Running',
  PENDING: 'Pending',
  BETWEEN_RUNS: 'Between_Runs',
  NO_STATUS: '',
} as const

const formatScheduledAt = ({
  date,
  format,
  template,
  replacer,
}: LiveEventScheduledAt): string => {
  const formatted = dayjs(date).format(format)
  return template.replace(replacer, formatted)
}

const formatScheduledAtShort = ({
  date,
  format,
}: LiveEventScheduledAt): string => dayjs(date).format(format)

export {
  formatScheduledAt,
  formatScheduledAtShort,
  LIVE_EVENT_DATA_TEAM_QUALIFIER_TYPES,
  LIVE_EVENT_DATA_FORMULA1_STAGE_TYPES,
  LIVE_EVENT_DATA_FORMULA1_EVENT_STATUS,
  LIVE_EVENT_DATA_SKI_STAGE_TYPES,
  LIVE_EVENT_DATA_SOCCER_MATCH_STATUS,
  LIVE_EVENT_DATA_HOCKEY_MATCH_STATUS,
  LIVE_EVENT_DATA_TENNIS_MATCH_STATUS,
  LIVE_EVENT_DATA_TENNIS_EVENT_STATUS,
  LIVE_EVENT_DATA_SPORT_TYPES,
  LIVE_EVENT_DATA_STATUS_TYPES,
}
