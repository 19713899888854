import styled, { css } from 'styled-components'
import { mobileCSS, tabletCSS, desktopCSS } from '@measures/responsive'
import Blink from '@components/Blink'

export interface StyledTeaserStandardHorizontalWrapperProps {
  hideImageOnMobile: boolean
  imagePositionOnMobile: 'left' | 'right'
  isHybridTeaser: boolean
}

const StyledTeaserStandardHorizontalWrapper = styled(
  Blink
)<StyledTeaserStandardHorizontalWrapperProps>`
  ${({
    theme: {
      spacing: { spacing16 },
    },
    imagePositionOnMobile,
    hideImageOnMobile,
    isHybridTeaser,
  }) => {
    return css`
      box-sizing: border-box;
      position: relative;
      ${!hideImageOnMobile &&
      css`
        ${mobileCSS(css`
          padding-${imagePositionOnMobile}: calc(${spacing16} + 109px);
          min-height: 109px;
        `)};
      `};

      ${tabletCSS(css`
        padding-${imagePositionOnMobile}: calc(${spacing16} + 109px);
        min-height: 109px;
      `)};

      ${desktopCSS(css`
        min-height: calc(134px);
        padding-left: calc(${spacing16} + 134px);
      `)};

      display: grid;
      align-items: flex-start;
      justify-items: flex-start;
      align-content: flex-start;
      justify-content: flex-start;
      grid-template-columns: minmax(0, 1fr);
      appearance: none;
      text-decoration: none;

      ${isHybridTeaser &&
      css`
        ${desktopCSS(css`
          display: none;
        `)}
      `}
    `
  }}
`

export default StyledTeaserStandardHorizontalWrapper
