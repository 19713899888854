import SvgIcon from '@components/SvgIcon'
import { forwardRef } from 'react'
import styled, { css, useTheme } from 'styled-components'
import { ButtonProps, StyledCommonButtonProps } from './types'

const StyledPrimaryButton = styled.button<StyledCommonButtonProps>`
  ${({
    theme: {
      fonts: { goodOT },
      colors: {
        white,
        grey: { grey_30, grey_70, grey_80, grey_90 },
        secondary: { focus },
      },
    },
    size,
    disabled,
  }) => css`
    font-size: 14px;
    line-height: 20px;
    background-color: ${grey_90};
    border-radius: ${size === 'small' ? '20px' : '24px'};
    color: ${white};
    padding: ${size === 'small' ? '10px' : '14px'} 24px;
    border: 0;
    cursor: pointer;
    font-family: ${goodOT};
    text-transform: uppercase;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    height: ${size === 'small' ? '40px' : '48px'};
    ${disabled
      ? css`
          background: ${grey_30};
          color: ${grey_70};
          cursor: auto;
        `
      : css`
          &:hover {
            background-color: ${grey_80};
          }
          &:focus-visible {
            border: 4px solid ${focus};
          }
        `}
  `}
`

const PrimaryButton = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      size,
      withArrow,
      isDownArrow,
      children,
      onClick,
      isDisabled,
      className,
      type = 'button',
    },
    ref
  ) => {
    const theme = useTheme()
    return (
      <StyledPrimaryButton
        ref={ref}
        className={className}
        size={size}
        disabled={isDisabled}
        onClick={onClick}
        type={type}>
        {children}
        {withArrow && (
          <SvgIcon
            iconName={isDownArrow ? 'chevron-down' : 'chevron-up'}
            size={20}
            color={isDisabled ? theme.colors.grey.grey_70 : theme.colors.white}
          />
        )}
      </StyledPrimaryButton>
    )
  }
)

PrimaryButton.displayName = 'PrimaryButton'

export default PrimaryButton
