import { FunctionComponent } from 'react'
import styled, { useTheme } from 'styled-components'
import IconButton from '@components/Buttons/IconButton'

interface VolumeCTAButtonProps {
  muted: boolean
  onVolumeButtonClick?: () => void
}

const StyledIconButton = styled(IconButton)`
  border-radius: 50%;
  background: rgba(17, 17, 17, 0.5);
`

const VolumeCTAButton: FunctionComponent<VolumeCTAButtonProps> = ({
  muted,
  onVolumeButtonClick,
}) => {
  const theme = useTheme()

  return (
    <StyledIconButton
      ariaLabel="Volume"
      buttonSize={40}
      onClick={onVolumeButtonClick}
      iconName={muted ? 'volume-slash-solid' : 'volume-high-solid'}
      iconSize={20}
      iconColor={theme.color.primary.primary02}
    />
  )
}

export default VolumeCTAButton
