import ResponsiveImageV2 from '@components/ResponsiveImageV2'
import { ImageCrops, ImageWidths } from '@components/ResponsiveImageV2/types'
import { GalleryWidget } from '@widgets/Gallery/types'
import { FunctionComponent } from 'react'
import { getHeroImageRatios } from '../Common/SlideImage/utils'

interface HerotellingSingleImageProps {
  src: string
  alt: string
  crops?: ImageCrops
  imageWidth: GalleryWidget['imageWidth']
}

const imageWidths: ImageWidths = {
  desktop: 964,
  tablet: 664,
  mobile: 430,
  mobileSmall: 320,
}

const HerotellingSingleImage: FunctionComponent<
  HerotellingSingleImageProps
> = ({ src, alt, crops, imageWidth }) => {
  return (
    <ResponsiveImageV2
      disablePlaceholder
      src={src}
      alt={alt}
      ratios={getHeroImageRatios(imageWidth)}
      crops={crops ?? {}}
      widths={imageWidths}
    />
  )
}

export default HerotellingSingleImage
