import { stripHtml } from '@hooks/useTracking/utils'

import {
  VideoTrackingParams,
  PlaylistTrackingParams,
  ChapterTrackingParams,
  VideoWidgetImpressionTrackingParams,
  VideoWidgetClickTrackingParams,
  VideoWidgetActionTrackingParams,
  VideoElementTrackingParams,
} from '@widgets/Video/types'
import { getVideoMainElementTrackingValues } from './utils'
import { ViewportOrientationValues } from '@hooks/useViewport/types'
import { TrackingFnType } from '@hooks/useTracking'

type VideoOrientationChangeTrackingParams = VideoTrackingParams & {
  orientation: ViewportOrientationValues
}

type VideoChapterTrackingParams = VideoTrackingParams & ChapterTrackingParams

export const videoImpressionHandler: TrackingFnType<VideoTrackingParams> = ({
  extraData,
}) => ({
  event: 'video_impression',
  eventCategory: 'video',
  eventAction: 'impression',
  // Event specific data
  ...extraData,
})

export const videoPlayHandler: TrackingFnType<VideoTrackingParams> = ({
  extraData,
}) => ({
  // Basic event data
  event: 'video_play_click',
  eventCategory: 'video',
  eventAction: 'play_click',
  // Event specific data
  ...extraData,
})

export const videoPlayerSuccessHandler: TrackingFnType<VideoTrackingParams> = ({
  extraData,
}) => ({
  event: 'video_play',
  eventCategory: 'video',
  eventAction: 'play',
  // Event specific data
  ...extraData,
})

export const videoAdRequestHandler: TrackingFnType<VideoTrackingParams> = ({
  extraData,
}) => ({
  event: 'video_ads_ad_request',
  eventCategory: 'video',
  eventAction: 'ads_ad_request',
  // Event specific data
  ...extraData,
})

export const videoAdStartedHandler: TrackingFnType<VideoTrackingParams> = ({
  extraData,
}) => ({
  event: 'video_ads_ad_started',
  eventCategory: 'video',
  eventAction: 'ads_ad_started',
  // Event specific data
  ...extraData,
})

export const videoAdEndedHandler: TrackingFnType<VideoTrackingParams> = ({
  extraData,
}) => ({
  event: 'video_ads_ad_ended',
  eventCategory: 'video',
  eventAction: 'ads_ad_end',
  // Event specific data
  ...extraData,
})

export const videoProgressHandler: TrackingFnType<VideoTrackingParams> = ({
  extraData,
}) => ({
  event: 'video_watched',
  eventCategory: 'video',
  eventAction: 'watched',
  // Event specific data
  ...extraData,
})

export const videoProgress5SecIntervalHandler: TrackingFnType<
  VideoTrackingParams
> = ({ extraData }) => ({
  event: 'video_watched_5',
  eventCategory: 'video',
  eventAction: 'watched_5',
  // Event specific data
  ...extraData,
})

export const videoProgress10SecIntervalHandler: TrackingFnType<
  VideoTrackingParams
> = ({ extraData }) => ({
  event: 'video_watched_10',
  eventCategory: 'video',
  eventAction: 'watched_10',
  // Event specific data
  ...extraData,
})

export const videoQuartileHandler: TrackingFnType<VideoTrackingParams> = ({
  extraData,
}) => {
  const { quartileState } = extraData

  return {
    event: `video_${quartileState}`,
    eventCategory: 'video',
    eventAction: `video_${quartileState}`,
    // Event specific data
    ...extraData,
  }
}

export const videoEndedHandler: TrackingFnType<VideoTrackingParams> = ({
  extraData,
}) => ({
  event: `video_end`,
  eventCategory: 'video',
  eventAction: `video_end`,
  // Event specific data
  ...extraData,
})

export const videoErrorHandler: TrackingFnType<VideoTrackingParams> = ({
  extraData,
}) => ({
  event: `video_error`,
  eventCategory: 'video',
  eventAction: `error`,
  // Event specific data
  ...extraData,
})

export const videoMuteHandler: TrackingFnType<VideoTrackingParams> = ({
  extraData,
}) => ({
  event: `video_audio`,
  eventCategory: 'video',
  eventAction: `mute`,
  // Event specific data
  ...extraData,
})

export const videoUnmuteHandler: TrackingFnType<VideoTrackingParams> = ({
  extraData,
}) => ({
  event: `video_audio`,
  eventCategory: 'video',
  eventAction: `unmute`,
  // Event specific data
  ...extraData,
})

export const videoCaptionsEnableHandler: TrackingFnType<
  VideoTrackingParams
> = ({ extraData }) => ({
  event: `video_captions`,
  eventCategory: 'video',
  eventAction: `enable`,
  // Event specific data
  ...extraData,
})

export const videoCaptionsDisableHandler: TrackingFnType<
  VideoTrackingParams
> = ({ extraData }) => ({
  event: `video_captions`,
  eventCategory: 'video',
  eventAction: `disable`,
  // Event specific data
  ...extraData,
})

export const videoCloseFloatingPlayerHandler: TrackingFnType<
  VideoTrackingParams
> = ({ extraData }) => ({
  event: `video_close_floatingPlayer`,
  eventCategory: 'video',
  eventAction: `close_floatingPlayer`,
  // Event specific data
  ...extraData,
})

export const videoToFloatingPlayerHandler: TrackingFnType<
  VideoTrackingParams
> = ({ extraData }) => ({
  // Basic event data
  event: `video_to_floating`,
  eventCategory: 'video',
  eventAction: `to_floating`,
  // Event specific data
  ...extraData,
})

export const videoOverlayExitHandler: TrackingFnType<VideoTrackingParams> = ({
  extraData,
}) => ({
  event: `video_close_overlay`,
  eventCategory: 'video',
  eventAction: `close_overlay`,
  // Event specific data
  ...extraData,
})

export const videoOrientationChangeHandler: TrackingFnType<
  VideoOrientationChangeTrackingParams
> = ({ extraData }) => ({
  event: 'video_orientation_change',
  eventCategory: 'video',
  eventAction: 'video_orientation_change',
  // Event specific data
  ...extraData,
})

export const videoPlaylistShowHandler: TrackingFnType<
  PlaylistTrackingParams
> = ({ pageMetadata, extraData }) => ({
  event: 'playlist_show',
  eventCategory: 'Playlist',
  eventAction: 'playlist_show',
  eventLabel: extraData.playlistTitle,
  // Video specific data (playlist)
  ...getVideoMainElementTrackingValues(pageMetadata),
})

export const videoPlaylistClickHandler: TrackingFnType<
  PlaylistTrackingParams
> = ({ pageMetadata, extraData }) => ({
  event: 'playlist_click',
  eventCategory: 'Playlist',
  eventAction: 'playlist_click',
  eventLabel: extraData.playlistTitle,
  // Video specific data (playlist)
  ...getVideoMainElementTrackingValues(pageMetadata),
})

export const videoWidgetImpressionHandler: TrackingFnType<
  VideoWidgetImpressionTrackingParams
> = ({ extraData }) => {
  const { videoId, videoTitle, playerType, indexPosition, widgetImpression } =
    extraData

  return {
    event: 'video_widget_impression',
    eventCategory: 'widget_recommendations',
    eventAction: `impression_${widgetImpression}`,
    eventLabel: indexPosition,
    // Video specific data
    videoTitle: stripHtml(videoTitle),
    videoId: videoId,
    playerType: playerType,
  }
}

export const videoWidgetClickHandler: TrackingFnType<
  VideoWidgetClickTrackingParams
> = ({ extraData }) => {
  const { videoId, videoTitle, playerType, indexPosition } = extraData

  return {
    event: 'video_widget_click',
    eventCategory: 'widget_recommendations',
    eventAction: 'click',
    eventLabel: indexPosition,
    // Video specific data
    videoTitle: stripHtml(videoTitle),
    videoId: videoId,
    playerType: playerType,
  }
}

export const videoWidgetActionHandler: TrackingFnType<
  VideoWidgetActionTrackingParams
> = ({ extraData }) => {
  const { videoId, playerType, widgetAction, videoTitle } = extraData

  return {
    // Basic event data
    event: 'video_widget_action',
    eventCategory: 'widget_recommendations',
    eventAction: widgetAction, // play | replay | close
    // eventLabel: "-",
    // Video specific data
    videoTitle: videoTitle,
    videoId: videoId,
    playerType: playerType,
  }
}

export const videoChapterClickHandler: TrackingFnType<
  VideoChapterTrackingParams
> = ({ extraData }) => ({
  event: 'video_chapterClick',
  eventCategory: 'video',
  eventAction: 'video_chapterClick',
  // Event specific data
  ...extraData,
})

export const videoChapterSwipeHandler: TrackingFnType<
  VideoChapterTrackingParams
> = ({ extraData }) => ({
  event: 'video_chapterSwipe',
  eventCategory: 'video_chapters',
  eventAction: extraData?.buttonLabel ? 'click' : `swipe`,
  // Event specific data
  ...extraData,
})

export const videoChapterButtonClickHandler: TrackingFnType<
  VideoChapterTrackingParams
> = ({ extraData }) => ({
  event: `video_chapterButtonClick`,
  eventCategory: 'video_chapters',
  eventAction: `video_chapterButtonClick`,
  eventLabel: extraData?.buttonLabel,
  // Event specific data
  ...extraData,
})

export const videoElementImpressionHandler = ({
  extraData,
}: {
  extraData: VideoElementTrackingParams
}) => {
  return {
    ...extraData,
    event: 'video_element_impression',
  }
}

export const videoElementClickHandler = ({
  extraData,
}: {
  extraData: VideoElementTrackingParams
}) => {
  const { buttonText, ...rest } = extraData
  return {
    ...rest,
    event: 'video_element_click',
    button_text: buttonText,
  }
}

const exports = {
  videoImpressionHandler,
  videoPlayHandler,
  videoPlayerSuccessHandler,
  videoAdRequestHandler,
  videoAdStartedHandler,
  videoAdEndedHandler,
  videoProgressHandler,
  videoProgress10SecIntervalHandler,
  videoQuartileHandler,
  videoEndedHandler,
  videoErrorHandler,
  videoMuteHandler,
  videoUnmuteHandler,
  videoCaptionsEnableHandler,
  videoCaptionsDisableHandler,
  videoCloseFloatingPlayerHandler,
  videoToFloatingPlayerHandler,
  videoOverlayExitHandler,
  videoOrientationChangeHandler,
  videoPlaylistShowHandler,
  videoPlaylistClickHandler,
  videoWidgetImpressionHandler,
  videoWidgetClickHandler,
  videoWidgetActionHandler,
  videoChapterClickHandler,
  videoChapterSwipeHandler,
  videoChapterButtonClickHandler,
  videoElementImpressionHandler,
  videoElementClickHandler,
}

export default exports
