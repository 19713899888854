import { ConfigProps } from '@config/types'

const config = ({ searchUrl }: ConfigProps) =>
  ({
    urlHashParam: 'bid',
    utmContent: 'blick-bites',
    blickBitesApiUrl: `${searchUrl}/bites`,
  }) as const satisfies Record<string, unknown>

export default config
