import { useContext, useEffect, useMemo, useRef, useCallback } from 'react'
import {
  convertPlayerCurrentTimeToTimestamp,
  isBetween,
  oneMinInFuture,
  hasChaptersPlaceholder,
  isInsideLiveWindow,
} from './utils'
import {
  CurrentTimeParam,
  MetadataParam,
} from '@components/Video/VideoPlayer/JwLibrary/types'
import { Chapter, TimelinePointer } from './types'
import LivestreamStoryContext from './LivestreamStoryContext'
import { dayjs } from '@utils/date'
import throttle from 'lodash.throttle'
import { getJWPlayer } from '@utils/videoPlayer'

type UseLivestreamStoryType = {
  isPlayerReady: boolean
  widgetId: string
}

const emptyFn = () => {}

const findActiveChapter = (timestamp: string, chapters?: Chapter[]) => {
  return chapters?.find((chapter, index, chapters) =>
    isBetween(
      timestamp,
      chapter.timestamp,
      chapters[index + 1]?.timestamp || oneMinInFuture()
    )
  )
}
const removeObsoleteTimelinePointers = (
  timelinePointers: TimelinePointer[]
): TimelinePointer[] => {
  let wasFiltered = false

  const newTimelinePointers = timelinePointers.filter(
    (timelinePointer: TimelinePointer) => {
      const isPointerInsideLiveWindow = isInsideLiveWindow(
        timelinePointer.timeString,
        15
      )

      if (!wasFiltered && !isPointerInsideLiveWindow) {
        wasFiltered = true
      }

      return isPointerInsideLiveWindow
    }
  )
  // return previous array if it wasn't filtered (performance improvement)
  return wasFiltered ? newTimelinePointers : timelinePointers
}

const newFilteredTimelinePointersArray = (
  oldArray: TimelinePointer[],
  timelinePointer: TimelinePointer
) => {
  if (oldArray[0]?.source === 'program-date-time') {
    return [timelinePointer]
  } else {
    return [...removeObsoleteTimelinePointers(oldArray), timelinePointer]
  }
}

const getTimelinePointer = ({
  metadata,
  metadataTime,
}: any): TimelinePointer | undefined => {
  const privTimecode =
    metadata?.PRIV?.['com.elementaltechnologies.timestamp.utc']
  if (privTimecode) {
    return {
      timeString: new TextDecoder().decode(new Uint8Array(privTimecode)),
      timelineSecond: metadataTime,
      source: 'PRIV',
    }
  }
}

const useLivestreamStory = ({
  isPlayerReady,
  widgetId,
}: UseLivestreamStoryType): {
  setAdPlaying: (adPlaying: boolean) => void
  onPlaySuccess: () => void
} => {
  const firstTimePlaySuccess = useRef<boolean>(true)

  const context = useContext(LivestreamStoryContext)

  const {
    enabled,
    chapters = [],
    setActiveChapter = emptyFn,
    adPlaying,
    setAdPlaying = emptyFn,
    timelinePointers,
    setTimelinePointers = emptyFn,
    userLastSelectedTimestamp,
  } = context || {}

  const areChaptersEnabled = !!context && enabled

  const onPlaySuccess = useCallback(() => {
    if (!firstTimePlaySuccess.current || !areChaptersEnabled) {
      return
    }

    firstTimePlaySuccess.current = false

    const player = getJWPlayer(widgetId)

    if (!player) {
      return
    }

    /** setting the initial pointer based on real
     * world measurements but slightly innacurate
     * - will be replaced when the 1st ID3 tag is parsed*/
    const initialPointer: TimelinePointer = {
      timeString: dayjs().subtract(34, 'seconds').toISOString(),
      timelineSecond: player.getCurrentTime?.() ?? 0,
      source: 'program-date-time',
    }
    setTimelinePointers([initialPointer])

    /**
     * The function looks for the TCOD sent from the studio
     * along with the ID3 tags and updates the existing pointers
     */
    player.on('metadataCueParsed', (event: MetadataParam) => {
      setTimelinePointers((oldArray: TimelinePointer[]) => {
        if (event?.metadataType === 'id3') {
          const timelinePointer = getTimelinePointer(event)
          if (timelinePointer) {
            const existingPointer = oldArray.find(
              (t: TimelinePointer) => t.timelineSecond === event.metadataTime
            )
            if (!existingPointer) {
              return newFilteredTimelinePointersArray(oldArray, timelinePointer)
            }
          }
        }

        return oldArray
      })
    })
  }, [areChaptersEnabled, setTimelinePointers, widgetId])

  const findAndSetActiveChapter = useCallback(
    (currentTime: number) => {
      if (timelinePointers.length > 0) {
        const timestamp = convertPlayerCurrentTimeToTimestamp(
          currentTime,
          timelinePointers
        )

        if (timestamp) {
          const activeChapter = findActiveChapter(timestamp, chapters)

          if (activeChapter) {
            setActiveChapter(activeChapter)
          }
        }
      }
    },
    [chapters, setActiveChapter, timelinePointers]
  )

  const onProgress = useCallback(
    (data: CurrentTimeParam) => {
      const player = getJWPlayer(widgetId)

      if (
        !player ||
        adPlaying ||
        !areChaptersEnabled ||
        hasChaptersPlaceholder(chapters)
      ) {
        return
      }

      const currentTime = player?.getCurrentTime?.() || data.currentTime

      /**
       * since this operation is not time-sensitive, in order to avoid
       * jumping back and forth, due to not having close enough
       * timeline pointers (around ads mostly or when the user jumps close
       * to the end of the DVR window = 30min), we are calling the
       * setActiveChapter below only if the user did not
       * set it manually for more than 5sec
       */
      const shouldSetChapter =
        Date.now().valueOf() - userLastSelectedTimestamp > 5000

      if (shouldSetChapter) {
        findAndSetActiveChapter(currentTime)
      }
    },
    [
      adPlaying,
      areChaptersEnabled,
      chapters,
      findAndSetActiveChapter,
      userLastSelectedTimestamp,
      widgetId,
    ]
  )

  const onTimeHandler = useMemo(
    () =>
      throttle(
        ({ position }: jwplayer.TimeParam) => {
          onProgress?.({
            currentTime: position,
          })
        },
        1000,
        { trailing: false }
      ),
    [onProgress]
  )

  useEffect(() => {
    // cleanup function
    return () => {
      onTimeHandler?.cancel()
    }
  }, [onTimeHandler])

  useEffect(() => {
    const player = getJWPlayer(widgetId)

    if (!isPlayerReady || !player || !areChaptersEnabled) {
      return
    }

    player.on('time', onTimeHandler)

    return () => {
      player.off('time', onTimeHandler)
    }
  }, [areChaptersEnabled, isPlayerReady, onTimeHandler, widgetId])

  return useMemo(
    () => ({
      setAdPlaying,
      onPlaySuccess,
    }),
    [onPlaySuccess, setAdPlaying]
  )
}

export default useLivestreamStory
