import { ConfigProps } from '@config/types'

export type SlotCodePlaceholderConfig = Record<
  string,
  {
    desktop?:
      | {
          specialStickyVertical?: false
          stretchToFullSectionWidth?: boolean
          stretchToFullSingleColumnArticleWidth?: boolean
          noExtendedPlaceholderOnArticle?: boolean
          height: number
        }
      | {
          specialStickyVertical?: true
          stretchToFullSectionWidth?: false
          stretchToFullSingleColumnArticleWidth?: false
          noExtendedPlaceholderOnArticle?: false
          height?: undefined
        }
    tablet?: {
      noExtendedPlaceholderOnArticle?: boolean
      noStretchOnSection?: boolean
      height: number
    }
    mobile?: {
      noExtendedPlaceholderOnArticle?: boolean
      noStretchOnSection?: boolean
      height: number
    }
  }
>

const config = ({ publication }: ConfigProps) =>
  ({
    adSkipTimeDurationInSeconds: 10,
    videoTypeThresholdInSeconds: 90,
    adMidrollThresholdInSeconds: 180,
    adMidrollOffsetInPercent: 0.5,
    gallery: {
      firstAdAfterXSlides: 2,
      showAdAfterXSlides: 5,
    },
    adPlaceholderTitle: publication === 'romandie' ? 'Publicité' : 'Werbung',
    slotCodeMap: {
      TA_1: 'top_special_1',
      'SBA_1-DDS': 'right_ad_1',
      WBM_1: 'top_ad_1',
      'WB_2-ECB': 'index_ad_1',
      'WB_3-ECB': 'details_ad_4',
      'WB_4-ECB': 'bottom_ad_1',
      DS_1: 'index_special_1',
      DS_2: 'index_special_2',
      MTA_1: 'top_special_1',
      MMR_1: 'top_ad_1',
      MR_2: 'details_ad_2',
      MR_4: 'gallery_ad_1',
      MPA_2: 'details_ad_3',
      MHPA_2: 'details_ad_2',
      MHPA_3: 'index_ad_2',
      MMR_5: 'details_ad_5',
      MMR_4: 'gallery_ad_1',
      MDS_1: 'index_special_1',
      MDS_2: 'index_special_2',
    },
    slotCodePlaceholderConfig: {
      top_ad_1: {
        desktop: {
          stretchToFullSectionWidth: true,
          noExtendedPlaceholderOnArticle: true,
          height: 250,
        },
        tablet: {
          noExtendedPlaceholderOnArticle: true,
          height: 250,
        },
        mobile: {
          noExtendedPlaceholderOnArticle: true,
          height: 250,
        },
      },
      top_ad_2: {
        tablet: {
          noExtendedPlaceholderOnArticle: true,
          height: 480,
        },
        mobile: {
          noExtendedPlaceholderOnArticle: true,
          height: 480,
        },
      },
      index_ad_1: {
        desktop: {
          stretchToFullSectionWidth: true,
          height: 250,
        },
        tablet: {
          height: 600,
        },
        mobile: {
          height: 600,
        },
      },
      index_ad_2: {
        desktop: {
          stretchToFullSectionWidth: true,
          height: 250,
        },
        tablet: {
          height: 600,
        },
        mobile: {
          height: 600,
        },
      },
      index_ad_3: {
        desktop: {
          specialStickyVertical: true,
        },
        tablet: {
          height: 600,
        },
        mobile: {
          height: 600,
        },
      },
      index_special_1: {
        desktop: {
          stretchToFullSectionWidth: true,
          height: 250,
        },
        tablet: {
          height: 250,
        },
        mobile: {
          height: 250,
        },
      },
      index_special_2: {
        desktop: {
          stretchToFullSectionWidth: true,
          height: 250,
        },
        tablet: {
          height: 250,
        },
        mobile: {
          height: 250,
        },
      },
      bottom_ad_1: {
        desktop: {
          stretchToFullSectionWidth: true,
          height: 250,
        },
        tablet: {
          height: 600,
        },
        mobile: {
          height: 600,
        },
      },
      index_teaser_1: {
        desktop: {
          height: 250,
        },
        tablet: {
          noStretchOnSection: true,
          height: 109,
        },
        mobile: {
          noStretchOnSection: true,
          height: 109,
        },
      },
      details_ad_2: {
        desktop: {
          height: 250,
        },
        tablet: {
          height: 600,
        },
        mobile: {
          height: 600,
        },
      },
      details_ad_3: {
        desktop: {
          height: 250,
        },
        tablet: {
          height: 480,
        },
        mobile: {
          height: 480,
        },
      },
      details_ad_4: {
        desktop: {
          stretchToFullSingleColumnArticleWidth: true,
          stretchToFullSectionWidth: true,
          height: 250,
        },
        tablet: {
          height: 250,
        },
        mobile: {
          height: 250,
        },
      },
      details_ad_5: {
        desktop: {
          height: 250,
        },
        tablet: {
          height: 250,
        },
        mobile: {
          height: 250,
        },
      },
      'side-right_ad_1': {
        desktop: {
          noExtendedPlaceholderOnArticle: true,
          height: 250,
        },
      },
      'side-right_teaser_1': {
        desktop: {
          noExtendedPlaceholderOnArticle: true,
          height: 250,
        },
      },
      special_ad_1: {
        desktop: {
          height: 250,
        },
        tablet: {
          height: 250,
        },
        mobile: {
          height: 250,
        },
      },
      special_ad_2: {
        desktop: {
          stretchToFullSectionWidth: true,
          height: 250,
        },
        tablet: {
          height: 250,
        },
        mobile: {
          height: 250,
        },
      },
      IAV_1: {},
    } satisfies SlotCodePlaceholderConfig,
    admeira: {
      storageKey: 'admeira',
      sdk: {
        fallbackVersion: 'latest',
        fallbackEnvironment: 'prod',
        publisherId: `blick.ch_${publication === 'romandie' ? 'fr' : 'de'}`,
        articleIdKeyName: `articleid${publication === 'romandie' ? '_fr' : ''}`,
        fallbackChannel: 'ROS',
        channelMapping:
          publication === 'romandie'
            ? ({
                News: 'News',
                Suisse: 'News',
                Monde: 'News',
                Opinion: 'Opinion',
                'Tech & Science': 'Tech',
                Sport: 'Sport',
                Lifestyle: 'Life',
                Vidéos: 'Videos',
              } as const)
            : ({
                News: 'News',
                Schweiz: 'News',
                Ausland: 'News',
                Digital: 'Digital',
                People: 'People',
                Sport: 'Sport',
                Meinung: 'Meinung',
                Politik: 'Politik',
                Wirtschaft: 'Wirtschaft',
                Leben: 'Leben',
                Mobil: 'Auto',
                Video: 'Video',
                'Test Section Home': 'Home',
              } as const),
      },
    },
  }) as const satisfies Record<string, unknown>

export default config
