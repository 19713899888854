import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { mobileCSS } from '@measures/responsive'
import Blink from '@components/Blink'
import FreeRatioImage from '@components/FreeRatioImage'
import { calculateSingleConstraintDimensions } from '@components/FreeRatioImage/utils'
import useViewportTracking from '@hooks/useViewportTracking'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import { BrandStudioAdItemProps } from './types'
import useViewportType from '@hooks/useViewport/useViewportType'
import useViewportDimensions from '@hooks/useViewport/useViewportDimensions'

interface StyledBrandStudioAdItemContainerProps {
  isFullWidth: boolean
}

interface StyledBrandStudioImageContainerProps {
  mobileWidth: number
  mobileHeight: number
}

const maxWidths = {
  desktop: 620,
  tablet: 624,
  mobile: 430,
  mobileSmall: 320,
}

const StyledBlink = styled(Blink)`
  display: inline-block;
`

const StyledBrandStudioAdItemContainer = styled.div<StyledBrandStudioAdItemContainerProps>`
  ${({ isFullWidth }) => {
    return css`
      width: ${isFullWidth ? '100%' : '50%'};

      ${mobileCSS(css`
        width: 100%;
      `)};

      &:nth-child(2) {
        margin-left: 32px;
      }

      & > img {
        position: absolute;
        top: -1px;
        left: -1px;
      }
    `
  }}
`

const StyledBrandStudioImageContainer = styled.div<StyledBrandStudioImageContainerProps>`
  ${({ mobileWidth, mobileHeight }) => {
    return css`
      width: auto;
      height: auto;

      ${mobileCSS(css`
        width: ${mobileWidth}px;
        height: ${mobileHeight}px;

        > * {
          width: 100%;
          height: 100%;
        }

        picture > img {
          width: 100%;
          height: 100%;
        }
      `)}
    `
  }}
`

const BrandStudioAdItem: FunctionComponent<BrandStudioAdItemProps> = ({
  trackingClass,
  adInfo: {
    fullwidth,
    clickTrackingUrl,
    trackingUrl,
    image: { src, alt, crops, width, height },
  },
  adTitle,
}) => {
  const onClick = useCallback<TrackingFnType>(
    () => ({
      event: 'contextual_click',
      eventCategory: 'BrandStudio',
      // Event specific data
      eventAction: `Click / ${trackingClass}`,
      clickUrl: clickTrackingUrl,
      element: adTitle,
      link_url: clickTrackingUrl,
    }),
    [adTitle, clickTrackingUrl, trackingClass]
  )

  const onImpression = useCallback<TrackingFnType>(
    () => ({
      event: 'contextual_impression',
      eventCategory: 'BrandStudio',
      // Event specific data
      eventAction: `Impression / ${trackingClass}`,
      impressionUrl: trackingUrl,
      element: adTitle,
      link_url: clickTrackingUrl,
    }),
    [trackingClass, trackingUrl, adTitle, clickTrackingUrl]
  )

  const trackedOnImpression = useTracking(onImpression)
  const trackedOnClick = useTracking(onClick)

  const viewportType = useViewportType()
  const { width: viewportWidth } = useViewportDimensions()
  const viewportRef = useViewportTracking({
    track: true,
    onImpression: trackedOnImpression,
  })

  const [actualMobileWidth, setActualMobileWidth] = useState<number>(0)
  const [actualMobileHeight, setActualMobileHeight] = useState<number>(0)

  const clickableProps = {
    href: clickTrackingUrl ?? '#',
    target: '_blank',
    rel: 'nofollow',
    onClick: trackedOnClick,
  }

  useEffect(() => {
    if (viewportType === 'mobile') {
      const constrainedWidth = viewportWidth - 24
      const { width: calculatedMobileWidth, height: calculatedMobileHeight } =
        calculateSingleConstraintDimensions({
          constraint: {
            width: constrainedWidth,
            height,
          },
          originalDimensions: { width, height },
        })

      setActualMobileWidth(calculatedMobileWidth)
      setActualMobileHeight(calculatedMobileHeight)
    }
  }, [viewportType, viewportWidth, width, height])

  return (
    <StyledBrandStudioAdItemContainer
      isFullWidth={!!fullwidth}
      ref={viewportRef}>
      <StyledBlink {...clickableProps}>
        <StyledBrandStudioImageContainer
          mobileWidth={actualMobileWidth}
          mobileHeight={actualMobileHeight}>
          <FreeRatioImage
            src={src}
            alt={alt}
            originalDimensions={{ width, height }}
            constraints={{
              desktop: {
                width: maxWidths.desktop,
                height,
              },
              tablet: { width: maxWidths.tablet, height },
              mobile: { width: maxWidths.mobile, height },
              mobileSmall: {
                width: maxWidths.mobileSmall,
                height,
              },
            }}
            crops={{ free: crops }}
          />
        </StyledBrandStudioImageContainer>
      </StyledBlink>
      {trackingUrl ? (
        // eslint-disable-next-line @next/next/no-img-element
        <img width="1" height="1" src={trackingUrl} alt="" />
      ) : null}
    </StyledBrandStudioAdItemContainer>
  )
}

export default BrandStudioAdItem
