import { useMemo } from 'react'
import { extractPageType, hasImmersiveHero } from '@utils/cook'
import usePageMetadata from '@hooks/usePageMetadata'

export type UseGetPageIdentity = () => {
  isArticle: boolean
  isDossier: boolean
  isRecipe: boolean
  isAuthor: boolean
  isVideoOnly: boolean
  isHerotelling: boolean
  isStorytelling: boolean
  isImmersive: boolean
}

const useGetPageIdentity: UseGetPageIdentity = () => {
  const pageMetadata = usePageMetadata()
  const isRecipe = pageMetadata.teaser?.targetContentType === 'recipe'
  const isDossier = extractPageType(pageMetadata) === 'dossier'
  const isArticle = extractPageType(pageMetadata) === 'article'
  const isAuthor = pageMetadata.teaser?.targetContentType === 'author'
  const isVideoOnly = pageMetadata.teaser?.targetContentType === 'video'
  const isHerotelling = pageMetadata.targetContentSubType === 'hero'
  const isStorytelling = pageMetadata.targetContentSubType === 'normal'
  const isImmersive = hasImmersiveHero(pageMetadata)

  return useMemo(
    () => ({
      isArticle,
      isDossier,
      isRecipe,
      isAuthor,
      isVideoOnly,
      isHerotelling,
      isStorytelling,
      isImmersive,
    }),
    [
      isArticle,
      isDossier,
      isRecipe,
      isAuthor,
      isVideoOnly,
      isHerotelling,
      isStorytelling,
      isImmersive,
    ]
  )
}

export default useGetPageIdentity
