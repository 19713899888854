import HeadlineLeadComponents from '@components/HeadlineLead'
import config from '@config'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import Credit from '@components/ArticleTextbox/Credit'
import { desktopCSS, mobileCSS, tabletCSS } from '@measures/responsive'
import {
  ExtraHeadlineCommonProps,
  HerotellingHeadlineLeadAPIProps,
} from '@widgets/HeadlineLead/types'
import { printCSS } from '@utils/style'
import { stripHtml } from '@hooks/useTracking/utils'
import usePageMetadata from '@hooks/usePageMetadata'
import TitleCatchword from '@components/HeadlineLead/TitleCatchword'
import PlusLinkShareButton from '@components/PlusLinkShare/Button'
import { scrollToCommentingWidget } from '@widgets/Commenting/utils'
import { useQueryClient } from '@tanstack/react-query'
import useBlickPlusSkeleton from '@hooks/useBlickPlusSkeleton'
import useABTestValue from '@hooks/useABTestValue'
import ShareCTAButton from '@components/Social/ShareCTAButton'

export type HerotellingHeadlineLeadProps = HerotellingHeadlineLeadAPIProps &
  ExtraHeadlineCommonProps

const {
  publication: { publication },
  socialembeds: { blickplusTitleAddition },
} = config

const { ArticleLead, CommentButton, HeadlineHeroImage } = HeadlineLeadComponents

const StyledContentsWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing24, spacing16, spacing64 },
      color: {
        primary: { primary01: primary01Color },
      },
    },
  }) => css`
    grid-area: scw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-row: 2;
    padding: ${spacing24} ${spacing16} 0;
    text-align: left;
    background-color: ${primary01Color};

    ${tabletCSS(css`
      text-align: center;
      padding: ${spacing24} ${spacing64} 0;
    `)}

    ${desktopCSS(css`
      grid-row: auto;
      text-align: center;
      padding: 3vw;
    `)}
  `}
`

const HerotellingWrapper = styled.div`
  display: grid;
  width: 100%;

  ${desktopCSS(css`
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-areas: 'scw hhi';
  `)};

  ${tabletCSS(css`
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-template-areas:
      'hhi'
      'scw';
  `)};

  ${mobileCSS(css`
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-template-areas:
      'hhi'
      'scw';
  `)};
`

const SharingAndCommentWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing8 },
    },
  }) => css`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    margin-bottom: -2px;
    margin-top: ${spacing8};
    align-items: center;
    width: 100%;

    ${desktopCSS(css`
      margin: 0;
      align-items: center;
    `)}

    ${printCSS(css`
      display: none;
    `)};
  `}
`

const StyledSocial = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;

  ${printCSS(css`
    display: none;
  `)}
  ${mobileCSS(css`
    align-self: flex-start;
  `)}
`

const SocialButtonsContainer = styled.div`
  ${({
    theme: {
      spacing: { spacing8, spacing24, spacing16 },
    },
  }) => css`
    display: flex;
    flex-direction: row;
    gap: ${spacing8};
    margin: ${spacing24} 0;

    ${mobileCSS(css`
      margin: ${spacing16} 0;
      width: 100%;
    `)}
  `}
`

const HerotellingHeadlineLead: FunctionComponent<
  HerotellingHeadlineLeadProps
> = ({
  catchword,
  title,
  lead,
  displayCommentsOnArticle,
  storytellingImage,
  commentsNumber,
  handleArticleClickShowCommentTracking,
}) => {
  const pageMetadata = usePageMetadata()
  const articleUrl = pageMetadata?.url
  const articleId = pageMetadata?.id
  const formattedCredit = `Foto: ${storytellingImage?.credit?.text}`
  const blickplusTitleAdditionForUrl = encodeURIComponent(
    ` ${blickplusTitleAddition}`
  )
  const queryClient = useQueryClient()
  const sharingTitle = `${stripHtml(title)}${blickplusTitleAdditionForUrl}`

  const sharingCatchword = catchword ? stripHtml(catchword) : ''
  const fullShareTitleAndCatchword = `${sharingCatchword}%20${sharingTitle}`

  // TODO Cleanup after go live: remove the AB test check and use the skeleton value only, or remove isPlusSharingLive completely.
  const { isPlusSharingLive: isPlusSharingLiveSkeleton } =
    useBlickPlusSkeleton()
  const isPlusSharingABTestEnabled =
    useABTestValue('blickPlusLinkSharing') === 'active'
  const isPlusSharingLive =
    isPlusSharingLiveSkeleton || isPlusSharingABTestEnabled

  return (
    <HerotellingWrapper>
      <StyledContentsWrapper>
        <TitleCatchword title={title} catchword={catchword}>
          {!!lead && <ArticleLead>{lead}</ArticleLead>}
        </TitleCatchword>
        <SharingAndCommentWrapper>
          {!articleUrl || !articleId ? null : (
            <StyledSocial>
              <SocialButtonsContainer>
                <ShareCTAButton
                  title={fullShareTitleAndCatchword}
                  articleId={articleId}
                />
                {isPlusSharingLive && <PlusLinkShareButton />}
              </SocialButtonsContainer>
            </StyledSocial>
          )}
          {publication !== 'romandie' && displayCommentsOnArticle && (
            <CommentButton
              onClick={() => {
                handleArticleClickShowCommentTracking()
                scrollToCommentingWidget(queryClient)
              }}>
              {commentsNumber}
            </CommentButton>
          )}
        </SharingAndCommentWrapper>
        <Credit>{formattedCredit}</Credit>
      </StyledContentsWrapper>
      <HeadlineHeroImage
        src={storytellingImage?.src}
        alt={storytellingImage?.alt}
        crops={storytellingImage?.crops}
      />
    </HerotellingWrapper>
  )
}

export default HerotellingHeadlineLead
