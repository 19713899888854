import SvgIcon from '@components/SvgIcon'
import useHeaderSearchBar from '@hooks/useHeaderSearchBar'
import { NormalizedWeatherData, useWeather } from '@hooks/useWeather'
import { desktopCSS } from '@measures/responsive'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import Blink from '@components/Blink'
import config from '@config'

interface WeatherProps {
  className?: string
}

interface StyledContainerProps {
  isWeatherVisible: boolean
}

const {
  weather: { meteoNewsUrl },
} = config

const Container = styled(Blink)<StyledContainerProps>`
  ${({
    theme: {
      color: {
        primary: { blickRed },
      },
    },
    isWeatherVisible,
  }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    appearance: none;
    text-decoration: none;
    user-select: none;
    height: 40px;
    width: 64px;
    overflow: hidden;

    transition: width 0.2s ease-in-out;

    ${isWeatherVisible &&
    css`
      ${desktopCSS(css`
        width: 0;
      `)}
    `}

    &:hover {
      svg {
        fill: ${blickRed};
      }
      > span {
        color: ${blickRed};
      }
    }
  `}
`

const Temperature = styled.span`
  ${({
    theme: {
      typography: {
        eyebrows: {
          medium2: { bundledCSS: medium2EyebrowsCSS },
        },
      },
      color: {
        primary: { primary01 },
      },
      spacing: { spacing4 },
    },
  }) => css`
    ${medium2EyebrowsCSS};
    color: ${primary01};
    margin-left: ${spacing4};
  `}
`

const Weather: FunctionComponent<WeatherProps> = ({ className }) => {
  const { isSearchBarCollapsed } = useHeaderSearchBar()
  const weatherData = useWeather()

  const { temperature, iconName, location } =
    weatherData as NormalizedWeatherData

  return (
    <Container
      isWeatherVisible={!isSearchBarCollapsed}
      className={className}
      href={meteoNewsUrl}
      target="_blank">
      {temperature && location && iconName && (
        <>
          <SvgIcon iconName={iconName} size={24} />
          <Temperature>{temperature}º</Temperature>
        </>
      )}
    </Container>
  )
}

export default Weather
