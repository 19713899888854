import { FunctionComponent, ReactNode, useState } from 'react'
import LivestreamStoryContext from './LivestreamStoryContext'
import { Chapter, TimelinePointer } from './types'
import { chapterPlaceholder } from './utils'

const LivestreamStoryContextProvider: FunctionComponent<{
  enabled?: boolean
  widgetId: string
  children?: ReactNode
}> = ({ enabled, widgetId, children }) => {
  const [activeChapter, setActiveChapter] = useState<Chapter>()
  const [chapters, setChapters] = useState<Chapter[]>([chapterPlaceholder])
  const [adPlaying, setAdPlaying] = useState<boolean>(false)
  const [timelinePointers, setTimelinePointers] = useState<TimelinePointer[]>(
    []
  )
  const [userLastSelectedTimestamp, setUserLastSelectedTimestamp] = useState(0)

  return (
    <LivestreamStoryContext.Provider
      value={{
        enabled,
        chapters,
        setChapters,
        activeChapter,
        setActiveChapter,
        adPlaying,
        setAdPlaying,
        timelinePointers,
        setTimelinePointers,
        userLastSelectedTimestamp,
        setUserLastSelectedTimestamp,
        widgetId,
      }}>
      {children}
    </LivestreamStoryContext.Provider>
  )
}

export default LivestreamStoryContextProvider
