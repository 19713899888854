const setLocalStorageItem = (
  key: Parameters<typeof localStorage.setItem>[0],
  value: Parameters<typeof JSON.stringify>[0]
): void => {
  try {
    localStorage.setItem(key, JSON.stringify(value))
  } catch (err) {
    //Do nothing. This is due to security settins in certain browsers.
  }
}

const getLocalStorageItem = <T = unknown>(
  key: Parameters<typeof localStorage.getItem>[0]
): T | undefined => {
  try {
    return JSON.parse(localStorage.getItem(key) ?? '') as T
  } catch (err) {
    //Do nothing. This is due to security settins in certain browsers.
  }
}

const removeLocalStorageItem = (
  key: Parameters<typeof localStorage.removeItem>[0]
): void => {
  try {
    localStorage.removeItem(key)
  } catch (err) {
    //Do nothing. This is due to security settins in certain browsers.
  }
}

export { setLocalStorageItem, getLocalStorageItem, removeLocalStorageItem }
