import { useFullScreenGallery } from '@hooks/useFullScreenGallery'
import useFullScreenImagePortal from '@hooks/useFullScreenImagePortal'
import {
  desktopCSS,
  isMobileUserAgent,
  landscapeOrientationCSS,
  mobileCSS,
  mobileLandscapeCSS,
} from '@measures/responsive'
import { AdSlide, GalleryWidget, Slide } from '@widgets/Gallery/types'
import { isAdSlide } from '@widgets/Gallery/utils'
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useInView } from 'react-intersection-observer'
import styled, { css } from 'styled-components'
import Swiper, { SwiperClass, ArrowsVisibilityType } from '@components/Swiper'
import CloseButton from '../Common/CloseButton'
import SlideFooter from '../Common/SlideFooter'
import SlideImage from '../Common/SlideImage'
import useGalleryImagePreloading from '../utils/useGalleryImagePreloading'
import {
  useGallerySlideChangeTracking,
  useGallerySlideClickTracking,
} from '../utils/useGalleryTracking'
import GalleryAdSlide from './GalleryAdSlide'
import useShouldStretch from '@hooks/useShouldStretch'
import { StretchType } from '@contexts/storyTellingFullWidth'
import { printCSS } from '@utils/style'
import SwiperNavigation from './SwiperNavigation'
import { useSwiperKeyboard } from '@hooks/useSwiper/useSwiperKeyboard'

export interface SwiperWrapperProps extends StretchType {
  isFullScreen: boolean
}

const SwiperWrapper = styled.div<SwiperWrapperProps>`
  ${({
    shouldStretch,
    shouldStretchToCardWidth,
    isFullScreen,
    shouldStretchToHeaderWidth,
    theme: {
      measures: { stretchWidth, stretchToLayoutCardWidth },
    },
  }) => css`
    ${shouldStretch && stretchWidth(shouldStretchToHeaderWidth)};

    ${shouldStretchToCardWidth && stretchToLayoutCardWidth()};

    ${isFullScreen &&
    css`
      width: 100%;
      max-width: 994px;

      box-sizing: border-box;
      display: grid;
      grid-template-rows: auto 1fr auto;
      height: 100%;

      ${desktopCSS(css`
        display: block;
        height: auto;
      `)}
    `}
  `}
`

const SwiperContainer = styled.div<Pick<SwiperWrapperProps, 'isFullScreen'>>`
  ${({ isFullScreen }) => css`
    width: 100%;
    padding-top: ${(2 / 3) * 100}%;
    position: relative;

    ${isFullScreen &&
    css`
      ${mobileCSS(css`
        padding-top: 0;
      `)}

      ${mobileLandscapeCSS(css`
        padding-top: 0;
      `)}
    `}
  `}
`

const SwiperInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const SwiperSlide = styled.div`
  height: 100%;
  width: 100%;
`

const MultiImageGalleryPlaceholder = styled.div<
  {
    isFullScreen: boolean
    hasPortalNode: boolean
  } & Partial<SwiperWrapperProps>
>`
  ${({
    isFullScreen,
    hasPortalNode,
    shouldStretch,
    shouldStretchToCardWidth,
    shouldStretchToHeaderWidth,
    theme: {
      measures: { stretchWidth, stretchToLayoutCardWidth },
    },
  }) => css`
    width: 100%;
    position: relative;
    ${printCSS(css`
      display: none;
    `)};
    ${(!hasPortalNode || isFullScreen) &&
    css`
      width: initial;
      ${shouldStretch && stretchWidth(shouldStretchToHeaderWidth)};
      ${shouldStretchToCardWidth && stretchToLayoutCardWidth()};
    `};
  `}
`

const PlaceholderContent = styled.div<{
  isFullScreen: boolean
  hasPortalNode: boolean
}>`
  ${({ isFullScreen, hasPortalNode }) => css`
    ${!isFullScreen &&
    hasPortalNode &&
    css`
      display: none;
    `}

    width: 100%;
    padding-top: ${100 * (2 / 3)}%;

    ${mobileCSS(css`
      ${isFullScreen &&
      landscapeOrientationCSS(css`
        padding-top: calc(100vh - 160px);
      `)}
    `)}
  `}
`

export interface MultiImageGalleryProps {
  galleryTitle: GalleryWidget['title']
  galleryId: GalleryWidget['id']
  galleryUUID: GalleryWidget['uuid']
  slides: (Slide | AdSlide)[] | [Slide]
}

const MultiImageGallery: FunctionComponent<MultiImageGalleryProps> = ({
  slides,
  galleryTitle,
  galleryId,
  galleryUUID,
}) => {
  const swiperRef = useRef<SwiperClass | null>(null)
  const [arrowsVisibility, setArrowsVisibility] =
    useState<ArrowsVisibilityType>()
  const {
    isFullScreen,
    setIsFullScreen,
    hasControls,
    setHasControls,
    setIsGalleryWidget,
  } = useFullScreenGallery()

  const [activeIndex, setActiveIndex] = useState<number>(0)
  const { shouldPreloadImage, onPreloadNext } = useGalleryImagePreloading(
    slides.length
  )

  const { portalNode, enterFullScreen, exitFullScreen, InPortal, OutPortal } =
    useFullScreenImagePortal()
  const stretch = useShouldStretch()

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  })

  const {
    enableKeyboard: enableKeyboardNavigation,
    disableKeyboard: disableKeyboardNavigation,
  } = useSwiperKeyboard({ swiperRef })

  const numberOfSlides = slides?.length
  const activeCaption = isAdSlide(slides[activeIndex])
    ? ''
    : (slides[activeIndex] as Slide).caption
  const isMobile = isMobileUserAgent()
  const credit = (slides?.find((_, index) => index === activeIndex) as Slide)
    ?.credit

  /**
   * load the initial images only when in view,
   * and then every time the activeIndex changes
   */
  useEffect(() => {
    if (inView) {
      onPreloadNext(activeIndex)
    }
  }, [inView, onPreloadNext, activeIndex])

  useEffect(() => {
    setIsGalleryWidget(true)
  }, [setIsGalleryWidget])

  useEffect(() => {
    setTimeout(() => {
      swiperRef.current?.slideTo(swiperRef.current?.activeIndex, true)
    }, 0)
  }, [isFullScreen])

  const trackSlideClick = useGallerySlideClickTracking(
    activeIndex + 1,
    galleryId,
    galleryTitle
  )

  const handleEnterFullScreen = useCallback(() => {
    if (!isFullScreen) {
      setIsFullScreen(enterFullScreen())
      trackSlideClick()
      enableKeyboardNavigation()
    }
  }, [
    setIsFullScreen,
    enterFullScreen,
    isFullScreen,
    trackSlideClick,
    enableKeyboardNavigation,
  ])

  const handleLeaveFullScreen = useCallback(() => {
    setIsFullScreen(!exitFullScreen())
    disableKeyboardNavigation()
  }, [setIsFullScreen, exitFullScreen, disableKeyboardNavigation])

  const toggleControls = useCallback(() => {
    setHasControls(!hasControls)
  }, [setHasControls, hasControls])

  const handleSlideClick = useCallback(() => {
    if (isFullScreen && isMobile) {
      toggleControls()
    } else {
      handleEnterFullScreen()
    }
  }, [handleEnterFullScreen, toggleControls, isFullScreen, isMobile])

  const trackSlideChange = useGallerySlideChangeTracking(
    activeIndex + 1,
    galleryId,
    galleryTitle
  )

  const onSlideChange = useCallback(
    ({ nextIndex }: { nextIndex: number }) => {
      if (nextIndex !== activeIndex) {
        setActiveIndex(activeIndex)
        trackSlideChange()
      }
    },
    [trackSlideChange, activeIndex]
  )

  const onInit = useCallback((swiper: any) => {
    swiperRef.current = swiper
  }, [])

  const onArrowsVisibility = useCallback(
    (arrow: ArrowsVisibilityType, currentIndex: number) => {
      setArrowsVisibility(arrow)
      setActiveIndex(currentIndex)

      if (swiperRef.current) {
        swiperRef.current.activeIndex = currentIndex
      }
    },
    []
  )

  return (
    <MultiImageGalleryPlaceholder
      ref={ref}
      isFullScreen={isFullScreen}
      hasPortalNode={!!portalNode}
      {...stretch}>
      <PlaceholderContent
        isFullScreen={isFullScreen}
        hasPortalNode={!!portalNode}>
        <SlideFooter
          caption={activeCaption}
          isPlaceholder={true}
          hasPortalNode={!!portalNode}
          credit={credit}>
          <SwiperNavigation
            swiperRef={swiperRef}
            itemsCount={numberOfSlides}
            activeIndex={activeIndex}
            arrowsVisibility={arrowsVisibility}
          />
        </SlideFooter>
      </PlaceholderContent>
      {!!portalNode && (
        <>
          <InPortal node={portalNode}>
            <SwiperWrapper isFullScreen={isFullScreen} {...stretch}>
              <CloseButton onClose={handleLeaveFullScreen} />
              <SwiperContainer isFullScreen={isFullScreen}>
                <SwiperInner>
                  <Swiper
                    spaceBetween={16}
                    spaceTopBottom={0}
                    initialSlide={0}
                    navigation={false}
                    fullWidthSlides={true}
                    onInit={onInit}
                    onSlideChange={onSlideChange}
                    onArrowsVisibility={onArrowsVisibility}
                    slides={slides?.map(
                      (item: Slide | AdSlide, index: number) => (
                        <SwiperSlide key={index}>
                          {isAdSlide(item) ? (
                            <GalleryAdSlide
                              isActive={activeIndex === index}
                              isFullScreen={isFullScreen}
                              galleryUUID={galleryUUID}
                              adSlide={item}
                              index={index}
                            />
                          ) : (
                            <SlideImage
                              image={item.image}
                              onClick={handleSlideClick}
                              shouldPreload={shouldPreloadImage(index)}
                            />
                          )}
                        </SwiperSlide>
                      )
                    )}
                  />
                </SwiperInner>
              </SwiperContainer>
              <SlideFooter
                caption={activeCaption}
                credit={credit}
                hasExtraPadding={stretch.shouldStretchToCardWidth}>
                <SwiperNavigation
                  swiperRef={swiperRef}
                  itemsCount={numberOfSlides}
                  activeIndex={activeIndex}
                  arrowsVisibility={arrowsVisibility}
                  isFullScreen={isFullScreen}
                />
              </SlideFooter>
            </SwiperWrapper>
          </InPortal>
          {!isFullScreen && <OutPortal node={portalNode} />}
        </>
      )}
    </MultiImageGalleryPlaceholder>
  )
}

export default MultiImageGallery
