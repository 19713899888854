import usePageMetadata from '@hooks/usePageMetadata'
import config from '@config'
import { extractPageType } from '@utils/cook'
import { getPrimaryImageSchema } from '@utils/schema'
import { useMemo } from 'react'
import SchemaHTMLElement from '@components/SchemaHTMLElement'

const {
  backend: { baseUrl },
  schema: { webSiteId, breadcrumbListSchemaUrl },
} = config

const WebPageSchema = () => {
  const pageMetadata = usePageMetadata()
  const {
    url,
    context,
    metaDescription,
    lastModifiedDate,
    publishedDate,
    title,
    teaser,
  } = pageMetadata
  const isHomePage = context === 'home'
  const isArticle = extractPageType(pageMetadata) === 'article'
  const primaryImageOfPage = useMemo(
    () =>
      getPrimaryImageSchema({
        teaserImage: teaser?.image,
        isArticle,
      }),
    [isArticle, teaser?.image]
  )
  const additionalInfo = {
    primaryImageOfPage: {
      '@id': primaryImageOfPage?.['@id'] || '',
    },
    breadcrumb: {
      '@id': `${breadcrumbListSchemaUrl}${url}`,
    },
  }

  return (
    <>
      <SchemaHTMLElement
        scriptKey="webPagePrimaryImage"
        schemaObject={{ ...primaryImageOfPage }}
      />
      <SchemaHTMLElement
        scriptKey="webPage"
        schemaObject={{
          '@type': 'WebPage',
          id: `${baseUrl}${url}`,
          url: `${baseUrl}${url}`,
          name: title,
          description: metaDescription,
          datePublished: publishedDate,
          dateModified: lastModifiedDate,
          isPartOf: {
            '@id': webSiteId,
          },
          ...(!isHomePage ? additionalInfo : {}),
        }}
      />
    </>
  )
}

export default WebPageSchema
