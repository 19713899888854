/**
 * https://developer.jwplayer.com/jwplayer/docs/jw8-css-skin-reference#controlbar
 */

import { css } from 'styled-components'
import { iconSize, iconSizeRepeat } from './common'
import { mobileCSS } from '@measures/responsive'
import typography from '@themes/default/typography'
import color from '@themes/default/color'
import svgSprite from '@assets/images/video-player-sprite.svg'

export const controlbar = css`
  .jw-controlbar {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    z-index: 7;

    ${iconSize}
    .jw-text-elapsed {
      width: auto;
      padding-left: 8px;
    }

    .jw-text-elapsed,
    .jw-text-duration {
      ${typography.bodycopy.medium2.bundledCSS}

      ${mobileCSS(css`
        ${typography.bodycopy.xsmall.bundledCSS}
      `)}
    }

    .jw-text-duration:before {
      padding: 0 5px;
    }

    .jw-tooltip-time {
      height: auto;
      width: 0;
    }

    .jw-slider-time {
      .jw-progress {
        background-color: ${color.primary.blickRed};
      }

      /** preview thumbnails */

      .jw-time-tip {
        color: transparent;
        background-color: transparent;
        box-shadow: 0 0 0px transparent;
        padding-bottom: 4px;

        .jw-time-thumb {
          border: 1px solid white;
        }

        .jw-text {
          background-color: transparent;
          color: white;
          ${typography.bodycopy.xsmall.bundledCSS}
        }
      }
    }

    .jw-button-container {
      /** unwanted icons */

      .jw-icon-settings,
      .jw-icon-cc,
      .jw-icon-rewind {
        display: none;
      }

      /** updating icons */

      .replay-button {
        order: -1;

        > div {
          ${iconSizeRepeat}
        }
      }

      .jw-icon-captions {
        > div {
          ${iconSizeRepeat}
        }

        &.jw-icon-captions-disabled {
          cursor: default;
          opacity: 0.2;
          pointer-events: none;
        }

        order: 1;
      }

      .btv-icon-more-videos {
        > div {
          ${iconSizeRepeat}
        }

        order: 2;
      }

      .btv-icon-more-videos-unavailable {
        cursor: default;
        opacity: 0.2;
        pointer-events: none;
      }

      .jw-icon-fullscreen {
        order: 3;
      }

      .btv-icon-fullscreen-overlay {
        > div {
          ${iconSizeRepeat}
        }

        order: 3;
      }

      .jw-icon-volume::after {
        width: 32px;
      }

      .jw-svg-icon-play path,
      .jw-svg-icon-pause path,
      .jw-svg-icon-fullscreen-on path,
      .jw-svg-icon-fullscreen-off path,
      .jw-svg-icon-volume-0 path,
      .jw-svg-icon-volume-50 path,
      .jw-svg-icon-volume-100 path,
      .jw-svg-icon-pip-on path,
      .jw-svg-icon-pip-off path,
      .jw-svg-icon-airplay-on path,
      .jw-svg-icon-airplay-off path {
        display: none;
      }

      .jw-svg-icon-play {
        background: url(${svgSprite}#play);
        ${iconSizeRepeat}
      }

      .jw-svg-icon-pause {
        background: url(${svgSprite}#pause);
        ${iconSizeRepeat}
      }

      .jw-svg-icon-fullscreen-on {
        background: url(${svgSprite}#expand);
        ${iconSizeRepeat}
      }

      .jw-svg-icon-fullscreen-off {
        background: url(${svgSprite}#compress);
        ${iconSizeRepeat}
      }

      .btv-svg-icon-more-videos-on {
        background: url(${svgSprite}#more-videos-on);
        ${iconSizeRepeat}
      }

      .btv-svg-icon-more-videos-off {
        background: url(${svgSprite}#more-videos-off);
        ${iconSizeRepeat}
      }

      .jw-svg-icon-volume-0 {
        background: url(${svgSprite}#volume-slash);
        ${iconSizeRepeat}
      }

      .jw-svg-icon-volume-50 {
        background: url(${svgSprite}#volume);
        ${iconSizeRepeat}
      }

      .jw-svg-icon-volume-100 {
        background: url(${svgSprite}#volume-high);
        ${iconSizeRepeat}
      }

      .jw-svg-icon-pip-on {
        background: url(${svgSprite}#pip-on);
        ${iconSizeRepeat}
      }

      .jw-svg-icon-pip-off {
        background: url(${svgSprite}#pip-off);
        ${iconSizeRepeat}
      }

      .jw-svg-icon-airplay-on {
        background: url(${svgSprite}#airplay);
        ${iconSizeRepeat}
      }

      .jw-svg-icon-airplay-off {
        background: url(${svgSprite}#airplay);
        ${iconSizeRepeat}
      }

      .jw-icon-cast google-cast-launcher {
        background: url(${svgSprite}#screencast);
        ${iconSizeRepeat}
      }
    }
  }

  &.jw-flag-fullscreen {
    .jw-knob {
      height: 18px;
      width: 18px;
    }
  }
`
