import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import styled, { css } from 'styled-components'
import config from '@config'
import FeatureFlagEntry from './FeatureFlagEntry'
import { resetLocalStorage } from './utils'

const {
  abTest: { featureFlagsEntries },
} = config

const StyledFeatureFlagsWrapper = styled.ul`
  ${({
    theme: {
      spacing: { spacing20, spacing40 },
    },
  }) => css`
    grid-column: span 6;
    font-size: 24px;
    list-style: none;
    padding: 0;
    margin: ${spacing40} 0 0 0;
    display: grid;
    grid-row-gap: ${spacing20};
  `}
`

const DashboardTitle = styled.h1`
  margin: 0;
  padding: 0;
`

const DashboardWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing20 },
    },
  }) => css`
    padding: ${spacing20};
  `}
`

const DashboardDescription = styled.p`
  ${({
    theme: {
      spacing: { spacing20 },
      color: {
        primary: { blickRed },
      },
    },
  }) => css`
    margin: ${spacing20} 0 0 0;
    font-size: 30px;
    color: ${blickRed};
  `}
`

const Superbold = styled.span`
  font-weight: 900;
  font-size: 20px;
`

const BigFatResetAllNoTaksiesBacksiesButton = styled.button`
  ${({
    theme: {
      spacing: { spacing20 },
      color: {
        primary: { blickRed, primary02 },
        secondary: { darkRed },
        tertiary: { grey700 },
      },
    },
  }) => css`
    appearance: none;
    margin: ${spacing20} 0 0 0;
    padding: ${spacing20};
    font-size: 20px;
    border: 0;
    cursor: pointer;
    box-shadow: ${grey700} 8px 11px 8px 4px;
    border-radius: 10px;
    background-color: ${blickRed};
    color: ${primary02};

    &:active {
      box-shadow: ${darkRed} 8px 11px 8px 4px inset;
    }
  `}
`

const FeatureFlagDashboardWidget: FunctionComponent = () => {
  const [updateKey, setUpdateKey] = useState(0)

  const forceUpdate = useCallback(() => {
    setUpdateKey((prevUpdateKey) => prevUpdateKey + 1)
  }, [])

  useEffect(() => {
    addEventListener('focus', forceUpdate)

    return () => {
      removeEventListener('focus', forceUpdate)
    }
  }, [forceUpdate])

  useEffect(() => {
    addEventListener('storage', forceUpdate)

    return () => {
      removeEventListener('storage', forceUpdate)
    }
  }, [forceUpdate])

  return (
    <DashboardWrapper>
      <DashboardTitle>Available Feature Flags</DashboardTitle>
      <DashboardDescription>
        <Superbold>
          Important: Resetting a variant means that the default behavior is
          exhibited, which means Optimize could still set a variant if an
          experiment is running.
        </Superbold>
      </DashboardDescription>
      <BigFatResetAllNoTaksiesBacksiesButton
        onClick={() => {
          resetLocalStorage()
          forceUpdate()
        }}>
        Reset to initial settings
      </BigFatResetAllNoTaksiesBacksiesButton>
      <StyledFeatureFlagsWrapper>
        {(
          Object.entries(featureFlagsEntries) as unknown as [
            keyof typeof featureFlagsEntries,
            (typeof featureFlagsEntries)[keyof typeof featureFlagsEntries],
          ][]
        ).map(([featureFlagName, featureFlagInfo]) => (
          <FeatureFlagEntry
            key={featureFlagName}
            name={featureFlagName}
            {...featureFlagInfo}
            updateKey={`${updateKey}`}
          />
        ))}
      </StyledFeatureFlagsWrapper>
    </DashboardWrapper>
  )
}

const widget = {
  kind: ['widget', 'feature-flag-dashboard'],
  component: FeatureFlagDashboardWidget,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
