export type FontsType = {
  blick: string
  goodOT: string
  goodOTCond: string
  goodOTNarrow: string
  inter: string
  remSize: string
  sourceSansPro: string
}

const fonts = {
  blick: 'BlickVariable',
  goodOT: 'GoodOT',
  goodOTCond: 'GoodOTCond',
  goodOTNarrow: 'GoodOTNarrow',
  inter: 'InterVariable',
  remSize: '16px',
  sourceSansPro: 'Source Sans Pro',
} as const satisfies FontsType

export default fonts
