import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { desktopCSS, tabletCSS, mobileCSS } from '@measures/responsive'
import useClientViewportType from '@hooks/useClientViewportType'

export interface SideFlowWrapperProps {
  hasRelatedItems: boolean
  numberOfNonAdItemsInSideFlow: number
  children: ReactNode
}

export type StyledSideFlowWrapperProps = Pick<
  SideFlowWrapperProps,
  'hasRelatedItems' | 'numberOfNonAdItemsInSideFlow'
>

const StyledSideFlowWrapper = styled.div<StyledSideFlowWrapperProps>`
  ${({
    theme: {
      spacing: { spacing16, spacing24 },
    },
    hasRelatedItems,
    numberOfNonAdItemsInSideFlow,
  }) => css`
    ${desktopCSS(css`
      grid-column: span 4;
      display: grid;
      height: 100%;
      grid-template-columns: minmax(0, 1fr);
      grid-template-rows: repeat(${numberOfNonAdItemsInSideFlow}, auto) minmax(
          0,
          1fr
        );
      grid-row-gap: ${hasRelatedItems ? spacing16 : spacing24};
      align-items: flex-start;
    `)}

    ${tabletCSS(css`
      display: none;
    `)};

    ${mobileCSS(css`
      display: none;
    `)};
  `}
`

const SideFlowWrapper: FunctionComponent<SideFlowWrapperProps> = ({
  children,
  hasRelatedItems,
  numberOfNonAdItemsInSideFlow,
}) => {
  const clientViewportType = useClientViewportType()

  if (!['server', 'desktop'].includes(clientViewportType)) {
    return null
  }

  return (
    <StyledSideFlowWrapper
      hasRelatedItems={hasRelatedItems}
      numberOfNonAdItemsInSideFlow={numberOfNonAdItemsInSideFlow}>
      {children}
    </StyledSideFlowWrapper>
  )
}

export default SideFlowWrapper
