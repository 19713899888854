export type SummaryType = { text: string; category: string; link: string }

export type SourcesType = {
  url: string
  title: string
  isPlus: boolean
}

export enum FetchState {
  Initial,
  Loading,
  Success,
  Error,
}

export interface SectionSummaryProps {
  kind: string[]
  title: string
  links?: Record<string, SourcesType>
  content?: SummaryType[]
  state?: FetchState
}
