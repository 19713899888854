import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

interface CTAButtonProps {
  text: string
  className?: string
  onClick?: () => void
}

const ButtonWrapper = styled.button`
  ${({
    theme: {
      color: {
        primary: { blickRed: blickRedColor },
      },
      spacing: { spacing8, spacing12, spacing24 },
    },
  }) => css`
    display: flex;
    width: fit-content;
    justify-content: center;
    align-items: center;

    padding: ${spacing12} ${spacing24};
    gap: ${spacing8};

    appearance: none;
    text-decoration: none;
    border: 0;
    cursor: pointer;

    border-radius: 50px;
    background-color: ${blickRedColor};
  `}
`

const ButtonText = styled.div`
  ${({
    theme: {
      typography: {
        subheads: {
          small1: { bundledCSS: small1SubheadsCSS },
        },
      },
      color: {
        primary: { primary02: primary02Color },
      },
    },
  }) => css`
    ${small1SubheadsCSS};
    color: ${primary02Color};
  `}
`

const PrimaryCTAButton: FunctionComponent<CTAButtonProps> = ({
  className,
  text,
  onClick,
}) => {
  return (
    <ButtonWrapper className={className} onClick={onClick}>
      <ButtonText>{text}</ButtonText>
    </ButtonWrapper>
  )
}

export default PrimaryCTAButton
