import usePageMetadata from '@hooks/usePageMetadata'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import { FunctionComponent, useCallback, useEffect } from 'react'
import { getPageViewType } from './utils'
import config from '@config'
import { getURLParam } from '@utils/urlParams'

const {
  subscriptions: { offerPagePath },
} = config

const TrackPageView: FunctionComponent = () => {
  const { url } = usePageMetadata()

  const onImpression = useCallback<TrackingFnType>(
    ({ pageMetadata, queryClient }) => {
      const { sectionUniqueNames, ressort, url } = pageMetadata
      const sharingPlusQueryParam = getURLParam('sharingPlus', queryClient)

      const type = getPageViewType(pageMetadata)
      let eventData: ReturnType<TrackingFnType>

      switch (type) {
        case 'home': {
          eventData = { event: 'home_page_view' }
          break
        }
        case 'section': {
          eventData = {
            ...(url === offerPagePath ? { isPlus: 1 } : {}),
            event: 'section_page_view',
          }
          break
        }
        case 'article': {
          eventData = {
            ...{
              pageRessort: sectionUniqueNames?.[sectionUniqueNames.length - 1],
            },
            analyticsRessort: ressort,
            eventLabel: `${!!sharingPlusQueryParam}`,
            event: 'article_page_view',
          }
          break
        }
        case 'dossier': {
          eventData = { event: 'dossier_page_view' }
          break
        }
        default: {
          break
        }
      }

      return eventData
    },
    []
  )

  const trackOnImpression = useTracking(onImpression)

  useEffect(() => {
    trackOnImpression()
  }, [trackOnImpression, url])

  return null
}

export default TrackPageView
