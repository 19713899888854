import HockeySoccerSportsFooter from '@components/SportsFooter/HockeySoccer'
import SportLabelContent from '@components/SportsFooter/SportLabelContent'
import { transformDataForSportsComponents } from '@components/SportsFooter/utils'
import TeaserAnimatedPreview from '@components/TeaserAnimatedPreview'
import BlickPlusLogo from '@components/TeaserStandardVertical/BlickPlusLogo'
import Catchword from '@components/TeaserStandardVertical/Catchword'
import ImageComponent from '@components/TeaserStandardVertical/Image'
import Label from '@components/TeaserStandardVertical/Label'
import PromoLabel from '@components/TeaserStandardVertical/PromoLabel'
import SponsoredLabel from '@components/TeaserStandardVertical/SponsoredLabel'
import SponsoredLogo from '@components/TeaserStandardVertical/SponsoredLogo'
import Title from '@components/TeaserStandardVertical/Title'
import StyledTeaserStandardVerticalWrapper from '@components/TeaserStandardVertical/Wrapper'
import VideoLabel from '@components/VideoLabel'
import useAnimatedPreview from '@hooks/useAnimatedPreview'
import useClientViewportType from '@hooks/useClientViewportType'
import useIsHybridTeaser from '@hooks/useIsHybridTeaser'
import useRefDelegate from '@hooks/useRefDelegate'
import useSportsEvent from '@hooks/useSportsEvent'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import { stripHtml } from '@hooks/useTracking/utils'
import useViewportTracking from '@hooks/useViewportTracking'
import { desktopCSS } from '@measures/responsive'
import { useQueryClient } from '@tanstack/react-query'
import { triggerAureusImpression } from '@utils/aureus'
import { Image, Link, TargetContentType, TeaserVideo } from '@utils/cook/types'
import { LIVE_EVENT_DATA_SPORT_TYPES } from '@utils/formatters/common'
import { LiveEventDataSportTypes } from '@utils/formatters/liveEvent'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent, useCallback } from 'react'
import styled, { css, useTheme } from 'styled-components'

export interface TeaserStandardVerticalCommonProps {
  layoutTemplate?: 'default' | 'compact'
  accentColor: string
  title: string
  catchword?: string
  isPlus?: boolean
  articleId?: string
  track?: boolean
  link: Link
  image: Image<'3_2'>
  section: {
    name: string
    uniqueName: string
  }
  contentOrigin?: string
  targetContentType: TargetContentType
  video?: TeaserVideo
  aureusOfferId?: string
}

export interface TeaserStandardVerticalNoCommercialProps {
  commercialType?: undefined
  labelText?: string
}

export interface TeaserStandardVerticalPromoCommercialProps {
  commercialType: 'promo'
  commercial: {
    label: string
  }
}

export interface TeaserStandardVerticalSponsoredCommercialProps {
  commercialType: 'sponsored'
  commercial: {
    label: string
    logo?: Image<'free'>
  }
}

export type TeaserStandardVerticalMaybeCommercialProps =
  | TeaserStandardVerticalNoCommercialProps
  | TeaserStandardVerticalPromoCommercialProps
  | TeaserStandardVerticalSponsoredCommercialProps

export interface TeaserStandardVerticalNoSportProps {
  typeOfSport?: undefined
}

export interface TeaserStandardVerticalSportProps {
  typeOfSport: Extract<LiveEventDataSportTypes, 'soccer' | 'hockey'>
  matchId: string
}

export type TeaserStandardVerticalMaybeSportProps =
  | TeaserStandardVerticalNoSportProps
  | TeaserStandardVerticalSportProps

export type TeaserStandardVerticalAPIProps = TeaserStandardVerticalCommonProps &
  TeaserStandardVerticalMaybeCommercialProps &
  TeaserStandardVerticalMaybeSportProps

export interface StyledImageComponentProps {
  isCommercial: boolean
}

export interface StyledVideoLabelProps {
  isCompact: boolean
}

const StyledImageComponent = styled(ImageComponent)<StyledImageComponentProps>`
  ${({
    theme: {
      spacing: { spacing8 },
      color: {
        primary: { primary02: primary02Color },
      },
    },
    isCommercial,
  }) => css`
    margin-bottom: ${spacing8};
    ${isCommercial &&
    css`
      &:before {
        content: '';
        position: absolute;
        top: ${spacing8};
        right: ${spacing8};
        bottom: ${spacing8};
        left: ${spacing8};
        border: 1px solid ${primary02Color};
        z-index: 1;
      }
    `}
  `};
`

const StyledPromoLabel = styled(PromoLabel)`
  ${({
    theme: {
      spacing: { spacing20 },
    },
  }) => css`
    position: absolute;
    top: ${spacing20};
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  `}
`

const StyledVideoLabel = styled(VideoLabel)<StyledVideoLabelProps>`
  ${({
    theme: {
      spacing: { spacing16, spacing24 },
    },
    isCompact,
  }) => css`
    position: absolute;
    z-index: 1;
    bottom: ${spacing16};
    left: ${spacing16};
    ${desktopCSS(css`
      bottom: ${isCompact ? spacing16 : spacing24};
      left: ${isCompact ? spacing16 : spacing24};
    `)};
  `}
`

const StyledSportsFooter = styled(HockeySoccerSportsFooter)<{
  isCompact: boolean
}>`
  ${({ isCompact }) => css`
    position: absolute;
    bottom: 0;
    left: 0;
    ${!isCompact &&
    desktopCSS(css`
      height: 72px;
    `)}
  `}
`

const TeaserStandardVertical: FunctionComponent<
  TeaserStandardVerticalAPIProps
> = (props) => {
  const {
    accentColor,
    title,
    layoutTemplate,
    link,
    image,
    isPlus,
    catchword,
    commercialType,
    targetContentType,
    typeOfSport,
    articleId,
    contentOrigin,
    track,
    video,
    aureusOfferId,
  } = props

  const {
    color: {
      secondary: { greenAccessible },
    },
  } = useTheme()

  const isCommercial = !!commercialType

  const isPromo = commercialType === 'promo'

  const isSponsored = commercialType === 'sponsored'

  const showCatchword = !!isPlus || !!catchword

  const isHybridTeaser = useIsHybridTeaser()

  const clientViewportType = useClientViewportType()

  const isValidSport =
    !!typeOfSport &&
    (
      [
        LIVE_EVENT_DATA_SPORT_TYPES.SOCCER,
        LIVE_EVENT_DATA_SPORT_TYPES.HOCKEY,
      ] as LiveEventDataSportTypes[]
    ).includes(typeOfSport)

  const { data: sportsEventData } = useSportsEvent({
    matchId: isValidSport ? props.matchId : '',
    typeOfSport: isValidSport ? typeOfSport : 'NO_SPORT',
    shouldRefetch: true,
  })

  const sportsData = sportsEventData
    ? transformDataForSportsComponents(sportsEventData)
    : undefined

  const shouldShowSportsComponents =
    sportsData?.typeOfSport === LIVE_EVENT_DATA_SPORT_TYPES.SOCCER ||
    sportsData?.typeOfSport === LIVE_EVENT_DATA_SPORT_TYPES.HOCKEY

  const labelColor = sportsData?.isLive
    ? undefined
    : isValidSport
      ? greenAccessible
      : accentColor

  const hasLabelText = !isCommercial && !!props.labelText

  const queryClient = useQueryClient()

  const onImpression = useCallback<TrackingFnType>(
    () => ({
      event: 'teaser_impression',
      eventCategory: video ? 'video_teaser' : 'article_teaser',
      eventLabel: `${articleId}:${stripHtml(title)}`,
      eventAction: 'impression',
      id: articleId,
      is_branded: !!contentOrigin,
    }),
    [articleId, contentOrigin, title, video]
  )

  const onPromoImpression = useCallback<TrackingFnType>(
    () => ({
      event: 'contentcommerce_impression',
      eventCategory: 'BrandStudio',
      eventAction: 'Impression / Brandstudio',
    }),
    []
  )

  const onClick = useCallback<TrackingFnType>(
    () => ({
      event: 'teaser_click',
      eventCategory: video ? 'video_teaser' : 'article_teaser',
      eventLabel: `${articleId}:${stripHtml(title)}`,
      eventAction: 'click',
      id: articleId,
      is_branded: !!contentOrigin,
    }),
    [articleId, contentOrigin, title, video]
  )

  const trackedOnImpression = useTracking(onImpression)
  const trackedOnPromoImpression = useTracking(onPromoImpression)
  const trackedOnClick = useTracking(onClick)

  const shouldBeTracked = !!contentOrigin || track

  const finalOnImpression = useCallback(() => {
    triggerAureusImpression({
      aureusOfferId,
      queryClient,
    })

    if (shouldBeTracked) {
      trackedOnImpression()
      if (commercialType) {
        trackedOnPromoImpression()
      }
    }
  }, [
    aureusOfferId,
    commercialType,
    queryClient,
    shouldBeTracked,
    trackedOnImpression,
    trackedOnPromoImpression,
  ])

  const clickableProps = {
    aureusOfferId,
    ...(shouldBeTracked ? { onClick: trackedOnClick } : {}),
    ...link,
  }

  const ref = useRefDelegate(
    useAnimatedPreview({
      video,
      articleUrl: clickableProps.href,
    }),
    useViewportTracking({
      onImpression: finalOnImpression,
      track: true,
    })
  )

  if (isHybridTeaser && !['server', 'desktop'].includes(clientViewportType)) {
    return null
  }

  return (
    <StyledTeaserStandardVerticalWrapper
      {...clickableProps}
      isHybridTeaser={isHybridTeaser}
      ref={ref}>
      <StyledImageComponent
        {...image}
        isCommercial={isCommercial}
        layoutTemplate={layoutTemplate}>
        {isPromo ? (
          <StyledPromoLabel>{props.commercial.label}</StyledPromoLabel>
        ) : null}
        {!!video && (
          <TeaserAnimatedPreview video={video} articleUrl={link.href} />
        )}
        {!!props.video && (
          <StyledVideoLabel
            isCompact={layoutTemplate === 'compact'}
            duration={props.video.duration}
            targetContentType={targetContentType}
          />
        )}
        {shouldShowSportsComponents && (
          <StyledSportsFooter
            isCompact={layoutTemplate === 'compact'}
            sportsData={sportsData}
            viewType="full"
          />
        )}
      </StyledImageComponent>
      {(hasLabelText || isValidSport) && (
        <Label labelColor={labelColor}>
          {shouldShowSportsComponents ? (
            <SportLabelContent sportsData={sportsData} />
          ) : hasLabelText ? (
            props.labelText
          ) : null}
        </Label>
      )}
      {isSponsored && (
        <SponsoredLabel>
          {props.commercial.label}
          {!!props.commercial.logo && (
            <SponsoredLogo {...props.commercial.logo} />
          )}
        </SponsoredLabel>
      )}
      {showCatchword && (
        <Catchword>
          {isPlus && <BlickPlusLogo />}
          {catchword}
        </Catchword>
      )}
      <Title layoutTemplate={layoutTemplate}>{title}</Title>
    </StyledTeaserStandardVerticalWrapper>
  )
}

const widget = {
  kind: ['teaser-standard-vertical'],
  component: TeaserStandardVertical,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
