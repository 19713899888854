import mapValues from 'lodash.mapvalues'
import { useState, useEffect, useContext } from 'react'
import { reactionMapping } from './constants'
import { getOwnReactionValues } from './utils'
import APIContext from '@contexts/commenting/apiContext'
import GenericContext from '@contexts/commenting/genericContext'
import {
  Answer,
  ArticleComment,
  LocalReaction,
  OwnReactionValues,
} from './types'

const createGetReactionCount =
  (initialValues: OwnReactionValues, values: OwnReactionValues) =>
  (count: number, reaction: LocalReaction): number => {
    const hasInitialReaction = initialValues[reaction]
    const hasCurrentReaction = values[reaction]

    const finalCount = count ? count : 0

    if (hasInitialReaction && count === 0) {
      // hack to never show 0 on own reactions, as the count aggregation needs some time on BE side
      return 1
    } else if (hasInitialReaction && !hasCurrentReaction) {
      return finalCount - 1
    } else if (!hasInitialReaction && hasCurrentReaction) {
      return finalCount + 1
    }

    return finalCount
  }

const useReactionsHook = (comment: ArticleComment | Answer) => {
  const {
    id,
    like_count,
    dislike_count,
    reaction1_count,
    reaction2_count,
    reaction3_count,
  } = comment

  const { reactions, initialReactions, reactOnComment } = useContext(APIContext)
  const { requireValidUser } = useContext(GenericContext)

  // form values
  const initialValues = getOwnReactionValues(id, initialReactions)
  const [values, setValues] = useState<OwnReactionValues>(
    getOwnReactionValues(id, reactions)
  )

  // on successful server requests, we want to reset the form
  useEffect(() => {
    setValues(getOwnReactionValues(id, reactions))
  }, [id, reactions])

  const createOnReaction = (reaction: LocalReaction) =>
    requireValidUser(() => {
      const ownReaction: boolean = values[reaction]

      reactOnComment({
        commentId: id,
        reaction: reactionMapping[reaction],
        set: !ownReaction,
      })

      setValues({
        ...mapValues(values, () => false),
        [reaction]: !ownReaction,
      })
    })

  const getReactionCount = createGetReactionCount(initialValues, values)

  return [
    {
      heartCount: getReactionCount(like_count, 'heart'),
      boltCount: getReactionCount(dislike_count, 'bolt'),
      happyCount: getReactionCount(reaction1_count, 'happy'),
      sadCount: getReactionCount(reaction2_count, 'sad'),
      criticalCount: getReactionCount(reaction3_count, 'critical'),
      ownHeart: values.heart,
      ownBolt: values.bolt,
      ownHappy: values.happy,
      ownSad: values.sad,
      ownCritical: values.critical,
      onReactionHeart: createOnReaction('heart'),
      onReactionBolt: createOnReaction('bolt'),
      onReactionHappy: createOnReaction('happy'),
      onReactionSad: createOnReaction('sad'),
      onReactionCritical: createOnReaction('critical'),
    },
  ]
}

export default useReactionsHook
