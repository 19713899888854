import translate from '@i18n'
import { desktopCSS, mobileCSS, tabletCSS } from '@measures/responsive'
import { Chapter } from '@widgets/Video/story/types'
import { FunctionComponent, MouseEvent } from 'react'
import styled, { css } from 'styled-components'
import ChapterImage from './ChapterImage'

interface ChapterCardProps extends Chapter {
  isActive?: boolean
  onClick?: (e: MouseEvent<HTMLDivElement>) => void
}

const ChapterCardWrapper = styled.div<{ isLive: boolean; isActive: boolean }>`
  cursor: pointer;
  height: 100%;
  background: ${({
    theme: {
      colors: {
        blicktvGrey: { btvGrey_50, btvGrey_60 },
      },
    },
    isActive,
  }) => (isActive ? btvGrey_50 : btvGrey_60)};
  border-radius: 4px;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
  }
`

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 50%;

  ${tabletCSS(css`
    height: 112.5px;
  `)}

  ${desktopCSS(css`
    height: 112.5px;
  `)}

  border-radius: 4px 4px 0 0;
`

const DurationBadge = styled.div<{ isLive: boolean }>`
  ${({ theme, isLive }) => css`
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 0px 3px;
    border-radius: 2px;
    font-family: ${theme.fonts.sourceSansPro};
    font-size: 17px;
    font-style: normal;
    font-weight: normal;
    line-height: 1.25;
    text-transform: uppercase;
    letter-spacing: 0.05em;

    color: ${theme.colors.white};
    background: ${isLive
      ? theme.colors.brandColors.brand_55
      : theme.colors.grey.grey_100};

    ${mobileCSS(css`
      font-size: 14px;
      top: 4px;
      right: 4px;
    `)}
  `}
`

export const TitleWrapper = styled.div`
  width: 100%;
  height: 50%;

  ${tabletCSS(css`
    height: 87.5px;
  `)}

  ${desktopCSS(css`
    height: 87.5px;
  `)}
`

export const Title = styled.div`
  ${({
    theme: {
      colors: {
        blicktvGrey: { btvGrey_5 },
      },
      fonts: { goodOTNarrow },
      utils: { ellipsis },
    },
  }) => css`
    padding: 12px 12px 0px 12px;
    margin-bottom: 16px;
    overflow: hidden;
    font-family: ${goodOTNarrow};
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.5px;
    color: ${btvGrey_5};
    word-break: break-word;

    ${ellipsis('100%', 3)};
    ${mobileCSS(css`
      font-size: 14px;
      padding: 8px 8px 0px 8px;
      margin-bottom: 8px;
      line-height: 18px;
    `)}
  `}
`

const ChapterCard: FunctionComponent<ChapterCardProps> = ({
  headline,
  duration,
  isLive,
  isActive = false,
  onClick,
  image = {
    src: '/incoming/31574-v2-missing.png',
    alt: 'image not found',
  },
}) => {
  return (
    <ChapterCardWrapper isLive={isLive} isActive={isActive} onClick={onClick}>
      <ImageWrapper>
        <ChapterImage
          src={image?.src}
          alt={image?.alt}
          availableCrops={image?.crops}
        />
        <DurationBadge isLive={isLive}>
          {isLive ? translate('blickTVStory.live') : duration}
        </DurationBadge>
      </ImageWrapper>
      <TitleWrapper>
        <Title>{headline}</Title>
      </TitleWrapper>
    </ChapterCardWrapper>
  )
}

export default ChapterCard
