import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'

export const StyledVideoPlayerWrapper = styled.div`
  ${({
    theme: {
      colors: {
        blicktvGrey: { btvGrey_100 },
      },
    },
  }) => css`
    background-color: ${btvGrey_100};
    padding-top: 56.25%;
    width: 100%;
    position: relative;
    overflow: hidden;
  `}
`

export const StyledVideoPlayerContainer = styled.div`
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: absolute;
`

const VideoPlayerWrapper: FunctionComponent<{
  children: ReactNode
}> = ({ children }) => {
  return (
    <StyledVideoPlayerWrapper>
      <StyledVideoPlayerContainer>{children}</StyledVideoPlayerContainer>
    </StyledVideoPlayerWrapper>
  )
}

export default VideoPlayerWrapper
