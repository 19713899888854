import usePageMetadata from '@hooks/usePageMetadata'
import config from '@config'
import blickSchemaOrgLogo from '@assets/images/logo_schemaorg.png'
import { getSameAsInfo } from '@utils/schema'
import SchemaHTMLElement from '@components/SchemaHTMLElement'

const {
  backend: { baseUrl },
  schema: { organizationId, organizationAddressId },
} = config

const NewsMediaOrganizationSchema = () => {
  const pageMetadata = usePageMetadata()
  const { url, metaDescription } = pageMetadata
  const blickEmail = 'redaktion@blick.ch'
  const blickPhone = '+41442596262'
  return (
    <SchemaHTMLElement
      scriptKey="newsMediaOrganization"
      schemaObject={{
        '@type': 'NewsMediaOrganization',
        id: organizationId,
        url: `${baseUrl}${url}`,
        name: 'Blick',
        legalName: 'Blick',
        alternateName: 'blick.ch',
        description: metaDescription,
        logo: {
          '@id': blickSchemaOrgLogo,
        },
        image: [{ '@id': blickSchemaOrgLogo }],
        email: blickEmail,
        telephone: blickPhone,
        address: {
          '@id': organizationAddressId,
        },
        contactPoint: {
          '@type': 'ContactPoint',
          telephone: blickPhone,
          contactType: 'Customer support',
          email: blickEmail,
        },
        ...getSameAsInfo(),
      }}
    />
  )
}

export default NewsMediaOrganizationSchema
