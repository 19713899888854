import { FunctionComponent, useContext } from 'react'
import Blink from '@components/Blink'
import styled, { css, useTheme } from 'styled-components'
import { ActionLinkProps } from '@widgets/ArticleTextbox/types'
import contextArticleTextbox, {
  ArticleTextboxContextType,
} from '@contexts/articleTextbox'
import SvgIcon from '@components/SvgIcon'
import { mobileCSS } from '@measures/responsive'

const { ArticleTextboxContext } = contextArticleTextbox

type StyledActionLinkProps = Pick<ArticleTextboxContextType, 'isPromo'>

type ContainerProps = Pick<ArticleTextboxContextType, 'isPromo'> &
  Pick<ActionLinkProps, 'verticallyCentered'>

const Container = styled.div<ContainerProps>`
  ${({ isPromo, verticallyCentered }) =>
    isPromo &&
    !verticallyCentered &&
    css`
      width: 100%;
      display: flex;
      justify-content: center;
    `}
  ${({
    isPromo,
    verticallyCentered,
    theme: {
      spacing: { spacing20 },
    },
  }) =>
    isPromo &&
    verticallyCentered &&
    /* Negative left margin is needed to horizontally center the button, as the container has a padding of 20px  */
    css`
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      margin-left: -${spacing20};
      padding-bottom: ${spacing20};
    `}
`

const defaultCSS = css`
  ${({
    theme: {
      color: {
        primary: { blickRed },
        secondary: { darkRed },
      },
    },
  }) => css`
    color: ${blickRed};
    text-decoration: underline;

    &:hover {
      color: ${darkRed};
    }
  `}
`

const promoCSS = css`
  ${({
    theme: {
      color: {
        primary: { primary02, blickRed },
        secondary: { darkRed },
      },
      spacing: { spacing20, spacing24, spacing12 },
    },
  }) => css`
    border-radius: 50px;
    text-transform: none;
    color: ${primary02};
    background-color: ${blickRed};
    padding: ${spacing12} ${spacing24};
    margin-top: ${spacing24};

    &:hover {
      background-color: ${darkRed};
    }

    ${mobileCSS(css`
      margin-top: ${spacing20};
    `)}
  `}
`

const StyledSvgIcon = styled(SvgIcon)`
  ${({
    theme: {
      spacing: { spacing8 },
    },
  }) => css`
    margin-left: ${spacing8};
  `}
`

const StyledBlink = styled(Blink)<StyledActionLinkProps>`
  ${({
    theme: {
      typography: {
        subheads: {
          small1: { bundledCSS: small1SubheadCSS },
        },
      },
    },
    isPromo,
  }) => css`
    margin-top: 25px;
    text-decoration: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    ${small1SubheadCSS}
    ${isPromo ? promoCSS : defaultCSS}
  `}
`

const ActionLink: FunctionComponent<ActionLinkProps> = ({
  blinkProps,
  children,
  verticallyCentered,
}) => {
  const { isPromo } = useContext(ArticleTextboxContext)
  const theme = useTheme()
  return (
    <Container verticallyCentered={verticallyCentered} isPromo={isPromo}>
      <StyledBlink {...blinkProps} isPromo={isPromo}>
        {children}
        {isPromo && (
          <StyledSvgIcon
            iconName={'external-link'}
            size={24}
            color={theme.color.primary.primary02}
          />
        )}
      </StyledBlink>
    </Container>
  )
}

export default ActionLink
