import { useCallback } from 'react'

import {
  UseVideoPlayerPropsOutput,
  VideoWidgetImpressionTrackingParams,
  VideoWidgetClickTrackingParams,
  VideoWidgetActionTrackingParams,
  VideoWidgetImpressionAureusTrackingParams,
} from '../types'

import {
  videoWidgetImpressionHandler,
  videoWidgetClickHandler,
  videoWidgetActionHandler,
} from '@hooks/useVideoTracking/eventMapper'
import useTracking from '@hooks/useTracking'
import { triggerAureusImpression } from '@utils/aureus'
import { PLAYER_MODES } from '../utils'

export type UseVideoRecommendationsTrackingInputProps = Pick<
  UseVideoPlayerPropsOutput,
  'videoId' | 'isOverlay' | 'showRecommendationsOverlay'
> & { title: string }

export type UseVideoRecommendationsTrackingOutputProps = {
  onVideoWidgetImpression: (
    params: Pick<
      VideoWidgetImpressionTrackingParams,
      'videoId' | 'videoTitle' | 'indexPosition' | 'widgetImpression'
    >
  ) => void
  onVideoWidgetClick: (
    params: Pick<
      VideoWidgetClickTrackingParams,
      'videoId' | 'videoTitle' | 'indexPosition'
    >
  ) => void
  onVideoWidgetAction: (
    params: Pick<VideoWidgetActionTrackingParams, 'widgetAction'>
  ) => void
  onVideoWidgetImpressionAureus: (
    params: VideoWidgetImpressionAureusTrackingParams
  ) => void
}

const useVideoRecommendationsTracking = ({
  videoId,
  isOverlay,
  showRecommendationsOverlay,
  title,
}: UseVideoRecommendationsTrackingInputProps): UseVideoRecommendationsTrackingOutputProps => {
  const trackVideoWidgetImpression = useTracking(videoWidgetImpressionHandler)
  const trackVideoWidgetClick = useTracking(videoWidgetClickHandler)
  const trackVideoWidgetAction = useTracking(videoWidgetActionHandler)

  const getPlayerData = useCallback(
    (): Pick<
      VideoWidgetImpressionTrackingParams,
      'playerType' | 'widgetImpression' | 'videoTitle'
    > => ({
      playerType: isOverlay ? PLAYER_MODES.OVERLAY : PLAYER_MODES.INLINE,
      videoTitle: title,
      widgetImpression:
        showRecommendationsOverlay?.reason === 'clicked'
          ? 'buttonClick'
          : showRecommendationsOverlay?.reason === 'end'
            ? 'end'
            : undefined,
    }),
    [isOverlay, title, showRecommendationsOverlay?.reason]
  )

  const onVideoWidgetImpression: UseVideoRecommendationsTrackingOutputProps['onVideoWidgetImpression'] =
    useCallback(
      ({ videoId, videoTitle, indexPosition }) => {
        trackVideoWidgetImpression({
          ...getPlayerData(),
          videoId,
          videoTitle,
          indexPosition,
        })
      },
      [getPlayerData, trackVideoWidgetImpression]
    )

  const onVideoWidgetClick: UseVideoRecommendationsTrackingOutputProps['onVideoWidgetClick'] =
    useCallback(
      ({ videoId, videoTitle, indexPosition }) => {
        trackVideoWidgetClick({
          ...getPlayerData(),
          videoId,
          videoTitle,
          indexPosition,
        })
      },
      [getPlayerData, trackVideoWidgetClick]
    )

  const onVideoWidgetAction: UseVideoRecommendationsTrackingOutputProps['onVideoWidgetAction'] =
    useCallback(
      ({ widgetAction }) => {
        trackVideoWidgetAction({
          ...getPlayerData(),
          videoId,
          widgetAction,
        })
      },
      [getPlayerData, trackVideoWidgetAction, videoId]
    )

  const onVideoWidgetImpressionAureus: UseVideoRecommendationsTrackingOutputProps['onVideoWidgetImpressionAureus'] =
    triggerAureusImpression

  return {
    onVideoWidgetImpression,
    onVideoWidgetClick,
    onVideoWidgetAction,
    onVideoWidgetImpressionAureus,
  }
}

export default useVideoRecommendationsTracking
