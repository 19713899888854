import styled, { css } from 'styled-components'
import PrimaryButton from '@components/Buttons/Primary'

const CommentingPrimaryButton = styled(PrimaryButton)`
  ${({
    theme: {
      color: {
        tertiary: { grey300, grey400 },
        primary: { primary01 },
      },
      typography: {
        subheads: {
          small1: { bundledCSS: subheadingSmall1CSS },
        },
      },
      spacing: { spacing12, spacing16 },
    },
  }) => css`
    background-color: ${grey300};
    color: ${primary01};
    ${subheadingSmall1CSS};
    text-transform: capitalize;
    padding: ${spacing12} ${spacing16};
    @media (hover: hover) {
      &:hover:not([disabled]) {
        background-color: ${grey400};
      }
    }
  `}
`
export default CommentingPrimaryButton
