import { FunctionComponent, useCallback } from 'react'
import styled, { css, useTheme } from 'styled-components'
import { LinkCarouselProps } from '@widgets/LinkCarousel/types'
import Blink from '@components/Blink'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import SvgIcon from '@components/SvgIcon'

export interface LinkCarouselLabelProps {
  label: LinkCarouselProps['label']
  link: LinkCarouselProps['link']
}

const StyledBlink = styled(Blink)`
  ${({
    theme: {
      spacing: { spacing16, spacing24, spacing12 },
      typography: {
        subheads: {
          small1: { bundledCSS: subheadingSmallCSS },
        },
      },
      color: {
        primary: { primary02 },
        secondary: { greenAccessible, darkGreen },
      },
      utils: { ellipsis },
    },
  }) => css`
    ${subheadingSmallCSS};
    height: 48px;
    box-sizing: border-box;
    text-decoration: none;
    border-radius: 50px;
    margin-top: ${spacing16};
    padding: ${spacing12} ${spacing24};
    border: 0;
    cursor: pointer;
    text-transform: capitalize;
    background-color: ${greenAccessible};
    color: ${primary02};
    width: fit-content;

    @media (hover: hover) {
      &:hover {
        background-color: ${darkGreen};
      }
    }

    ${ellipsis('100%', 1)};
  `}
`

const StyledSvgIcon = styled(SvgIcon)`
  ${({
    theme: {
      spacing: { spacing8 },
    },
  }) => css`
    margin-left: ${spacing8};
    vertical-align: middle;
  `}
`

const LinkCarouselLabel: FunctionComponent<LinkCarouselLabelProps> = ({
  label,
  link,
}) => {
  const theme = useTheme()
  const onClickTrackingFn = useCallback<TrackingFnType>(
    () => ({
      event: 'linkcarousel_click',
      eventAction: 'click',
      eventCategory: 'linkcarousel_widget',
      eventLabel: label,
    }),
    [label]
  )

  const onClick = useTracking(onClickTrackingFn)

  return (
    <StyledBlink {...link} onClick={onClick}>
      {label}
      <StyledSvgIcon
        iconName={'arrow-right'}
        size={24}
        color={theme.color.primary.primary02}
      />
    </StyledBlink>
  )
}

export default LinkCarouselLabel
