import {
  FunctionComponent,
  useCallback,
  memo,
  useState,
  useEffect,
} from 'react'
import { VideoRecommendedItem } from '@hooks/useRecommendations'
import useVideoRecommendationsTracking from './useVideoRecommendationsTracking'
import VideoRecommendationsComponent from '@components/Video/VideoRecommendations'
import { UseVideoPlayerPropsOutput } from '../types'
import useVideoRecommendations from '@hooks/useVideoRecommendations'
import { useQueryClient } from '@tanstack/react-query'
import {
  changePlayerRecommendationsState,
  getJWPlayer,
  setPlayerRecommendationsAvailable,
} from '@utils/videoPlayer'

export type VideoRecommendationsProps = Pick<
  UseVideoPlayerPropsOutput,
  | 'videoId'
  | 'isBlickTV'
  | 'isOverlay'
  | 'widgetId'
  | 'showRecommendationsOverlay'
  | 'onRecommendationsOverlay'
  | 'hasNextPlaylistItem'
> & {
  isInArticle?: boolean
  hasPlaylist?: boolean
  title: string
}

const arrayIncludesItem = (array: string[], item: string) =>
  array?.indexOf(item) >= 0

const VideoRecommendations: FunctionComponent<VideoRecommendationsProps> = ({
  widgetId,
  videoId,
  isOverlay,
  showRecommendationsOverlay,
  isBlickTV,
  title,
  onRecommendationsOverlay,
  hasNextPlaylistItem,
}) => {
  const queryClient = useQueryClient()
  const videoRecommendations = useVideoRecommendations({ isBlickTV })
  const [impressionsOnPause, setImpressionsOnPause] = useState<string[]>([])

  const [impressionsOnEnd, setImpressionsOnEnd] = useState<string[]>([])
  const [hideUntilPlayingStatus, setHideUntilPlayingStatus] = useState(false)

  const trackingCallbacks = useVideoRecommendationsTracking({
    showRecommendationsOverlay,
    videoId,
    isOverlay,
    title,
  })

  useEffect(() => {
    if (showRecommendationsOverlay?.showRecommendations) {
      setHideUntilPlayingStatus(false)
    }
  }, [showRecommendationsOverlay?.showRecommendations])

  useEffect(() => {
    if (!isBlickTV) {
      const jwPlayer = getJWPlayer(widgetId)

      if (jwPlayer) {
        setPlayerRecommendationsAvailable(
          jwPlayer,
          videoRecommendations?.length > 0
        )
      }
    }
  }, [isBlickTV, videoRecommendations, widgetId])

  const getIndexPosition = useCallback(
    (articleId: string) =>
      articleId && videoRecommendations
        ? videoRecommendations?.findIndex(
            (item: VideoRecommendedItem) => item?.articleId === articleId
          )
        : -1,
    [videoRecommendations]
  )

  const handleOnClickItem = useCallback(
    (_id: string, recommendationData: VideoRecommendedItem) => {
      trackingCallbacks.onVideoWidgetClick({
        indexPosition: getIndexPosition(recommendationData.articleId),
        videoId: recommendationData.articleId,
        videoTitle: recommendationData.title,
      })
    },
    [getIndexPosition, trackingCallbacks]
  )

  const trackItemImpression = useCallback(
    (recommendationData: VideoRecommendedItem) => {
      const indexPosition = getIndexPosition(recommendationData.articleId)

      if (indexPosition !== -1) {
        trackingCallbacks.onVideoWidgetImpression({
          indexPosition: indexPosition,
          videoId: recommendationData.articleId,
          videoTitle: recommendationData.title,
        })

        trackingCallbacks.onVideoWidgetImpressionAureus({
          aureusOfferId: recommendationData.aureusOfferId,
          queryClient,
        })
      }
    },
    [getIndexPosition, trackingCallbacks, queryClient]
  )

  const handleClickClose = useCallback(() => {
    const jwPlayer = getJWPlayer(widgetId)

    if (jwPlayer) {
      changePlayerRecommendationsState(jwPlayer, 'hidden')
    }
    trackingCallbacks.onVideoWidgetAction({ widgetAction: 'close' })
    onRecommendationsOverlay?.({
      showRecommendations: false,
    })
  }, [onRecommendationsOverlay, trackingCallbacks, widgetId])

  /**
   * Every impression is tracked only once but separately between
   * onPause and onEnd. When the user replays, the state is cleared
   * in handleClickReplay() and then it can be tracked again once more
   */
  const handleItemImpression = useCallback(
    (recommendationsData: VideoRecommendedItem) => {
      if (showRecommendationsOverlay?.reason === 'clicked') {
        if (
          !arrayIncludesItem(impressionsOnPause, recommendationsData.articleId)
        ) {
          setImpressionsOnPause((oldArray) => [
            ...oldArray,
            recommendationsData.articleId,
          ])
          trackItemImpression(recommendationsData)
        }
      } else {
        if (
          !arrayIncludesItem(impressionsOnEnd, recommendationsData.articleId)
        ) {
          setImpressionsOnEnd((oldArray) => [
            ...oldArray,
            recommendationsData.articleId,
          ])
          trackItemImpression(recommendationsData)
        }
      }
    },
    [
      trackItemImpression,
      impressionsOnEnd,
      impressionsOnPause,
      showRecommendationsOverlay?.reason,
    ]
  )

  const isHidden =
    (hasNextPlaylistItem && showRecommendationsOverlay?.reason === 'end') ||
    !showRecommendationsOverlay?.showRecommendations ||
    hideUntilPlayingStatus

  return !isBlickTV && videoRecommendations?.length ? (
    <VideoRecommendationsComponent
      widgetId={widgetId}
      hidden={isHidden}
      data={videoRecommendations}
      onClickItem={handleOnClickItem}
      onClickClose={handleClickClose}
      onItemImpression={handleItemImpression}
      isFullScreen={isOverlay}
    />
  ) : null
}

const MemoizedVideoRecommendations = memo(VideoRecommendations)

// it's important here to set the displayName in memo, because it shows better on React Devtools
MemoizedVideoRecommendations.displayName = 'MemoizedVideoRecommendations'

export default MemoizedVideoRecommendations
