import { FunctionComponent } from 'react'
import { IngredientsProps, RecipeInfoType } from '@widgets/Ingredients/types'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import IngredientsComponent from '@components/Ingredients'
import { isRecipeIngredientValid } from '@widgets/Ingredients/utils'

const {
  IngredientItem,
  IngredientsWrapper,
  RecipeInfoItem,
  RecipeInfoWrapper,
} = IngredientsComponent

const IngredientsWidget: FunctionComponent<IngredientsProps> = ({
  recipeIngredient,
  portions,
  nutrition,
  prepTime,
  cookTime,
}) => {
  const recipeInfo = (
    Object.entries({ portions, nutrition, prepTime, cookTime }) as [
      RecipeInfoType,
      string,
    ][]
  )
    .filter(([_, value]) => !!value)
    .map(([key, value]) => {
      return (
        <RecipeInfoItem title={key} key={key}>
          {value}
        </RecipeInfoItem>
      )
    })

  if (!isRecipeIngredientValid(recipeIngredient)) {
    return null
  }

  return (
    <>
      <RecipeInfoWrapper>{recipeInfo}</RecipeInfoWrapper>
      <IngredientsWrapper>
        {recipeIngredient.map((ingredientItem, index) => (
          <IngredientItem {...ingredientItem} key={index} />
        ))}
      </IngredientsWrapper>
    </>
  )
}

const widget = {
  kind: ['widget', 'ingredients'],
  component: IngredientsWidget,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
