import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import Blink from '@components/Blink'
import { Link } from '@cook-types/cook'
import SvgIcon from '@components/SvgIcon'
import color from '@themes/default/color'

export interface ScoreboardLinkProps {
  link: Link
}

const StyledScoreboardLink = styled(Blink)`
  ${({
    theme: {
      typography: {
        subheads: {
          small1: { bundledCSS: small1SubheadingCSS },
        },
      },
      color: {
        secondary: { greenAccessible: greenAccessibleColor },
      },
      spacing: { spacing12 },
      utils: { ellipsis },
    },
  }) => css`
    color: ${greenAccessibleColor};
    display: flex !important;
    align-items: center;
    text-decoration: none;
    text-transform: uppercase;
    width: fit-content;
    margin-bottom: ${spacing12};
    ${ellipsis('100%', 1)};
    ${small1SubheadingCSS};
  `}
`

const StyledScoreboardArrow = styled(SvgIcon)`
  ${({
    theme: {
      spacing: { spacing4 },
    },
  }) => css`
    margin-left: ${spacing4};
  `}
`

const ScoreboardLink: FunctionComponent<ScoreboardLinkProps> = ({ link }) => (
  <StyledScoreboardLink {...link}>
    {link.title}
    <StyledScoreboardArrow
      color={color.primary.primary01}
      iconName="chevron-right"
      size={24}
    />
  </StyledScoreboardLink>
)

export default ScoreboardLink
