import styled, { css } from 'styled-components'
import { desktopCSS, mobileCSS, tabletCSS } from '@measures/responsive'
import TeaserToiCommercialWrapper from '@components/TeaserToiCommercial/Wrapper'
import TeaserToiWrapper from '@components/TeaserToi/Wrapper'
import { StyledEmbeddedContentContainer as EmbeddedContentWrapper } from '@components/EmbeddedContent'

const MainFlowWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing8, spacing16, spacing32 },
    },
  }) => css`
    display: grid;
    width: 100%;

    ${desktopCSS(css`
      grid-column: span 8;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-gap: ${spacing32};
      align-items: flex-start;

      ${TeaserToiCommercialWrapper}, ${TeaserToiWrapper}, ${EmbeddedContentWrapper} {
        grid-column: 1 / -1;
      }

      ${EmbeddedContentWrapper} {
        min-height: 300px !important;
      }
    `)};

    ${tabletCSS(css`
      ${TeaserToiCommercialWrapper}, ${TeaserToiWrapper} {
        &:first-child {
          margin-bottom: ${spacing8};
        }
      }

      grid-row-gap: ${spacing16};
    `)};

    ${mobileCSS(css`
      ${TeaserToiCommercialWrapper}, ${TeaserToiWrapper} {
        &:first-child {
          margin-bottom: ${spacing8};
        }
      }

      grid-row-gap: ${spacing16};
    `)};
  `}
`

export default MainFlowWrapper
