type ClassifyUrl = (url: string) => {
  hostname: string
  isExternal: boolean
  isOutbound: boolean
  isCMPOpener: boolean
}

export const classifyUrl: ClassifyUrl = (url) => {
  try {
    const { hostname, searchParams } = new URL(url)
    return {
      hostname,
      isExternal: searchParams.has('external_link'),
      isOutbound: !['blick.ch', 'localhost'].includes(
        hostname.split('.').slice(-2).join('.')
      ),
      isCMPOpener: false,
    }
  } catch (err) {
    //! This is a special URL, and its sole purpose is to open the CMP dialog.
    if (url?.endsWith?.('#cmpOpener')) {
      return {
        hostname: '',
        isExternal: false,
        isOutbound: false,
        isCMPOpener: true,
      }
    }

    return {
      hostname: '',
      isExternal: false,
      isOutbound: false,
      isCMPOpener: false,
    }
  }
}
