import { FunctionComponent, useCallback, useRef, useState } from 'react'
import styled, { css, useTheme } from 'styled-components'
import translate from '@i18n'
import useViewportType from '@hooks/useViewport/useViewportType'
import SummaryHeader from '@components/Summary/SummaryHeader'
import SvgIcon from '@components/SvgIcon'
import {
  SliderGradient,
  SliderWrapper,
  StyledNavigationArrows,
  StyledSwiper,
  StyledSwiperHeader,
  StyledSwiperHeadline,
  StyledSwiperText,
  SwiperSlide,
} from '@components/Summary/SummarySwiperContainer'
import { SwiperClass, ArrowsVisibilityType } from '@components/Swiper'
import SummaryPill from '@components/Summary/SummaryPill'
import SummaryList from '@components/Summary/SummaryList'
import SummaryDisclaimer, {
  type SummaryDisclaimerProps,
} from '@components/Summary/SummaryDisclaimer'
import { SectionSummaryProps } from './types'

const StyledSlider = styled(SliderWrapper)`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => {
    return css`
      margin-bottom: ${spacing16};
    `
  }}
`

const IconPullRight = styled.div`
  flex: 1;
  display: flex;
  justify-content: right;
  width: 100%;
  cursor: pointer;
`

const StyledPlusIcon = styled(SvgIcon)`
  ${({
    theme: {
      spacing: { spacing8 },
    },
  }) => {
    return css`
      vertical-align: text-bottom;
      margin-right: ${spacing8};
    `
  }}
`

const SummaryResultView: FunctionComponent<
  Omit<SectionSummaryProps, 'kind' | 'state'> & {
    activeIndex?: number | null
    onCloseHandler: () => void
    disclaimer: SummaryDisclaimerProps
  }
> = ({ title, links, content, disclaimer, onCloseHandler }) => {
  const theme = useTheme()
  const viewportType = useViewportType()
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const swiperHeaderRef = useRef<HTMLDivElement>(null)
  const swiperRef = useRef<SwiperClass | null>(null)
  const [arrowsVisibility, setArrowsVisibility] =
    useState<ArrowsVisibilityType>('none')
  const isDesktop = viewportType === 'desktop'
  const linksCount = Object.keys(links ?? {}).length
  const onInit = useCallback(
    (swiper: SwiperClass) => {
      if (swiperRef) {
        swiperRef.current = swiper
      }
    },
    [swiperRef]
  )
  const scrollToPosition = useCallback((index: number | null) => {
    setTimeout(() => {
      if (!swiperHeaderRef.current || (!index && index !== 0)) {
        setActiveIndex(null)
        return
      }
      window.scrollTo({
        top:
          swiperHeaderRef.current?.getBoundingClientRect().top +
          document.documentElement.scrollTop -
          200,
        behavior: 'smooth',
      })
      swiperRef.current?.slideTo(index)
      setActiveIndex(index)
    }, 0)
  }, [])

  const onArrowsVisibility = useCallback((arrow: ArrowsVisibilityType) => {
    setArrowsVisibility(arrow)
  }, [])

  return (
    <>
      <SummaryHeader
        prefixIconName="stars"
        after={
          <IconPullRight onClick={onCloseHandler}>
            <SvgIcon
              iconName="close"
              size={24}
              color={theme.color.primary.primary01}
            />
          </IconPullRight>
        }>
        {title}
      </SummaryHeader>

      {Array.isArray(content) && content?.length > 0 && (
        <SummaryList summaries={content} clickHandler={scrollToPosition} />
      )}

      {links && (
        <>
          <StyledSwiperHeader ref={swiperHeaderRef}>
            <StyledSwiperHeadline>
              {translate('sectionSummary.sources.title')}
            </StyledSwiperHeadline>

            {arrowsVisibility !== 'none' && (
              <StyledNavigationArrows
                swiperRef={swiperRef}
                arrowsVisibility={arrowsVisibility}
                itemsCount={linksCount}
              />
            )}
          </StyledSwiperHeader>
          <StyledSwiperText>
            {linksCount > 1
              ? translate('sectionSummary.sources.subtitle.plural', {
                  totalElements: linksCount.toString(),
                })
              : translate('sectionSummary.sources.subtitle.singular')}
          </StyledSwiperText>
          <StyledSlider>
            <StyledSwiper
              navigation={false}
              initialSlide={0}
              spaceTopBottom={0}
              slidesPerGroup={isDesktop ? 4 : 2}
              spaceBetween={8}
              onInit={onInit}
              onArrowsVisibility={onArrowsVisibility}
              slides={Object.keys(links).map((key) => {
                const item = links[key]
                const url = new URL(item.url)
                return (
                  <SwiperSlide
                    href={url.pathname}
                    key={`source-item-${item.url}-${key}`}>
                    <span>
                      {item.isPlus && (
                        <StyledPlusIcon
                          iconName="blick-plus-logo"
                          size={19}
                          color={theme.color.primary.primary01}
                        />
                      )}
                      {item.title}
                    </span>
                    <SummaryPill isActive={activeIndex === parseInt(key) - 1}>
                      {key}
                    </SummaryPill>
                  </SwiperSlide>
                )
              })}
            />
            <SliderGradient />
            <SliderGradient pullRight />
          </StyledSlider>
        </>
      )}

      <SummaryDisclaimer {...disclaimer} />
    </>
  )
}

export default SummaryResultView
