import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'

interface LabelProps {
  labelColor: string | undefined
  children: ReactNode
}

type StyledLabelProps = Pick<LabelProps, 'labelColor'>

const StyledLabel = styled.div<StyledLabelProps>`
  ${({
    labelColor,
    theme: {
      typography: {
        eyebrows: {
          small2: { bundledCSS: eyebrowsSmall2 },
        },
      },
      spacing: { spacing4, spacing12 },
      color: {
        primary: { primary02, blickRed },
      },
      utils: { ellipsis },
    },
  }) => css`
    ${eyebrowsSmall2};
    color: ${primary02};
    text-transform: uppercase;
    margin-bottom: ${spacing12};
    padding: 6px ${spacing4};
    background-color: ${labelColor ?? blickRed};
    ${ellipsis('100%', 1)};
    display: inline-block;

    &:empty {
      background-color: transparent;
      padding-left: 0;
      padding-right: 0;

      &:before {
        content: '\200B';
        width: 0;
      }
    }
  `}
`

const Label: FunctionComponent<LabelProps> = ({ labelColor, children }) => {
  return <StyledLabel labelColor={labelColor}>{children}</StyledLabel>
}

export default Label
