import Blink from '@components/Blink'
import config from '@config'
import { FunctionComponent, useContext } from 'react'
import styled, { css } from 'styled-components'
import { mobileCSS, tabletCSS } from '@measures/responsive'
import WebarchiveCalendarContext, {
  WebarchiveCalendarContextType,
} from '@contexts/webarchiveCalendar'

const {
  webarchive: { sectionUrl },
} = config

interface ContentProps {}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

interface StyledProps {
  isSelected: boolean
  isSelectable: boolean
  isOfSelectedMonth: boolean
}

const DayStyle = css<StyledProps>`
  ${({
    theme: {
      color: {
        primary: { primary01, primary02, blickRed },
        tertiary: { grey200, grey500, grey700 },
      },
      typography: {
        subheads: {
          small1: { bundledCSS: subheadsSmallCSS },
        },
      },
      spacing: { spacing2 },
    },
    isSelectable,
    isSelected,
    isOfSelectedMonth,
  }) => css`
    ${subheadsSmallCSS};
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${primary02};
    color: ${grey500};
    text-decoration: none;
    margin-top: ${spacing2};
    width: 41px;
    height: 41px;

    ${mobileCSS(css`
      width: calc((92vw / 7) - 2px);
      height: calc((92vw / 7) - 2px);
    `)}

    ${tabletCSS(css`
      width: 85px;
      height: 85px;
    `)};

    ${isSelected &&
    css`
      background-color: ${primary01};
      color: ${primary02};
    `};

    ${isOfSelectedMonth &&
    css`
      background-color: ${grey200};
      color: ${grey700};
    `};

    ${isSelectable &&
    css`
      color: ${primary01};

      &:hover {
        color: ${blickRed};
      }
    `};
  `}
`

const StyledBlink = styled(Blink)<StyledProps>`
  ${DayStyle}
`

const DayWrapper = styled.div<StyledProps>`
  ${DayStyle}
`

const Content: FunctionComponent<ContentProps> = () => {
  const calendarProps = useContext(WebarchiveCalendarContext)
  if (!calendarProps || Object.keys(calendarProps).length === 0) {
    return null
  }

  const { days, selectedDay } = calendarProps as WebarchiveCalendarContextType
  return (
    <Wrapper>
      {days.map(({ day, isSelectable, isOfSelectedMonth, dayUrl }, index) => {
        const isSelected = selectedDay ? day?.isSame?.(selectedDay) : false
        const dayIsSelectable = Boolean(!isSelected && isSelectable)
        const dayIsInSelectedMonth = Boolean(!isSelected && isOfSelectedMonth)

        return isSelectable ? (
          <StyledBlink
            key={`day-${index}`}
            isSelected={isSelected}
            isSelectable={dayIsSelectable}
            isOfSelectedMonth={dayIsInSelectedMonth}
            href={`${sectionUrl}${dayUrl}/`}
            target="_self">
            <span>{day?.date?.()}</span>
          </StyledBlink>
        ) : (
          <DayWrapper
            key={`day-${index}`}
            isSelected={isSelected}
            isSelectable={dayIsSelectable}
            isOfSelectedMonth={dayIsInSelectedMonth}>
            {day?.date?.()}
          </DayWrapper>
        )
      })}
    </Wrapper>
  )
}

export default Content
