import { ConfigProps } from '@config/types'

const config = ({}: ConfigProps) => {
  return {
    moreInfoLink: 'https://www.blick.ch/id19711657',
    feedbackLink: 'https://form.typeform.com/to/m2NxI7n5',
  } as const
}

export default config
