import Blink from '@components/Blink'
import config from '@config'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import Placeholder from './Placeholder'
import { VideoZattooProps } from './types'
import { createZattooUrl } from '@widgets/Video/Zattoo/utils'
import { printCSS } from '@utils/style'
import useIsInRecipeContent from '@hooks/useIsInRecipeContent'
import useIsInArticleBodyContent from '@hooks/useIsInArticleBodyContent'

export interface CMPAwareVideoZattooProps extends VideoZattooProps {
  bypassCMP?: boolean
}

const {
  zattoo: { streamUrl },
} = config

const StyledWrapper = styled(Blink)<{ shouldStretch: boolean }>`
  ${({
    theme: {
      measures: { stretchWidthOnMobile },
    },
    shouldStretch,
  }) => css`
    display: block;

    ${printCSS(css`
      display: none;
    `)}

    ${shouldStretch && stretchWidthOnMobile()};
  `}
`

const VideoZattoo: FunctionComponent<VideoZattooProps> = ({ channel }) => {
  const isInArticleBody = useIsInArticleBodyContent()
  const isInRecipe = useIsInRecipeContent()

  const shouldStretch = isInArticleBody && !isInRecipe

  return (
    <StyledWrapper
      href={createZattooUrl(streamUrl, channel)}
      target="_blank"
      rel="noopener"
      shouldStretch={shouldStretch}>
      <Placeholder channel={channel} />
    </StyledWrapper>
  )
}

export default VideoZattoo
