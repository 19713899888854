import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent } from 'react'
import { Link, Logo } from '@cook-types/cook'
import SectionHeaderComponent from '@components/SectionHeader'

export interface SectionHeaderAPIProps {
  title: string
  uniqueName: string
  accentColor: string
  logo?: Logo
  link?: Link
}

const SectionHeader: FunctionComponent<SectionHeaderAPIProps> = ({
  title,
  link,
  logo,
  accentColor,
}) => {
  return (
    <SectionHeaderComponent
      title={title}
      accentColor={accentColor}
      link={link}
      logo={logo}
    />
  )
}

const widget = {
  kind: ['section-header'],
  component: SectionHeader,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
