import { desktopCSS, mobileCSS, tabletCSS } from '@measures/responsive'
import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'
interface CatchwordProps {
  children: ReactNode
}

const StyledCatchword = styled.div`
  ${({
    theme: {
      spacing: { spacing8 },
      typography: {
        subheads: {
          small1: { bundledCSS: subheadSmall1 },
        },
      },
      color: {
        tertiary: { grey800 },
      },
      utils: { ellipsis },
    },
  }) => {
    return css`
      margin-bottom: ${spacing8};

      //! Needed because "ellipsis()" returns "display: inline-block;"
      //! when the second argument is 1.
      display: block;
      color: ${grey800};

      ${subheadSmall1};

      ${desktopCSS(css`
        ${ellipsis('100%', 1)};
      `)}

      ${tabletCSS(css`
        ${ellipsis('100%', 2)};
      `)}
      
      ${mobileCSS(css`
        ${ellipsis('100%', 2)};
      `)}
    `
  }}
`

const Catchword: FunctionComponent<CatchwordProps> = ({ children }) => (
  <StyledCatchword>{children}</StyledCatchword>
)

export default Catchword
