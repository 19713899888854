//! WARNING: AppWrapper includes GlobalStyle and has to be imported first, so styled-components places it as a first css definition inside the <style /> tag.
import AppWrapper from '@components/AppWrapper'
import { FunctionComponent, useMemo, useState } from 'react'
import { QueryClient } from '@tanstack/react-query'
import { AppProps } from 'next/app'
import {
  defaultConfig as queryConfig,
  dehydrateQueryStateWithNowTimestamp,
  disableStructuralSharingForQueries,
} from '@utils/react-query'
import { CatchAllPageServerProps } from './[[...pageUrl]]'
import DefaultPage from '@components/Page/DefaultPage'
import ErrorPage from '@components/Page/ErrorPage'
import BlickPlusOfferPage from '@components/Page/BlickPlusOfferPage'
import NonErrorPagePreCSSComponents from '@components/Page/NonErrorPagePreCSSComponents'
import NonErrorPagePostCSSComponents from '@components/Page/NonErrorPagePostCSSComponents'
import { initRegistry } from '@utils/registry'

interface MyAppProps extends AppProps {
  pageProps: CatchAllPageServerProps
}

initRegistry()

const MyApp: FunctionComponent<MyAppProps> = ({ Component, pageProps }) => {
  const [queryClient] = useState(() => new QueryClient(queryConfig))

  disableStructuralSharingForQueries(queryClient)

  const {
    dehydratedQueryState,
    isError,
    documentProps,
    isBlickPlusOfferPage,
    ...restOfPageProps
  } = pageProps

  const dehydratedQueryStateWithNowTimestamp = useMemo(
    () => dehydrateQueryStateWithNowTimestamp(dehydratedQueryState),
    [dehydratedQueryState]
  )
  const isErrorPage = isError !== false

  const PageComponent = isErrorPage
    ? ErrorPage
    : isBlickPlusOfferPage
      ? BlickPlusOfferPage
      : DefaultPage

  return (
    <AppWrapper
      dehydratedQueryStateWithNowTimestamp={
        dehydratedQueryStateWithNowTimestamp
      }
      queryClient={queryClient}
      isErrorPage={isErrorPage}
      preCSSComponents={
        isErrorPage ? null : (
          <NonErrorPagePreCSSComponents
            isBlickPlusOfferPage={isBlickPlusOfferPage}
          />
        )
      }
      postCSSComponents={
        isErrorPage ? null : <NonErrorPagePostCSSComponents />
      }>
      <PageComponent Component={Component} restOfPageProps={restOfPageProps} />
    </AppWrapper>
  )
}

export default MyApp
