import SvgIcon from '@components/SvgIcon'
import useLeserreporterContext from '@hooks/useLeserreporterContext'
import { desktopCSS } from '@measures/responsive'
import { FunctionComponent } from 'react'
import styled, { css, useTheme } from 'styled-components'
import ErrorMessage from './ErrorMessage'
import UploadedFiles from './UploadedFiles'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
`

const StyledLabel = styled.label`
  ${({
    theme: {
      typography: {
        subheads: {
          medium1: { bundledCSS: subheadsMedium1 },
        },
      },
      spacing: { spacing8 },
    },
  }) => css`
    ${subheadsMedium1};
    margin-bottom: ${spacing8};
    ${desktopCSS(css`
      margin-bottom: 0;
    `)}
  `}
`

const UploadLabel = styled.label<{
  isUploadDisabled: boolean
  isError: boolean
}>`
  ${({
    isUploadDisabled,
    isError,
    theme: {
      color: {
        tertiary: { grey600: grey600color },
        primary: { blickRed: blickRedColor },
      },
      spacing: { spacing4, spacing96, spacing56 },
    },
  }) => css`
    ${desktopCSS(css`
      width: ${spacing96};
      height: ${spacing96};
      padding: ${spacing4};
    `)};
    height: ${spacing56};
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed ${isError ? blickRedColor : grey600color};
    border-radius: ${spacing4};
    ${!isUploadDisabled && `cursor: pointer`};
  `}
`

const HiddenUploadInput = styled.input`
  display: none;
`

const ButtonAndFiles = styled.div`
  ${({
    theme: {
      spacing: { spacing8 },
    },
  }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: ${spacing8} 0;

    ${desktopCSS(css`
      flex-direction: row;
    `)}
  `}
`

const UploadFiles: FunctionComponent = () => {
  const {
    data: { files: uploadedFiles },
    addFile,
    removeFile,
    validation: { files: validation },
  } = useLeserreporterContext()
  const passedValidation = validation === true
  const theme = useTheme()
  const isUploadDisabled = !!(uploadedFiles.length === 9)
  return (
    <Wrapper>
      <StyledLabel>Bild/Video hinzufügen (max. 9)</StyledLabel>
      <HiddenUploadInput
        id="upload-file"
        type="file"
        onChange={addFile}
        disabled={isUploadDisabled}
      />
      <ButtonAndFiles>
        <UploadLabel
          htmlFor="upload-file"
          isUploadDisabled={isUploadDisabled}
          isError={!passedValidation}>
          <SvgIcon
            size={24}
            iconName="add"
            color={
              !passedValidation
                ? theme.color.primary.blickRed
                : theme.color.tertiary.grey600
            }
          />
        </UploadLabel>
        <UploadedFiles uploadedFiles={uploadedFiles} removeFile={removeFile} />
      </ButtonAndFiles>
      {isUploadDisabled && (
        <ErrorMessage>
          Sie haben die maximale Anzahl an hochzuladenden Dateien erreicht
        </ErrorMessage>
      )}
      {!passedValidation && validation && (
        <ErrorMessage>{validation as string}</ErrorMessage>
      )}
    </Wrapper>
  )
}

export default UploadFiles
