import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

interface SummaryPillProps {
  children: React.ReactNode
  isActive?: boolean
}

const Pill = styled.span<
  Pick<SummaryPillProps, 'isActive'> & { hasClickHandler?: boolean }
>`
  ${({
    theme: {
      color: {
        secondary: { darkRed },
        primary: { primary02 },
        tertiary: { grey600 },
      },
      typography: {
        labels: {
          label3: { bundledCSS: label3Labels },
        },
      },
    },
    isActive,
  }) => css`
    ${label3Labels}
    align-items: center;
    background-color: ${grey600};
    border-radius: 50%;
    color: ${primary02};
    display: inline-flex;
    height: 16px;
    justify-content: center;
    text-align: center;
    width: 16px;

    ${isActive && `background-color: ${darkRed};`}
  `}
`

const SummaryPill: FunctionComponent<SummaryPillProps> = ({
  children,
  isActive,
}) => {
  return <Pill isActive={isActive}>{children}</Pill>
}

export default SummaryPill
