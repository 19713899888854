import { FunctionComponent, useCallback, useContext } from 'react'
import styled, { css } from 'styled-components'

import { mobileCSS } from '@measures/responsive'

import useTracking, { TrackingFnType } from '@hooks/useTracking'
import useViewportTracking from '@hooks/useViewportTracking'
import { PlatformSpecificButtonProps } from '../types'
import EndOfArticleContext from '@contexts/endOfArticle'
import Blink from '@components/Blink'

const StyledGamificationLink = styled(Blink)`
  ${({
    theme: {
      color: {
        primary: { blickRed, primary02 },
        secondary: { darkRed },
      },
      spacing: { spacing4, spacing16 },
      typography: {
        subheads: {
          small1: { bundledCSS: subheadingSmall1CSS },
        },
      },
    },
  }) => css`
    ${subheadingSmall1CSS};
    border-radius: 50px;
    height: 40px;
    box-sizing: border-box;
    color: ${primary02};
    background-color: ${blickRed};
    padding: ${spacing4} ${spacing16};
    display: flex;
    text-decoration: none;
    flex-direction: row;
    align-items: center;
    border: unset;
    cursor: pointer;
    ${mobileCSS(css`
      display: none;
    `)};
    @media (hover: hover) {
      &:hover:not([disabled]) {
        background-color: ${darkRed};
      }
    }
  `}
`

const StyledIcon = styled.img`
  ${({
    theme: {
      spacing: { spacing8 },
    },
  }) => css`
    margin-right: ${spacing8};
  `}
`

const DesktopGamificationButton: FunctionComponent<
  PlatformSpecificButtonProps
> = ({ text, link, icon }) => {
  const endOfArticle = useContext(EndOfArticleContext)
  const trackingOnClick = useCallback<TrackingFnType>(() => {
    return {
      event: 'game_interaction',
      eventCategory: 'Game',
      eventAction: `click_${endOfArticle ? 'bottom' : 'top'}`,
      eventLabel: text,
    }
  }, [endOfArticle, text])

  const trackingOnImpression = useCallback<TrackingFnType>(() => {
    return {
      event: 'game_impression',
      eventCategory: 'Game',
      eventLabel: text,
      eventAction: `impression_${endOfArticle ? 'bottom' : 'top'}`,
    }
  }, [text, endOfArticle])

  const handleTrack = useTracking(trackingOnClick)
  const handleImpressionTrack = useTracking(trackingOnImpression)

  const viewportRef = useViewportTracking({
    onImpression: handleImpressionTrack,
    track: true,
  })

  const handleClick = () => {
    handleTrack()
  }

  return (
    <StyledGamificationLink onClick={handleClick} ref={viewportRef} {...link}>
      <StyledIcon src={icon} width="16" height="16" loading="lazy" />
      {text}
    </StyledGamificationLink>
  )
}

export default DesktopGamificationButton
