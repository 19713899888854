import { AdProps } from '@components/Ad'
import { Link } from '@cook-types/cook'
import { TargetContentType } from '@utils/cook/types'
import { ReactNode } from 'react'
import { ListItemTeaserAPIProps } from '@components/TeaserListItem/types'

export interface SearchWidgetProps {
  ad: AdProps
  globalMetadata: {
    id?: string
    title?: string
    url: string
    type: string
    sectionIds: string[]
    teaser?: {
      targetContentType: TargetContentType
    }
    syndication?: string
  }
}

export type ResultItemMaybeProps = ListItemTeaserAPIProps | {}

export function isResultItemProps(
  obj: ResultItemMaybeProps
): obj is ListItemTeaserAPIProps {
  return (obj as ListItemTeaserAPIProps).articleId !== undefined
}

export interface SearchResponseAPIProps {
  keywords: string[]
  content: ListItemTeaserAPIProps[]
  total_elements: number
  total_pages: number
  last: boolean
  page: number
  size: number
}

export interface SearchInputProps {
  initialValue: string
  onSubmit: (value: string) => void
}

export interface SearchResultsContainerProps {
  children: ReactNode
}

export type SearchResultLink = Link

export interface SearchMessageProps {
  className?: string
  children: string
}

export interface GetListItemTeasers {
  firstPartOfResults: ListItemTeaserAPIProps[]
  restOfResults: ListItemTeaserAPIProps[]
}
export interface SearchResultsData {
  totalElements: number
  totalPages: number
  searchResultRange: string
  searchTerm: string
}
