import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

import StyledButton from '../StyledButton'

import { desktopCSS, mobileCSS, tabletCSS } from '@measures/responsive'

const StyledPlaceholderButton = styled(StyledButton)`
  ${({
    theme: {
      colors: {
        blicktvGrey: { btvGrey_60 },
      },
    },
  }) => css`
    background: ${btvGrey_60};
    cursor: default;

    ${mobileCSS(css`
      width: 234px;
    `)}

    ${tabletCSS(css`
      width: 336px;
    `)}

  ${desktopCSS(css`
      width: 336px;
    `)}
  `}
`

const PlaceholderButton: FunctionComponent = () => {
  return <StyledPlaceholderButton disabled />
}

export default PlaceholderButton
