import styled, { css } from 'styled-components'
import { FunctionComponent } from 'react'
import PrimaryButton from '@components/Buttons/Primary'
import { desktopCSS } from '@measures/responsive'

interface LoginOverlayProps {
  text?: string
  buttonText?: string
  onClick: () => void
}

const LoginOverlayContainer = styled.div`
  ${({
    theme: {
      spacing: { spacing24, spacing32, spacing8 },
      color: {
        tertiary: { grey400 },
      },
    },
  }) => {
    return css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-height: 164px;
      padding: ${spacing24};
      box-sizing: border-box;
      border-radius: ${spacing8};
      border: 1px solid ${grey400};
      ${desktopCSS(css`
        padding: ${spacing32};
        min-height: 158px;
      `)}
    `
  }}
`

const StyledText = styled.span`
  ${({
    theme: {
      typography: {
        headings: {
          small: { bundledCSS: headingSmallCSS },
        },
      },
      color: {
        primary: { primary01 },
      },
    },
  }) => {
    return css`
      ${headingSmallCSS};
      color: ${primary01};
      text-align: center;
    `
  }}
`

const StyledPrimaryButton = styled(PrimaryButton)`
  ${({
    theme: {
      typography: {
        subheads: {
          small1: { bundledCSS: subheadingSmall1CSS },
        },
      },
      color: {
        primary: { primary02, blickRed },
        secondary: { darkRed },
      },
      spacing: { spacing24 },
    },
  }) => {
    return css`
      margin-top: ${spacing24};
      border-radius: 50px;
      color: ${primary02};
      background-color: ${blickRed};
      text-transform: capitalize;
      ${subheadingSmall1CSS};
      @media (hover: hover) {
        &:hover:not([disabled]) {
          background-color: ${darkRed};
        }
      }
    `
  }}
`

const LoginOverlay: FunctionComponent<LoginOverlayProps> = ({
  text,
  buttonText,
  onClick,
}) => (
  <LoginOverlayContainer>
    <StyledText>{text}</StyledText>
    <StyledPrimaryButton size="large" onClick={onClick}>
      {buttonText}
    </StyledPrimaryButton>
  </LoginOverlayContainer>
)

export default LoginOverlay
