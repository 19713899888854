import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { TransformedDataFormula1, TransformedDataSki } from '../utils'
import { LIVE_EVENT_DATA_SPORT_TYPES } from '@utils/formatters/common'
import { desktopCSS, mobileCSS, tabletCSS } from '@measures/responsive'

type RacingSkiingProps = {
  sportsData: TransformedDataFormula1 | TransformedDataSki
  className?: string
}

const commonStyle = css`
  ${({
    theme: {
      color: {
        primary: { primary02, primary01 },
      },
      spacing: { spacing8, spacing16 },
    },
  }) => css`
    color: ${primary02};
    background-color: ${primary01};
    width: 100%;
    box-sizing: border-box;
    padding: ${spacing8} ${spacing16};
    display: grid;

    ${desktopCSS(css`
      min-height: 72px;
    `)};

    ${tabletCSS(css`
      min-height: 54px;
    `)};

    ${mobileCSS(css`
      min-height: 54px;
    `)};
  `}
`

const NotStartedWrapper = styled.div`
  ${({}) => css`
    ${commonStyle};
    grid-template-columns: 50% 50%;
    justify-content: flex-start;
    align-items: center;
  `}
`

const RaceName = styled.div`
  ${({
    theme: {
      typography: {
        headings: {
          small: { bundledCSS: headingsSmall },
        },
      },
    },
  }) => css`
    ${headingsSmall};
  `}
`

const LeftInfo = styled.div`
  ${({
    theme: {
      typography: {
        eyebrows: {
          medium1: { bundledCSS: eyebrowsMedium1 },
        },
      },
    },
  }) => css`
    ${eyebrowsMedium1};
    text-transform: uppercase;
    justify-self: flex-end;
  `}
`

const CompetitorsWrapper = styled.div`
  ${({}) => css`
    ${commonStyle};
    ${desktopCSS(css`
      grid-template-columns: repeat(3, minmax(0, 1fr));
      justify-content: flex-start;
      align-items: center;
      grid-column-gap: 22px;
    `)};
  `}
`

const RankingItem = styled.div`
  ${({
    theme: {
      color: {
        tertiary: { grey900 },
      },
      spacing: { spacing8 },
    },
  }) => css`
    display: grid;

    ${desktopCSS(css`
      grid-template-areas:
        'P N'
        'P T';
      grid-template-columns: 40px 1fr;
      grid-template-rows: repeat(2, auto);
      grid-column-gap: ${spacing8};
    `)};

    ${tabletCSS(css`
      grid-template-columns: 22px 1fr auto;
      grid-column-gap: ${spacing8};
      align-items: center;
      padding: ${spacing8} 0;
      &:not(:last-child) {
        border-bottom: 1px solid ${grey900};
      }
    `)};

    ${mobileCSS(css`
      grid-template-columns: 22px 1fr auto;
      grid-column-gap: ${spacing8};
      align-items: center;
      padding: ${spacing8} 0;
      &:not(:last-child) {
        border-bottom: 1px solid ${grey900};
      }
    `)};
  `}
`

const Position = styled.div`
  ${({
    theme: {
      color: {
        tertiary: { grey900 },
      },
      typography: {
        headings: {
          small: { bundledCSS: headingsXSmall },
        },
      },
    },
  }) => css`
    ${headingsXSmall};
    border: 1px solid ${grey900};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    ${desktopCSS(css`
      grid-area: P;
      width: 40px;
      height: 40px;
    `)};

    ${tabletCSS(css`
      width: 22px;
      height: 22px;
    `)};

    ${mobileCSS(css`
      width: 22px;
      height: 22px;
    `)};
  `}
`

const Name = styled.div`
  ${({
    theme: {
      typography: {
        subheads: {
          medium2: { bundledCSS: subheadsMedium2 },
        },
      },
      utils: { ellipsis },
    },
  }) => css`
    ${subheadsMedium2};
    ${ellipsis('100%', 1)};
    display: inline-block;

    ${desktopCSS(css`
      grid-area: N;
    `)};
  `}
`

const Time = styled.div`
  ${({
    theme: {
      typography: {
        bodycopy: {
          xsmall: { bundledCSS: headingsSmall },
        },
      },
    },
  }) => css`
    ${headingsSmall};

    ${desktopCSS(css`
      grid-area: T;
    `)};
  `}
`

const SkiFormulaOne: FunctionComponent<RacingSkiingProps> = ({
  sportsData,
  className,
}) => {
  const { hasStarted, competitors, raceName } = sportsData
  const leftInfo =
    sportsData.typeOfSport === LIVE_EVENT_DATA_SPORT_TYPES.FORMULA1
      ? sportsData.raceType
      : sportsData.raceLocation

  if (!hasStarted || (hasStarted && !competitors.length)) {
    return (
      <NotStartedWrapper className={className}>
        <RaceName>{raceName}</RaceName>
        <LeftInfo>{leftInfo}</LeftInfo>
      </NotStartedWrapper>
    )
  }
  return (
    <CompetitorsWrapper className={className}>
      {competitors.map(({ name, time, position }) => (
        <RankingItem key={name}>
          <Position>{position}</Position>
          <Name>{name}</Name>
          <Time>{time}</Time>
        </RankingItem>
      ))}
    </CompetitorsWrapper>
  )
}

export default SkiFormulaOne
