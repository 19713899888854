import styled, { css } from 'styled-components'

import { desktopCSS, mobileCSS, tabletCSS } from '@measures/responsive'

const Wrapper = styled.div`
  ${({
    theme: {
      colors: {
        blicktvGrey: { btvGrey_70 },
      },
    },
  }) => css`
    background-color: ${btvGrey_70};

    ${desktopCSS(css`
      margin: 0 -20px;
    `)}

    ${tabletCSS(css`
      margin: 0 -52px;
    `)}

  ${mobileCSS(css`
      margin: 0;
    `)}
  `}
`

export default Wrapper
