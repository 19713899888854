export type UseVTTFileCues = ({
  fileUrl,
}: {
  fileUrl: string
}) => Promise<VTTCue[] | void>

const loadVTTData = ({
  fileUrl,
}: {
  fileUrl: string
}): Promise<string | void> =>
  fetch(fileUrl, {
    method: 'GET',
  })
    .then((response) => response.text())
    .catch((err) => console.error(err))

const parseVTTData = async ({
  vttData,
}: {
  vttData?: string
}): Promise<VTTCue[]> => {
  try {
    const parser = new (await import('webvtt-parser')).default.WebVTTParser()
    const tree = parser.parse(vttData, 'metadata')

    // if (tree.errors) logger.error('tree', tree.errors)
    return tree.cues
  } catch (e) {
    console.error('Error parsing the VTT file!!!', e)
  }

  return []
}

export const loadVTTCuesFromFile: UseVTTFileCues = async ({ fileUrl }) => {
  const fileContent = await loadVTTData({ fileUrl })

  return await parseVTTData({ vttData: fileContent || '' })
}
