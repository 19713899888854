import { createGlobalStyle, css } from 'styled-components'
import { printStyles } from '@utils/print'
import { globalAdStyles } from '@utils/ads'
import { globalPianoStyles } from '@components/Piano/utils'
import globalVideoPlayerStyles from '@components/Video/VideoPlayer/globalVideoPlayerStyles'

const GlobalStyle = createGlobalStyle`${({
  theme: {
    color: {
      primary: { primary01 },
    },
  },
}) => css`
  html {
    font-family: sans-serif;
    font-size: 1rem;
  }

  body {
    position: relative;
    margin: 0;
    font-family: sans-serif;
    font-size: 1rem;
    line-height: 1;
    color: ${primary01};
    word-wrap: break-word;
    -webkit-font-smoothing: antialiased;
    -webkit-overflow-scrolling: touch;
    overflow: hidden;
    overflow-y: auto;
  }

  #__next {
    position: relative;
  }

  //! CMP Hide Floating Button
  #ot-sdk-btn-floating.ot-floating-button {
    display: none;
  }

  ${globalVideoPlayerStyles};

  ${printStyles};

  /* Used for overriding styles in Piano modal */
  ${globalPianoStyles};

  ${globalAdStyles};
`}`

export default GlobalStyle
