import { FunctionComponent, ReactNode, useRef } from 'react'
import styled, { css } from 'styled-components'
import { desktopCSS, mobileCSS, tabletCSS } from '@measures/responsive'
import useIsInRecipeIngredientsContent from '@hooks/useIsInRecipeIngredientsContent'
import { printCSS } from '@utils/style'
import { layout } from '@measures/responsive'
import useGetPageIdentity from '@hooks/useGetPageIdentity'
import useIsInArticleBodyContent from '@hooks/useIsInArticleBodyContent'
import useIsInCueLiveContent from '@hooks/useIsInCueLiveContent'

const {
  header: {
    mobile: { offsetFirstRow: headerOffsetFirstRowMobile },
  },
} = layout

export type VideoContainerProps = {
  isInVideoOnlyArticle?: boolean
  isInOverlay?: boolean
  children?: ReactNode
  isInScoreboardContent?: boolean
}

interface StyledVideoContainerProps extends VideoContainerProps {
  isInArticleContent?: boolean
  isInRecipeIngredients?: boolean
  isInScoreboardContent?: boolean
  shouldStretch: boolean
  shouldStretchToCardWidth: boolean
}

const StyledVideoContainer = styled.div<StyledVideoContainerProps>`
  ${({
    theme: {
      measures: { stretchToLayoutCardWidth, stretchWidthOnMobile },
    },
    isInOverlay,
    isInVideoOnlyArticle,
    isInArticleContent,
    isInRecipeIngredients,
    isInScoreboardContent,
    shouldStretch,
    shouldStretchToCardWidth,
  }) => {
    const inVideoOnlyArticleCSS = css`
      //! The !important is here because we want to override top positioning set in useEffect
      //! for desktop and tablet
      ${desktopCSS(css`
        top: 0 !important;
      `)}

      ${tabletCSS(css`
        top: 0 !important;
      `)}

      ${mobileCSS(css`
        position: sticky;
        top: ${headerOffsetFirstRowMobile}px;
        z-index: 2;
      `)}

      ${stretchWidthOnMobile()}
    `

    return css`
      position: relative;
      ${printCSS(css`
        display: none;
      `)}

      ${isInVideoOnlyArticle && !isInOverlay && inVideoOnlyArticleCSS}

      ${isInArticleContent &&
      css`
        ${!isInRecipeIngredients &&
        css`
          margin-top: 16px;
          margin-bottom: 16px;
        `}

        ${(isInScoreboardContent || isInVideoOnlyArticle) &&
        css`
          margin-top: 0px;
          margin-bottom: 0px;
        `}

        ${shouldStretch && stretchWidthOnMobile()};
      `}

      ${shouldStretchToCardWidth && stretchToLayoutCardWidth()}
    `
  }}
`

const VideoContainer: FunctionComponent<VideoContainerProps> = ({
  isInOverlay,
  isInVideoOnlyArticle,
  isInScoreboardContent,
  children,
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const { isArticle, isRecipe } = useGetPageIdentity()
  const isInArticleBody = useIsInArticleBodyContent()
  const isInCueLive = useIsInCueLiveContent()
  const isInRecipeIngredients = useIsInRecipeIngredientsContent()

  const shouldStretch =
    isInArticleBody && !isRecipe && !isInScoreboardContent && !isInCueLive

  const shouldStretchToCardWidth = isInRecipeIngredients

  return (
    <StyledVideoContainer
      ref={containerRef}
      isInArticleContent={isArticle}
      isInRecipeIngredients={isInRecipeIngredients}
      isInOverlay={isInOverlay}
      isInVideoOnlyArticle={isInVideoOnlyArticle}
      isInScoreboardContent={isInScoreboardContent}
      shouldStretch={shouldStretch}
      shouldStretchToCardWidth={shouldStretchToCardWidth}>
      {children}
    </StyledVideoContainer>
  )
}

export default VideoContainer
