import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { TeaserVideo } from '@utils/cook/types'
import { JSONWidgetType as HeadlineLeadJSONWidgetType } from '@widgets/HeadlineLead'
import { JSONWidgetType as BodyJSONWidgetType } from '@widgets/Body'
import { JSONWidgetType as AdRingierSONWidgetType } from '@widgets/AdRingier'
import HerotellingHeadlineLead from '@components/HeadlineLead/HerotellingHeadlineLead'
import { HerotellingHeadlineLeadAPIProps } from '@widgets/HeadlineLead/types'
import { desktopCSS, mobileCSS, tabletCSS } from '@measures/responsive'
import JSONRenderer from '@components/JSONRenderer'
import InvertedColorsContext from '@contexts/invertedColors'

export interface ArticleLeadContentCommonVideoProps {
  video: TeaserVideo
  lead: HeadlineLeadJSONWidgetType
  body: BodyJSONWidgetType
  ad: AdRingierSONWidgetType
}

export interface ArticleLeadContentVideoProps
  extends ArticleLeadContentCommonVideoProps {
  layoutTemplate: 'video'
}

export interface ArticleLeadContentPlaylistProps
  extends ArticleLeadContentCommonVideoProps {
  layoutTemplate: 'playlist'
  playlist: {}
}

export interface ArticleLeadContentHerotellingProps {
  layoutTemplate: 'herotelling'
  lead: HerotellingHeadlineLeadAPIProps
}

export type ArticleLeadContentAPIProps =
  | ArticleLeadContentVideoProps
  | ArticleLeadContentPlaylistProps
  | ArticleLeadContentHerotellingProps

export interface StyledArticleLeadContent {
  layoutTemplate: ArticleLeadContentAPIProps['layoutTemplate']
}

const StyledArticleLeadContent = styled.div<StyledArticleLeadContent>`
  ${({
    theme: {
      spacing: { spacing16, spacing24, spacing32 },
      color: {
        primary: { primary01: primary01Color },
        tertiary: { grey1000: grey1000Color },
      },
    },
    layoutTemplate,
  }) => css`
    ${layoutTemplate === 'herotelling' &&
    css`
      &&&&& {
        ${desktopCSS(css`
          width: min(100vw, 1920px);
          margin-left: calc((964px - min(100vw, 1920px)) / 2);
        `)};

        background-color: ${grey1000Color};
      }
    `};

    ${layoutTemplate === 'video' &&
    css`
      grid-row-gap: ${spacing24};
      align-items: flex-start;

      &&&&& {
        ${desktopCSS(css`
          padding: ${spacing32} 15px;
          display: grid;
          width: calc(100% + 2 * 15px);
          box-sizing: border-box;
          grid-template-columns: repeat(6, minmax(0, 1fr));
          grid-column-gap: ${spacing32};
          grid-auto-flow: dense;

          > *:first-child {
            grid-column: 1 / 7;
          }

          > *:nth-child(2) {
            grid-column: 1 / 5;
          }

          > article {
            grid-column: 1 / 5;
          }

          > aside {
            grid-column: 5 / 7;
          }
        `)};

        ${tabletCSS(css`
          display: grid;
          grid-template-columns: minmax(0, 1fr);
          padding: 0 68px ${spacing16} 68px;
          width: calc(100% + 2 * 68px);
          box-sizing: border-box;
        `)};

        ${mobileCSS(css`
          display: grid;
          grid-template-columns: minmax(0, 1fr);
          padding: 0 16px ${spacing16} 16px;
          width: calc(100% + 2 * 16px);
          box-sizing: border-box;
        `)};

        background-color: ${primary01Color};
      }
    `};

    ${layoutTemplate === 'playlist' &&
    css`
      grid-row-gap: ${spacing24};
      align-items: flex-start;

      &&&&& {
        ${desktopCSS(css`
          padding: ${spacing32} 15px;
          display: grid;
          width: calc(100% + 2 * 15px);
          box-sizing: border-box;
          grid-template-columns: repeat(6, minmax(0, 1fr));
          grid-column-gap: ${spacing32};
          grid-auto-flow: dense;

          > *:first-child {
            grid-column: 1 / 5;
          }

          > *:nth-child(2) {
            grid-column: 1 / 5;
          }

          > article {
            grid-column: 1 / 5;
          }

          > aside {
            grid-column: 5 / 7;
          }

          > *:last-child {
            grid-row: 1;
            grid-column: 5 / 7;
          }
        `)};

        ${tabletCSS(css`
          display: grid;
          grid-template-columns: minmax(0, 1fr);
          padding: 0 68px ${spacing16} 68px;
          width: calc(100% + 2 * 68px);
          box-sizing: border-box;
        `)};

        ${mobileCSS(css`
          display: grid;
          grid-template-columns: minmax(0, 1fr);
          padding: 0 16px ${spacing16} 16px;
          width: calc(100% + 2 * 16px);
          box-sizing: border-box;
        `)};

        background-color: ${primary01Color};
      }
    `};
  `};
`

const ArticleLeadContent: FunctionComponent<ArticleLeadContentAPIProps> = (
  props
) => {
  const { layoutTemplate } = props

  return (
    <StyledArticleLeadContent layoutTemplate={layoutTemplate}>
      {layoutTemplate === 'herotelling' && (
        <HerotellingHeadlineLead
          {...props.lead}
          commentsNumber={0}
          handleArticleClickShowCommentTracking={() => {}}
        />
      )}

      {layoutTemplate === 'video' && (
        <InvertedColorsContext.Provider value={true}>
          <JSONRenderer>
            {[props.video, props.lead, props.body, props.ad]}
          </JSONRenderer>
        </InvertedColorsContext.Provider>
      )}

      {layoutTemplate === 'playlist' && (
        <InvertedColorsContext.Provider value={true}>
          <JSONRenderer>
            {[props.video, props.lead, props.body, props.ad, props.playlist]}
          </JSONRenderer>
        </InvertedColorsContext.Provider>
      )}
    </StyledArticleLeadContent>
  )
}

const widget = {
  kind: ['article-lead-content'],
  component: ArticleLeadContent,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
