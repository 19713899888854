import {
  resetExperienceCookie,
  setCustomVariables,
  setTags,
  setModalZIndexes,
  setJWTToken,
  doParseStringifiedData,
  logPiano,
  isGoogleBot,
  getSubscriptionStatusForPianoTemplates,
  getSubscriptionExpiringSoonForPianoTemplates,
} from './utils'
import { getCookieValue } from '@utils/cookie'
import config from '@config'
import {
  GetUserResponse,
  InitExperienceParams,
  InitializePianoParams,
  UpdatePianoExperienceParams,
} from './types'
import { QueryClient } from '@tanstack/react-query'

const {
  piano: { applicationId, pianoEndPoint, cxenseSiteId, experienceCookieName },
} = config

// @ts-ignore
const pianoInit = ({
  isSubscribed,
  userToken,
  queryClient,
}: {
  isSubscribed: boolean
  userToken?: string
  queryClient: QueryClient
}) =>
  window?.tp?.push?.([
    'init',
    () => {
      const encodedPianoData = getCookieValue(experienceCookieName) as string
      const pianoData = decodeURIComponent(encodedPianoData)
      const parsedPianoData = doParseStringifiedData(pianoData)

      if (parsedPianoData && window?.tp?.user?.isUserValid() && !isSubscribed) {
        let attempts = 0
        // We need to do this to workaround a Piano issue, when a new user is created from the Piano cliffhanger (see BLIAA-318)
        const intervalId = setInterval(() => {
          if (attempts > 4) {
            clearInterval(intervalId)
            return
          }

          attempts++
          window.tp.api.callApi(
            '/anon/user/get',
            {
              aid: applicationId,
              user_provider: 'piano_id_lite',
              user_token: userToken,
            },
            function (pianoResponse: GetUserResponse) {
              logPiano(
                { queryClient },
                `PIANO called /anon/user/get, attempt n.${attempts}. Response is: ${JSON.stringify(
                  pianoResponse
                )}`
              )
              if (pianoResponse.code === 0) {
                clearInterval(intervalId)
                resetExperienceCookie()
                window?.tp?.offer.startCheckout(parsedPianoData)
              }
            }
          )
        }, 500)
      }
      logPiano({ queryClient }, 'Calling init experience')
      window?.tp?.experience?.init?.()
    },
  ])

const initExperience = ({
  isLoggedIn,
  isSubscribed,
  queryClient,
}: InitExperienceParams): void => {
  setModalZIndexes()

  if (isLoggedIn) {
    setJWTToken((userToken) => {
      // init piano after having set userRef
      pianoInit({ isSubscribed, userToken, queryClient })
    })
  } else {
    // on logging out remove previously set experience cookie
    resetExperienceCookie()
    pianoInit({ isSubscribed, queryClient })
  }
}

const updateExperience = ({
  currentUrl,
  ssoSource,
  isPlus,
  isVideoOnly,
  queryClient,
}: UpdatePianoExperienceParams) => {
  // Update global variables in Piano
  setCustomVariables({
    currentUrl,
    sourceSSO: ssoSource,
    // Any article will be gifted only at initial page load. When the user navigates
    // away from the gifted article, isGifted will be always false from there on.
    isGifted: 'false',
  })
  setTags({ isPlus, isVideoOnly })
  logPiano({ queryClient }, 'updateExperience: Calling experience execute')
  window?.tp?.experience?.execute?.()
}

// apart from the arguments passed by dependency injection, the code below is generated by Piano
const initializePiano = ({
  isLoggedIn,
  currentUrl,
  ssoSource,
  pianoEvents,
  isPlus,
  isSubscribed,
  isVideoOnly,
  isGifted,
  isDebugMode = false,
  queryClient,
}: InitializePianoParams) => {
  const {
    onCheckoutClose,
    onCheckoutCustom,
    onShowOffer,
    onShowTemplate,
    onCustomEventTracking,
    onLoginRequired,
    onSubmitPayment,
    onExperienceExecute,
    onCheckoutComplete,
    onCheckoutSelectTerm,
  } = pianoEvents

  logPiano(
    { queryClient },
    'initializePiano: Setting handlers and variables in Piano'
  )
  window.tp?.push(['setAid', applicationId])
  window.tp?.push(['setEndpoint', pianoEndPoint])
  window.tp?.push(['setUseTinypassAccounts', false])

  window.tp?.push(['setUsePianoIdUserProvider', false])
  window.tp?.push(['setUsePianoIdLiteUserProvider', true])

  window.tp?.push(['setDebug', isDebugMode])

  // Needed for the new piano segmentation engine
  window.tp?.push(['setCxenseSiteId', cxenseSiteId])

  /* checkout related events */
  window.tp?.push(['addHandler', 'submitPayment', onSubmitPayment])
  window.tp?.push(['addHandler', 'checkoutClose', onCheckoutClose])
  window.tp?.push(['addHandler', 'checkoutCustomEvent', onCheckoutCustom])
  window.tp?.push(['addHandler', 'checkoutComplete', onCheckoutComplete])
  window.tp?.push(['addHandler', 'loginRequired', onLoginRequired])
  window.tp?.push(['addHandler', 'showOffer', onShowOffer])
  window.tp?.push(['addHandler', 'showTemplate', onShowTemplate])
  window.tp?.push(['addHandler', 'experienceExecute', onExperienceExecute])
  window.tp?.push(['addHandler', 'checkoutSelectTerm', onCheckoutSelectTerm])

  window.tp?.push([
    'addHandler',
    'checkoutError',
    (errorData: { message: string }) => {
      logPiano(
        { queryClient, method: 'error' },
        'Web PIANO: checkoutError occurred',
        errorData.message
      )
    },
  ])

  // nonSite actions are for tracking custom events
  window.tp?.push(['addHandler', 'nonSite', onCustomEventTracking])

  // custom variables accessible in Piano templates
  setCustomVariables({
    currentUrl,
    sourceSSO: ssoSource,
    ...queryClient.getQueryData(['cliffhanger-bulletpoints']),
    subscriptionStatus: getSubscriptionStatusForPianoTemplates(queryClient),
    isGoogleBot: `${isGoogleBot(navigator?.userAgent)}`,
    subscriptionExpiringSoon:
      getSubscriptionExpiringSoonForPianoTemplates(queryClient),
    isGifted: `${isGifted}`,
  })

  queryClient.removeQueries({
    queryKey: ['cliffhanger-bulletpoints'],
    exact: true,
  })

  // Set tags
  setTags({ isPlus, isVideoOnly })

  initExperience({ isLoggedIn, isSubscribed, queryClient })
}

export { updateExperience, initializePiano }
