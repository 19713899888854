import { Dispatch, SetStateAction, createContext } from 'react'
import { Chapter, TimelinePointer } from './types'

export interface LivestreamStoryContextType {
  enabled?: boolean
  chapters?: Chapter[]
  setChapters: (chapters: Chapter[]) => void
  activeChapter?: Chapter
  setActiveChapter: (chapter: Chapter) => void
  adPlaying?: boolean
  setAdPlaying: (adPlaying: boolean) => void
  timelinePointers: TimelinePointer[]
  setTimelinePointers: Dispatch<SetStateAction<TimelinePointer[]>>
  widgetId: string
  userLastSelectedTimestamp: number
  setUserLastSelectedTimestamp: (timestamp: number) => void
}

const LivestreamStoryContext = createContext<LivestreamStoryContextType>({
  enabled: false,
  chapters: [],
  setChapters: () => {},
  activeChapter: undefined,
  setActiveChapter: () => {},
  adPlaying: false,
  setAdPlaying: () => {},
  timelinePointers: [],
  setTimelinePointers: () => {},
  widgetId: 'widgetId',
  userLastSelectedTimestamp: 0,
  setUserLastSelectedTimestamp: () => {},
})

export default LivestreamStoryContext
