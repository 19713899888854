import PrimaryButton from '@components/Buttons/Primary'
import translate from '@i18n'
import { QueryClient, useQueryClient } from '@tanstack/react-query'
import { FunctionComponent, RefObject, useCallback, useEffect } from 'react'
import styled, { css } from 'styled-components'
import LinkToFaqs from './LinkToFaqs'
import Title from './Title'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import Text from '@components/PlusLinkShare/Text'

interface NotSubscribedPopoverProps {
  toogleTooltip: () => void
}

const ScrollToCliffhangerBtn = styled(PrimaryButton)`
  ${({
    theme: {
      typography: {
        subheads: {
          small1: { bundledCSS: subheadingSmall1CSS },
        },
      },
      color: {
        primary: { blickRed },
        secondary: { darkRed },
      },
    },
  }) => css`
    ${subheadingSmall1CSS};
    margin-top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    text-transform: none;
    background-color: ${blickRed};
    @media (hover: hover) {
      &:hover:not([disabled]) {
        background-color: ${darkRed};
      }
    }
  `}
`

const scrollToCliffhanger = (queryClient: QueryClient) => {
  const cliffhangerRef = queryClient.getQueryData<RefObject<HTMLDivElement>>([
    'cliffhanger-widget-ref',
  ])

  if (!cliffhangerRef?.current) {
    return
  }

  try {
    window.scrollTo({
      top: cliffhangerRef.current?.offsetTop,
      left: 0,
      behavior: 'smooth',
    })
  } catch {
    window.scrollTo(0, cliffhangerRef.current?.offsetTop)
  }
}

const NotSubscribedPopover: FunctionComponent<NotSubscribedPopoverProps> = ({
  toogleTooltip,
}) => {
  const queryClient = useQueryClient()

  const popoverShownTrackingFn = useCallback<TrackingFnType>(
    () => ({
      event: 'element_impression',
      element: 'article_share_top',
      method: 'blickplus_gift',
    }),
    []
  )

  const buttonClickTrackingFn = useCallback<TrackingFnType>(
    () => ({
      event: 'element_click',
      element: 'article_share_top',
      method: 'blickplus_gift',
    }),
    []
  )

  const onPopoverShownTracking = useTracking(popoverShownTrackingFn)
  const onButtonClickTracking = useTracking(buttonClickTrackingFn)

  useEffect(() => {
    onPopoverShownTracking()
  }, [onPopoverShownTracking])

  const clickAndScrollToCliffhanger = useCallback(() => {
    onButtonClickTracking()
    toogleTooltip()
    scrollToCliffhanger(queryClient)
  }, [onButtonClickTracking, toogleTooltip, queryClient])

  return (
    <>
      <Title />
      <Text>{translate('blickPlus.linkSharing.notSubscribedText')}</Text>
      <LinkToFaqs />
      <ScrollToCliffhangerBtn
        type="button"
        size="small"
        onClick={clickAndScrollToCliffhanger}>
        {translate('blickPlus.linkSharing.linkToAbos')}
      </ScrollToCliffhangerBtn>
    </>
  )
}

export default NotSubscribedPopover
