import useDisableThirdPartyScripts from '@hooks/useDisableThirdPartyScripts'
import Script from 'next/script'
import { FunctionComponent } from 'react'
import config from '@config'
import useCMPHasUserInteracted from '@hooks/useCMPHasUserInteracted'

const {
  tracking: {
    gtm: { id: gtmId },
  },
} = config

const GTMScript: FunctionComponent = () => {
  const disableThirdPartyScripts = useDisableThirdPartyScripts()
  const hasUserInteracted = useCMPHasUserInteracted()

  if (disableThirdPartyScripts) {
    return null
  }

  return hasUserInteracted ? (
    <Script
      id="GTM"
      dangerouslySetInnerHTML={{
        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${gtmId}');`,
      }}
    />
  ) : null
}

export default GTMScript
