import useHeaderTopics from '@hooks/useHeaderTopics'
import { FunctionComponent, useCallback, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import ArrowFader from './ArrowFader'
import SimpleLink from '../SimpleLink'
import { scrollToLeft, scrollToRight } from './utils'
import useIsImmersiveHeaderActive from '@hooks/useIsImmersiveHeaderActive'
import useSwiperArrows, {
  ArrowsVisibilityType,
} from '@hooks/useSwiper/useSwiperArrows'
import { useVirtualizer } from '@tanstack/react-virtual'

const Container = styled.div`
  position: relative;
  display: grid;
  grid-auto-flow: column;
  height: 100%;
`

interface LinksWrapperProps {
  showLeftArrow: boolean
  showRightArrow: boolean
}

const LinksWrapper = styled.div<LinksWrapperProps>`
  ${({ showLeftArrow, showRightArrow }) => css`
    height: 100%;
    position: relative;

    ${(showLeftArrow || showRightArrow) &&
    css`
      mask-image: linear-gradient(
        90deg,
        ${[
          ...(showLeftArrow ? ['transparent 0%', '#ffffff 5%'] : []),
          ...(showRightArrow ? ['#ffffff 95%', 'transparent 100%'] : []),
        ].join(',')}
      );
    `}
  `}
`

const StyledWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
  box-sizing: border-box;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
`

const StyledTopicWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 48px;
  display: flex;
  align-items: center;

  &:last-child {
    a {
      margin-right: 0;
    }
  }
`

const HeaderTopicsSwiper: FunctionComponent = () => {
  const headerTopics = useHeaderTopics()
  const [showLeftArrow, setShowLeftArrow] = useState<boolean>(false)
  const [showRightArrow, setShowRightArrow] = useState<boolean>(false)
  const linksWrapperElement = useRef<HTMLDivElement>(null)
  const isImmersiveHeaderActive = useIsImmersiveHeaderActive()

  const onArrowsVisibility = useCallback((arrow: ArrowsVisibilityType) => {
    setShowLeftArrow(arrow === 'prev' || arrow === 'both')
    setShowRightArrow(arrow === 'next' || arrow === 'both')
  }, [])

  useSwiperArrows({
    enabled: true,
    itemsContainerRef: linksWrapperElement,
    onArrowsVisibility,
  })
  const virtualizer = useVirtualizer({
    horizontal: true,
    count: headerTopics.length,
    getScrollElement: () => linksWrapperElement.current,
    estimateSize: () => 100,
    overscan: headerTopics.length,
  })

  return (
    <Container>
      {showLeftArrow && (
        <ArrowFader.Left
          isImmersiveHeaderActive={isImmersiveHeaderActive}
          scrollToStart={() => scrollToLeft({ virtualizer })}
        />
      )}
      <StyledWrapper ref={linksWrapperElement}>
        <LinksWrapper
          showLeftArrow={showLeftArrow}
          showRightArrow={showRightArrow}
          style={{
            width: `${virtualizer.getTotalSize()}px`,
          }}>
          {virtualizer.getVirtualItems().map((virtualItem) => {
            const { text, link } = headerTopics[virtualItem.index]
            return (
              <StyledTopicWrapper
                key={text}
                style={{
                  transform: `translateX(${virtualItem.start}px)`,
                }}
                ref={virtualizer.measureElement}
                data-index={virtualItem.index}>
                <SimpleLink link={link}>{text}</SimpleLink>
              </StyledTopicWrapper>
            )
          })}
        </LinksWrapper>
        {showRightArrow && (
          <ArrowFader.Right
            isImmersiveHeaderActive={isImmersiveHeaderActive}
            scrollToEnd={() => scrollToRight({ virtualizer })}
          />
        )}
      </StyledWrapper>
    </Container>
  )
}

export default HeaderTopicsSwiper
