import { FunctionComponent, MouseEvent, useCallback, useRef } from 'react'
import styled, { css } from 'styled-components'
import SummaryPill from './SummaryPill'
import { SummaryType } from '@widgets/SectionSummary/types'
import useClickOutside from '@hooks/useClickOutside'

interface Props {
  summaries: SummaryType[]
  clickHandler: (index: number | null) => void
}

const SummaryListWrapper = styled.ul`
  ${({
    theme: {
      color: {
        primary: { blickRed: blickRedColor },
      },
      spacing: { spacing12, spacing16 },
      typography: {
        bodycopy: {
          small2: { bundledCSS: small2BodyCopy },
        },
      },
    },
  }) => css`
    list-style-type: disc;
    margin-top: 0;
    margin-left: ${spacing16};
    padding-left: ${spacing16};

    li {
      margin: 0;
      max-width: 632px;
      padding-bottom: ${spacing12};
      ${small2BodyCopy}

      &:last-child {
        padding-bottom: 0;
      }
    }

    li::marker {
      color: ${blickRedColor};
      font-size: 20px;
      line-height: 8px;
    }
  `}
`

const BoldItem = styled.b`
  ${({
    theme: {
      typography: {
        subheads: {
          medium1: { bundledCSS: medium1Subhead },
        },
      },
    },
  }) => css`
    ${medium1Subhead}
  `}
`

const SummaryPillWrapper = styled.a`
  ${({
    theme: {
      color: {
        secondary: { darkRed },
      },
    },
  }) => css`
    position: relative;
    top: -3px;
    cursor: pointer;

    &:hover {
      span {
        background-color: ${darkRed};
      }
    }
  `}
`

const SummaryList: FunctionComponent<Props> = ({ summaries, clickHandler }) => {
  const pillRef = useRef<HTMLAnchorElement>(null)
  useClickOutside({
    elementRef: pillRef,
    onClickOutside: () => clickHandler(null),
  })

  const scrollToClickHandler = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      const activeIndex = parseInt(event.currentTarget.innerText)
      if (isNaN(activeIndex) || activeIndex === null) {
        clickHandler(null)
        return
      }
      clickHandler(activeIndex - 1)
    },
    [clickHandler]
  )
  return (
    <SummaryListWrapper>
      {summaries.map((summary, index) => (
        <li key={`summary-list-item-${summary.link}-${index}`}>
          {summary.category && <BoldItem>{summary.category}:&nbsp;</BoldItem>}
          {summary.text}{' '}
          {summary.link && (
            <SummaryPillWrapper
              ref={pillRef}
              aria-label="Zur selektierten Zusammenfassung springen"
              onClick={scrollToClickHandler}>
              <SummaryPill>{summary.link}</SummaryPill>
            </SummaryPillWrapper>
          )}
        </li>
      ))}
    </SummaryListWrapper>
  )
}

export default SummaryList
