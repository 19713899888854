import { useState, useCallback, useEffect } from 'react'
import { useRouter } from 'next/router'

type UseURLHashParam = (param?: string) => string | undefined

const useURLHashParam: UseURLHashParam = (param) => {
  const router = useRouter()

  const getURLHashParamValue = useCallback<
    (url: string, param?: string) => string | undefined
  >((url, param) => {
    const URLHashValue = url?.split('#')[1]

    if (URLHashValue && param) {
      return URLHashValue.split(`${param}=`)[1]
    }

    return URLHashValue
  }, [])

  const [URLHashParamValue, setURLHashParamValue] = useState<
    string | undefined
  >(() => getURLHashParamValue(router.asPath, param))

  useEffect(() => {
    setURLHashParamValue(getURLHashParamValue(router.asPath, param))
  }, [getURLHashParamValue, param, router.asPath])

  return URLHashParamValue
}

export default useURLHashParam
