import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { BlickBitesItem } from '@widgets/Video/BlickBites'
import useInViewChange from '@hooks/useInViewChange'
import useBlickBitesFastlaneContext from '@hooks/useBlickBitesFastlaneContext'
import ItemContainer from './ItemContainer'
import BlickBitesFastlaneVideo from './BlickBitesFastlaneVideo'
import {
  VideoMetadataPlaylist,
  VideoMetadataResponse,
} from '@hooks/useVideoMetadataFetching/types'
import {
  fetchVideoMetadata,
  getM3U8File,
  getSmallestJpgImage,
} from '@hooks/useVideoMetadataFetching/utils'

export interface BlickBitesFastlaneItemProps {
  item: BlickBitesItem
  onClose: () => void
  index: number
  activeIndex: number
  onImpression: (index: number, item: BlickBitesItem) => void
}

const StyledVideoPoster = styled.div<{ isVisible: boolean }>`
  ${({ isVisible }) => css`
    opacity: ${isVisible ? 1 : 0};
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: #433e3e;
    z-index: 6;
    transition: opacity 0.5s ease-in-out;
  `}
`

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const BlickBitesFastlaneItem: FunctionComponent<
  BlickBitesFastlaneItemProps
> = ({ item, onClose, index, activeIndex, onImpression }) => {
  const { video, image } = item

  const videoId = video?.jwVideoId

  const [isInViewport, setIsInViewport] = useState<boolean | null>(null)
  const [showVideoPoster, setShowVideoPoster] = useState(false)
  const [videoMetadata, setVideoMetadata] = useState<
    VideoMetadataPlaylist | undefined
  >()

  const { isLoading, videoId: videoIdFromContext } =
    useBlickBitesFastlaneContext()

  const onInViewChangeHandler = useCallback((inView: any) => {
    setIsInViewport(inView)
  }, [])

  const viewportRef = useInViewChange({
    track: true,
    threshold: 0.5,
    delay: 0,
    onInViewChange: onInViewChangeHandler,
  })

  useEffect(() => {
    if (isInViewport) {
      onImpression(index, item)
    }
  }, [index, isInViewport, item, onImpression])

  useEffect(() => {
    if (isInViewport) {
      setShowVideoPoster(false)
    } else if (videoId !== videoIdFromContext) {
      if (activeIndex + 1 === index) {
        // preload playlist
        fetchVideoMetadata(videoId).then((res) => {
          const response = res as VideoMetadataResponse
          const playlist = response?.playlist?.[0]
          setVideoMetadata(playlist)

          // preload manifest
          const manifest = getM3U8File(playlist)
          if (manifest) {
            fetch(manifest)
          }
        })

        setShowVideoPoster(true)
      } else if (activeIndex - 1 === index) {
        setShowVideoPoster(true)
      } else {
        setShowVideoPoster(false)
      }
    }
  }, [isInViewport, activeIndex, index, videoId, item, videoIdFromContext])

  return (
    <ItemContainer ref={viewportRef}>
      {isInViewport ? (
        <BlickBitesFastlaneVideo item={item} onClose={onClose} />
      ) : null}
      {videoMetadata ? (
        <StyledVideoPoster isVisible={showVideoPoster || isLoading}>
          <StyledImage
            src={getSmallestJpgImage(videoMetadata)}
            alt={image.alt ?? ''}
          />
        </StyledVideoPoster>
      ) : null}
    </ItemContainer>
  )
}

export default BlickBitesFastlaneItem
