import { FunctionComponent } from 'react'
import { VideoZattooPlaceholderProps } from '../types'
import ZattooLogoImage from '../ZattooLogoImage'
import styled from 'styled-components'
import PlayIcon from '@components/PlayIcon'
import config from '@config'

import { createZattooUrl } from '@widgets/Video/Zattoo/utils'

const {
  zattoo: { channelPlaceholderUrl },
} = config

const StyledVideoPlaceholder = styled.div`
  width: 100%;
  padding-top: 56.25%;
  position: relative;
`
const StyledPlayIconContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const VideoZattooPlaceholder: FunctionComponent<
  VideoZattooPlaceholderProps
> = ({ channel }) => (
  <StyledVideoPlaceholder>
    <StyledImage
      src={createZattooUrl(channelPlaceholderUrl, channel)}
      alt="zattoo placeholder"
      width="16"
      height="9"
      loading="lazy"
    />
    <StyledPlayIconContainer>
      <PlayIcon />
    </StyledPlayIconContainer>
    <ZattooLogoImage />
  </StyledVideoPlaceholder>
)

export default VideoZattooPlaceholder
