import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

import { Chapter } from '@widgets/Video/story/types'

import { desktopCSS, mobileCSS, tabletCSS } from '@measures/responsive'

import RestartButton, { RestartButtonProps } from './RestartButton'
import GoToLiveButton, { GoToLiveButtonProps } from './GoToLiveButton'
import PlaceholderButton from './PlaceholderButton'
import ChapterSwiper, { ChapterSwiperProps } from './ChapterSwiper'

interface BlickTVStoryProps {
  activeItem?: Chapter
  data: Chapter[]
  showRestartButton: boolean
  showGoToLiveButton: boolean
  showPlaceholderButton: boolean
  restartButtonlabel: string
  goToLiveButtonlabel: string
  storyTitle: string
  swiperRef?: ChapterSwiperProps['swiperRef']
  onClickRestart: RestartButtonProps['onClick']
  onClickGoToLive: GoToLiveButtonProps['onClick']
  onClickItem: ChapterSwiperProps['onItemClick']
  onNavigationChange: ChapterSwiperProps['onNavigationChange']
}

const Wrapper = styled.div`
  position: relative;
`

const RestartButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  ${mobileCSS(css`
    min-height: 32px;
  `)}

  ${tabletCSS(css`
    min-height: 40px;
    padding-bottom: 16px;
  `)}

  ${desktopCSS(css`
    min-height: 40px;
    padding-bottom: 16px;
  `)}
`

const ChapterInfo = styled.div`
  ${mobileCSS(css`
    padding: 26px 8px 6px 16px;
  `)}

  ${tabletCSS(css`
    position: absolute;
    top: 0;
    padding: 10px 20px 10px 24px;
  `)}

  ${desktopCSS(css`
    position: absolute;
    top: 0;
    padding: 10px 20px 10px 24px;
  `)}
`

const ChapterTitle = styled.div`
  ${({
    theme: {
      fonts: { goodOT },
      colors: {
        blicktvGrey: { btvGrey_10 },
      },
    },
  }) => css`
    font-family: ${goodOT};
    font-size: 14px;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: ${btvGrey_10};
  `}
`

const BlickTVStory: FunctionComponent<BlickTVStoryProps> = ({
  activeItem,
  swiperRef,
  data,
  showRestartButton = false,
  showGoToLiveButton = false,
  showPlaceholderButton = false,
  restartButtonlabel,
  goToLiveButtonlabel,
  storyTitle,
  onClickRestart,
  onClickGoToLive,
  onClickItem,
  onNavigationChange,
}) => {
  return (
    <Wrapper>
      <RestartButtonWrapper>
        {showRestartButton ? (
          <RestartButton onClick={onClickRestart} label={restartButtonlabel} />
        ) : showGoToLiveButton ? (
          <GoToLiveButton
            onClick={onClickGoToLive}
            label={goToLiveButtonlabel}
          />
        ) : showPlaceholderButton ? (
          <PlaceholderButton />
        ) : null}
      </RestartButtonWrapper>
      <ChapterInfo>
        <ChapterTitle>{storyTitle}</ChapterTitle>
      </ChapterInfo>

      <ChapterSwiper
        data={data}
        activeItem={activeItem}
        swiperRef={swiperRef}
        onItemClick={onClickItem}
        onNavigationChange={onNavigationChange}
      />
    </Wrapper>
  )
}

export default BlickTVStory
