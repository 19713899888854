import { FunctionComponent } from 'react'
import styled, { css, useTheme } from 'styled-components'
import SvgIcon from '@components/SvgIcon'
import StyledButton from './StyledButton'
import translate from '@i18n'

interface VideoSuspendedOverlayProps {
  onReplay: () => void
  onContinue: () => void
}

const StyledVideoSuspendedOverlay = styled.div`
  position: absolute;
  z-index: 2;

  height: 100%;
  width: 100%;
  background: rgba(8, 8, 8, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const VideoSuspendedText = styled.div`
  ${({
    theme: {
      typography: {
        headings: {
          xsmall: { bundledCSS: xsmallHeadingCSS },
        },
      },
      color: {
        primary: { primary02 },
      },
    },
  }) => css`
    color: ${primary02};
    text-align: center;
    ${xsmallHeadingCSS}

    margin-bottom: 24px;
  `}
`

const VideoSuspendedButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
`

const VideoSuspendedOverlay: FunctionComponent<VideoSuspendedOverlayProps> = ({
  onReplay,
  onContinue,
}) => {
  const theme = useTheme()

  return (
    <StyledVideoSuspendedOverlay>
      <div>
        <VideoSuspendedText>
          {translate('video.resumePlayback')}
        </VideoSuspendedText>
        <VideoSuspendedButtonsContainer>
          <StyledButton onClick={onReplay}>
            <span>{translate('video.resumePlayback.replay')}</span>
            <SvgIcon
              iconName="arrow-rotate-left"
              size={24}
              color={theme.color.primary.primary02}
            />
          </StyledButton>
          <StyledButton type="primary" onClick={onContinue}>
            <span>{translate('video.resumePlayback.continue')}</span>
            <SvgIcon
              iconName="play-solid"
              size={24}
              color={theme.color.primary.primary01}
            />
          </StyledButton>
        </VideoSuspendedButtonsContainer>
      </div>
    </StyledVideoSuspendedOverlay>
  )
}

export default VideoSuspendedOverlay
