import { ReactNode, FunctionComponent, MouseEvent, RefObject } from 'react'
import styled, { css, useTheme } from 'styled-components'

import SvgIcon from '@components/SvgIcon'

const ButtonWrapper = styled.div<{ direction: 'prev' | 'next' }>`
  ${({ direction }) => css`
    position: absolute;
    top: 0;
    height: 100%;
    width: 83px;
    z-index: 1;
    cursor: pointer;
    user-select: none;

    display: flex;
    align-items: center;

    ${direction === 'next'
      ? css`
          right: 0;
        `
      : css`
          left: 0;
        `}
  `}
`

const PrevButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 7px;
  width: 100%;

  &:after {
    content: '';
  }
`

const NextButton = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-left: 7px;
  width: 100%;

  &:after {
    content: '';
  }
`

const ButtonCircle = styled.div`
  ${({
    theme: {
      colors: { white },
    },
  }) => {
    return css`
      display: flex;
      align-items: center;
      justify-content: center;

      width: 46px;
      height: 46px;

      border: 2px solid ${white};
      border-radius: 50%;

      transition: transform 0.2s;

      &:hover {
        transform: scale(1.16);
      }
    `
  }}
`

const ButtonSVGIcon = styled(SvgIcon)`
  background-color: transparent;
`

interface NavigationProps {
  prevArrowRef: RefObject<HTMLDivElement>
  nextArrowRef: RefObject<HTMLDivElement>
  prevButtonElement?: ReactNode
  nextButtonElement?: ReactNode
  onPrevButtonClick?: (e: MouseEvent<HTMLDivElement>) => void
  onNextButtonClick?: (e: MouseEvent<HTMLDivElement>) => void
}

const Navigation: FunctionComponent<NavigationProps> = ({
  prevArrowRef,
  nextArrowRef,
  prevButtonElement,
  nextButtonElement,
  onPrevButtonClick,
  onNextButtonClick,
}) => {
  const theme = useTheme()

  return (
    <>
      <ButtonWrapper
        ref={prevArrowRef}
        onClick={onPrevButtonClick}
        direction="prev">
        <PrevButton>
          {prevButtonElement || (
            <ButtonCircle>
              <ButtonSVGIcon
                iconName="gallery-previous"
                size={26}
                color={theme.colors.white}
              />
            </ButtonCircle>
          )}
        </PrevButton>
      </ButtonWrapper>
      <ButtonWrapper
        ref={nextArrowRef}
        onClick={onNextButtonClick}
        direction="next">
        <NextButton>
          {nextButtonElement || (
            <ButtonCircle>
              <ButtonSVGIcon
                iconName="gallery-next"
                size={26}
                color={theme.colors.white}
              />
            </ButtonCircle>
          )}
        </NextButton>
      </ButtonWrapper>
    </>
  )
}

export default Navigation
