import { FunctionComponent } from 'react'
import dynamic from 'next/dynamic'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import config from '@config'
import styled, { css } from 'styled-components'
import usePageMetadata from '@hooks/usePageMetadata'
import useSubscriptionStatus from '@hooks/useSubscriptionStatus'
import TextToSpeechInfo from '@components/TextToSpeech/TextToSpeechInfo'
import useGetPageIdentity from '@hooks/useGetPageIdentity'
import { printCSS } from '@utils/style'

const TextToSpeechPlayer = dynamic(
  () => import('@components/TextToSpeech/TextToSpeechPlayer')
)

const {
  textToSpeech: { nonBlickPlusSubscriberContentId },
} = config

const PlayerWrapper = styled.div<{ isInStorytelling: boolean }>`
  ${({ isInStorytelling }) => css`
    position: relative;
    display: grid;
    grid-template-columns: 1fr 48px;
    margin-top: ${isInStorytelling ? '8px !important' : '0px !important'};
  `}
`

const OuterWraper = styled.div`
  margin-bottom: 8px !important;
  ${printCSS(css`
    display: none;
  `)}
`

const TextToSpeechWidget: FunctionComponent = () => {
  const { id, teaser } = usePageMetadata()
  const { subscriptionStatus } = useSubscriptionStatus()
  const { isStorytelling } = useGetPageIdentity()

  const loadSubscriptionPromotionContent =
    !!teaser?.isPlus && subscriptionStatus !== 'subscribed'

  const playerProps = loadSubscriptionPromotionContent
    ? {
        contentId: nonBlickPlusSubscriberContentId,
      }
    : {
        sourceId: `${id}`,
      }

  return (
    <OuterWraper>
      <PlayerWrapper isInStorytelling={isStorytelling}>
        <TextToSpeechPlayer {...playerProps} />
        <TextToSpeechInfo />
      </PlayerWrapper>
    </OuterWraper>
  )
}

const widget = {
  kind: ['text-to-speech'],
  component: TextToSpeechWidget,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
