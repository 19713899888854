import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import SvgIcon from '@components/SvgIcon'
import { StoryProps } from './types'
import translate from '@i18n'

type StoryContentProps = Pick<
  StoryProps,
  'title' | 'catchword' | 'isPlus' | 'commentCount'
>

const StyledStoryContent = styled.div`
  ${({
    theme: {
      spacing: { spacing12 },
    },
  }) => css`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: ${spacing12};
    overflow: hidden;
    justify-content: center;
  `}
`

const StyledTitle = styled.div`
  ${({
    theme: {
      typography: {
        headings: {
          xsmall: { bundledCSS: xsmallHeadingCSS },
        },
      },
      spacing: { spacing4 },
      color: {
        primary: { primary01 },
      },
      utils: { ellipsis },
    },
  }) => {
    return css`
      ${xsmallHeadingCSS};
      font-style: normal;
      text-align: left;
      margin-top: ${spacing4};
      color: ${primary01};

      ${ellipsis('100%', 2)}
    `
  }}
`

const StyledCatchword = styled.div`
  ${({
    theme: {
      typography: {
        subheads: {
          small1: { bundledCSS: small1SubheadingCSS },
        },
      },
      color: {
        tertiary: { grey800 },
      },
      utils: { ellipsis },
    },
  }) => {
    return css`
      ${small1SubheadingCSS};
      text-align: left;
      color: ${grey800};

      ${ellipsis('100%', 1)}
    `
  }}
`

const CatchwordAndPlusLogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const BlickPlusIcon = styled(SvgIcon)`
  ${({
    theme: {
      spacing: { spacing4 },
    },
  }) => {
    return css`
      margin-right: ${spacing4};
      height: 18px;
    `
  }}
`

const CommentCountWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing4 },
    },
  }) => {
    return css`
      display: flex;
      align-items: center;
      margin-top: ${spacing4};
    `
  }}
`

const CommentCountIcon = styled(SvgIcon)`
  ${({
    theme: {
      spacing: { spacing4 },
    },
  }) => {
    return css`
      margin-right: ${spacing4};
    `
  }}
`

const CommentCountText = styled.span`
  ${({
    theme: {
      typography: {
        eyebrows: {
          medium1: { bundledCSS: eyebrowMedium1CSS },
        },
      },
      color: {
        primary: { primary01 },
      },
    },
  }) => {
    return css`
      ${eyebrowMedium1CSS};
      color: ${primary01};
      text-decoration: none;
    `
  }}
`

const StoryContent: FunctionComponent<StoryContentProps> = ({
  title,
  catchword,
  isPlus,
  commentCount,
}) => (
  <StyledStoryContent>
    <CatchwordAndPlusLogoContainer>
      {isPlus && <BlickPlusIcon iconName="blick-plus-logo" size={18} />}
      {!!catchword && (
        <StyledCatchword
          dangerouslySetInnerHTML={{
            __html: catchword,
          }}
        />
      )}
    </CatchwordAndPlusLogoContainer>
    <StyledTitle
      dangerouslySetInnerHTML={{
        __html: title,
      }}
    />
    {!!commentCount && (
      <CommentCountWrapper>
        <CommentCountIcon size={24} iconName="comment" />
        <CommentCountText>
          {commentCount} {translate('topList.commentCount')}
        </CommentCountText>
      </CommentCountWrapper>
    )}
  </StyledStoryContent>
)

export default StoryContent
