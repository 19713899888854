import { FunctionComponent, SyntheticEvent, useCallback, useState } from 'react'
import translate from '@i18n'
import config from '@config'
import SvgIcon from '@components/SvgIcon'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import styled, { css, useTheme } from 'styled-components'
import SummaryContainer from '@components/Summary/SummaryContainer'
import SummaryContent from '@components/Summary/SummaryContent'
import SummaryDisclaimer from '@components/Summary/SummaryDisclaimer'
import SummaryHeader from '@components/Summary/SummaryHeader'
import usePageMetadata from '@hooks/usePageMetadata'
import useSubscriptionStatus from '@hooks/useSubscriptionStatus'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import useViewportTracking from '@hooks/useViewportTracking'

const {
  articleSummary: { moreInfoLink, feedbackLink },
} = config

interface Props {
  id: string
  kind: string[]
  title: string
  text: string
}

const CollapsedWrapper = styled.button<{
  isExpanded: boolean
  initialHeight: number
}>`
  ${({
    isExpanded,
    initialHeight,
    theme: {
      color: {
        tertiary: { grey100 },
      },
    },
  }) => css`
    align-items: center;
    appearance: none;
    border: 0;
    bottom: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    left: 0;
    padding-bottom: 0 0 4px;
    position: absolute;
    right: 0;
    text-align: center;
    width: 100%;
    z-index: 0;

    &::after {
      background: linear-gradient(0deg, ${grey100} 53%, transparent 100%);
      bottom: 0;
      content: '';
      display: block;
      height: ${initialHeight}px;
      left: 0;
      position: absolute;
      right: 0;
      width: 100%;
      z-index: -1;
    }

    ${isExpanded &&
    css`
      background-color: transparent;

      &::after {
        display: none;
      }
    `}
  `}
`

const DisclaimerContainer = styled.div`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => {
    return css`
      margin-bottom: ${spacing16};
    `
  }}
`

const ArticleSummary: FunctionComponent<Props> = ({ title, text }) => {
  const { teaser } = usePageMetadata()
  const { subscriptionStatus } = useSubscriptionStatus()
  const theme = useTheme()
  const [isExpanded, setIsExpanded] = useState(false)
  const initialHeight = 96
  const isNotSubscribedOnBlickPlus =
    teaser?.isPlus && subscriptionStatus !== 'subscribed'
  const summaryTitle = isNotSubscribedOnBlickPlus
    ? translate('articleSummary.title')
    : title
  const summaryText = isNotSubscribedOnBlickPlus
    ? translate('articleSummary.text')
    : text

  const trackingOnIsCollapsedClick = useCallback<TrackingFnType>(
    () => ({
      event: 'article_summary_click',
    }),
    []
  )
  const widgetImpressionHandler: TrackingFnType = () => ({
    event: 'article_summary_impression',
  })

  const handleIsCollapsedTrack = useTracking(trackingOnIsCollapsedClick)
  const toggleIsExpanded = useCallback(
    (e?: SyntheticEvent) => {
      e?.preventDefault?.()
      if (!isExpanded) {
        handleIsCollapsedTrack()
      }

      setIsExpanded(!isExpanded)
    },
    [handleIsCollapsedTrack, setIsExpanded, isExpanded]
  )

  const trackWidgetImpression = useTracking(widgetImpressionHandler)

  const viewportRef = useViewportTracking({
    track: true,
    onImpression: trackWidgetImpression,
  })

  const trackingOnClick = useCallback<TrackingFnType<{ label: string }>>(
    ({ extraData: { label } }) => ({
      event: 'article_summary_click',
      button_label: label,
    }),
    []
  )
  const handleTrackingOnClick = useTracking(trackingOnClick)

  const disclaimer = {
    title: translate('articleSummary.disclaimerText'),
    links: [
      {
        label: translate('articleSummary.disclaimerMoreInfo'),
        link: moreInfoLink,
        target: '_blank',
        onClickHandler: (label: string) => handleTrackingOnClick({ label }),
      },
      {
        label: translate('articleSummary.disclaimerFeedback'),
        link: feedbackLink,
        target: '_blank',
        onClickHandler: (label: string) => handleTrackingOnClick({ label }),
      },
    ],
  }
  return (
    <div ref={viewportRef}>
      <SummaryContainer isExpanded={isExpanded} height={initialHeight}>
        <SummaryHeader prefixIconName="stars">{summaryTitle}</SummaryHeader>

        {summaryText && <SummaryContent text={summaryText} />}
        {(!isNotSubscribedOnBlickPlus || !teaser?.isPlus) && (
          <DisclaimerContainer>
            <SummaryDisclaimer {...disclaimer} />
          </DisclaimerContainer>
        )}
        <CollapsedWrapper
          aria-label={translate(
            `articleSummary.${isExpanded ? 'collapse' : 'expand'}Content`
          )}
          isExpanded={isExpanded}
          initialHeight={initialHeight}
          onClick={toggleIsExpanded}>
          <SvgIcon
            iconName={isExpanded ? 'chevron-up' : 'chevron-down'}
            size={24}
            color={theme.color.primary.primary01}
          />
        </CollapsedWrapper>
      </SummaryContainer>
    </div>
  )
}

const widget = {
  kind: ['article-summary'],
  component: ArticleSummary,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
