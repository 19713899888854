import usePageMetadata from '@hooks/usePageMetadata'
import { FunctionComponent } from 'react'
import config from '@config'
import { useQueryClient } from '@tanstack/react-query'
import { CueLiveSchemaData } from '@utils/cueLive'
import { dayjs } from '@utils/date'
import SchemaHTMLElement from '@components/SchemaHTMLElement'

const {
  backend: { baseUrl },
} = config

const CueLiveSchema: FunctionComponent = () => {
  const queryClient = useQueryClient()
  const pageMetadata = usePageMetadata()

  const { url, publishedDate, metaTitle, metaDescription, cueLiveIds } =
    pageMetadata

  const cueLiveSchemaData = cueLiveIds?.[0]
    ? queryClient.getQueryData<CueLiveSchemaData>([
        'cue-live',
        cueLiveIds?.[0],
        'schemaOrg',
      ])
    : undefined

  if (!cueLiveSchemaData) {
    return null
  }

  return (
    <SchemaHTMLElement
      scriptKey="cue-live-schema"
      schemaObject={{
        '@type': 'LiveBlogPosting',
        '@id': `${baseUrl}${url}`,
        coverageStartTime: dayjs(publishedDate).format('YYYY-MM-DDTHH:mm:ssZ'),
        headline: metaTitle,
        description: metaDescription,
        liveBlogUpdate: cueLiveSchemaData.entries.map((entry) => ({
          '@type': 'BlogPosting',
          headline: entry.title,
          datePublished: dayjs(entry.publishedAt).format(
            'YYYY-MM-DDTHH:mm:ssZ'
          ),
          articleBody: entry.body,
        })),
      }}
    />
  )
}

export default CueLiveSchema
