import Blink from '@components/Blink'
import SvgIcon from '@components/SvgIcon'
import { FunctionComponent, useCallback } from 'react'
import styled, { css, useTheme } from 'styled-components'
import config from '@config'
import useHeaderSearchBar from '@hooks/useHeaderSearchBar'
import { desktopCSS, mobileCSS, tabletCSS } from '@measures/responsive'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import useIsImmersiveHeaderActive from '@hooks/useIsImmersiveHeaderActive'

const {
  leserreporter: { leserreporterPageUrl },
} = config

const StyledLink = styled(Blink)<{ isLinkVisible: boolean }>`
  ${({
    theme: {
      color: {
        primary: { blickRed },
      },
    },
    isLinkVisible,
  }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    appearance: none;
    text-decoration: none;
    user-select: none;

    width: 48px;
    height: 48px;

    user-select: none;

    transition: width 0.2s ease-in-out;

    ${desktopCSS(css`
      width: 40px;
      height: 40px;
    `)}

    ${isLinkVisible &&
    css`
      ${desktopCSS(css`
        width: 0;
      `)}
    `}

    &:hover {
      svg {
        fill: ${blickRed};
      }
    }
  `}
`

const StyledSvgIcon = styled(SvgIcon)<{ isImmersiveHeaderActive: boolean }>`
  ${({
    theme: {
      color: {
        primary: { primary02: primary02Color },
      },
    },
    isImmersiveHeaderActive,
  }) => css`
    ${isImmersiveHeaderActive &&
    css`
      ${mobileCSS(css`
        fill: ${primary02Color};
      `)}
      ${tabletCSS(css`
        fill: ${primary02Color};
      `)}
    `}
  `}
`

const LeserreporterLink: FunctionComponent = () => {
  const theme = useTheme()
  const isImmersiveHeaderActive = useIsImmersiveHeaderActive()
  const { isSearchBarCollapsed } = useHeaderSearchBar()

  const trackingOnClick = useCallback<TrackingFnType>(
    () => ({
      event: 'leserreporter_lp_click',
      button_label: 'Leserreporter',
    }),
    []
  )

  const handleTrack = useTracking(trackingOnClick)

  return (
    <StyledLink
      isLinkVisible={!isSearchBarCollapsed}
      href={leserreporterPageUrl}
      onClick={handleTrack}
      ariaLabel="Leserreporter">
      <StyledSvgIcon
        iconName="camera"
        size={24}
        color={theme.color.primary.primary01}
        isImmersiveHeaderActive={isImmersiveHeaderActive}
      />
    </StyledLink>
  )
}

export default LeserreporterLink
