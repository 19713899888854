import styled, { css } from 'styled-components'
import SecondaryButton from '@components/Buttons/Secondary'

const CommentingSecondaryButton = styled(SecondaryButton)`
  ${({
    theme: {
      typography: {
        subheads: {
          small1: { bundledCSS: subheadingSmall1CSS },
        },
      },
      color: {
        tertiary: { grey500 },
        primary: { primary01 },
      },
      spacing: { spacing12, spacing16, spacing8 },
    },
  }) => css`
    margin-right: ${spacing8};
    border-color: ${primary01};
    color: ${primary01};
    ${subheadingSmall1CSS};
    text-transform: capitalize;
    padding: ${spacing12} ${spacing16};
    @media (hover: hover) {
      &:hover:not([disabled]) {
        border-color: ${grey500};
        color: ${primary01};
      }
    }
  `}
`

export default CommentingSecondaryButton
