import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { ImageRatios } from './types'
import { desktopCSS, tabletCSS, mobileCSS } from '@measures/responsive'

export interface PlaceholderProps {
  ratios: ImageRatios
  disablePlaceholder?: boolean
  isLoaded: boolean
  children?: ReactNode
  className?: string
}

type StyledPlaceholderProps = Omit<PlaceholderProps, 'children'>

const StyledPlaceholder = styled.div<StyledPlaceholderProps>`
  ${({
    theme: {
      colors: {
        grey: { grey_30 },
      },
    },
    disablePlaceholder,
    ratios,
    isLoaded,
  }) => {
    const [ratioWidthDesktop, ratioHeightDesktop] = ratios['desktop']
      .split('_')
      .map((ratio) => parseInt(ratio, 10))

    const [ratioWidthTablet, ratioHeightTablet] = ratios['tablet']
      .split('_')
      .map((ratio) => parseInt(ratio, 10))

    const [ratioWidthMobile, ratioHeightMobile] = ratios['mobile']
      .split('_')
      .map((ratio) => parseInt(ratio, 10))

    return css`
      position: relative;
      width: 100%;
      height: 0;
      background-color: ${isLoaded ? 'transparent' : grey_30};

      ${!disablePlaceholder &&
      css`
        ${desktopCSS(css`
          padding-bottom: ${(ratioHeightDesktop * 100) / ratioWidthDesktop}%;
        `)}

        ${tabletCSS(css`
          padding-bottom: ${(ratioHeightTablet * 100) / ratioWidthTablet}%;
        `)}

        ${mobileCSS(css`
          padding-bottom: ${(ratioHeightMobile * 100) / ratioWidthMobile}%;
        `)}
      `}
    `
  }}
`

const Placeholder: FunctionComponent<PlaceholderProps> = ({
  ratios,
  isLoaded,
  children,
  disablePlaceholder,
  className,
}) => {
  return (
    <StyledPlaceholder
      ratios={ratios}
      isLoaded={isLoaded}
      disablePlaceholder={disablePlaceholder}
      className={className}>
      {children}
    </StyledPlaceholder>
  )
}

export default Placeholder
