import { mobileCSS } from '@measures/responsive'
import { memo } from 'react'
import styled, { css } from 'styled-components'

const StyledButton = styled.button`
  ${({
    theme: {
      fonts: { goodOT },
      colors: {
        blicktvGrey: { btvGrey_5, btvGrey_55, btvGrey_60 },
      },
    },
  }) => css`
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 0;
    cursor: pointer;

    background: ${btvGrey_60};
    border-radius: 25px;

    font-family: ${goodOT};
    font-weight: normal;
    letter-spacing: 0.441176px;
    padding: 10px 24px 10px 24px;
    font-size: 14px;
    line-height: 20px;

    color: ${btvGrey_5};
    text-transform: uppercase;

    &:hover:not([disabled]) {
      background: ${btvGrey_55};
    }

    & > span {
      font-weight: 500;
    }

    & > svg {
      vertical-align: text-bottom;
      margin-right: 8px;
    }

    ${mobileCSS(css`
      padding: 6px 12px 6px 6px;

      & > svg {
        vertical-align: middle;
        margin-right: 6px;
        margin-bottom: 1px;
      }
    `)}
  `}
`

export default memo(StyledButton)
