import {
  memo,
  useCallback,
  useMemo,
  useRef,
  FunctionComponent,
  MouseEvent,
  MutableRefObject,
} from 'react'
import styled, { css } from 'styled-components'

import { Chapter } from '@widgets/Video/story/types'
import { hasChaptersPlaceholder } from '@widgets/Video/story/utils'

import { desktopCSS, mobileCSS, tabletCSS } from '@measures/responsive'
import Swiper, { SwiperProps, SwiperClass } from '@components/Swiper'

import ChapterCard from './ChapterCard'
import ChapterFiller from './ChapterFiller'
import useViewportType from '@hooks/useViewport/useViewportType'

type DeviceSwiperConfigType = Pick<
  SwiperProps,
  'navigation' | 'spaceBetween' | 'slidesPerGroup'
>

const SwiperWrapper = styled.div``

const ChapterFillerWrapper = styled.div`
  padding: 10px 0;
  display: flex;
`

const SwiperSlide = styled.div`
  scroll-snap-align: start;
  scroll-snap-stop: always;

  ${mobileCSS(css`
    width: 144px;
    height: 153px;

    scroll-margin-left: 16px;
    scroll-margin-right: 16px;

    &:first-child {
      margin-left: 16px;
    }

    &:last-child {
      margin-right: 16px;
    }
  `)}

  ${tabletCSS(css`
    width: 200px;
    height: 200px;

    scroll-margin-left: 24px;
    scroll-margin-right: 24px;

    &:first-child {
      margin-left: 24px;
    }

    &:last-child {
      margin-right: 24px;
    }
  `)}

  ${desktopCSS(css`
    width: 200px;
    height: 200px;

    scroll-margin-left: 24px;
    scroll-margin-right: 24px;

    &:first-child {
      margin-left: 24px;
    }

    &:last-child {
      margin-right: 24px;
    }
  `)}
    

  &.chapter-filler {
    flex: 1;
    margin-right: 24px;

    ${mobileCSS(css`
      margin-right: 16px;
    `)}
  }
`

const commonConfig = {
  centeredSlides: false,
  centeredSlidesBounds: false,
}

const mobileConfig: DeviceSwiperConfigType = {
  ...commonConfig,
  navigation: false,
  spaceBetween: 16,
}

const desktopConfig: DeviceSwiperConfigType = {
  ...commonConfig,
  navigation: true,
  spaceBetween: 24,
  slidesPerGroup: 3,
}

export interface ChapterSwiperProps {
  activeItem?: Chapter
  data: Chapter[]
  swiperRef?: MutableRefObject<SwiperClass | null>
  onItemClick: (
    e: MouseEvent<HTMLDivElement>,
    id: string,
    chapterData: Chapter
  ) => void
  onNavigationChange: () => void
}

const ChapterSwiper: FunctionComponent<ChapterSwiperProps> = ({
  activeItem,
  data,
  onItemClick,
  onNavigationChange,
  swiperRef: externalSwiperRef,
}) => {
  const viewportType = useViewportType()
  const isDesktop = viewportType === 'desktop'
  const slidesPerView = isDesktop ? 4 : 2

  const swiperConfig = isDesktop
    ? {
        ...desktopConfig,
        navigation: desktopConfig.navigation && data?.length > 1,
      }
    : mobileConfig

  const lastSlideIndex = data?.length ? data?.length - 1 : 0
  const initialSlide = lastSlideIndex > slidesPerView - 1 ? lastSlideIndex : 0 // slide to last chapter only if we have more than 3
  const shouldHaveChapterFiller =
    data?.length === 1 && !hasChaptersPlaceholder(data)

  const swiperRef = useRef<SwiperClass | null>(null)

  const formattedSlides = useMemo(
    () =>
      data?.map((chapterData: Chapter) => (
        <SwiperSlide key={chapterData.id}>
          <ChapterCard
            {...chapterData}
            isActive={activeItem && chapterData.id === activeItem?.id}
            onClick={(e: MouseEvent<HTMLDivElement>) =>
              onItemClick(e, chapterData?.id, chapterData)
            }
          />
        </SwiperSlide>
      )) || [],
    [activeItem, data, onItemClick]
  )

  const onInit = useCallback(
    (swiper: any) => {
      swiperRef.current = swiper

      if (externalSwiperRef) {
        externalSwiperRef.current = swiper
      }
    },
    [externalSwiperRef]
  )

  return shouldHaveChapterFiller ? (
    <ChapterFillerWrapper>
      <SwiperSlide className="chapter-filler" key="ChapterFiller">
        <ChapterFiller />
      </SwiperSlide>
      {formattedSlides}
    </ChapterFillerWrapper>
  ) : (
    <SwiperWrapper>
      <Swiper
        {...swiperConfig}
        initialSlide={initialSlide}
        onInit={onInit}
        onNavigationChange={onNavigationChange}
        slides={formattedSlides}
      />
    </SwiperWrapper>
  )
}

const MemoizedChapterSwiper = memo(ChapterSwiper)

MemoizedChapterSwiper.displayName = 'MemoizedChapterSwiper'

export default MemoizedChapterSwiper
