import BlinkingDot from '@components/BlinkingDot'
import SvgIcon from '@components/SvgIcon'
import translate from '@i18n'
import { LIVE_EVENT_DATA_SPORT_TYPES } from '@utils/formatters/common'
import { FunctionComponent } from 'react'
import styled, { css, useTheme } from 'styled-components'
import {
  TransformedData,
  TransformedDataFormula1,
  TransformedDataSki,
} from './utils'

interface SportLabelContentProps {
  sportsData: TransformedData
}

const StyledIcon = styled(SvgIcon)`
  ${({
    theme: {
      spacing: { spacing4 },
      typography: {
        eyebrows: {
          small2: { fontSize, lineHeight },
        },
      },
    },
  }) => css`
    vertical-align: top;
    margin-right: ${spacing4};
    height: calc(${fontSize} * ${lineHeight});
    width: calc(${fontSize} * ${lineHeight});
  `}
`

const StyledBlinkingDot = styled(BlinkingDot)`
  ${({
    theme: {
      spacing: { spacing4 },
      typography: {
        eyebrows: {
          small2: { fontSize, lineHeight },
        },
      },
    },
  }) => css`
    margin-right: ${spacing4};
    height: calc(${fontSize} * ${lineHeight});
  `}
`

const StatusInfo = styled.span`
  ${({
    theme: {
      color: {
        primary: { primary02 },
      },
      spacing: { spacing8 },
    },
  }) => css`
    border-left: 1px solid ${primary02};
    margin-left: ${spacing8};
    padding-left: ${spacing8};
  `}
`

const SportLabelContent: FunctionComponent<SportLabelContentProps> = ({
  sportsData,
}) => {
  const {
    color: {
      primary: { primary02: primary02Color },
    },
  } = useTheme()
  if (!sportsData) {
    return null
  }
  if (sportsData.isLive) {
    const { hasEnded } = sportsData
    const shouldShowStatusInfoForSki =
      sportsData.typeOfSport === LIVE_EVENT_DATA_SPORT_TYPES.SKI &&
      (!hasEnded || sportsData.isBetweenRuns) &&
      !!sportsData.statusInfo
    const shouldShowStatusInfoForFormula1 =
      sportsData.typeOfSport === LIVE_EVENT_DATA_SPORT_TYPES.FORMULA1 &&
      !hasEnded &&
      !!sportsData.statusInfo
    return (
      <>
        <StyledBlinkingDot />
        {translate('liveEvent.live')}
        {(shouldShowStatusInfoForSki || shouldShowStatusInfoForFormula1) && (
          <StatusInfo>
            {
              (sportsData as TransformedDataSki | TransformedDataFormula1)
                .statusInfo
            }
          </StatusInfo>
        )}
      </>
    )
  }
  if (!sportsData.hasEnded) {
    return (
      <>
        <StyledIcon iconName="clock-three" size={12} color={primary02Color} />
        {sportsData.statusTitle}
      </>
    )
  }
  const shouldShowStatusInfoForSki =
    sportsData.typeOfSport === LIVE_EVENT_DATA_SPORT_TYPES.SKI &&
    sportsData.isBetweenRuns &&
    !!sportsData.statusInfo

  const shouldShowIsCancelledSki =
    sportsData.typeOfSport === LIVE_EVENT_DATA_SPORT_TYPES.SKI &&
    sportsData.isCancelled

  const shouldShowIsCancelledTennis =
    sportsData.typeOfSport === LIVE_EVENT_DATA_SPORT_TYPES.TENNIS &&
    sportsData.isCancelled

  const shouldShowIsCancelledFormula1 =
    sportsData.typeOfSport === LIVE_EVENT_DATA_SPORT_TYPES.FORMULA1 &&
    sportsData.isCancelled

  const shouldShowIsCancelled =
    shouldShowIsCancelledSki ||
    shouldShowIsCancelledTennis ||
    shouldShowIsCancelledFormula1
  return (
    <>
      {shouldShowIsCancelled
        ? translate('liveEvent.cancelled')
        : translate('liveEvent.finished')}
      {shouldShowStatusInfoForSki && (
        <StatusInfo>{(sportsData as TransformedDataSki).statusInfo}</StatusInfo>
      )}
    </>
  )
}

export default SportLabelContent
