import config from '@config'
import { PageData, PageMetadata } from '@hooks/usePageMetadata'
import { getCurrentViewportType } from '@measures/responsive'
import { QueryClient } from '@tanstack/react-query'
import { getURLParam } from '@utils/urlParams'

const {
  publication: { publication },
  tracking: { metadataFields },
  urlParams: { clientOnlyURLParams },
} = config

type DataLayerObject = Record<
  string,
  boolean | number | string | string[] | undefined
>

const convertToStrings = (obj: DataLayerObject): DataLayerObject => {
  const newObj: DataLayerObject = {}

  Object.entries(obj).forEach(([key, value]) => {
    if (['boolean', 'number'].includes(typeof value)) {
      newObj[key] = `${value}`
    } else if (Array.isArray(value)) {
      newObj[key] = (value as string[]).toString()
    } else {
      newObj[key] = value
    }
  })

  return newObj
}

// Define default values for data layer object
// undefined for flushing old data layer values
const getDefaultDataLayerObjectValues = (
  queryClient: QueryClient
): Record<string, unknown> => ({
  // Default event parameter values
  articleFeatures: undefined,
  author: undefined,
  brandedBox: undefined,
  button_text: undefined,
  clickUrl: undefined,
  contentId: undefined,
  context: undefined,
  content_origin: undefined,
  contextId: undefined,
  contextType: undefined,
  correlationId: undefined,
  countingPixelTargetDiv: undefined,
  countingPixelUrls: undefined,
  creator: undefined,
  element: undefined,
  event: undefined,
  eventAction: undefined,
  eventCategory: undefined,
  eventLabel: undefined,
  eventValue: 0,
  firstPublishedDate: undefined,
  hasLivescore: undefined,
  hasLivestream: undefined,
  hasLiveticker: undefined,
  hasPlaylist: undefined,
  hostname: undefined,
  id: undefined,
  impressionUrl: undefined,
  isPlus: undefined,
  isTextBox: undefined,
  lastModifiedDate: undefined,
  page: undefined,
  pageRessort: undefined,
  platform_region: publication === 'romandie' ? 'Blick_Romandie' : 'Blick',
  product_id: undefined,
  product_name: undefined,
  requestSource: `blick_web_${getCurrentViewportType() === 'desktop' ? 'desktop' : 'mobile'}`,
  analyticsRessort: undefined,
  slideNumber: undefined,
  subscription_valid_until: undefined,
  tags: undefined,
  target_url: undefined,
  teaserTitle: undefined,
  thirdPartyId: undefined,
  title: undefined,
  urlTitle: undefined,
  userId: undefined,
  user_status: undefined,
  variant: undefined,
  variationId: undefined,
  videoDuration: undefined,
  targetContentType: undefined,
  sub_type: undefined,
  ...clientOnlyURLParams.reduce(
    (acc, urlParam) => {
      if ('includeInDataLayer' in urlParam && urlParam.includeInDataLayer) {
        acc[urlParam.name] = getURLParam(urlParam.name, queryClient)
      }
      return acc
    },
    {} as Record<string, unknown>
  ),
})

// Extracts the title from the page url
// example:
// full page url: /sport/wintersport/7-1-gegen-die-oesis-schweizer-freestyler-rocken-die-wm-id15164109.html
// extracted urlTitle: 7-1-gegen-die-oesis-schweizer-freestyler-rocken-die-wm
// new page url: /sport/wintersport/id15164109.html
const extractUrlTitleFromPage = (url?: string) => {
  if (url && url !== '' && url.includes('.html')) {
    const lastSegmentIndex = url.lastIndexOf('/') + 1
    const lastDashIndex = url.lastIndexOf('-') + 1
    const titleSegment = url.substring(lastSegmentIndex, lastDashIndex)

    return {
      urlTitle:
        parseInt(titleSegment, 10) < 100
          ? titleSegment.substring(0, titleSegment.length - 1) // remove the last dash as it doesn't belong to the title
          : titleSegment.substring(0, 100), // Firebase doesn't allow event parameters with more than 100 characters
      page: url.replace(titleSegment, ''),
    }
  }
}

const getPageParameters = (
  queryClient: QueryClient
): Record<string, unknown> => {
  const pageMetadata = queryClient.getQueryData<PageData>(['page'])
    ?.metadata as PageMetadata

  const {
    url = undefined,
    title = undefined,
    context = undefined,
    contextId = undefined,
    thirdPartyId = undefined,
    hasPlaylist = false,
    contentOrigin = undefined,
    primaryUserNeed = undefined,
  } = pageMetadata

  // Return the event object
  return {
    content_origin: contentOrigin,
    context,
    contextId,
    page: url,
    thirdPartyId,
    title,
    targetContentType: pageMetadata?.teaser?.targetContentType,
    sub_type: pageMetadata?.targetContentSubType,
    isPlus: !!pageMetadata.teaser?.isPlus,
    // Only used for articles
    // Extract url title from page string because sometimes it's too long (> 100 chars)
    ...(context === 'article' && extractUrlTitleFromPage(pageMetadata?.url)),
    hasPlaylist,
    user_need: primaryUserNeed,
  }
}

const getTrackingMetadata = (
  queryClient: QueryClient
): Record<string, unknown> => {
  const pageMetadata = queryClient.getQueryData<PageData>(['page'])
    ?.metadata as PageMetadata

  if (typeof pageMetadata === 'undefined') {
    return {}
  }
  const adjustedMetadataObj = { ...pageMetadata }

  if (adjustedMetadataObj.hasOwnProperty('ressort')) {
    adjustedMetadataObj.analyticsRessort = adjustedMetadataObj.ressort
    delete adjustedMetadataObj.ressort
  }

  return Object.keys(pageMetadata).reduce<{ [key: string]: any }>(
    (acc, key) => {
      if (metadataFields.includes(key as (typeof metadataFields)[number])) {
        if (!adjustedMetadataObj[key]) {
          // To flush the data layer we have to set the not existing ones to undefined
          acc[key] = undefined
        } else {
          acc[key] = adjustedMetadataObj[key]

          // Firebase doesn't allow arrays so we have to call toString
          // This is only a safeguard and can be changed, by changing the backend value
          // Maybe we can remove that in the future
          if (acc[key] instanceof Array) {
            acc[key] = acc[key].toString()
          }
        }
      }
      return acc
    },
    {}
  )
}

const stripHtml = (html = '') => html.replace(/(<([^>]+)>)/gi, '')

export {
  convertToStrings,
  getDefaultDataLayerObjectValues,
  getPageParameters,
  getTrackingMetadata,
  stripHtml,
}
