import { useCallback } from 'react'

import config from '@config'
import useTracking, { TrackingFnType } from '@hooks/useTracking'

import {
  doParseStringifiedData,
  enableSubscriptionAutoRenewal,
  getTrackingCheckoutParams,
  getTrackingTemplateParams,
  setExperienceCookie,
  setTrackingCheckoutParams,
} from './utils'
import useAuthentication from '@hooks/useAuthentication'
import {
  LoginFromPiano,
  NonSiteActionFn,
  OnExperienceExecuteParams,
  OnShowOfferParams,
  OnCheckoutCompleteFn,
  PianoEvents,
  PianoTrackingEvent,
  SubmitPaymentParams,
  OnCheckoutCloseParams,
  PianoCheckoutParams,
  OnCheckoutSelectTermParams,
} from './types'
import { useQueryClient } from '@tanstack/react-query'
import { User } from '@sentry/types'
import { captureExceptionWithUser } from '@utils/sentry'

const {
  auth: { ssoSourceSuffix },
  piano: { blickPlusOfferCardName, regwallTemplateCardName, applicationId },
  subscriptions: { conversionUrl, footerBannerClassname },
} = config

const usePianoEvents = (): PianoEvents => {
  const queryClient = useQueryClient()

  const { loginWithSource } = useAuthentication()

  const trackPianoEvent = useCallback<TrackingFnType<PianoTrackingEvent>>(
    ({ extraData }) => {
      const { type, params } = extraData

      const pianoTrackingTemplateNameParams = getTrackingTemplateParams(
        params?.experienceActionId
      )

      return type
        ? {
            event: type,
            eventCategory: params?.eventCategory ?? 'piano',
            eventAction: params?.eventAction ?? type,
            piano_offer_id: params?.offerId,
            piano_template_id: params?.templateId ?? 'MAIN',
            piano_template_variant_id: params?.templateVariantId ?? 'MAIN',
            piano_experience_id: params?.experienceId,
            piano_term_id: params?.termId,
            piano_term_name: params?.termName,
            piano_term_conversion_id: params?.termConversionId,
            price: params?.price,
            currency: params?.currency,
            transaction_id: params?.transactionId,
            coupon: params?.coupon,
            button_text: params?.buttontext,
            element: params?.clickElement,
            target_url: params?.targeturl,
            element_status: params?.state,
            ...pianoTrackingTemplateNameParams,
          }
        : undefined
    },
    []
  )

  const trackingFunction = useTracking<PianoTrackingEvent>(trackPianoEvent)

  const doTrackPianoEvent = useCallback(
    (trackingEvent: PianoTrackingEvent) => {
      if (trackingEvent?.type) {
        trackingFunction(trackingEvent)
      }
    },
    [trackingFunction]
  )

  //TODO: Special Polaris Beta Banner tracking. Remove after beta phase!
  const trackPianoBetaEvent = useCallback<TrackingFnType<PianoTrackingEvent>>(
    ({ extraData }) => {
      const { type, params } = extraData

      return type
        ? {
            event: type,
            eventCategory: 'piano',
            eventAction: type,
            piano_template_id: params?.templateId,
            piano_template_variant_id: params?.templateVariantId,
          }
        : undefined
    },
    []
  )

  //TODO: Special Polaris Beta Banner tracking. Remove after beta phase!
  const handleTrackPianoBeta = useTracking(trackPianoBetaEvent)

  const loginFromPiano: LoginFromPiano = (
    params,
    loginCase,
    isCheckout = false
  ) => {
    // Only set a cookie, if it's a click that happened on a startCheckout button in the Piano template
    // Otherwise an offer could be shown for Logins, where we don't want to show the user an offer
    if (isCheckout) {
      setExperienceCookie(params)
    }

    const { offerId, templateId, templateVariantId, experienceId } = params

    // Gets the source from pianos custom variable, set on every page change
    // and appends the piano specific event data
    const source = `${
      window?.tp?.customVariables?.sourceSSO
    }_${offerId}_${templateId}${
      templateVariantId ? `_${templateVariantId}` : '_MAIN' // Tracking team requested to hardcode a value in case variant is undefined
    }_${experienceId}_${ssoSourceSuffix}`

    loginWithSource({
      source,
      loginCase: loginCase || 'email_only',
    })
  }

  const onLoginRequired = (params: any) => {
    loginFromPiano(params, 'blickplus_piano', true)
  }

  const onCheckoutCustom = (event: any) => {
    const { eventName } = event
    const { params, ...rest } = event?.params
    const parsedParams = doParseStringifiedData(params)
    const userData = queryClient.getQueryData<User>(['user'])

    const mergedParams = {
      ...(parsedParams ?? {}), // data about Piano experience metadata
      ...rest, // external events in Piano template
      clickElement: rest.clickelement,
    }

    if (eventName.startsWith('offer-subscribe-')) {
      const termId = eventName.split('offer-subscribe-')[1]

      const previousPianoCheckoutParams = getTrackingCheckoutParams(
        queryClient
      ) as PianoCheckoutParams

      setTrackingCheckoutParams(queryClient, {
        ...previousPianoCheckoutParams,
        ...mergedParams,
        termName: rest.termname,
        termId,
        experienceActionId: mergedParams.experienceActionId,
      })

      doTrackPianoEvent({
        type: 'piano_click_offer',
        params: {
          ...previousPianoCheckoutParams,
          ...mergedParams,
          clickElement: rest.clickelement,
          buttontext: rest.buttontext,
          termName: rest.termname,
          termId,
          experienceActionId: mergedParams.experienceActionId,
        },
      })
    }

    switch (eventName) {
      case 'piano_click':
        if (rest.clickelement === 'login' && !userData) {
          loginFromPiano(parsedParams, rest.logincase, false)
        }
        doTrackPianoEvent({
          type: eventName,
          params: mergedParams,
        })

        break

      case 'adblock_interaction':
        doTrackPianoEvent({
          type: eventName,
          params: { ...mergedParams, eventCategory: 'adblock' },
        })

        break

      case 'piano_click_close':
        doTrackPianoEvent({
          type: eventName,
          params: mergedParams,
        })

        break

      case 'enable_autorenewal':
        enableSubscriptionAutoRenewal(queryClient, mergedParams)

        doTrackPianoEvent({
          type: 'piano_click',
          params: mergedParams,
        })

        break

      //TODO: Special Polaris Beta Banner tracking. Remove after beta phase!
      case 'beta_banner_click':
      case 'beta_banner_back_click':
        handleTrackPianoBeta({
          type: 'beta_banner_click',
          params: mergedParams,
        })

        break
    }
  }

  const setBlickPlusOfferShown = useCallback(() => {
    queryClient.setQueryData(['isBlickPlusOfferShown'], true)
    queryClient.invalidateQueries({
      queryKey: ['isBlickPlusOfferShown'],
      exact: true,
    })
  }, [queryClient])

  const onShowOffer = (params: OnShowOfferParams) => {
    const { templateVariantId, offerId, templateId, experienceId } = params

    // Save some params in react-query cache as some callbacks don't include them in the params
    queryClient.setQueryData(['pianoCheckoutParams'], {
      templateVariantId,
      offerId,
      templateId,
      experienceId,
    })
    queryClient.invalidateQueries({
      queryKey: ['pianoCheckoutParams'],
      exact: true,
    })
    handleTracking(params)
  }

  const handleTracking = (params: OnShowOfferParams) => {
    const { displayMode } = params
    if (
      displayMode === 'modal' ||
      params.containerSelector === `.${footerBannerClassname}`
    ) {
      doTrackPianoEvent({ type: 'piano_show', params })
    } else {
      // Impression tracking for templates that should not immediately be tracked
      const pianoTemplateParams = queryClient.getQueryData<OnShowOfferParams[]>(
        ['pianoTemplateParams']
      )

      // As there can be multiple templates on one page and the impression tracking will be called
      // when the template comes into the viewport, we need to save the template params in a template params array
      const pianoTemplateParamsArray = pianoTemplateParams || []
      pianoTemplateParamsArray.push(params)

      queryClient.setQueryData(
        ['pianoTemplateParams'],
        pianoTemplateParamsArray
      )
      queryClient.invalidateQueries({
        queryKey: ['pianoTemplateParams'],
        exact: true,
      })
    }
  }

  const onShowTemplate = (params: OnShowOfferParams) => {
    //TODO: Special Polaris Beta Banner tracking. Remove after beta phase!
    if (params.experienceActionId.includes('showBetaBanner')) {
      handleTrackPianoBeta({ type: 'beta_banner_impression', params })
      return
    }

    handleTracking(params)
  }

  const onExperienceExecute = (params: OnExperienceExecuteParams) => {
    const experienceEvents = params.result.events

    const isBlickPlusOfferShown = experienceEvents.some(
      (experienceEvent) =>
        experienceEvent.eventType === 'showOffer' &&
        experienceEvent.eventModuleParams?.moduleName === blickPlusOfferCardName
    )
    const isRegwallTemplateShown = experienceEvents.some(
      (experienceEvent) =>
        experienceEvent.eventType === 'showTemplate' &&
        experienceEvent.eventModuleParams?.moduleName ===
          regwallTemplateCardName
    )

    if (isBlickPlusOfferShown || isRegwallTemplateShown) {
      setBlickPlusOfferShown()
    }
  }

  const onCustomEventTracking: NonSiteActionFn = (
    _eventParams,
    cardParams,
    contextParams
  ) => {
    //@ts-expect-error
    const cardNameChunks = cardParams.moduleName.split(/_(.*)/s)
    if (!cardNameChunks?.length) {
      console.error(
        `[Piano tracking] Invalid event name for nonSite action: ${cardParams.moduleName}`
      )
      return
    }
    // Event name is whatever is after the first "_" in the card name
    const [eventPrefix, eventName] = cardNameChunks
    if (eventPrefix === 'track' && eventName) {
      const { experienceId } = contextParams
      doTrackPianoEvent({ type: eventName, params: { experienceId } })
    }
  }

  const onSubmitPayment = (params: SubmitPaymentParams) => {
    const {
      term: { chargeCurrency, chargeAmount, billingPlanTable },
    } = params

    const previousPianoCheckoutParams = getTrackingCheckoutParams(
      queryClient
    ) as PianoCheckoutParams

    const trackingRelevantParams = {
      ...previousPianoCheckoutParams,
      eventCategory: 'purchase',
      eventAction: 'transaction',
      clickElement: 'checkout',
      variant: billingPlanTable[0]?.period,
      price: chargeAmount,
      currency: chargeCurrency,
    }

    setTrackingCheckoutParams(queryClient, {
      ...previousPianoCheckoutParams,
      variant: billingPlanTable[0]?.period,
      price: chargeAmount,
      currency: chargeCurrency,
    })

    doTrackPianoEvent({
      type: 'purchase_attempt',
      params: trackingRelevantParams,
    })
  }

  const onCheckoutComplete: OnCheckoutCompleteFn = async (conversionObj) => {
    const { paymentId, termConversionId } = conversionObj

    const previousPianoCheckoutParams = getTrackingCheckoutParams(queryClient)

    doTrackPianoEvent({
      type: 'purchase_success',
      params: {
        ...previousPianoCheckoutParams,
        eventCategory: 'purchase',
        eventAction: 'transaction',
        clickElement: 'checkout',
        transactionId: paymentId,
        termConversionId,
      },
    })

    const payload = { aid: applicationId, ...conversionObj }
    const response = await fetch(conversionUrl, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })

    if (!response.ok) {
      captureExceptionWithUser(
        `[SUB. CHECKER ERROR] POST conversion returned an error: ${response.statusText}`,
        queryClient
      )
    }
  }

  const onCheckoutClose = (event: OnCheckoutCloseParams) => {
    const pianoCheckoutParams = getTrackingCheckoutParams(
      queryClient
    ) as PianoCheckoutParams

    // Piano sends the checkout close event also on template events (e.g. footer banner)
    // We only want to track the checkout_close if it's really from the checkout template
    // The termId is only available after a user started the checkout
    if (pianoCheckoutParams.termId) {
      doTrackPianoEvent({
        type: 'checkout_close',
        params: {
          ...pianoCheckoutParams,
          state: event.state,
        },
      })

      // Reset checkout parameters when checkout is closed
      setTrackingCheckoutParams(queryClient, null)
    }

    // Default behavior is to refresh the page on successful checkout
    if (event.state === 'checkoutCompleted') {
      location?.reload?.()
    }
  }

  const onCheckoutSelectTerm = (termDetails: OnCheckoutSelectTermParams) => {
    const { termId, termName } = termDetails

    const previousPianoCheckoutParams = getTrackingCheckoutParams(
      queryClient
    ) as PianoCheckoutParams

    const trackingRelevantParams = {
      ...previousPianoCheckoutParams,
      eventCategory: 'overlay',
      eventAction: 'start',
      clickElement: 'checkout',
      termId,
      termName,
      experienceActionId: 'plusOffer',
    }
    // Add termId to the cached checkout params
    setTrackingCheckoutParams(queryClient, {
      ...previousPianoCheckoutParams,
      clickElement: 'checkout',
      termId,
      termName,
      experienceActionId: 'plusOffer',
    })

    doTrackPianoEvent({
      type: 'start_checkout',
      params: trackingRelevantParams,
    })
  }

  return {
    onCheckoutClose,
    onCheckoutCustom,
    onShowOffer,
    onShowTemplate,
    onCustomEventTracking,
    onLoginRequired,
    onSubmitPayment,
    onExperienceExecute,
    onCheckoutComplete,
    onCheckoutSelectTerm,
  }
}

export default usePianoEvents
