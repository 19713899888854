import { FunctionComponent } from 'react'
import Head from 'next/head'
import config from '@config'
import usePageMetadata from '@hooks/usePageMetadata'

import android192_192 from '@assets/icons/android/android-chrome-192x192.png'
import apple180_180 from '@assets/icons/apple/apple-touch-icon-180x180.png'

const {
  backend: { baseUrl },
} = config

const HTMLLinks: FunctionComponent = () => {
  const { url, canonical } = usePageMetadata()

  return (
    <Head>
      <link
        key="canonical"
        rel="canonical"
        href={canonical || `${baseUrl}${url}`}
      />
      <link key="apple-touch-icon" rel="apple-touch-icon" href={apple180_180} />
      <link
        key="icon"
        rel="icon"
        type="image/png"
        sizes="192x192"
        href={android192_192}
      />
    </Head>
  )
}

export default HTMLLinks
