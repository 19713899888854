import { Fragment, FunctionComponent } from 'react'
import styled, { css, useTheme } from 'styled-components'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { Link } from '@cook-types/cook'
import Item from './Item'
import usePageMetadata from '@hooks/usePageMetadata'
import { desktopCSS, mobileCSS, tabletCSS } from '@measures/responsive'
import SvgIcon from '@components/SvgIcon'

export interface BreadcrumbItem {
  title: string
  uniqueName?: string
  link?: Link
  isLast: boolean
}

interface BreadcrumbProps {
  children: BreadcrumbItem[]
  options: {
    pageType: string
  }
}

interface ScrollWrapperProps {
  isDossierPage: boolean
}

const ScrollWrapper = styled.div<ScrollWrapperProps>`
  ${({
    theme: {
      color: {
        tertiary: { grey100 },
      },
    },
    isDossierPage,
  }) => css`
    background-color: ${grey100};
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 12px 40px;
    box-sizing: border-box;
    text-align: center;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    ${mobileCSS(css`
      padding: 14px 16px;
      background-color: transparent;
    `)}

    ${!isDossierPage &&
    css`
      ${desktopCSS(css`
        margin-right: 20px;
        margin-left: 20px;
      `)};
      ${tabletCSS(css`
        margin-right: 68px;
        margin-left: 68px;
      `)};
      ${mobileCSS(css`
        margin-right: 16px;
        margin-left: 16px;
      `)};
    `}
  `}
`

const StyledBreadcrumbContainer = styled.nav`
  ${({
    theme: {
      color: {
        tertiary: { grey900 },
      },
      typography: {
        subheads: {
          small2: { bundledCSS: small2SubheadCSS },
        },
      },
      spacing: { spacing4, spacing8 },
    },
  }) => css`
    ${small2SubheadCSS};
    color: ${grey900};
    grid-column: span 6;
    display: flex;
    flex: 1;
    flex-direction: row;
    white-space: nowrap;
    text-align: center;
    gap: ${spacing4};

    ${desktopCSS(css`
      gap: ${spacing8};
    `)}
  `}
`

const Breadcrumb: FunctionComponent<BreadcrumbProps> = ({ children }) => {
  const theme = useTheme()
  const lastIndex = children.length
  const { context } = usePageMetadata()
  const isDossierPage = context === 'dossier'

  const Separator: FunctionComponent<{ index: number }> = ({ index }) =>
    ![0, lastIndex].includes(index) ? (
      <SvgIcon
        iconName="chevron-right"
        color={theme.color.primary.primary01}
        size={16}
      />
    ) : null

  return (
    <ScrollWrapper isDossierPage={isDossierPage}>
      <StyledBreadcrumbContainer>
        {children.map((crumb, index, crumbsArray) => (
          <Fragment key={index}>
            <Separator index={index} />
            <Item {...crumb} isLast={index === crumbsArray.length - 1} />
          </Fragment>
        ))}
      </StyledBreadcrumbContainer>
    </ScrollWrapper>
  )
}

const widget = {
  kind: ['widget', 'breadcrumb'],
  component: Breadcrumb,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
