import dynamic from 'next/dynamic'
import Footer from '../Footer'
import { Link } from '@cook-types/cook'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { SwisslottoData } from '../types'
import Header from '../Header'
import Numbers from '../Numbers'

interface SwisslottoProps {
  data: SwisslottoData
  link: Link
}

const Table = dynamic(() => import('../Table'))

const StyledSwisslotto = styled.div`
  ${({
    theme: {
      color: {
        tertiary: { grey100 },
      },
      spacing: { spacing20 },
    },
  }) => css`
    padding: ${spacing20} ${spacing20} 15px ${spacing20};
    background-color: ${grey100};
  `}
`

const Swisslotto: FunctionComponent<SwisslottoProps> = ({ data, link }) => {
  const {
    numbers,
    luckynumber,
    'replay-number': replayNumber,
    'formatted-jackpot': jackpot,
    'next-draw-date': nextDate,
    'draw-date': drawDate,
  } = data
  return (
    <StyledSwisslotto>
      <Header title="Swiss Lotto" drawDate={drawDate} id="swisslotto" />
      <Numbers
        numbers={numbers}
        luckyNumber={luckynumber}
        replayNumber={replayNumber}
      />
      <Table lottoData={data} id="swisslotto" />
      <Footer link={link} jackpot={jackpot} nextDate={nextDate} />
    </StyledSwisslotto>
  )
}

export default Swisslotto
