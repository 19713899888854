import { FunctionComponent, useMemo } from 'react'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { JSONWidgetType as TeaserToiJSONWidgetType } from '@widgets/TeaserToi'
import { JSONWidgetType as TeaserToiCommercialJSONWidgetType } from '@widgets/TeaserToiCommercial'
import { JSONWidgetType as TeaserStandardHybridJSONWidgetType } from '@widgets/TeaserStandardHybrid'
import { JSONWidgetType as TeaserStandardHorizontalJSONWidgetType } from '@widgets/TeaserStandardHorizontal'
import SectionHeader from '@components/SectionHeader'
import JSONRenderer from '@components/JSONRenderer'
import { JSONWidgetType as AdRingierJSONWidgetType } from '@widgets/AdRingier'
import { Image, Link } from '@utils/cook/types'
import { JSONWidgetType as RelatedMatchesJSONWidgetType } from '@widgets/RelatedMatches'
import { Logo } from '@cook-types/cook'
import UltraModuleWrapper from '@components/UltraModule/Wrapper'
import SportsPartner from '@components/UltraModule/SportsPartner'
import SideAd from '@components/UltraModule/SideAd'
import Separator from '@components/UltraModule/Separator'
import MobileOnlySeparator from '@components/UltraModule/MobileOnlySeparator'
import MainFlowWrapper from '@components/UltraModule/MainFlowWrapper'
import SideFlowWrapper from '@components/UltraModule/SideFlowWrapper'
import RelatedMatchesMobileAndTabletOnly from '@components/UltraModule/RelatedMatchesMobileAndTabletOnly'

export interface UltraModuleRelatedItemsProps {
  relatedItems: TeaserStandardHorizontalJSONWidgetType[]
  relatedItemsTitle?: string
}

export interface UltraModuleNoRelatedItemsProps {
  relatedItems?: undefined
  relatedMatches?: RelatedMatchesJSONWidgetType
}

export type UltraModuleMaybeRelatedItemsProps =
  | UltraModuleNoRelatedItemsProps
  | UltraModuleRelatedItemsProps

export interface UltraModuleCommonProps {
  mainItem: TeaserToiJSONWidgetType | TeaserToiCommercialJSONWidgetType
  unrelatedItems: TeaserStandardHybridJSONWidgetType[]
  secondaryItem?: TeaserToiJSONWidgetType | TeaserToiCommercialJSONWidgetType
  accentColor: string
  title?: string
  link?: Link
  logo?: Logo
  sideAd: AdRingierJSONWidgetType
  inlineAd?: AdRingierJSONWidgetType
  ctaText?: string
  partner?: {
    logo: Image<'free'>
    title: string
    url: string
  }
}

export type UltraModuleAPIProps = UltraModuleCommonProps &
  UltraModuleMaybeRelatedItemsProps

export interface SideFlowWrapperProps {
  hasRelatedItems: boolean
  numberOfNonAdItemsInSideFlow: number
}

const UltraModule: FunctionComponent<UltraModuleAPIProps> = (props) => {
  const {
    mainItem,
    secondaryItem,
    accentColor,
    title,
    link,
    inlineAd,
    sideAd,
    unrelatedItems,
    relatedItems,
    partner,
    logo,
    ctaText,
  } = props

  const hasRelatedItems = !!relatedItems

  const relatedMatches = !hasRelatedItems ? props.relatedMatches : undefined

  const showUnrelatedItemsInSidebar = !hasRelatedItems && !relatedMatches

  const hasSecondaryItem = !!secondaryItem

  const hasInlineAd = !!inlineAd

  const hasPartner = !!partner

  const numberOfNonAdItemsInSideFlow =
    (hasRelatedItems ? relatedItems.length : 0) +
    (hasRelatedItems && props.relatedItemsTitle ? 1 : 0) +
    (relatedMatches ? 1 : 0) +
    (showUnrelatedItemsInSidebar ? 3 : 0)

  const relatedItemsMobileAndTabletOnly = useMemo(
    () =>
      hasRelatedItems
        ? relatedItems.map((relatedItem) => ({
            ...relatedItem,
            kind: ['teaser-standard-hybrid'],
            render: 'horizontal',
          }))
        : [],
    [hasRelatedItems, relatedItems]
  )

  return (
    <UltraModuleWrapper>
      {title && (
        <SectionHeader
          accentColor={accentColor}
          title={title}
          link={link}
          logo={logo}
          ctaText={ctaText}
        />
      )}
      <MainFlowWrapper hasPartner={hasPartner}>
        <JSONRenderer>{mainItem}</JSONRenderer>
        <SportsPartner partner={partner} />
        <RelatedMatchesMobileAndTabletOnly>
          {relatedMatches}
        </RelatedMatchesMobileAndTabletOnly>
        {hasRelatedItems && (
          <JSONRenderer>{relatedItemsMobileAndTabletOnly}</JSONRenderer>
        )}
        {hasInlineAd ? (
          <JSONRenderer>{inlineAd}</JSONRenderer>
        ) : hasRelatedItems ? (
          <MobileOnlySeparator />
        ) : null}
        {showUnrelatedItemsInSidebar && (
          <JSONRenderer>
            {[
              { ...unrelatedItems[2], render: 'vertical' },
              { ...unrelatedItems[3], render: 'vertical' },
            ]}
          </JSONRenderer>
        )}
        {!showUnrelatedItemsInSidebar && (
          <JSONRenderer>
            {[
              { ...unrelatedItems[0], render: 'vertical' },
              { ...unrelatedItems[1], render: 'vertical' },
            ]}
          </JSONRenderer>
        )}
        <JSONRenderer>
          {{ ...unrelatedItems[0], render: 'horizontal' }}
        </JSONRenderer>
        <MobileOnlySeparator />
        <JSONRenderer>
          {{ ...unrelatedItems[1], render: 'horizontal' }}
        </JSONRenderer>
        {unrelatedItems.length > 2 && (
          <>
            <MobileOnlySeparator />
            <JSONRenderer>
              {{ ...unrelatedItems[2], render: 'horizontal' }}
            </JSONRenderer>
            <MobileOnlySeparator />
            <JSONRenderer>
              {{ ...unrelatedItems[3], render: 'horizontal' }}
            </JSONRenderer>
          </>
        )}
        {hasSecondaryItem && <JSONRenderer>{secondaryItem}</JSONRenderer>}
        {!showUnrelatedItemsInSidebar && unrelatedItems.length > 2 && (
          <JSONRenderer>
            {[
              { ...unrelatedItems[2], render: 'vertical' },
              { ...unrelatedItems[3], render: 'vertical' },
            ]}
          </JSONRenderer>
        )}
      </MainFlowWrapper>
      <SideFlowWrapper
        hasRelatedItems={hasRelatedItems}
        numberOfNonAdItemsInSideFlow={numberOfNonAdItemsInSideFlow}>
        {hasRelatedItems && !!props.relatedItemsTitle && (
          <SectionHeader
            title={props.relatedItemsTitle}
            isSubheader={true}
            accentColor={accentColor}
          />
        )}
        {hasRelatedItems && <JSONRenderer>{relatedItems}</JSONRenderer>}
        {!!relatedMatches && <JSONRenderer>{relatedMatches}</JSONRenderer>}
        {showUnrelatedItemsInSidebar && (
          <>
            <JSONRenderer>
              {{ ...unrelatedItems[0], render: 'vertical' }}
            </JSONRenderer>
            <Separator />
            <JSONRenderer>
              {{ ...unrelatedItems[1], render: 'vertical' }}
            </JSONRenderer>
          </>
        )}
        <SideAd hasRelatedItems={hasRelatedItems}>
          <JSONRenderer>{sideAd}</JSONRenderer>
        </SideAd>
      </SideFlowWrapper>
    </UltraModuleWrapper>
  )
}

const widget = {
  kind: ['ultra-module'],
  component: UltraModule,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
