import Blink from '@components/Blink'
import { Link } from '@cook-types/cook'
import { desktopCSS, mobileCSS, tabletCSS } from '@measures/responsive'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import useTrackingUtils from '../useTrackingUtils'
import useIsImmersiveHeaderActive from '@hooks/useIsImmersiveHeaderActive'

interface SimpleLinkProps {
  link: Link
  children: string
}

const StyledBlink = styled(Blink)<{ isImmersiveHeaderActive: boolean }>`
  ${({
    theme: {
      typography: {
        labels: {
          label2: { bundledCSS: label2LabelsCSS },
        },
      },
      color: {
        primary: {
          primary01: primary01Color,
          primary02: primary02Color,
          blickRed: blickRedColor,
        },
      },
      spacing: { spacing24, spacing16 },
    },
    isImmersiveHeaderActive,
  }) => css`
    ${label2LabelsCSS};
    color: ${primary01Color};

    appearance: none;
    text-decoration: none;

    white-space: nowrap;
    position: relative;
    margin-right: ${spacing16};

    ${desktopCSS(css`
      margin-right: ${spacing24};
    `)}

    ${isImmersiveHeaderActive &&
    css`
      ${mobileCSS(css`
        color: ${primary02Color};
      `)}
      ${tabletCSS(css`
        color: ${primary02Color};
      `)}
    `}

    ${desktopCSS(css`
      &:hover:after {
        transform: scaleX(1);
        transform-origin: bottom left;
        background-color: ${blickRedColor};
      }

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        bottom: -4px;
        left: 0;
        transform-origin: bottom left;
        transition: transform 0.28s ease-out;
      }
    `)}
  `}
`

const SimpleLink: FunctionComponent<SimpleLinkProps> = ({ link, children }) => {
  const isImmersiveHeaderActive = useIsImmersiveHeaderActive()
  const { navbarClickTrackingFn } = useTrackingUtils({
    title: children,
    url: link.href,
  })

  return (
    <StyledBlink
      {...link}
      onClick={navbarClickTrackingFn}
      isImmersiveHeaderActive={isImmersiveHeaderActive}>
      {children}
    </StyledBlink>
  )
}

export default SimpleLink
