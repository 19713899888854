import SvgIcon from '@components/SvgIcon'
import { desktopCSS } from '@measures/responsive'
import { ChangeEvent, FunctionComponent, ReactNode } from 'react'
import styled, { css, useTheme } from 'styled-components'

interface CheckboxProps {
  name: string
  checked: boolean
  disabled?: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  className?: string
  children?: ReactNode
}

const Container = styled.div`
  ${({
    theme: {
      spacing: { spacing24, spacing56 },
    },
  }) => css`
    position: relative;
    ${desktopCSS(css`
      height: ${spacing24};
    `)}
    height: ${spacing56};
  `}
`

const StyledLabel = styled.label`
  cursor: pointer;
`

interface HiddenInputProps {
  disabled: CheckboxProps['disabled']
}

const HiddenCheckbox = styled.input<HiddenInputProps>`
  ${({ disabled }) => css`
    appearance: none;
    outline: 0;
    display: inline-block;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    cursor: ${disabled ? 'default' : 'pointer'};
  `}
`

const CheckboxWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing24, spacing8 },
      typography: {
        bodycopy: {
          small2: { bundledCSS: bodyCopySmall2CSS },
        },
      },
    },
  }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: grid;
    align-items: center;
    justify-items: start;
    grid-template-columns: ${spacing24} auto;
    grid-gap: ${spacing8};
    ${bodyCopySmall2CSS};
  `}
`
interface StyledCheckboxProps {
  checked: CheckboxProps['checked']
}

const StyledCheckbox = styled.div<StyledCheckboxProps>`
  ${({
    checked,
    theme: {
      color: {
        primary: {
          primary01: primary01Color,
          primary02: primary02color,
          blickRed: blickRedcolor,
        },
      },
      spacing: { spacing24, spacing4 },
    },
  }) => css`
    width: ${spacing24};
    height: ${spacing24};
    border-radius: ${spacing4};
    border: 2px solid ${checked ? blickRedcolor : primary01Color};
    box-sizing: border-box;
    display: grid;
    align-items: center;
    justify-items: center;
    background-color: ${checked ? blickRedcolor : primary02color};
  `}
`

const ControlledCheckbox: FunctionComponent<CheckboxProps> = ({
  name,
  checked,
  disabled,
  onChange,
  className,
  children,
}) => {
  const theme = useTheme()

  return (
    <Container className={className}>
      <StyledLabel>
        <HiddenCheckbox
          disabled={disabled}
          type="checkbox"
          id={name}
          name={name}
          checked={checked}
          onChange={onChange}
        />
        <CheckboxWrapper>
          <StyledCheckbox checked={checked}>
            {checked && (
              <SvgIcon
                iconName="tick"
                size="16px"
                color={theme.color.primary.primary02}
              />
            )}
          </StyledCheckbox>
          <span>{children}</span>
        </CheckboxWrapper>
      </StyledLabel>
    </Container>
  )
}

export default ControlledCheckbox
