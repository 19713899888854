import JSONRenderer from '@components/JSONRenderer'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import { mobileCSS } from '@measures/responsive'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import {
  FunctionComponent,
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled, { css } from 'styled-components'
import {
  LayoutTabsProps,
  ScrollableTabProps,
  ScrollableTabsProps,
} from './types'
import useScrollArrow from '@hooks/useScrollArrow'
import { scrollToEnd, scrollToStart } from '@components/LayoutTabs/utils'
import ArrowFader from '@components/LayoutTabs/ArrowFader'
import useViewportType from '@hooks/useViewport/useViewportType'
import useViewportDimensions from '@hooks/useViewport/useViewportDimensions'
import { ALLOWED_TAB_TYPES } from './utils'
import useIsInSportArticle from '@hooks/useIsInSportArticle'

const TabsWrapper = styled.div`
  ${({
    theme: {
      color: {
        tertiary: { grey200 },
      },
      measures: { stretchWidthOnMobile },
      spacing: { spacing16 },
    },
  }) => css`
    position: relative;
    background-color: ${grey200};
    padding: 0 ${spacing16};
    box-sizing: border-box;
    overflow: hidden;

    ${stretchWidthOnMobile()}
  `}
`

const TabsContent = styled.div``

const StyledTabContent = styled.div<{ isVisible: boolean }>`
  ${({ isVisible }) => css`
    ${!isVisible &&
    css`
      display: none;
    `}
  `}
`

const TabContent: FunctionComponent<{
  children: ReactNode
  isVisible: boolean
}> = ({ children, isVisible }) => {
  return (
    <StyledTabContent isVisible={isVisible}>
      <JSONRenderer>{children}</JSONRenderer>
    </StyledTabContent>
  )
}

const ScrollableContainer = styled.div<{ tabsNumber: number }>`
  ${({
    tabsNumber,
    theme: {
      spacing: { spacing12, spacing24 },
    },
  }) => css`
    height: 100%;
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    grid-gap: ${spacing24};
    ${mobileCSS(css`
      grid-gap: ${spacing12};
    `)}
    grid-template-columns: repeat(${tabsNumber}, max-content);
    overflow-x: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  `}
`

interface TabProps {
  isActive: boolean
  isSportArticle: boolean
}

const StyledButton = styled.button<TabProps>`
  ${({
    theme: {
      typography: {
        subheads: {
          medium1: { bundledCSS: subheadingMedium1CSS },
          medium2: { bundledCSS: subheadingMedium2CSS },
        },
      },
      color: {
        tertiary: { grey800 },
        primary: { primary01, blickRed },
        secondary: { green },
      },
      spacing: { spacing12 },
    },
    isActive,
    isSportArticle,
  }) => css`
    ${isActive ? subheadingMedium1CSS : subheadingMedium2CSS};
    color: ${isActive ? primary01 : grey800};
    overflow: hidden;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 0;
    padding: ${spacing12} 0;
    margin: 0;
    display: grid;
    align-content: center;
    justify-content: center;
    background-color: transparent;
    border-bottom: 3px solid transparent;
    ${isActive &&
    css`
      border-bottom-color: ${isSportArticle ? green : blickRed};
    `}
    ${!isActive &&
    css`
      cursor: pointer;
    `};
  `}
`
const ScrollableTab: FunctionComponent<ScrollableTabProps> = ({
  tab,
  tabIndex,
  isActive,
  updateActiveTab,
}) => {
  const onClick = useCallback<TrackingFnType>(
    () => ({
      event: 'layout_tabs',
      eventCategory: 'layout_tabs',
      eventAction: 'tab_click',
      eventLabel: `${tab}`,
    }),
    [tab]
  )
  const trackedOnClick = useTracking(onClick)
  const isSportArticle = useIsInSportArticle()
  return (
    <StyledButton
      key={tab}
      isActive={isActive}
      isSportArticle={isSportArticle}
      {...(isActive
        ? {}
        : {
            onClick: () => {
              updateActiveTab(tabIndex)
              trackedOnClick()
            },
          })}>
      {tab}
    </StyledButton>
  )
}

const ScrollableTabs: FunctionComponent<ScrollableTabsProps> = ({
  tabs,
  activeTabIndex,
  updateActiveTab,
}) => {
  const [showLeftArrow, setShowLeftArrow] = useState<boolean>(false)
  const [showRightArrow, setShowRightArrow] = useState<boolean>(false)
  const scrollableContainerEl = useRef<HTMLDivElement>(null)

  const viewportType = useViewportType()
  const { width: viewportWidth } = useViewportDimensions()

  useEffect(() => {
    if (scrollableContainerEl?.current) {
      const isOverflowing =
        scrollableContainerEl.current.clientWidth <
        scrollableContainerEl.current.scrollWidth
      setShowRightArrow(isOverflowing)
    }
  }, [viewportWidth, viewportType])

  useScrollArrow({
    elementRef: scrollableContainerEl,
    setShowLeftArrow,
    setShowRightArrow,
  })

  return (
    <TabsWrapper>
      {showLeftArrow && (
        <ArrowFader.Left
          scrollToStart={() =>
            scrollToStart({ containerRef: scrollableContainerEl })
          }
        />
      )}
      <ScrollableContainer tabsNumber={tabs.length} ref={scrollableContainerEl}>
        {tabs.map((tab, index) => {
          return (
            <ScrollableTab
              key={tab}
              tab={tab}
              tabIndex={index}
              isActive={activeTabIndex === index}
              updateActiveTab={updateActiveTab}
            />
          )
        })}
      </ScrollableContainer>
      {showRightArrow && (
        <ArrowFader.Right
          scrollToEnd={() =>
            scrollToEnd({ containerRef: scrollableContainerEl })
          }
        />
      )}
    </TabsWrapper>
  )
}

const LayoutTabs: FunctionComponent<LayoutTabsProps> = ({
  content,
  initialTabIndex,
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(initialTabIndex)

  const updateActiveTab = useCallback((index: any) => {
    setActiveTabIndex(index)
  }, [])

  const allowedContent = content.filter((content) =>
    Object.values(ALLOWED_TAB_TYPES).includes(content.type)
  )

  const tabsInfo = useMemo(
    () => allowedContent.map((allowedContent) => allowedContent.name),
    [allowedContent]
  )

  const tabsContent = useMemo(
    () => allowedContent.map((allowedContent) => allowedContent.content),
    [allowedContent]
  )

  const hasAllowedContent = allowedContent.length > 0

  if (!hasAllowedContent) {
    return null
  }

  return (
    <>
      <ScrollableTabs
        tabs={tabsInfo}
        activeTabIndex={activeTabIndex}
        updateActiveTab={updateActiveTab}
      />
      <TabsContent>
        {tabsContent.map((tabContent, index) => (
          <TabContent
            key={tabsInfo[index]}
            isVisible={index === activeTabIndex}>
            {tabContent}
          </TabContent>
        ))}
      </TabsContent>
    </>
  )
}

const MemoizedLayoutTabs = memo(LayoutTabs)

MemoizedLayoutTabs.displayName = 'MemoizedLayoutTabs'

const widget = {
  kind: ['layout', 'tabs2'],
  component: MemoizedLayoutTabs,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
