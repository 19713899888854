import usePageMetadata, { PageMetadata } from '@hooks/usePageMetadata'
import { APICommentingData } from '@widgets/Commenting/types'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { InfiniteData, useQueryClient } from '@tanstack/react-query'
import {
  HeadlineLeadAPIProps,
  isInHerotellingPageHeadlineLeadAPIProps,
} from './types'
import { stripHtml } from '@hooks/useTracking/utils'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import useArticleSchema from '@hooks/useArticleSchema'
import useIsInHerotellingPage from '@hooks/useIsInHerotellingPage'
import HerotellingHeadlineLead from '@components/HeadlineLead/HerotellingHeadlineLead'
import DefaultHeadlineLead from '@components/HeadlineLead/NormalHeadlineLead'

const HeadlineLead: FunctionComponent<HeadlineLeadAPIProps> = (props) => {
  const [commentsNumber, setCommentsNumber] = useState<number>(0)
  const unsubscribeFnRef = useRef<() => void>()
  const pageMetadata = usePageMetadata()
  const queryClient = useQueryClient()
  const queryCache = queryClient.getQueryCache()

  const articleClickShowCommentHandler = useCallback<TrackingFnType>(
    ({ pageMetadata }) => ({
      event: 'article_show_comments',
      eventCategory: 'article',
      eventAction: 'show_comments',
      eventLabel: `${pageMetadata.id}:${stripHtml(pageMetadata.title)}`,
    }),
    []
  )

  const handleArticleClickShowCommentTracking = useTracking(
    articleClickShowCommentHandler
  )

  const { id: articleId } = pageMetadata

  const updateCommentsNumberFromCache = useCallback<
    (articleId: PageMetadata['articleId']) => void
  >(
    (articleId) => {
      const data = queryClient.getQueryData<InfiniteData<APICommentingData>>([
        'commentingData',
        articleId,
      ])

      const numberOfComments = data?.pages?.[0]?.number_of_comments ?? 0

      setCommentsNumber(numberOfComments)
    },
    [queryClient]
  )

  const updateCommentsNumberData = useCallback<
    (articleId: PageMetadata['articleId']) => (args: any) => void
  >(
    (articleId) => (args: any) => {
      if (
        args.type === 'updated' &&
        args.query.queryKey?.[0] === 'commentingData' &&
        args.query.queryKey?.[1] === articleId
      ) {
        updateCommentsNumberFromCache(articleId)
      }
    },
    [updateCommentsNumberFromCache]
  )

  useEffect(() => {
    updateCommentsNumberFromCache(articleId)

    unsubscribeFnRef.current = queryCache.subscribe(
      updateCommentsNumberData(articleId)
    )

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [
    articleId,
    queryCache,
    updateCommentsNumberData,
    updateCommentsNumberFromCache,
  ])

  useArticleSchema({ description: props.lead })

  const extraProps = {
    commentsNumber,
    handleArticleClickShowCommentTracking,
  }

  if (
    isInHerotellingPageHeadlineLeadAPIProps(props, useIsInHerotellingPage())
  ) {
    return <HerotellingHeadlineLead {...{ ...props, ...extraProps }} />
  } else {
    return <DefaultHeadlineLead {...{ ...props, ...extraProps }} />
  }
}

const widget = {
  kind: ['widget', 'headline-lead'],
  component: HeadlineLead,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
