import { FunctionComponent, ReactNode } from 'react'
import styled, { DefaultTheme, css } from 'styled-components'
import { Link, Logo } from '@cook-types/cook'
import Blink from '@components/Blink'
import useHasInvertedColors from '@hooks/useHasInvertedColors'
import { ArrowsVisibilityType } from './Swiper'
import { desktopCSS } from '@measures/responsive'

export type SectionHeaderProps = {
  title?: string
  link?: Link
  isSubheader?: boolean
  arrowsVisibility?: ArrowsVisibilityType
  accentColor: string
  className?: string
  children?: ReactNode
  logo?: Logo
  ctaText?: string
}

export interface SubheaderStyledProps {
  isSubheader?: boolean
}

export interface InvertedColorsStyledProps {
  hasInvertedColors: boolean
}

export interface AccentColorStyledProps {
  accentColor: string
}

interface StyledSectionHeaderProps {
  hasLogo?: boolean
  hasChildren?: boolean
}

const StyledSectionHeader = styled.div<
  SubheaderStyledProps & AccentColorStyledProps & StyledSectionHeaderProps
>`
  ${({
    theme: {
      spacing: { spacing2, spacing4, spacing8, spacing12, spacing16 },
    },
    accentColor,
    isSubheader,
    hasLogo,
    hasChildren,
  }) => css`
    box-sizing: border-box;
    width: 100%;
    ${!isSubheader &&
    css`
      border-style: solid;
      border-width: 0;
      border-color: ${accentColor};
      border-top-width: ${spacing2};
      border-left-width: ${spacing2};
      padding-top: ${spacing8};
      padding-left: ${spacing12};

      ${desktopCSS(css`
        border-top-width: ${spacing4};
        border-left-width: ${spacing4};
        padding-top: ${spacing12};
        padding-left: ${spacing16};
      `)};
    `};
    display: grid;
    grid-template-columns: auto ${hasLogo ? '1fr' : ''} minmax(0, auto);
    ${hasLogo &&
    css`
      grid-gap: ${spacing16};
    `}
    align-items: center;

    ${hasChildren &&
    css`
      > :last-child {
        justify-self: flex-end;
      }
    `}
    > :first-child {
      justify-self: flex-start;
    }
  `}
`

export type CommonTitleCSSArgs = {
  theme: DefaultTheme
} & InvertedColorsStyledProps &
  SubheaderStyledProps &
  AccentColorStyledProps

const commonTitleCSS = ({
  theme: {
    spacing: { spacing8 },
    typography: {
      headings: {
        medium: { bundledCSS: mediumHeadingCSS },
        small: { bundledCSS: smallHeadingCSS },
      },
    },
    color: {
      primary: { primary01: primary01Color, primary02: primary02Color },
    },
    utils: { ellipsis },
  },
  hasInvertedColors,
  isSubheader,
  accentColor,
}: CommonTitleCSSArgs) => css`
  ${isSubheader
    ? css`
        ${smallHeadingCSS};
        min-height: 1.2em;
        border-style: solid;
        border-width: 0;
        border-left-width: 2px;
        border-left-color: ${accentColor};
        padding-left: ${spacing8};
      `
    : mediumHeadingCSS};
  color: ${hasInvertedColors ? primary02Color : primary01Color};
  text-transform: uppercase;
  ${ellipsis('100%', 2)}
`

const StyledPlainTitle = styled.div<
  InvertedColorsStyledProps & SubheaderStyledProps & AccentColorStyledProps
>`
  ${({ theme, hasInvertedColors, isSubheader, accentColor }) => css`
    ${commonTitleCSS({
      theme,
      hasInvertedColors,
      isSubheader,
      accentColor,
    })};
  `}
`

const StyledLinkTitle = styled(Blink)<
  InvertedColorsStyledProps & SubheaderStyledProps & AccentColorStyledProps
>`
  ${({ theme, hasInvertedColors, isSubheader, accentColor }) => css`
    display: block;
    appearance: none;
    text-decoration: none;
    ${commonTitleCSS({
      theme,
      hasInvertedColors,
      isSubheader,
      accentColor,
    })};

    &:hover {
      color: ${accentColor};
    }
  `}
`

const StyledChildrenWrapper = styled.div``

const StyledShowAllLink = styled(Blink)<
  InvertedColorsStyledProps & AccentColorStyledProps
>`
  ${({
    theme: {
      typography: {
        headings: {
          xxsmall: { bundledCSS: xxsmallHeadingCSS },
        },
      },
      color: {
        primary: { primary01: primary01Color, primary02: primary02Color },
      },
      spacing: { spacing8, spacing16 },
    },
    hasInvertedColors,
    accentColor,
  }) => css`
    text-decoration: none;
    ${xxsmallHeadingCSS};
    color: ${hasInvertedColors ? primary02Color : primary01Color};
    margin-left: ${spacing8};

    ${desktopCSS(css`
      margin-left: ${spacing16};
    `)}
    &:hover {
      color: ${accentColor};
    }
  `}
`

const StyledLogo = styled.img`
  height: fit-content;
  max-height: 32px;
  width: auto;
  max-width: 64px;
  object-fit: contain;
`

const StyledBlink = styled(Blink)`
  width: fit-content;
`
const SectionHeader: FunctionComponent<SectionHeaderProps> = (props) => {
  const {
    title,
    link,
    isSubheader,
    accentColor,
    className,
    logo,
    arrowsVisibility,
    ctaText,
  } = props
  const hasCustomComponent = !!props.children
  const hasInvertedColors = useHasInvertedColors()
  const hasLink = !!link
  const hasArrows =
    arrowsVisibility !== 'none' && arrowsVisibility !== undefined
  const showAllButtonEnabled =
    hasLink && !isSubheader && !hasArrows && !hasCustomComponent && !!ctaText

  if (!title) {
    return null
  }
  const hasLogo = !!logo
  const hasChildren = showAllButtonEnabled || hasCustomComponent

  return (
    <StyledSectionHeader
      isSubheader={isSubheader}
      accentColor={accentColor}
      className={className}
      hasChildren={hasChildren}
      hasLogo={hasLogo}>
      {!hasLink && (
        <StyledPlainTitle
          hasInvertedColors={hasInvertedColors}
          isSubheader={isSubheader}
          accentColor={accentColor}>
          {title}
        </StyledPlainTitle>
      )}
      {hasLink && (
        <StyledLinkTitle
          hasInvertedColors={hasInvertedColors}
          isSubheader={isSubheader}
          accentColor={accentColor}
          {...link}>
          {title}
        </StyledLinkTitle>
      )}
      {hasLogo && (
        <StyledBlink {...logo?.link}>
          <StyledLogo src={logo?.imageUrl} alt={logo?.name} loading="lazy" />
        </StyledBlink>
      )}
      {showAllButtonEnabled && (
        <StyledShowAllLink
          {...link}
          hasInvertedColors={hasInvertedColors}
          accentColor={accentColor}>
          {ctaText}
        </StyledShowAllLink>
      )}
      {hasCustomComponent && (
        <StyledChildrenWrapper>{props.children}</StyledChildrenWrapper>
      )}
    </StyledSectionHeader>
  )
}

export { StyledSectionHeader }

export default SectionHeader
