import translate from '@i18n'
import { desktopCSS, mobileCSS, tabletCSS } from '@measures/responsive'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { Title, TitleWrapper } from '../ChapterCard'
import blickTVLogo from '@assets/images/blick-tv-logo.svg'

const ChapterFillerWrapper = styled.div`
  height: 100%;

  display: flex;
  justify-content: flex-end;

  background: linear-gradient(270deg, #2e363d 0%, rgba(46, 54, 61, 0) 100%);

  border-radius: 0 4px 4px 0;
`

const ChapterCardWrapper = styled.div`
  width: 200px;
  height: 100%;

  ${mobileCSS(css`
    width: 148px;
  `)}
`

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  position: relative;

  width: 100%;
  height: 50%;

  ${tabletCSS(css`
    height: 112.5px;
  `)}

  ${desktopCSS(css`
    height: 112.5px;
  `)}
`

const StyledImg = styled.img`
  ${() => {
    const widthValue = 176
    const scaleValueDesktop = 0.8
    const scaleValueMobile = 0.6

    // because of 'transform' we need to use negative margins to align on the left
    const marginLeftDesktop = -((widthValue * (1 - scaleValueDesktop)) / 2)
    const marginLeftMobile = -((widthValue * (1 - scaleValueMobile)) / 2)

    return css`
      width: ${widthValue}px;
      height: 56px;
      transform: scale(${scaleValueDesktop});
      margin-left: ${marginLeftDesktop}px;
      // the same padding as for title
      padding: 12px 12px 0px 12px;

      ${mobileCSS(css`
        transform: scale(${scaleValueMobile});
        margin-left: ${marginLeftMobile}px;
        padding: 8px 8px 0px 8px;
      `)}
    `
  }}
`

const ChapterFiller: FunctionComponent = () => {
  return (
    <ChapterFillerWrapper>
      <ChapterCardWrapper>
        <ImageWrapper>
          <StyledImg src={blickTVLogo} alt="BlickTV Logo" loading="lazy" />
        </ImageWrapper>
        <TitleWrapper>
          <Title>{translate('blickTVStory.filler')}</Title>
        </TitleWrapper>
      </ChapterCardWrapper>
    </ChapterFillerWrapper>
  )
}

export default ChapterFiller
