import { memo } from 'react'
import styled, { css } from 'styled-components'

const StyledButton = styled.button<{ type?: 'primary' }>`
  ${({
    theme: {
      typography: {
        subheads: {
          small1: { bundledCSS: small1SubheadingCSS },
        },
      },
      color: {
        primary: { primary02, primary01 },
      },
    },
    type,
  }) => css`
    ${small1SubheadingCSS};
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 15px 24px;

    height: 48px;

    appearance: none;
    cursor: pointer;

    background: ${type === 'primary' ? primary02 : 'transparent'};
    border: 1px solid ${primary02};
    border-radius: 50px;

    color: ${type === 'primary' ? primary01 : primary02};
    text-transform: uppercase;

    & > svg {
      fill: ${type === 'primary' ? primary01 : primary02};
    }
  `}
`

export default memo(StyledButton)
