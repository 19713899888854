import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { ImageTextWrapperProps } from '@widgets/ArticleTextbox/types'
import { desktopCSS, tabletCSS, mobileCSS } from '@measures/responsive'

const StyledWrapper = styled.div`
  display: flex;
  ${desktopCSS(css`
    flex-direction: row;
  `)}
  ${tabletCSS(css`
    flex-direction: row;
  `)}
    ${mobileCSS(css`
    flex-direction: column-reverse;
  `)}
`

const PromoNoTextHalfWidth: FunctionComponent<ImageTextWrapperProps> = ({
  children,
}) => <StyledWrapper>{children}</StyledWrapper>

export default PromoNoTextHalfWidth
