import {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
} from 'react'
import { useQueryClient } from '@tanstack/react-query'
import styled, { css } from 'styled-components'
import {
  desktopCSS,
  desktopCenteredContentCSS,
  mobileCSS,
  tabletCSS,
} from '@measures/responsive'
import usePageMetadata from '@hooks/usePageMetadata'
import LayoutComponents from './Layouts'
import useIsContentForceCentered from '@hooks/useIsContentForceCentered'
import useIsErrorPage from '@hooks/useIsErrorPage'
import { printCSS } from '@utils/style'
import useIsHeaderSecondRowCollapsed, {
  IsHeaderSecondRowCollapsedType,
} from '@hooks/useIsHeaderSecondRowCollapsed'
import useIsImmersiveHeaderActive from '@hooks/useIsImmersiveHeaderActive'
import useGetPageIdentity from '@hooks/useGetPageIdentity'

const COLLAPSE_THRESHOLD = 20

const { HomeSection, FirstLevelSection, OtherSection } = LayoutComponents

interface StyledSecondRowProps {
  isImmersive: boolean
}

const StyledSecondRow = styled.div<StyledSecondRowProps>`
  ${({
    theme: {
      color: {
        tertiary: { grey400 },
        primary: { primary02 },
      },
    },
    isImmersive,
  }) => css`
    overflow: hidden;
    position: relative;
    height: 48px;
    top: 0px;
    display: grid;
    grid-template-columns: 994px;
    ${!isImmersive &&
    css`
      background-color: ${primary02};
    `}

    ${desktopCSS(css`
      border-top: 1px solid ${grey400};
    `)};

    ${printCSS(css`
      display: none;
    `)};

    ${desktopCenteredContentCSS(css`
      justify-content: center;
    `)};

    ${tabletCSS(css`
      grid-template-columns: 1fr;
    `)};

    ${mobileCSS(css`
      grid-template-columns: 1fr;
    `)};
  `}
`

const ContentWrapper = styled.div<{ isImmersiveHeaderActive: boolean }>`
  ${({
    theme: {
      spacing: { spacing16 },
      color: {
        primary: { primary02: primary02Color },
      },
    },
    isImmersiveHeaderActive,
  }) => css`
    background-color: ${primary02Color};
    transition: background-color 0.5s linear;

    display: grid;
    padding: 0 ${spacing16};

    ${desktopCSS(css`
      padding: 0 15px;
    `)}

    ${isImmersiveHeaderActive &&
    css`
      ${mobileCSS(css`
        background-color: transparent;
      `)}
      ${tabletCSS(css`
        background-color: transparent;
      `)}
    `}
  `}
`

const SecondRow: FunctionComponent = () => {
  const lastScrollPosition = useRef<number>(0)
  const secondRowRef = useRef<HTMLDivElement>(null)
  const isInitialTransitionRef = useRef<boolean>(false)
  const isContentForceCentered = useIsContentForceCentered()
  const isErrorPage = useIsErrorPage()
  const pageMetadata = usePageMetadata()
  const queryClient = useQueryClient()
  const isHeaderSecondRowCollapsed = useIsHeaderSecondRowCollapsed()
  const isImmersiveHeaderActive = useIsImmersiveHeaderActive()
  const { isImmersive } = useGetPageIdentity()

  let content: ReactNode = null

  const handleScroll = useCallback(() => {
    // Because of elastic scrolling on certain smartphones, we have to also check
    // negative values of scrollY
    const shouldBeExpanded =
      window.scrollY <= 0 ||
      window.scrollY + COLLAPSE_THRESHOLD < lastScrollPosition.current

    const shouldBeCollapsed =
      window.scrollY - COLLAPSE_THRESHOLD > lastScrollPosition.current

    // Set the last scroll position to the current scrollY, so that we can check
    // if the user scrolled up or down
    lastScrollPosition.current = window.scrollY

    const isInitialTransition =
      queryClient.getQueryData<IsHeaderSecondRowCollapsedType>([
        'is-header-second-row-collapsed',
      ]) === null
    if (isInitialTransition) {
      isInitialTransitionRef.current = true
    }

    if (shouldBeExpanded) {
      queryClient.setQueryData(['is-header-second-row-collapsed'], false)
      queryClient.invalidateQueries({
        queryKey: ['is-header-second-row-collapsed'],
      })
    } else if (shouldBeCollapsed) {
      queryClient.setQueryData(['is-header-second-row-collapsed'], true)
      queryClient.invalidateQueries({
        queryKey: ['is-header-second-row-collapsed'],
      })
    }
  }, [queryClient])

  useEffect(() => {
    if (secondRowRef.current) {
      const isInitialTransition =
        isInitialTransitionRef.current ||
        queryClient.getQueryData<IsHeaderSecondRowCollapsedType>([
          'is-header-second-row-collapsed',
        ]) === null

      secondRowRef.current.style.transition = isHeaderSecondRowCollapsed
        ? 'top 0.3s, height 0.3s'
        : isInitialTransition
          ? ''
          : 'top 0.3s'
      secondRowRef.current.style.top = isHeaderSecondRowCollapsed ? '-48px' : ''
      secondRowRef.current.style.height = isHeaderSecondRowCollapsed ? '0' : ''

      isInitialTransitionRef.current = false
    }
  }, [isHeaderSecondRowCollapsed, queryClient])

  useEffect(() => {
    if (secondRowRef.current) {
      if (isContentForceCentered) {
        secondRowRef.current.style.justifyContent = 'center'
      } else {
        secondRowRef.current.style.justifyContent = ''
      }
    }
  }, [isContentForceCentered])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  if (['home'].includes(pageMetadata.context) || isErrorPage) {
    content = <HomeSection />
  } else if (pageMetadata.breadcrumbs.length === 2) {
    content = <FirstLevelSection />
  } else if (pageMetadata.breadcrumbs.length > 2) {
    content = <OtherSection />
  }

  return (
    <StyledSecondRow ref={secondRowRef} isImmersive={isImmersive}>
      <ContentWrapper isImmersiveHeaderActive={isImmersiveHeaderActive}>
        {content}
      </ContentWrapper>
    </StyledSecondRow>
  )
}

export default SecondRow
