import usePageMetadata from '@hooks/usePageMetadata'
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  createAuthor,
  createAuthorForAuthorSchema,
  createItemList,
  getImageSet,
  getParsedAuthors,
} from '@utils/schema'
import config from '@config'
import { useQueryClient } from '@tanstack/react-query'
import SchemaHTMLElement from '@components/SchemaHTMLElement'

const {
  backend: { baseUrl },
  schema: { organizationId },
} = config

const AuthorSchema: FunctionComponent = () => {
  const pageMetadata = usePageMetadata()
  const {
    url,
    lastModifiedDate,
    publishedDate,
    teaser,
    authors,
    author,
    authorSchema,
  } = pageMetadata
  const queryClient = useQueryClient()
  const unsubscribeFnRef = useRef<() => void>()
  const queryCache = queryClient.getQueryCache()
  const [socialMediaLinks, setSocialMediaLinks] = useState<string[]>([])

  const updateAuthorSchema = useCallback(
    (args: any) => {
      if (
        args.query.queryKey[0] === 'authorSchemaData' &&
        args.type === 'updated'
      ) {
        const data = queryClient.getQueryData<{ links: string[] }>([
          'authorSchemaData',
        ])
        if (data?.links?.length) {
          setSocialMediaLinks(data.links)
        }
      }
    },
    [queryClient]
  )

  useEffect(() => {
    unsubscribeFnRef.current = queryCache.subscribe(updateAuthorSchema)

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [queryCache, updateAuthorSchema])

  const imageSet = useMemo(() => getImageSet(teaser?.image), [teaser?.image])
  const preparedAuthors = useMemo(() => getParsedAuthors(authors), [authors])
  return (
    <SchemaHTMLElement
      scriptKey="author"
      schemaObject={{
        '@type': 'CollectionPage',
        mainEntityOfPage: `${baseUrl}${url}`,
        url: `${baseUrl}${url}`,
        name: authors?.[0]?.name || author,
        dateModified: lastModifiedDate,
        datePublished: publishedDate,
        ...(authorSchema?.links?.length
          ? { mainEntity: createItemList(authorSchema.links) }
          : {}),
        author: !preparedAuthors.length
          ? createAuthor()
          : preparedAuthors.map((author) =>
              createAuthorForAuthorSchema({
                ...author,
                imageSet,
                description: authorSchema?.description,
                socialMediaLinks,
              })
            ),
        publisher: {
          '@id': organizationId,
        },
      }}
    />
  )
}

export default AuthorSchema
