import { SvgIconName } from '@components/SvgIcon/types'
import config from '@config'
import { useQuery } from '@tanstack/react-query'

interface WeatherCodeMap {
  [key: string]: SvgIconName
}

const weatherCodeMap: WeatherCodeMap = {
  su: 'weather-sun',
  su_fo: 'weather-sun-cloud',
  cu: 'weather-cloud',
  su_cl: 'weather-sun-cloud',
  cl: 'weather-cloud',
  su_cl_ra: 'weather-sun-cloud-rain',
  cl_ra: 'weather-cloud-rain',
  su_cl_sn: 'weather-sun-cloud-snow',
  cl_sn: 'weather-cloud-snow',
  su_cl_ra_st: 'weather-sun-cloud-rain-storm',
  cl_st: 'weather-cloud-storm',
  fo: 'weather-cloud',
}

const {
  weather: { baseUrl },
} = config

export interface Location {
  name: string
  country?: { name: string }
}

export interface WeatherData {
  weather?: {
    current?: {
      condition?: {
        code: keyof typeof weatherCodeMap
      }
      temperature?: {
        current: number
      }
    }
  }
  location?: Location
}

export interface NormalizedWeatherData {
  temperature: string
  iconName: SvgIconName | null
  location: string
}

export type UseWeatherType = () => NormalizedWeatherData | {}

const getWeatherDetailsLocation = (weatherData?: WeatherData) => {
  const city = weatherData?.location?.name
  const country = weatherData?.location?.country?.name

  return city && country ? `${city}, ${country}` : ''
}

const getWeatherData = async () => await (await fetch(baseUrl)).json()

const parseWeatherData = (payload: WeatherData) => {
  const iconCode = payload?.weather?.current?.condition?.code
  const iconName = iconCode ? weatherCodeMap[iconCode] || null : null
  return {
    location: getWeatherDetailsLocation(payload),
    iconName,
    temperature:
      payload?.weather?.current?.temperature?.current.toString() || '',
  }
}

const useWeather: UseWeatherType = () => {
  const { data: weatherData } = useQuery<
    WeatherData,
    unknown,
    NormalizedWeatherData
  >({
    queryKey: ['weather'],
    queryFn: getWeatherData,
    enabled: true,
    select: parseWeatherData,
  })

  return weatherData || {}
}

export { useWeather }
