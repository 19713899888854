import { ReactNode, FunctionComponent } from 'react'
import styled, { css, useTheme } from 'styled-components'
import { SocialButtonType } from '@widgets/SocialShare/types'
import SvgIcon from '@components/SvgIcon'
import config from '@config'
import { SvgIconName } from '@components/SvgIcon/types'
import useIsDarkModeInSocialButton from '@hooks/useIsDarkModeInSocialButton'

const {
  socialembeds: {
    share: { types: socialEmbedTypes },
  },
} = config

interface ButtonProps {
  type: SocialButtonType
  onClick: () => void
  className?: string
  children?: ReactNode
  isPopoverOpen?: boolean
}

type StyledButtonProps = Pick<ButtonProps, 'isPopoverOpen'> & {
  isDarkMode?: boolean
}

const Wrapper = styled.button<StyledButtonProps>`
  ${({
    theme: {
      color: {
        tertiary: { grey300, grey800 },
      },
    },
    isDarkMode,
  }) => {
    return css`
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      border: none;
      cursor: pointer;
      padding: 0;
      line-height: 0;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      user-select: none;
      background-color: ${isDarkMode ? grey800 : grey300};
    `
  }}
`
const OuterWrapper = styled.div<StyledButtonProps>`
  ${({
    theme: {
      color: {
        primary: { blickRed, primary01 },
        tertiary: { grey400, grey600, grey800 },
      },
      spacing: { spacing16 },
      typography: {
        bodycopy: {
          small2: { bundledCSS: bodyCopySmall2CSS },
        },
      },
    },
    isDarkMode,
    isPopoverOpen,
  }) => css`
    display: flex;
    cursor: pointer;
    align-items: center;
    gap: ${spacing16};

    ${bodyCopySmall2CSS}

    color: ${primary01};

    &:hover {
      color: ${grey800};

      button {
        background-color: ${isDarkMode ? grey600 : grey400};
      }
    }

    ${isPopoverOpen &&
    css`
      &:hover {
        button {
          background-color: ${isDarkMode ? grey600 : grey400};

          svg {
            color: ${primary01};
            fill: ${primary01};
          }
        }
      }
      button {
        background-color: ${blickRed};
      }
    `}
  `}
`

const Button: FunctionComponent<ButtonProps> = ({
  type,
  onClick,
  className,
  children,
  isPopoverOpen,
}) => {
  const theme = useTheme()
  const isDarkMode = useIsDarkModeInSocialButton()
  const iconName = socialEmbedTypes[type]?.iconName as SvgIconName
  const iconColor =
    isDarkMode || isPopoverOpen
      ? theme.color.primary.primary02
      : theme.color.primary.primary01
  return (
    <OuterWrapper
      isPopoverOpen={isPopoverOpen}
      isDarkMode={isDarkMode}
      onClick={onClick}
      aria-label={type}>
      <Wrapper isDarkMode={isDarkMode} className={className}>
        <SvgIcon iconName={iconName} size={20} color={iconColor} />
      </Wrapper>
      {children}
    </OuterWrapper>
  )
}

export default Button
