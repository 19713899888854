import color from './color'
import typography from './typography'
import spacing from './spacing'
import animation from './animation'
import colors from './colors'
import fonts from './fonts'
import measures from './measures'
import { ThemeType } from './types'
import zIndex from './zIndex'
import utils from './utils'

const defaultTheme = {
  color,
  typography,
  spacing,
  animation,
  colors,
  fonts,
  measures,
  zIndex,
  utils,
} as const satisfies ThemeType

export default defaultTheme
