import { FunctionComponent, useEffect } from 'react'
import config from '@config'
import { useQueryClient } from '@tanstack/react-query'
import { getLocalStorageItem, setLocalStorageItem } from '@utils/localStorage'
import { captureException, captureExceptionWithUser } from '@utils/sentry'
import { User } from '@hooks/useUser'
import {
  FetchAuthDataFn,
  FetchSubscriptionDataFn,
  SubscriptionAPIValidResponse,
} from './types'
import {
  getSubscriptionDataToTrack,
  notSpecifiedPayload,
  notSubscribedPayload,
} from './utils'

const {
  ads: {
    admeira: { storageKey: admeiraStorageKey },
  },
  auth: { authURL, metadataPath },
  subscriptions: { subscriptionsUrl },
} = config

const fetchAuthData: FetchAuthDataFn = async () => {
  try {
    const response = await fetch(`${authURL}${metadataPath}`, {
      credentials: 'include',
    })

    if (!response.ok && response.status !== 401) {
      captureException(`HTTP ${response.status} error fetching user`)
      return null
    }

    const userMetadata: User =
      ((await response.json()) as { metadata?: User })?.metadata ?? null

    if (!!userMetadata?.admeira_id) {
      setLocalStorageItem(admeiraStorageKey, {
        ...getLocalStorageItem<{ admeiraId: string }>(admeiraStorageKey),
        admeiraId: userMetadata.admeira_id,
      })
    }

    return userMetadata
  } catch (err) {
    return null
  }
}

const fetchSubscriptionData: FetchSubscriptionDataFn = async (queryClient) => {
  const userData = queryClient.getQueryData<User>(['user'])

  // In case of error when we fetch user metadata, we don't want to end up with inconsistent state
  // where user is undefined but plus content is shown, so we should avoid calling the subscription checker in this case
  if (!userData) {
    return notSubscribedPayload
  }

  try {
    const response = await fetch(subscriptionsUrl, {
      credentials: 'include',
    })

    if (!response.ok) {
      if (response.status !== 401) {
        captureExceptionWithUser(
          `[SUB. CHECKER ERROR] GET subscriptions returned a HTTP error: ${response.status}`,
          queryClient
        )
      }
      // In case of error on the subscription checker, the user should be able to see the "plus" content
      return notSpecifiedPayload
    }

    const subscriptionData = (
      (await response.json()) as SubscriptionAPIValidResponse
    ).subscriptions

    return getSubscriptionDataToTrack(subscriptionData)
  } catch (err: any) {
    captureExceptionWithUser(
      `[SUB. CHECKER ERROR] GET subscriptions returned an error: ${err}`,
      queryClient
    )
    // In case of error on the subscription checker, the user should be able to see the "plus" content
    return notSpecifiedPayload
  }
}

const Auth: FunctionComponent = () => {
  const queryClient = useQueryClient()

  useEffect(() => {
    const isAuthResolved = queryClient.getQueryData<boolean>(['auth-resolved'])
    if (!isAuthResolved) {
      Promise.all([
        queryClient.prefetchQuery({
          queryKey: ['user'],
          queryFn: fetchAuthData,
        }),
        queryClient.prefetchQuery({
          queryKey: ['auth-resolved'],
          queryFn: async () => true,
        }),
      ])
        .then(() =>
          queryClient.prefetchQuery({
            queryKey: ['subscription-data'],
            queryFn: () => fetchSubscriptionData(queryClient),
          })
        )
        .then(() => {
          queryClient.invalidateQueries({ queryKey: ['auth-resolved'] })
          queryClient.invalidateQueries({ queryKey: ['subscription-data'] })
          queryClient.invalidateQueries({ queryKey: ['user'] })
        })
    }
  }, [queryClient])

  return null
}

export default Auth
