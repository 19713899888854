import useExecuteOnClientNavigation, {
  UseExecuteOnClientNavigation,
} from '@hooks/useExecuteOnClientNavigation'
import usePageMetadata, { PageMetadata } from '@hooks/usePageMetadata'
import { calculateSSOTrackingSource } from '@utils/sso'
import useSubscriptionStatus from '@hooks/useSubscriptionStatus'
import useUser from '@hooks/useUser'
import { isVideoOnlyArticle } from '@utils/cook'
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { QueryClient, useQueryClient } from '@tanstack/react-query'
import { initializePiano, updateExperience } from './skeleton'
import usePianoEvents from './usePianoEvents'
import useIsPianoDebugModeActive from '@hooks/useIsPianoDebugModeActive'
import useCMPCookieCategories from '@hooks/useCMPCookieCategories'
import config from '@config'
import Script from 'next/script'
import { PianoEvents } from './types'
import { getURLParam } from '@utils/urlParams'
import useABTestValue from '@hooks/useABTestValue'
import useBlickPlusSkeleton from '@hooks/useBlickPlusSkeleton'
import useCMPHasUserInteracted from '@hooks/useCMPHasUserInteracted'

const {
  backend: { hotModuleReloading },
  piano: { pianoSdkUrl, cxenseSiteId, cXenseScriptUrl },
  plusLinkShare: { linkConsumptionApiUrl },
} = config

type InitializePianoAsyncFn = ({
  queryClient,
  articleId,
  ssoSource,
  isSubscribed,
  isPlus,
  isLoggedIn,
  isDebugMode,
  isVideoOnly,
  isPlusSharingLive,
  pianoEvents,
}: {
  queryClient: QueryClient
  articleId: PageMetadata['id']
  ssoSource: string
  isSubscribed: boolean
  isPlus: boolean
  isLoggedIn: boolean
  isDebugMode: boolean
  isVideoOnly: boolean
  isPlusSharingLive: boolean
  pianoEvents: PianoEvents
}) => Promise<void>

const initializePianoAsync: InitializePianoAsyncFn = async ({
  queryClient,
  articleId,
  ssoSource,
  isSubscribed,
  isPlus,
  isLoggedIn,
  isDebugMode,
  isVideoOnly,
  isPlusSharingLive,
  pianoEvents,
}) => {
  initializePiano({
    isLoggedIn,
    currentUrl: `${window.location.href}`,
    ssoSource,
    pianoEvents,
    isPlus,
    isSubscribed,
    isVideoOnly,
    isGifted:
      isPlusSharingLive &&
      !isSubscribed &&
      !!articleId &&
      (await fetchIsGifted(articleId, queryClient)),
    isDebugMode,
    queryClient,
  })

  queryClient.setQueryData(['isPianoSDKLoaded'], true)
  queryClient.invalidateQueries({
    queryKey: ['isPianoSDKLoaded'],
    exact: true,
  })
}

const fetchIsGifted = async (
  articleId: PageMetadata['id'],
  queryClient: QueryClient
) => {
  const sharingPlusQueryParam = getURLParam('sharingPlus', queryClient)
  if (sharingPlusQueryParam) {
    const response = await fetch(linkConsumptionApiUrl, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        articleId,
        sharingCode: sharingPlusQueryParam,
      }),
    })
    if (response.ok) {
      queryClient.setQueryData(['isGiftedArticle'], true)
      queryClient.invalidateQueries({
        queryKey: ['isGiftedArticle'],
        exact: true,
      })
      return true
    }
  }
  return false
}

const PianoManager: FunctionComponent = () => {
  const queryClient = useQueryClient()

  const userData = useUser()

  const pianoEvents = usePianoEvents()

  const { subscriptionStatus } = useSubscriptionStatus()

  const hasUserInteracted = useCMPHasUserInteracted()

  const { teaser, id: articleId } = usePageMetadata()

  const isVideoOnly = isVideoOnlyArticle(teaser?.targetContentType)
  const isDebugMode = useIsPianoDebugModeActive()

  // TODO Cleanup after go live: remove the AB test check and use the skeleton value only, or remove isPlusSharingLive completely.
  const { isPlusSharingLive: isPlusSharingLiveSkeleton } =
    useBlickPlusSkeleton()
  const isPlusSharingABTestEnabled =
    useABTestValue('blickPlusLinkSharing') === 'active'
  const isPlusSharingLive =
    isPlusSharingLiveSkeleton || isPlusSharingABTestEnabled

  const {
    C0002: arePerformanceCookiesEnabled,
    C0003: areFunctionalCookiesEnabled,
    C0004: areTargetingCookiesEnabled,
  } = useCMPCookieCategories('someCategoriesChanged', [
    'C0002',
    'C0003',
    'C0004',
  ])

  const [cxenseSetupDone, setCxenseSetupDone] = useState(false)
  const [cxenseFetched, setCxenseFetched] = useState(false)
  const [pianoSdkDownloaded, setPianoSdkDownloaded] = useState(false)

  const initialCxenseSetupHasRun = useRef(false)

  const getSSOSource = useCallback(
    () =>
      calculateSSOTrackingSource(
        {
          prefix: 'PIA',
          omitPublication: true,
        },
        queryClient
      ),
    [queryClient]
  )

  const updatePianoExperience = useCallback<
    Parameters<UseExecuteOnClientNavigation>[0]
  >(
    (metadata) => {
      const { teaser } = metadata
      const isVideoOnly = isVideoOnlyArticle(teaser?.targetContentType)

      // On every route change we have to close templates that were opened
      // by Piano, so that they don't stay open when going back
      // This has to be done on SPA's according to Piano
      window?.tp?.offer?.close?.()

      // Update the piano experience every time the page metadata changes
      updateExperience({
        currentUrl: `${window.location.href}`,
        ssoSource: getSSOSource(),
        isPlus: !!teaser?.isPlus,
        isVideoOnly,
        queryClient,
      })
    },
    [getSSOSource, queryClient]
  )

  useEffect(() => {
    if (
      [
        arePerformanceCookiesEnabled,
        areFunctionalCookiesEnabled,
        areTargetingCookiesEnabled,
      ].every((category) => category !== undefined)
    ) {
      if (!initialCxenseSetupHasRun.current) {
        var cX = window.cX || { options: { consent: true } }
        cX.callQueue = cX.callQueue || []
        cX.CCE = cX.CCE || {}
        cX.CCE.callQueue = cX.CCE.callQueue || []
        cX.callQueue.push(['setSiteId', cxenseSiteId])
        initialCxenseSetupHasRun.current = true
      }

      window.cX?.callQueue.push([
        'setConsent',
        {
          pv: arePerformanceCookiesEnabled,
          segment: arePerformanceCookiesEnabled,
          ad: areTargetingCookiesEnabled,
          recs: areFunctionalCookiesEnabled,
        },
      ])
      setCxenseSetupDone(true)
    }
  }, [
    arePerformanceCookiesEnabled,
    areFunctionalCookiesEnabled,
    areTargetingCookiesEnabled,
  ])

  useEffect(() => {
    // Initialize Piano as soon as the Piano SDK is loaded
    if (
      pianoSdkDownloaded &&
      !queryClient.getQueryData(['isPianoSDKLoaded']) &&
      subscriptionStatus
    ) {
      const ssoSource = getSSOSource()
      const isSubscribed = subscriptionStatus === 'subscribed'
      const isPlus = !!teaser?.isPlus
      const isLoggedIn = !!userData

      initializePianoAsync({
        queryClient,
        articleId,
        ssoSource,
        isSubscribed,
        isPlus,
        isLoggedIn,
        isDebugMode,
        isVideoOnly,
        isPlusSharingLive,
        pianoEvents,
      })
    }
  }, [
    userData,
    subscriptionStatus,
    pianoEvents,
    teaser?.isPlus,
    isVideoOnly,
    isPlusSharingLive,
    isDebugMode,
    queryClient,
    pianoSdkDownloaded,
    getSSOSource,
    articleId,
  ])

  const onHandlePostMessage = useCallback((event: MessageEvent) => {
    const { data } = event

    if (!data) {
      return
    }

    const { blick_event_type, scrollPosition } = data

    if (blick_event_type) {
      // This is used for the Piano checkout to scroll the user to the expanding payment
      // container that opens when clicking on one of the payment options
      if (blick_event_type === 'PIANO_WEB_CHECKOUT_SCROLL_BOTTOM') {
        const tpModalList = Array.from(document.querySelectorAll('.tp-modal'))

        // Unfortunately Piano is injecting the .tp-modal div every time a checkout is started and
        // doesn't clean up the remaining ones. To make the scrolling work every time, we need to
        // get the last of all the .tp-modal divs
        tpModalList?.[tpModalList.length - 1]?.scrollTo({
          top: scrollPosition,
          behavior: 'smooth',
        })
      }
    }
  }, [])

  useEffect(() => {
    window.addEventListener('message', onHandlePostMessage)

    return () => {
      window.removeEventListener('message', onHandlePostMessage)
    }
  }, [onHandlePostMessage])

  useExecuteOnClientNavigation(updatePianoExperience)

  const onCxenseDownloaded = useCallback(() => {
    setCxenseFetched(true)
  }, [])

  const onPianoSdkDownloaded = useCallback(() => {
    setPianoSdkDownloaded(true)
  }, [])

  return hasUserInteracted ? (
    <>
      {!hotModuleReloading && (
        <Script
          id="piano-ad-block"
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s){try{d.cookie='__adblocker=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';var setNptTechAdblockerCookie=function(adblocker){try{var date=new Date();date.setTime(date.getTime()+60*5*1000);d.cookie='__adblocker='+(adblocker?'true':'false')+'; expires='+date.toUTCString()+'; path=/'}catch(err){}};if({}.isAdDisabled){setNptTechAdblockerCookie(true); return};var script=d.createElement('script');script.setAttribute('defer',true);script.setAttribute('src','https://www.npttech.com/advertising.js');script.onerror=function(){if(typeof setNptTechAdblockerCookie==="function"){setNptTechAdblockerCookie(true)}};d.getElementsByTagName('head')[0].appendChild(script)}catch(err){}})(window,document,'script');`,
          }}
        />
      )}
      {cxenseSetupDone && (
        <Script
          id="cxense-script"
          strategy="afterInteractive"
          defer={true}
          src={cXenseScriptUrl}
          onError={onCxenseDownloaded}
          onLoad={onCxenseDownloaded}
        />
      )}
      {cxenseFetched && (
        <Script
          id="piano-script"
          strategy="afterInteractive"
          defer={true}
          src={pianoSdkUrl}
          onLoad={onPianoSdkDownloaded}
        />
      )}
    </>
  ) : null
}

export default PianoManager
