export type ColorsType = {
  brandColors: {
    brand_5: string
    brand_10: string
    brand_20: string
    brand_30: string
    brand_40: string
    brand_50: string
    brand_55: string
    brand_60: string
    brand_65: string
    brand_70: string
    brand_80: string
    brand_90: string
    brand_100: string
  }
  sport: {
    sport_5: string
    sport_10: string
    sport_20: string
    sport_30: string
    sport_40: string
    sport_50: string
    sport_55: string
    sport_60: string
    sport_65: string
    sport_70: string
    sport_80: string
    sport_90: string
    sport_100: string
  }
  blicktvGrey: {
    btvGrey_5: string
    btvGrey_10: string
    btvGrey_20: string
    btvGrey_30: string
    btvGrey_40: string
    btvGrey_45: string
    btvGrey_50: string
    btvGrey_55: string
    btvGrey_60: string
    btvGrey_70: string
    btvGrey_80: string
    btvGrey_90: string
    btvGrey_100: string
  }

  blicktvGradients: {
    radialRed: string
  }

  white: string

  grey: {
    grey_10: string
    grey_15: string
    grey_20: string
    grey_30: string
    grey_50: string
    grey_60: string
    grey_70: string
    grey_75: string
    grey_80: string
    grey_90: string
    grey_100: string
    /**
     * NOTE: Added suffix based on figma color names
     * @see https://www.figma.com/file/ponOdGVZ2BqW7g47Rxwwli/%5BEXT%5D-Blick-Dev-Handoff?node-id=18%3A6404&mode=dev
     */
    grey_300: string
    grey_800: string
    grey_900: string
  }

  food: {
    food_50: string
  }

  page: {
    inverted: string
  }

  /**
   * NOTE: Added color names based on figma
   * @see https://www.figma.com/file/ponOdGVZ2BqW7g47Rxwwli/%5BEXT%5D-Blick-Dev-Handoff?node-id=18%3A6397&mode=dev
   */
  primary: {
    primary_01: string
    blick_red: string
  }

  secondary: {
    error: string
    focus: string
    star_yellow: string
  }

  lotto: {
    lottoJackpot: string
    lottoLucky: string
  }
}

const colors = {
  brandColors: {
    brand_5: '#FFF3F2',
    brand_10: '#FED1CD',
    brand_20: '#FDADA8',
    brand_30: '#FC8985',
    brand_40: '#FB6561',
    brand_50: '#EF2D2B',
    brand_55: '#E20000' /* brand*/,
    brand_60: '#C00500',
    brand_65: '#9E0900',
    brand_70: '#7C0A00',
    brand_80: '#5B0900',
    brand_90: '#390700',
    brand_100: '#170400',
  },
  sport: {
    sport_5: '#F5FFFA',
    sport_10: '#CCF3D9',
    sport_20: '#A7E7B0',
    sport_30: '#87DB85',
    sport_40: '#7BCF65',
    sport_50: '#68B83A',
    sport_55: '#62A01A' /* brand*/,
    sport_60: '#3B8910',
    sport_65: '#1D710B',
    sport_70: '#085A08',
    sport_80: '#064311',
    sport_90: '#052C13',
    sport_100: '#03140C',
  },
  blicktvGrey: {
    btvGrey_5: '#F9FBFF',
    btvGrey_10: '#F3F8FE',
    btvGrey_20: '#E7F0FE',
    btvGrey_30: '#D2E0F2',
    btvGrey_40: '#B2C1D4',
    btvGrey_45: '#8796A6',
    btvGrey_50: '#616C77',
    btvGrey_55: '#46505A',
    btvGrey_60: '#2E363D' /* darkGrey*/,
    btvGrey_70: '#1C1E21',
    btvGrey_80: '#101012',
    btvGrey_90: '#080808',
    btvGrey_100: '#000000',
  },

  blicktvGradients: {
    radialRed:
      'radial-gradient(100% 100% at 100% 100%, #98021C 0%, #1C1E21 100%)',
  },

  white: '#FFFFFF',

  grey: {
    grey_10: '#F8F8F8',
    grey_15: '#F3F3F3',
    grey_20: '#EEEEEE',
    grey_30: '#E0E0E0',
    grey_50: '#C8C8C8',
    grey_60: '#A9A9A9',
    grey_70: '#8E8E90',
    grey_75: '#767676',
    grey_80: '#545454',
    grey_90: '#3D3D3D',
    grey_100: '#1F1F1F',
    /**
     * NOTE: Added suffix based on figma color names
     * @see https://www.figma.com/file/ponOdGVZ2BqW7g47Rxwwli/%5BEXT%5D-Blick-Dev-Handoff?node-id=18%3A6404&mode=dev
     */
    grey_300: '#E3E3E3',
    grey_800: '#5C5C5C',
    grey_900: '#494949',
  },

  food: {
    food_50: '#FFBB16',
  },

  page: {
    inverted: '#000000',
  },

  /**
   * NOTE: Added color names based on figma
   * @see https://www.figma.com/file/ponOdGVZ2BqW7g47Rxwwli/%5BEXT%5D-Blick-Dev-Handoff?node-id=18%3A6397&mode=dev
   */
  primary: {
    primary_01: '#111111',
    blick_red: '#E3051B',
  },

  secondary: {
    error: '#F8E71C',
    focus: '#2460C5',
    star_yellow: '#FFCE00',
  },

  lotto: {
    lottoJackpot: '#D6081C',
    lottoLucky: '#F8E71C',
  },
} as const satisfies ColorsType

export default colors
